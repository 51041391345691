import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListCheck } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IToggleCheckProps } from './ToggleCheck.props';
import styles from './ToggleCheck.module.scss';

const ToggleCheck = ({ isAvailable, highlightMultipleQuestions, setHighlightMultipleQuestions }: IToggleCheckProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<button
			className={cn({
				[styles.active]: highlightMultipleQuestions,
			})}
			onClick={() => setHighlightMultipleQuestions(prev => !prev)}
			disabled={!isAvailable}
			title={translate(highlightMultipleQuestions ? 'buttonTitle_cancelSelection' : 'buttonTitle_selectQuestions')}
		>
			<FontAwesomeIcon
				icon={faListCheck}
				size="xl"
				color={backgroundColor}
			/>
		</button>
	);
};

export default ToggleCheck;
