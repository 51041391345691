import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import useTranslate from '../../hooks/useTranslate';
import { IResizeBlockProps } from './ResizeBlock.props';
import styles from './ResizeBlock.module.scss';

const ResizeBlock = ({ fullHeightFormFlg, setFullHeightFormFlg }: IResizeBlockProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div className={cn(styles.blockTagWrapper, {
			[styles.blockTagWrapperFull]: fullHeightFormFlg
		})}>
			<div
				className={cn(styles.blockTagInner, {
					[styles.blockTagInnerFull]: fullHeightFormFlg

				})}
				onClick={() => setFullHeightFormFlg(prev => !prev)}
				title={translate('buttonTitle_expand')}
			>
				{fullHeightFormFlg ?
					<FontAwesomeIcon icon={faChevronDown} size='sm' />
					:
					<FontAwesomeIcon icon={faChevronUp} size='sm' />
				}
			</div>
		</div>
	);
};

export default ResizeBlock;
