import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormMovingQuestions from '../../../Forms/FormMovingQuestions/FormMovingQuestions';
import { IMovingQuestionsProps } from './MovingQuestions.props';

const MovingQuestions = ({ isAvailable, selectedListQuestionIds, setShowNotification }: IMovingQuestionsProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы перемещения вопросов

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_moveQuestions')}>
				<FontAwesomeIcon icon={faCopy} size="xl" color={backgroundColor} />
			</button>

			{showModal &&
				<FormMovingQuestions
					showModal={showModal}
					setShowModal={setShowModal}
					selectedListQuestionIds={selectedListQuestionIds}
					setShowNotification={setShowNotification}
				/>
			}
		</>
	);
};

export default MovingQuestions;
