import { useAppSelector } from '../../store/hooks';
import { selectSummare } from '../../store/qasSummareSlice';
import useTranslate from '../../hooks/useTranslate';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import styles from './Protocol.module.scss';

const Protocol = (): JSX.Element => {
	const summare = useAppSelector(selectSummare); // store - протокол

	const translate = useTranslate(); // hook для перевода текста

	return (
		<div className={styles.protocol}>
			{/* нет данных */}
			{(summare.status === RequestStatus.IDLE && !summare.data) &&
				<div className={styles.noData}>
					{translate('title_createPprotocol')}
				</div>
			}

			{/* ошибка создания протокола */}
			{(summare.status === RequestStatus.FAILED || summare.error === ResponseStatus.FAILED) &&
				<div className={styles.failed}>
					{translate(summare.message || 'title_loadFailed')}
				</div>
			}

			{/* создание протокола */}
			{summare.status === RequestStatus.LOADING &&
				<div className={styles.loading}>
					<ProgressCircle title={translate('spinnerTitle_creatingProtocol')} />
				</div>
			}

			{(summare.status === RequestStatus.IDLE && summare.data) &&
				<div>{summare.data}</div>
			}
		</div>
	);
};

export default Protocol;
