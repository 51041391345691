import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import cn from 'classnames';
import useTranslate from '../../../../hooks/useTranslate';
import { colorPrimary } from '../../../../constants/colors';
import TableCustomPagination from '../../TableCustomPagination/TableCustomPagination';
import { ICustomFooterProps } from './CustomFooter.props';
import styles from './CustomFooter.module.scss';

const CustomFooter = ({ table, tableContainerRef }: ICustomFooterProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<div className={cn(styles.footer, {
			[styles.footerBoxShadow]: tableContainerRef.current && tableContainerRef.current.scrollHeight - tableContainerRef.current.offsetHeight > Math.ceil(tableContainerRef.current.scrollTop) + 1
		})}>
			<Box sx={{ display: 'flex', gap: '8px', alignItems: 'center', justifyContent: 'flex-end' }}>
				<div className={styles.pagination}>
					<FormControl sx={{ width: 120, '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
						<InputLabel id="demo-simple-select-label" sx={{ fontSize: 13 }}>{translate('select_quantityRows')}</InputLabel>
						<Select
							labelId="demo-simple-select-label"
							id="simple-select"
							value={table.getState().pagination.pageSize}
							label={translate('select_quantityRows')}
							onChange={e => table.setPageSize(Number(e.target.value))}
							style={{ fontSize: 13, height: 36, color: colorPrimary, textAlign: 'left' }}
						>
							{[10, 20, 50, 100, 500, 1000].map(value => <MenuItem key={value} value={value} sx={{ fontSize: 13, color: colorPrimary }}>{value}</MenuItem>)}
						</Select>
					</FormControl>
					<TableCustomPagination table={table} tableContainerRef={tableContainerRef} />
				</div>
			</Box>
		</div>
	);
};

export default CustomFooter;
