import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearReplaceInDocument, replaceTextInAllDocuments, selectCategoriesList, selectReplaceInDocument } from '../../../store/qasSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormProcessingDocumentsProps } from './FormProcessingDocuments.props';
import styles from './FormProcessingDocuments.module.scss';

const FormProcessingDocuments = ({ showModal, setShowModal, documentUpdateHandler }: IFormProcessingDocumentsProps): JSX.Element => {
	const [selectCategoryId, setSelectCategoryId] = useState<string>(''); // id категории
	const [inputSearch, setInputSearch] = useState<string>(''); // текст для поиска
	const [inputReplacement, setInputReplacement] = useState<string>(''); // текст для замены

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const replaceInDocument = useAppSelector(selectReplaceInDocument); // store - данные замены текста в документах

	const translate = useTranslate(); // hook для перевода текста

	// следим за данными замены текста
	useEffect(() => {
		// если заменили текст
		if (typeof replaceInDocument.replacements === 'number' && replaceInDocument.replacements > 0) {
			documentUpdateHandler(); // обновляем текущий документ
		}
	}, [replaceInDocument.replacements]);

	// обработчик массовой обработки документов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		dispatch(clearReplaceInDocument()); // очистка данных замены текста в документах
		dispatch(replaceTextInAllDocuments({ categoryId: selectCategoryId, search: inputSearch, replace: inputReplacement })); // замена текста во всех документах
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет отправка - запрещаем покидать форму
		if (replaceInDocument.status === RequestStatus.LOADING) return;
		dispatch(clearReplaceInDocument()); // очистка данных замены ответов
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_processingDocuments' close={handleClose}>
			<form onSubmit={submitHandler}>
				{/* категория */}
				<FormControl fullWidth margin='dense' sx={{
					'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
					'.MuiInputBase-input': { padding: '8px 14px' },
				}} required>
					<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
					<Select
						labelId="category-label"
						id="category"
						label={translate('select_category')}
						disabled={replaceInDocument.status === RequestStatus.LOADING}
						value={selectCategoryId}
						onChange={(e) => setSelectCategoryId(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='' sx={{ fontSize: 13 }}>{translate('selectItem_notSelected')}</MenuItem>
						{categoriesList.data.map((category) => (
							<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
						))}
					</Select>
				</FormControl>

				{/* искомый текст */}
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						label={translate("input_textToSearchInDocuments")}
						variant="outlined"
						value={inputSearch}
						onChange={(e) => setInputSearch(e.target.value)}
						disabled={replaceInDocument.status === RequestStatus.LOADING}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				{/* текст для замены */}
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						label={translate("input_replacement")}
						variant="outlined"
						value={inputReplacement}
						onChange={(e) => setInputReplacement(e.target.value)}
						disabled={replaceInDocument.status === RequestStatus.LOADING}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				{replaceInDocument.replacements !== null &&
					<div className={styles.blockInfo}>{translate('title_replacedOccurrences')}: {replaceInDocument.replacements}</div>
				}
				{(replaceInDocument.status === RequestStatus.FAILED || replaceInDocument.error === ResponseStatus.FAILED) &&
					<div className={styles.blockInfoError}>{translate(replaceInDocument.message || 'title_errorOccurred')}</div>
				}

				<div className={styles.block}>
					{/* запуск */}
					<FormControl fullWidth margin='dense'>
						<Button
							variant="outlined"
							type="submit"
							sx={{ fontSize: 11 }}
							disabled={replaceInDocument.status === RequestStatus.LOADING}
						>
							{translate('button_start')}
							{replaceInDocument.status === RequestStatus.LOADING &&
								<ProgressCircle isBtnDisabled />
							}
						</Button>
					</FormControl>

					{/* закрыть */}
					<FormControl fullWidth margin='dense'>
						<Button
							variant="outlined"
							type="button"
							sx={{ fontSize: 11 }}
							disabled={replaceInDocument.status === RequestStatus.LOADING}
							onClick={handleClose}
						>
							{translate('button_close')}
						</Button>
					</FormControl>
				</div>
			</form>
		</ModalFormWindow>
	);
};

export default FormProcessingDocuments;
