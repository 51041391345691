import { Fade } from '@mui/material';
import cn from 'classnames';
import { IInfoBlockProps } from './InfoBlock.props';
import styles from './InfoBlock.module.scss';

const InfoBlock = ({ className, ...props }: IInfoBlockProps): JSX.Element => {

	return (
		<Fade in={true} timeout={300} mountOnEnter unmountOnExit>
			<section className={cn(styles.infoBlock, className)} {...props}>
				<div className={styles.infoBlockInner}>
					{props.children}
				</div>
			</section>
		</Fade>
	);
};

export default InfoBlock;
