import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import Notification from '../../../Notification/Notification';
import { ISaveProps } from './Save.props';
import { useState } from 'react';
import AlertDialog from '../../../AlertDialog/AlertDialog';

const Save = ({ changeFlg, dataResponse, clearDataResponse, submitHandler, name, dialogTitle, dialogConfirm }: ISaveProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	// обработчик сохранения
	const saveHandler = (): void => {
		setShowNotification(true); // включаем уведомление
		setShowAlertDialog(false); // выключаем диалоговое окно
		submitHandler(); // сохранение
	};

	return (
		<>
			<button
				onClick={() => setShowAlertDialog(true)}
				disabled={!changeFlg}
				title={translate('buttonTitle_saveChanges')}>
				<FontAwesomeIcon icon={faFloppyDisk} size="xl" color={backgroundColor} />
			</button>

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={dataResponse}
					clearDataResponse={clearDataResponse}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}

			<AlertDialog
				showAlertDialog={showAlertDialog}
				setShowAlertDialog={setShowAlertDialog}
				submitHandler={saveHandler}
				title={dialogTitle}
				description={dialogConfirm}
				name={name}
			/>
		</>
	);
};

export default Save;
