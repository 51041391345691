import { useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectFullModel } from '../../../store/modelSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import { HANDLER, MODEL, SERVER } from '../../../constants/accessRights';
import { ModelStatus } from '../../../types/statusTypes';
import TagsWrapper from '../../../HOC/TagsWrapper/TagsWrapper';
import CodeEditorPython from '../../CodeEditorPython/CodeEditorPython';
import Log from '../../Log/Log';
import Errors from '../../Errors/Errors';
import ServerStatus from '../../ServerStatus/ServerStatus';
import Tag from '../Tag/Tag';
import { IModelTagsProps } from './ModelTags.props';

const ModelTags = ({ serviceType, activeMatrix, showManualCheck, setShowManualCheck, showErrorsMark, setShowErrorsMark, showErrors, setShowErrors }: IModelTagsProps): JSX.Element => {
	const [showCodeEditor, setShowCodeEditor] = useState<boolean>(false); // показ вкладки редактора кода python
	const [showLog, setShowLog] = useState<boolean>(false); // показ вкладки логов модели
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const fullModel = useAppSelector(selectFullModel); // store - информация о модели

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	return (
		<>
			<TagsWrapper>
				<>
					{isAccess(HANDLER.GET) &&
						<Tag setShowTag={setShowCodeEditor} tagName='tag_handler' smallTitleSize />
					}
					{isAccess(MODEL.LOGS) &&
						<Tag setShowTag={setShowLog} tagName='tag_logs' />
					}
					{isAccess(MODEL.ERRORS) && serviceType === 'smc' && activeMatrix === 'train' &&
						<Tag setShowTag={setShowErrorsMark} tagName='tag_errorsMark' smallTitleSize />
					}
					{isAccess(MODEL.ERRORS) && fullModel.fullModel[fullModel.activeType] && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.EMPTY && fullModel.fullModel[fullModel.activeType]?.status !== ModelStatus.FAILED && !fullModel.training &&
						<Tag setShowTag={setShowErrors} tagName={activeMatrix === 'train' ? 'tag_errorsTrain' : 'tag_errorsTest'} smallTitleSize />
					}
					{isAccess(SERVER.ADDRESSES) &&
						<Tag setShowTag={setShowServers} tagName='tag_servers' serverStatus />
					}
				</>
			</TagsWrapper>

			<CodeEditorPython showCodeEditor={showCodeEditor} setShowCodeEditor={setShowCodeEditor} serviceType={serviceType} />
			<Log showLog={showLog} setShowLog={setShowLog} serviceType={serviceType} />
			<Errors showErrors={showErrorsMark} setShowErrors={setShowErrorsMark} serviceType={serviceType} showManualCheck={showManualCheck} setShowManualCheck={setShowManualCheck} typeErrors='mark' />
			<Errors showErrors={showErrors} setShowErrors={setShowErrors} serviceType={serviceType} showManualCheck={showManualCheck} setShowManualCheck={setShowManualCheck} typeErrors={activeMatrix} />
			<ServerStatus showServers={showServers} setShowServers={setShowServers} />
		</>
	);
};

export default ModelTags;
