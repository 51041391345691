import { useState } from 'react';
import useAccessRight from '../../../hooks/useAccessRight';
import { DATASET, USER } from '../../../constants/accessRights';
import TagsWrapper from '../../../HOC/TagsWrapper/TagsWrapper';
import UserLogs from '../../UserLogs/UserLogs';
import Datasets from '../../Datasets/Datasets';
import Tag from '../Tag/Tag';

const UserTags = (): JSX.Element => {
	const [showUserLogs, setShowUserLogs] = useState<boolean>(false); // показ вкладки логов пользователей
	const [showDatasets, setShowDatasets] = useState<boolean>(false); // показ вкладки наборов данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	return (
		<>
			<TagsWrapper>
				<>
					{isAccess(USER.LOGS) &&
						<Tag setShowTag={setShowUserLogs} tagName='tag_logs' />
					}
					{isAccess(DATASET.LIST) &&
						<Tag setShowTag={setShowDatasets} tagName='tag_datasets' smallTitleSize />
					}
				</>
			</TagsWrapper>

			<UserLogs showUserLogs={showUserLogs} setShowUserLogs={setShowUserLogs} />
			<Datasets showDatasets={showDatasets} setShowDatasets={setShowDatasets} />
		</>
	);
};

export default UserTags;
