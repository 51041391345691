import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IUpdateProps } from './Update.props';

const Update = ({ isAvailable, title = 'buttonTitle_update', handler }: IUpdateProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<button
			onClick={handler}
			disabled={!isAvailable}
			title={translate(title)}
		>
			<FontAwesomeIcon icon={faRotate} size="xl" color={backgroundColor} />
		</button>
	);
};

export default Update;
