import { forwardRef, SyntheticEvent, useEffect, useRef } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus } from '../../../types/statusTypes';
import { colorSecondary } from '../../../constants/colors';
import { INoticeSingleActionProps } from './NoticeSingleAction.props';

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NoticeSingleAction = ({ showNotification, setShowNotification, title, severity = 'error', dataResponse }: INoticeSingleActionProps): JSX.Element => {
	const noticeRef = useRef<HTMLElement>(null); // ссылка на Notification
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		// подписываемся на событие нажатия мыши
		document.addEventListener('mousedown', handleClick);
		// при выходе отписываемся
		return () => {
			document.removeEventListener('mousedown', handleClick);
		};
	}, []);

	// следим за переданными данными
	useEffect(() => {
		// если есть данные и закончилась загрузка - выключаем уведомление
		if (dataResponse) {
			dataResponse.status !== RequestStatus.LOADING && handleClose();
		}
	}, [dataResponse]);

	// обработчик нажатия кнопки мыши
	const handleClick = (e: MouseEvent): void => {
		if (noticeRef.current && !noticeRef.current?.contains(e.target as Node)) {
			handleClose();
		}
	};

	// обработчик закрытия уведомления
	const handleClose = (_?: SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}
		setShowNotification(false); // выключаем уведомление
	};

	return (
		<Snackbar
			ref={noticeRef}
			open={showNotification}
			autoHideDuration={7000}
			onClose={handleClose}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			sx={{ marginRight: '10px' }}
		>
			<Alert
				onClose={handleClose}
				severity={severity}
				sx={{ width: '100%', fontSize: 13, padding: '2px 12px', backgroundColor: severity === 'info' ? colorSecondary : '' }}
			>
				{translate(title)}
			</Alert>
		</Snackbar>
	);
};

export default NoticeSingleAction;
