import { useEffect, useState } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder-2';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPaperPlane, faPhone, faPhoneSlash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearAudioPlaybackQueues, selectDebuggerAnswer, setEndOfSession } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { backgroundColor, colorPrimary, colorRed } from '../../../constants/colors';
import Vad from './Vad/Vad';
import { ISubmissionFormProps } from './SubmissionForm.props';
import styles from './SubmissionForm.module.scss';

const SubmissionForm = ({ submitHandler, inputChannel, setInputChannel, audioPlaybackStatusForVad, inputMessageRef, inputMessage, setInputMessage, webSocketStatus, conversationFlg, setConversationFlg, setMessageSendingMode, uuid }: ISubmissionFormProps): JSX.Element => {
	const [recordingFlg, setRecordingFlg] = useState<boolean>(false); // флаг записи с микрофона

	const dispatch = useAppDispatch();
	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота

	const { status, startRecording, stopRecording } = useReactMediaRecorder({
		audio: true,
		onStop: (_: string, blob: Blob) => {
			blob.size > 0 && submitHandler({ audio: blob }); // запускаем обработчик отправки аудиозаписи, если есть запись с микрофона
		}
	}); // hook для записи аудио
	const translate = useTranslate(); // hook для перевода текста

	// следим за флагом и статусом записи микрофона
	useEffect(() => {
		// и если кнопка мыши не зажата в плашке в момент подготовки или начала записи - останавливаем запись
		(status === 'acquiring_media' || status === 'recording') && recordingFlg === false && stopRecording();
	}, [status, recordingFlg]);

	// обработчик запуска разговора
	const conversationStartHandler = (): void => {
		// если уже включен голосовой канал
		if (inputChannel === 'voice') {
			// и идет разговор
			if (conversationFlg) {
				setConversationFlg(false); // сбрасываем флаг разговора
				dispatch(setEndOfSession()); // ставим флаг конца сессии
				dispatch(clearAudioPlaybackQueues()); // очищаем очереди воспроизведения
			} else {
				setConversationFlg(true); // иначе устанавливаем флаг разговора
				setMessageSendingMode('conversation'); // переключаем режим
				submitHandler({ message: '', session: uuid }); // открытие сессии
			}
		} else {
			setInputChannel('voice'); // устанавливаем голосовой канал
			setConversationFlg(true); // устанавливаем флаг разговора
			setMessageSendingMode('conversation'); // переключаем режим
			submitHandler({ message: '', channel: 'voice', session: uuid }); // открытие сессии
		}
	};

	return (
		<form className={styles.sender} onSubmit={(e) => submitHandler({ e })}>
			{/* сообщение */}
			<FormControl fullWidth>
				<TextField
					inputRef={inputMessageRef}
					variant="outlined"
					placeholder={translate(debuggerAnswer.endOfSession ? 'title_restartSession' : 'title_writeMessage') + '...'}
					value={inputMessage}
					onChange={(e) => setInputMessage(e.target.value)}
					disabled={debuggerAnswer.endOfSession || conversationFlg}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
						endAdornment: (
							<InputAdornment position="end" >
								{inputMessage !== '' &&
									<FontAwesomeIcon
										icon={faXmark}
										onClick={() => {
											setInputMessage('');
											inputMessageRef.current?.focus();
										}}
										style={{ cursor: 'pointer' }}
									/>
								}
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '0 14px' },
					}}
				/>
			</FormControl>

			{/* кнопка отправки сообщения */}
			<button
				className={styles.button}
				type='submit'
				title={translate('buttonTitle_send')}
				disabled={inputMessage === '' || debuggerAnswer.endOfSession || webSocketStatus !== 1 || conversationFlg}
			>
				<FontAwesomeIcon icon={faPaperPlane} color={backgroundColor} size="lg" />
			</button>

			{/* кнопка отправки аудио с микрофона */}
			<button
				className={styles.button}
				type='button'
				title={translate('buttonTitle_pressAndHold')}
				disabled={inputMessage !== '' || debuggerAnswer.endOfSession || webSocketStatus !== 1 || conversationFlg}
				onMouseDown={() => {
					startRecording();
					setRecordingFlg(true);
				}}
				onMouseUp={() => setRecordingFlg(false)}
				onMouseLeave={() => setRecordingFlg(false)}
			>
				<FontAwesomeIcon icon={faMicrophone} size="xl" color={status === 'recording' ? colorRed : backgroundColor} fade={status === 'recording'} />
			</button>

			{/* кнопка разговора (vad) */}
			<button
				className={styles.button}
				type='button'
				title={translate(conversationFlg ? 'buttonTitle_endConversation' : 'buttonTitle_startConversation')}
				disabled={debuggerAnswer.endOfSession || webSocketStatus !== 1 || (inputChannel !== 'voice' && debuggerAnswer.dialog.length > 0)}
				onClick={conversationStartHandler}
			>
				<FontAwesomeIcon
					icon={conversationFlg ? faPhoneSlash : faPhone}
					size="lg"
					color={backgroundColor}
					fade={conversationFlg && !debuggerAnswer.endOfSession}
				/>
			</button>

			{/* для голосового канала при включенном режиме разговора и с настроками для VAD (listening) */}
			{inputChannel === 'voice' && conversationFlg && debuggerAnswer.vadSetting.listening &&
				<Vad
					submitHandler={submitHandler}
					audioPlaybackStatusForVad={audioPlaybackStatusForVad}
					webSocketStatus={webSocketStatus}
					vadSetting={debuggerAnswer.vadSetting.listening}
					statusForVadSetting='listening'
				/>
			}
			{/* для голосового канала при включенном режиме разговора и с настроками для VAD (playing) */}
			{inputChannel === 'voice' && conversationFlg && debuggerAnswer.vadSetting.playing &&
				<Vad
					submitHandler={submitHandler}
					audioPlaybackStatusForVad={audioPlaybackStatusForVad}
					webSocketStatus={webSocketStatus}
					vadSetting={debuggerAnswer.vadSetting.playing}
					statusForVadSetting='playing'
				/>
			}
		</form>
	);
};

export default SubmissionForm;
