import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addUserName, clearDeleteUserStatus, clearModifyUserStatus, clearUserList, deleteUser, getCurrentUserInfo, getUserList, modifyMyself, modifyUser, selectCurrentUserInfo, selectDeleteUserStatus, selectModifyUserStatus, selectUserInfo, updateUserInfo } from '../../../store/userSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { USER } from '../../../constants/accessRights';
import Controls from '../../../HOC/Controls/Controls';
import Save from '../Buttons/Save/Save';
import Delete from '../Buttons/Delete/Delete';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { IModifyUserData } from '../../../types/userTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import { IUserControlsProps } from './UserControls.props';

const UserControls = ({ userName, password, setPassword, userRoleId, datasetIdDefault, datasetsListAvailable, avatarBase64, changeFlg, setChangeFlg, setShowPage }: IUserControlsProps) => {
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const userInfo = useAppSelector(selectUserInfo); // store - информация о пользователе
	const currentUserInfo = useAppSelector(selectCurrentUserInfo); // store - информация о текущем пользователе
	const modifyUserStatus = useAppSelector(selectModifyUserStatus); // store - статус изменения информации пользователя
	const deleteUserStatus = useAppSelector(selectDeleteUserStatus); // store - статус об удалении пользователя

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления информации пользователя
	useEffect(() => {
		if (modifyUserStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_saving') }); // если идет изменение инфо пользователя
		else if (deleteUserStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_deletion') }); // если идет удаление пользователя
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если изменилось успешно 
		if (modifyUserStatus.status === RequestStatus.IDLE && modifyUserStatus.error === ResponseStatus.SUCCESS && modifyUserStatus.message === 'success') {
			setChangeFlg(false); // выключаем флаг изменения данных
			if (modifyUserStatus.myself) dispatch(getCurrentUserInfo()); // если изменяли свои данные - получаем заново
			else {
				setPassword(''); // сбрасываем пароль
				dispatch(updateUserInfo({
					userName,
					userRoleId,
					datasetIdDefault,
					datasetsListAvailable,
					avatarBase64
				})); // иначе обновляем данные в store
			}
		}
		// если удаление прошло успешно
		if (deleteUserStatus.error === ResponseStatus.SUCCESS && deleteUserStatus.status === RequestStatus.IDLE && deleteUserStatus.message === 'success') {
			dispatch(addUserName(null)); // очищаем из store имя активного пользователя
			dispatch(clearUserList()); // очищаем список пользователей
			dispatch(getUserList()); // получаем заново список пользователей
		}
	}, [modifyUserStatus, deleteUserStatus]);

	// обработчик сохранения данных пользователя
	const saveHandler = (): void => {
		if (userInfo.data && 'username' in userInfo.data) {
			const userModifyData: IModifyUserData = {}; // измененные данные пользователя
			if (userName !== userInfo.data.fullname) userModifyData.fullname = userName; // если изменилось имя пользователя
			if (password !== "") userModifyData.password = password; // если изменился пароль
			if (userRoleId !== userInfo.data.role) userModifyData.role = userRoleId; // если изменилась роль
			if (datasetIdDefault !== userInfo.data.dataset) userModifyData.dataset = datasetIdDefault; // если изменился набор данных по умолчанию
			if (JSON.stringify(datasetsListAvailable) !== JSON.stringify(userInfo.data.datasets)) userModifyData.datasets = datasetsListAvailable; // если изменился список доступных наборов данных
			if (avatarBase64 !== userInfo.data.image) userModifyData.image = avatarBase64; // если изменился аватар
			// если изменяем данные самого себя - ставим флаг в store, для следующего обновления данных
			if (userInfo.data.id === currentUserInfo.id) dispatch(modifyMyself(true));
			dispatch(modifyUser({
				data: userModifyData,
				id: userInfo.data.id,
			})); // изменение данных пользователя
		}
	};

	// обработчик удаления пользователя
	const deleteUserHandler = (): void => {
		userInfo.data && 'username' in userInfo.data && dispatch(deleteUser(userInfo.data.id));
	};

	return (
		<>
			<Controls
				header='users'
				setShowPage={setShowPage}
				rightSection={
					<div>
						{isAccess(USER.MODIFY) &&
							<Save
								changeFlg={changeFlg}
								dataResponse={selectModifyUserStatus}
								clearDataResponse={clearModifyUserStatus}
								submitHandler={saveHandler}
								name={userInfo.userName || ''}
								dialogTitle='dialog_modifyUserData'
								dialogConfirm='dialog_modifyUserDataConfirm'
							/>
						}
						{isAccess(USER.DELETE) &&
							<Delete
								isAvailable={userInfo.status !== RequestStatus.LOADING && userInfo.userName !== null && userInfo.data !== null && 'username' in userInfo.data && currentUserInfo.userName !== userInfo.data.username && userInfo.data.username !== 'admin'}
								dataResponse={selectDeleteUserStatus}
								clearDataResponse={clearDeleteUserStatus}
								submitHandler={deleteUserHandler}
								name={userInfo.userName || ''}
								buttonTitle='buttonTitle_deleteUser'
								dialogTitle='dialog_deleteUser'
								dialogConfirm='dialog_deleteUserConfirm'
							/>
						}
					</div>
				}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
		</>
	);
};

export default UserControls;
