import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { selectActiveRobotId, selectDeletingRobot } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import { SERVER, SES } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import TagsWrapper from '../../../HOC/TagsWrapper/TagsWrapper';
import Scripts from '../../Scripts/Scripts';
import Calendar from '../../Calendar/Calendar';
import Intervals from '../../Intervals/Intervals';
import ConfigRobot from '../../ConfigRobot/ConfigRobot';
import ServerStatus from '../../ServerStatus/ServerStatus';
import Tag from '../Tag/Tag';

const RobotTags = (): JSX.Element => {
	const [showScripts, setShowScripts] = useState<boolean>(false); // показ вкладки скриптов
	const [showCalendar, setShowCalendar] = useState<boolean>(false); // показ вкладки календаря
	const [showIntervals, setShowIntervals] = useState<boolean>(false); // показ вкладки настроек интервалов работы робота
	const [showSettings, setShowSettings] = useState<boolean>(false); // показ вкладки настроек робота
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const deletingRobot = useAppSelector(selectDeletingRobot); // store - статус удаления робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	useEffect(() => {
		// если удаление робота прошло успешно
		if (deletingRobot.status === RequestStatus.IDLE && deletingRobot.error === ResponseStatus.SUCCESS && deletingRobot.message !== '') {
			setShowSettings(false); // закрываем вкладку
		}
	}, [deletingRobot]);

	return (
		<>
			<TagsWrapper>
				<>
					{isAccess([SES.SCRIPT_LIST, SES.SCRIPT_GET]) && activeRobotId &&
						<Tag setShowTag={setShowScripts} tagName='tag_scripts' />
					}
					{isAccess(SES.ROBOT_GET) && activeRobotId &&
						<Tag setShowTag={setShowCalendar} tagName='tag_calendar' />
					}
					{isAccess(SES.ROBOT_GET) && activeRobotId &&
						<Tag setShowTag={setShowIntervals} tagName='tag_intervals' />
					}
					{isAccess(SES.ROBOT_GET) && activeRobotId &&
						<Tag setShowTag={setShowSettings} tagName='tag_settings' />
					}
					{isAccess(SERVER.ADDRESSES) &&
						<Tag setShowTag={setShowServers} tagName='tag_servers' serverStatus />
					}
				</>
			</TagsWrapper>

			<Scripts showScripts={showScripts} setShowScripts={setShowScripts} />
			<Calendar showCalendar={showCalendar} setShowCalendar={setShowCalendar} />
			<Intervals showIntervals={showIntervals} setShowIntervals={setShowIntervals} />
			<ConfigRobot showSettings={showSettings} setShowSettings={setShowSettings} />
			<ServerStatus showServers={showServers} setShowServers={setShowServers} />
		</>
	);
};

export default RobotTags;
