import { useState } from 'react';
import useAccessRight from '../../../hooks/useAccessRight';
import { SERVER } from '../../../constants/accessRights';
import TagsWrapper from '../../../HOC/TagsWrapper/TagsWrapper';
import ServerStatus from '../../ServerStatus/ServerStatus';
import Tag from '../Tag/Tag';

const SingleServerTag = (): JSX.Element => {
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	return (
		<>
			<TagsWrapper>
				<>
					{isAccess(SERVER.ADDRESSES) &&
						<Tag setShowTag={setShowServers} tagName='tag_servers' serverStatus />
					}
				</>
			</TagsWrapper>

			<ServerStatus showServers={showServers} setShowServers={setShowServers} />
		</>
	);
};

export default SingleServerTag;
