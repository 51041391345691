import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { Cookies } from 'react-cookie';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_QAS, URL_QAS } from '../constants/api_endpoints';
import { QAS_FILTER_ANSWER, QAS_FILTER_ANSWER_TYPE, QAS_FILTER_CATEGORY, QAS_FILTER_QUESTION } from '../constants/cookieNames';
import { CategoryFrom, IAppendAliasListProps, IAddFileProps, IAddQuestionProps, IAnswer, IAnswerData, ICategory, IDeleteQuestionsListProps, IEditCategoryProps, IEditChannelProps, IEditFileProps, IEditQuestionProps, IGenerateAnswersProps, IGetAnswerProps, IGetFragmentProps, IGetQuestionsListProps, IProcessQuestionsProps, IQasState, IQuestionItem, IResponse, IResponseAddingCategory, IResponseAddingChannel, IResponseAddingFile, IResponseAddingQuestion, IResponseCategoriesList, IResponseChannelList, IResponseDocumentData, IResponseFileData, IResponseFragmentData, IResponseGenerateAnswerStatus, IResponseGenerateQuestionStatus, IResponseLogProcessQuestions, IResponseProcessQuestionsStatus, IResponseQuestion, IResponseQuestionsList, IApproveCandidateListProps, IGenerateQuestions, IResponseGptList, IResponseReplaceText, IReplaceTextProps } from '../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';
import { AnswerFilterType } from '../types/questionTypes';

const initialState: IQasState = {
  filter: {
    category: '',
    question: '',
    answer: '',
    answerType: 'all',
  },

  filesList: {
    data: null,
    categories: [],
    status: RequestStatus.IDLE,
  },
  document: {
    data: null,
    glossary: {},
    fileId: null,
    fileName: null,
    category: null,
    status: RequestStatus.IDLE,
  },
  addingFile: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
    progress: 0,
  },
  editingFile: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    changesNameOrCategory: false,
  },
  deletingFile: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  replaceInDocument: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    replacements: null,
  },

  answerToQuestion: {
    data: null,
    status: RequestStatus.IDLE,
  },
  fragmentToQuestion: {
    data: null,
    fragmentForSelection: null,
    status: RequestStatus.IDLE,
  },

  questionsList: {
    status: RequestStatus.IDLE,
    data: [],
    error: ResponseStatus.SUCCESS,
    message: '',
    update: false,
  },
  question: {
    status: RequestStatus.IDLE,
    aliases: [],
    candidates: [],
    answers: [],
    channelsIdAndCountAnswers: {},
    questionId: null,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  addingQuestion: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
  },
  editingQuestion: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    autosave: false,
  },
  deletingQuestion: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    deletingAnswers: false,
  },
  replaceInQuestion: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    replacements: null,
  },

  questionManipulation: {
    questionsList: {
      status: RequestStatus.IDLE,
      data: [],
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    appendAliasList: {
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    question: {
      status: RequestStatus.IDLE,
      aliases: [],
      candidates: [],
      answers: [],
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    editingQuestion: {
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
    },
    approveCandidateList: {
      status: RequestStatus.IDLE,
      error: ResponseStatus.SUCCESS,
      message: '',
      deleteOnly: false,
    },
  },

  categoriesList: {
    status: RequestStatus.IDLE,
    data: [],
    dictionary: {},
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  addingCategory: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
    from: null,
  },
  editingCategory: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  deletingCategory: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },

  channelList: {
    status: RequestStatus.IDLE,
    data: [],
    dictionary: {},
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  addingChannel: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  editingChannel: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  deletingChannel: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },

  answerMakerStart: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  answerMakerStop: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  answerMakerStatus: {
    requestStatus: RequestStatus.IDLE,
    error: '',
    responseStatus: 'stopped',
    progress: 0,
  },

  questionMakerStart: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  questionMakerStop: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  questionMakerStatus: {
    requestStatus: RequestStatus.IDLE,
    error: '',
    responseStatus: 'stopped',
    progress: 0,
  },

  processingQuestionsStart: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  processingQuestionsStop: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  processingQuestionsStatus: {
    requestStatus: RequestStatus.IDLE,
    error: '',
    responseStatus: 'stopped',
    progress: 0,
  },
  processingQuestionsLog: {
    status: RequestStatus.IDLE,
    data: [],
    error: ResponseStatus.SUCCESS,
    message: '',
  },

  gptList: {
    data: [],
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  }
};

// получение списка документов
export const getFilesList = createAsyncThunk(
  'qas/getFilesList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseFileData | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.DOC_LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение документа
export const getFile = createAsyncThunk(
  'qas/getFile',
  async (id: string, { rejectWithValue, signal }) => {
    try {
      const response: AxiosResponse<IResponseDocumentData | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.DOC_GET}/${id}`, {
        signal,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление документа
export const addFile = createAsyncThunk(
  'qas/addFile',
  async ({ title, categoryId, formData }: IAddFileProps, { dispatch, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseAddingFile> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.DOC_ADD}`, { text: formData.get('file'), title, category: categoryId }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.progress) dispatch(changeProgressAddingFile(progressEvent.progress));
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение документа
export const editFile = createAsyncThunk(
  'qas/editFile',
  async ({ fileId, data }: IEditFileProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.DOC_EDIT}/${fileId}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление документа
export const deleteFile = createAsyncThunk(
  'qas/deleteFile',
  async (id: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_QAS}/${ENDPOINTS_QAS.DOC_DELETE}/${id}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// поиск и замена текста во всех документах
export const replaceTextInAllDocuments = createAsyncThunk(
  'qas/replaceTextInAllDocuments',
  async ({ search, replace, categoryId }: IReplaceTextProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseReplaceText> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.DOC_REPLACE}`, {
        search,
        replace,
        category: categoryId,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// перезапись файла
// export const rewriteFile = createAsyncThunk(
//   'qas/rewriteFile',
//   async ({ fileId, fileName, categoryId, document }: IRewriteFileProps, { rejectWithValue }) => {
//     try {
//       const responseDeleteFile: AxiosResponse<IResponseAddingFile> = await axiosApi.delete(`${URL_QAS}/${ENDPOINTS_QAS.DOC_DELETE}/${fileId}`);
//       if (responseDeleteFile.data.message.toLowerCase() === 'success') {
//         const responseAddFile: AxiosResponse<IResponseAddingFile> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.DOC_ADD}`, { text: document, title: fileName, category: categoryId }, {
//           headers: {
//             'Content-Type': 'multipart/form-data'
//           },
//         });
//         return responseAddFile.data;
//       } else return responseDeleteFile.data;
//     } catch (error) {
//       if (error) {
//         // возвращаем данные ошибки, пришедшие с бэка
//         return rejectWithValue(error);
//       }
//     }
//   }
// );

// получение ответа на вопрос
export const getAnswer = createAsyncThunk(
  'qas/getAnswer',
  async ({ question, categoryId, channel, caching, gpt, creative, addAlias, modelGpt }: IGetAnswerProps, { rejectWithValue, signal }) => {
    try {
      const response: AxiosResponse<IAnswerData | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.ASK}`, {
        params: {
          text: question,
          category: categoryId,
          channel,
          useCache: caching,
          useGPT: gpt,
          creative,
          addAlias,
          model: modelGpt,
        },
        signal,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение фрагмента из документа на вопрос
export const getFragment = createAsyncThunk(
  'qas/getFragment',
  async ({ question, categoryId }: IGetFragmentProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseFragmentData | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.SEARCH}`, {
        params: {
          text: question,
          category: categoryId,
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение списка вопросов
export const getQuestionsList = createAsyncThunk(
  'qas/getQuestionsList',
  async ({ question, answer }: IGetQuestionsListProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseQuestionsList | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_LIST}`, {
        params: {
          question,
          answer,
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение списка ответов на вопрос
export const getQuestion = createAsyncThunk(
  'qas/getQuestion',
  async (questionId: string, { rejectWithValue, signal }) => {
    try {
      const response: AxiosResponse<IResponseQuestion | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_GET}/${questionId}`, {
        signal,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление вопроса с ответами
export const addQuestion = createAsyncThunk(
  'qas/addQuestion',
  async ({ question, categoryId }: IAddQuestionProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseAddingQuestion> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_ADD}`, {
        question,
        category: categoryId,
        answers: JSON.stringify([]),
        aliases: JSON.stringify([]),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение вопроса
export const editQuestion = createAsyncThunk(
  'qas/editQuestion',
  async ({ questionId, answers, question, aliases, candidates, categoryId, incomplete, autosave }: IEditQuestionProps, { rejectWithValue, dispatch }) => {
    try {
      autosave && dispatch(addFlagAutosaveQuestion()); // ставим флаг автосохранения
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_EDIT}/${questionId}`, {
        data: JSON.stringify({ answers, question, aliases, candidates, category: categoryId, incomplete }),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление вопроса
export const deleteQuestion = createAsyncThunk(
  'qas/deleteQuestion',
  async (questionId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_DELETE}/${questionId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// поиск и замена текста во всех ответах
export const replaceTextInAllAnswers = createAsyncThunk(
  'qas/replaceTextInAllAnswers',
  async ({ search, replace, categoryId }: IReplaceTextProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseReplaceText> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_REPLACE}`, {
        search,
        replace,
        category: categoryId,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление списка вопросов
export const deleteQuestionsList = createAsyncThunk(
  'qas/deleteQuestionsList',
  async ({ questionsIdList, type }: IDeleteQuestionsListProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_LIST_DELETE}`, {
        questions: JSON.stringify(questionsIdList),
        type,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение полного списка вопросов для перемещения
export const getQuestionsListForMoving = createAsyncThunk(
  'qas/getQuestionsListForMoving',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseQuestionsList | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение списка ответов на вопрос для перемещения
export const getQuestionForMoving = createAsyncThunk(
  'qas/getQuestionForMoving',
  async (questionId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseQuestion | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_GET}/${questionId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение вопроса для перемещения
export const editQuestionForMoving = createAsyncThunk(
  'qas/editQuestionForMoving',
  async ({ questionId, answers, question, aliases, candidates, categoryId }: IEditQuestionProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_EDIT}/${questionId}`, {
        data: JSON.stringify({ answers, question, aliases, candidates, category: categoryId }),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление списка алиасов/кандидатов к другому вопросу
export const appendAliasList = createAsyncThunk(
  'qas/appendAliasList',
  async ({ questionId, aliasList, candidateList }: IAppendAliasListProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_APPEND}/${questionId}`, {
        aliases: JSON.stringify(aliasList),
        candidates: JSON.stringify(candidateList),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// перемещение списка кандидатов в формулировки/удаление списка кандидатов (если destinationQuestionId = -1)
export const approveCandidateList = createAsyncThunk(
  'qas/approveCandidateList',
  async ({ destinationQuestionId, candidateList }: IApproveCandidateListProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_APPROVE}/${destinationQuestionId}`, {
        candidates: JSON.stringify(candidateList),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение списка категорий
export const getCategoriesList = createAsyncThunk(
  'qas/getCategoriesList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseCategoriesList | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.CATEGORY_LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление категории
export const addCategory = createAsyncThunk(
  'qas/addCategory',
  async (categoryName: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseAddingCategory> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.CATEGORY_ADD}`, {
        name: categoryName,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение категории
export const editCategory = createAsyncThunk(
  'qas/editCategory',
  async ({ categoryId, categoryName }: IEditCategoryProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.CATEGORY_EDIT}/${categoryId}`, {
        name: categoryName,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление категории
export const deleteCategory = createAsyncThunk(
  'qas/deleteCategory',
  async (categoryId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_QAS}/${ENDPOINTS_QAS.CATEGORY_DELETE}/${categoryId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение списка каналов
export const getChannelList = createAsyncThunk(
  'qas/getChannelList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseChannelList | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.CHANNEL_LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление канала
export const addChannel = createAsyncThunk(
  'qas/addChannel',
  async (channelName: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseAddingChannel> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.CHANNEL_ADD}`, {
        name: channelName,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение канала
export const editChannel = createAsyncThunk(
  'qas/editChannel',
  async ({ channelId, channelName }: IEditChannelProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.CHANNEL_EDIT}/${channelId}`, {
        name: channelName,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление канала
export const deleteChannel = createAsyncThunk(
  'qas/deleteChannel',
  async (channelId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_QAS}/${ENDPOINTS_QAS.CHANNEL_DELETE}/${channelId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// генерация кешированных ответов
export const generateAnswers = createAsyncThunk(
  'qas/generateAnswers',
  async ({ categoryId, documentId, mode, option, variants, replace, modelGpt }: IGenerateAnswersProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.ANSWER_MAKER_START}`, {
        params: {
          category: categoryId,
          doc: documentId,
          mode,
          option,
          variants,
          replace,
          model: modelGpt,
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// остановка генерации кешированных ответов
export const stopGenerateAnswers = createAsyncThunk(
  'qas/stopGenerateAnswers',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.ANSWER_MAKER_STOP}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// статус генерации кешированных ответов
export const getStatusGenerateAnswers = createAsyncThunk(
  'qas/getStatusGenerateAnswers',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseGenerateAnswerStatus> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.ANSWER_MAKER_STATUS}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// генерация вопросов
export const generateQuestions = createAsyncThunk(
  'qas/generateQuestions',
  async ({ categoryId, documentId, modelGpt }: IGenerateQuestions, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_MAKER_START}`, {
        params: {
          category: categoryId,
          doc: documentId,
          model: modelGpt,
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// остановка генерации вопросов
export const stopGenerateQuestions = createAsyncThunk(
  'qas/stopGenerateQuestions',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_MAKER_STOP}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// статус генерации вопросов
export const getStatusGenerateQuestions = createAsyncThunk(
  'qas/getStatusGenerateQuestions',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseGenerateQuestionStatus> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.QUESTION_MAKER_STATUS}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// массовая обработка вопросов
export const processQuestions = createAsyncThunk(
  'qas/processQuestions',
  async ({ formData, categoryId, channel, caching, gpt, creative, addAlias, threshold, modelGpt, format }: IProcessQuestionsProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.PROCESSING_QUESTIONS_START}`, {
        questions: formData.get('file'),
        format,
        category: categoryId,
        channel,
        useCache: caching,
        useGPT: gpt,
        creative,
        addAlias,
        threshold,
        model: modelGpt,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// остановка массовой обработки вопросов
export const stopProcessingQuestions = createAsyncThunk(
  'qas/stopProcessingQuestions',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.PROCESSING_QUESTIONS_STOP}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// статус массовой обработки вопросов
export const getStatusProcessingQuestions = createAsyncThunk(
  'qas/getStatusProcessingQuestions',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseProcessQuestionsStatus> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.PROCESSING_QUESTIONS_STATUS}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// логи массовой обработки вопросов
export const getLogProcessingQuestions = createAsyncThunk(
  'qas/getLogProcessingQuestions',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseLogProcessQuestions | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.PROCESSING_QUESTIONS_LOG}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// список моделей gpt
export const getGptList = createAsyncThunk(
  'qas/getGptList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseGptList> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.GPT_LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const qasSlice = createSlice({
  name: 'qas',
  initialState,
  reducers: {
    // изменение фильтра списка документов/вопросов по категории
    changeCategoryFilter: (state, action: PayloadAction<string>) => {
      state.filter.category = action.payload;
      new Cookies().set(QAS_FILTER_CATEGORY, action.payload, { path: '/', maxAge: 2_592_000 });
    },
    // изменение фильтра вопросов по вопросу
    changeQuestionFilter: (state, action: PayloadAction<string>) => {
      state.filter.question = action.payload;
      new Cookies().set(QAS_FILTER_QUESTION, action.payload, { path: '/', maxAge: 2_592_000 });
    },
    // изменение фильтра списка вопросов по ответу
    changeAnswerFilter: (state, action: PayloadAction<string>) => {
      state.filter.answer = action.payload;
      new Cookies().set(QAS_FILTER_ANSWER, action.payload, { path: '/', maxAge: 2_592_000 });
    },
    // изменение фильтра списка вопросов по типу ответа
    changeAnswerTypeFilter: (state, action: PayloadAction<AnswerFilterType>) => {
      state.filter.answerType = action.payload;
      new Cookies().set(QAS_FILTER_ANSWER_TYPE, action.payload, { path: '/', maxAge: 2_592_000 });
    },
    // изменение прогресса добавления файла в %
    changeProgressAddingFile: (state, action: PayloadAction<number>) => {
      state.addingFile.progress = Math.round(action.payload * 100);
    },
    // добавление id активного файла
    addActiveFileId: (state, action: PayloadAction<string>) => {
      state.document.fileId = action.payload;
    },
    // изменение имени активного файла
    changeActiveFileName: (state, action: PayloadAction<{ fileName: string, change: boolean }>) => {
      state.document.fileName = action.payload.fileName;
      if (action.payload.change) state.editingFile.changesNameOrCategory = true;
    },
    // изменение категории активного файла
    changeActiveFileCategory: (state, action: PayloadAction<{ categoryName: string, change: boolean }>) => {
      state.document.category = action.payload.categoryName;
      if (action.payload.change) state.editingFile.changesNameOrCategory = true;
    },
    // изменение документа
    editDocument: (state, action: PayloadAction<string>) => {
      state.document.data = action.payload;
    },
    // изменение ячейки сокращения таблицы глоссария
    editGlossaryDataCellAbbreviation: (state, action: PayloadAction<{ abbreviationOld: string, abbreviationNew: string, transcript: string }>) => {
      delete state.document.glossary[action.payload.abbreviationOld];
      state.document.glossary[action.payload.abbreviationNew] = action.payload.transcript;
    },
    // изменение ячейки расшифровки таблицы глоссария
    editGlossaryDataCellTranscript: (state, action: PayloadAction<{ abbreviation: string, transcript: string }>) => {
      state.document.glossary[action.payload.abbreviation] = action.payload.transcript;
    },
    // добавления строки в таблицу глоссария
    addRow: (state, action: PayloadAction<{ abbreviation: string, transcript: string }>) => {
      state.document.glossary[action.payload.abbreviation] = action.payload.transcript;
    },
    // удаление строк из таблицы глоссария
    deleteRows: (state, action: PayloadAction<string[]>) => {
      for (const abbreviation in state.document.glossary) {
        if (action.payload.includes(abbreviation)) {
          delete state.document.glossary[abbreviation];
        }
      }
    },
    // изменение данных в списке документов
    changeDataInFilesList: (state) => {
      // если есть список документов
      if (Array.isArray(state.filesList.data)) {
        state.filesList.data = state.filesList.data.map(fileItem => {
          // меняем название и категорию активного файла
          if (fileItem.id === state.document.fileId && state.document.fileName && state.document.category) {
            return { ...fileItem, title: state.document.fileName, category: state.document.category };
          } else return fileItem;
        });
      }
    },
    // добавление фрагмента для выделения в документе
    addFragmentForSelection: (state, action: PayloadAction<string>) => {
      state.fragmentToQuestion.fragmentForSelection = action.payload;
    },
    // добавление id активного вопроса
    addActiveQuestionId: (state, action: PayloadAction<string>) => {
      state.question.questionId = action.payload;
    },
    // изменение одного из вариантов вопросов
    editingAnswerAlias: (state, action: PayloadAction<{ idx: number, alias: string, listType: 'aliases' | 'candidates' }>) => {
      state.question[action.payload.listType][action.payload.idx] = action.payload.alias;
    },
    // добавление варианта вопроса
    addAlias: (state) => {
      state.question.aliases.push('');
    },
    // удаление варианта вопроса
    deleteAlias: (state, action: PayloadAction<{ idx: number, listType: 'aliases' | 'candidates' }>) => {
      state.question[action.payload.listType].splice(action.payload.idx, 1);
    },
    // удаление нескольких вариантов алиасов/кандидатов
    deleteAliases: (state, action: PayloadAction<{ aliasIdxList: number[], listType: 'aliases' | 'candidates' }>) => {
      state.question[action.payload.listType] = state.question[action.payload.listType].filter((_alias, idx) => !action.payload.aliasIdxList.includes(idx));
    },
    // перемещение кандидатов в алиасы
    moveCandidatesToAliases: (state, action: PayloadAction<number[]>) => {
      const selectedCandidates = state.question.candidates.filter((_alias, idx) => action.payload.includes(idx));
      state.question.candidates = state.question.candidates.filter((_alias, idx) => !action.payload.includes(idx));
      const newSet = new Set([...state.question.aliases, ...selectedCandidates]); // убираем повторные алиасы
      const uniqueAliases = Array.from(newSet);
      state.question.aliases = uniqueAliases;
    },
    // изменение статуса проверки вопроса
    editingAnswerChecked: (state, action: PayloadAction<{ idx: number, value: boolean }>) => {
      state.question.answers[action.payload.idx].checked = action.payload.value;
    },
    // изменение канала
    editingAnswerChannel: (state, action: PayloadAction<{ idx: number, channelId: string }>) => {
      state.question.answers[action.payload.idx].channel = action.payload.channelId;
    },
    // изменение вопроса в списке вопросов
    editingQuestionInQuestionsList: (state, action: PayloadAction<IQuestionItem>) => {
      state.questionsList.data = state.questionsList.data.map(questionItem => {
        if (questionItem.id === action.payload.id) return { ...questionItem, question: action.payload.question, category: action.payload.category, incomplete: action.payload.incomplete };
        else return questionItem;
      });
    },
    // установка флага обновления списка вопросов
    setupUpdateQuestionsList: (state) => {
      state.questionsList.update = true;
    },
    // добавление каналов и количества ответов для каждого
    addChannelsIdAndCountAnswers: (state, action: PayloadAction<Record<string, number>>) => {
      state.question.channelsIdAndCountAnswers = action.payload;
    },
    // изменение текста ответа
    editingAnswerText: (state, action: PayloadAction<{ idx: number, value: string }>) => {
      state.question.answers[action.payload.idx].answer = action.payload.value;
    },
    // добавление ответа
    addAnswer: (state, action: PayloadAction<string>) => {
      state.question.answers.push({ checked: false, channel: action.payload, answer: '' });
    },
    // копирование ответа в канал
    copyAnswer: (state, action: PayloadAction<IAnswer>) => {
      state.question.answers.push(action.payload);
    },
    // перемещение ответа в канал
    moveAnswer: (state, action: PayloadAction<{ idx: number, channelId: string }>) => {
      state.question.answers[action.payload.idx].channel = action.payload.channelId;
    },
    // удаление ответа
    deleteAnswer: (state, action: PayloadAction<number>) => {
      state.question.answers.splice(action.payload, 1);
    },
    // добавление новой категории в список
    addCategoryInList: (state, action: PayloadAction<ICategory>) => {
      state.categoriesList.data.push(action.payload);
    },
    // удаление категории из списка
    deleteCategoryFromList: (state, action: PayloadAction<string>) => {
      const index = state.categoriesList.data.findIndex(categoryItem => categoryItem.name === action.payload);
      state.categoriesList.data.splice(index, 1);
    },
    // изменение места добавления категории
    changePlaceAddingCategory: (state, action: PayloadAction<CategoryFrom>) => {
      state.addingCategory.from = action.payload;
    },
    // уточнение удаления только ответов при удалении списка вопросов
    specifyDeletionOnlyAnswers: (state) => {
      state.deletingQuestion.deletingAnswers = true;
    },
    // установка флага только удаления кандидатов
    setupDeletionOnlyCandidates: (state) => {
      state.questionManipulation.approveCandidateList.deleteOnly = true;
    },
    // добавление флага автосохранения
    addFlagAutosaveQuestion: (state) => {
      state.editingQuestion.autosave = true;
    },
    // очистка state
    clearState: (state) => {
      state.filesList = initialState.filesList;
      state.document = initialState.document;
      state.addingFile = initialState.addingFile;
      state.editingFile = initialState.editingFile;
      state.deletingFile = initialState.deletingFile;
      state.replaceInDocument = initialState.replaceInDocument;
      state.answerToQuestion = initialState.answerToQuestion;
      state.fragmentToQuestion = initialState.fragmentToQuestion;
      state.questionsList = initialState.questionsList;
      state.question = initialState.question;
      state.addingFile = initialState.addingFile;
      state.editingQuestion = initialState.editingQuestion;
      state.deletingQuestion = initialState.deletingQuestion;
      state.replaceInQuestion = initialState.replaceInQuestion;
      state.questionManipulation = initialState.questionManipulation;
      state.categoriesList = initialState.categoriesList;
      state.addingCategory = initialState.addingCategory;
      state.editingCategory = initialState.editingCategory;
      state.deletingCategory = initialState.deletingCategory;
      state.channelList = initialState.channelList;
      state.addingChannel = initialState.addingChannel;
      state.editingChannel = initialState.editingChannel;
      state.deletingChannel = initialState.deletingChannel;
      state.answerMakerStart = initialState.answerMakerStart;
      state.answerMakerStop = initialState.answerMakerStop;
      state.answerMakerStatus = initialState.answerMakerStatus;
      state.questionMakerStart = initialState.questionMakerStart;
      state.questionMakerStop = initialState.questionMakerStop;
      state.questionMakerStatus = initialState.questionMakerStatus;
      state.processingQuestionsStart = initialState.processingQuestionsStart;
      state.processingQuestionsStop = initialState.processingQuestionsStop;
      state.processingQuestionsStatus = initialState.processingQuestionsStatus;
      state.processingQuestionsLog = initialState.processingQuestionsLog;
      state.gptList = initialState.gptList;
    },
    // очистка списка файлов
    clearFilesList: (state) => {
      state.filesList = initialState.filesList;
    },
    // очистка данных документа
    clearDocument: (state) => {
      state.document = initialState.document;
    },
    // очистка статуса добавления файла
    clearAddFileStatus: (state) => {
      state.addingFile = initialState.addingFile;
    },
    // очистка статуса изменения файла
    clearEditingFileStatus: (state) => {
      state.editingFile = initialState.editingFile;
    },
    // очистка статуса удаления файла
    clearDeletingFileStatus: (state) => {
      state.deletingFile = initialState.deletingFile;
    },
    // очистка данных замены текста в документах
    clearReplaceInDocument: (state) => {
      state.replaceInDocument = initialState.replaceInDocument;
    },
    // очистка данных ответа на вопрос
    clearAnswerToQuestion: (state) => {
      state.answerToQuestion = initialState.answerToQuestion;
    },
    // очистка данных фрагмента на вопрос
    clearFragmentToQuestion: (state) => {
      state.fragmentToQuestion = initialState.fragmentToQuestion;
    },
    // очистка фрагмента для выделения в документе
    clearFragmentForSelection: (state) => {
      state.fragmentToQuestion.fragmentForSelection = initialState.fragmentToQuestion.fragmentForSelection;
    },
    // очистка списка вопросов
    clearQuestionsList: (state) => {
      state.questionsList = initialState.questionsList;
    },
    // очистка ответов на вопрос
    clearQuestion: (state) => {
      state.question = initialState.question;
    },
    // очистка статуса добавления вопроса
    clearAddingQuestion: (state) => {
      state.addingQuestion = initialState.addingQuestion;
    },
    // очистка статуса изменения ответа на вопрос
    clearEditingQuestion: (state) => {
      state.editingQuestion = initialState.editingQuestion;
    },
    // очистка статуса удаления вопроса
    clearDeletingQuestion: (state) => {
      state.deletingQuestion = initialState.deletingQuestion;
    },
    // очистка данных замены текста в ответах
    clearReplaceInQuestion: (state) => {
      state.replaceInQuestion = initialState.replaceInQuestion;
    },
    // очистка данных и статуса доп.манипуляций с вопросами
    clearQuestionManipulation: (state) => {
      state.questionManipulation = initialState.questionManipulation;
    },
    // очистка списка категорий
    clearCategoriesList: (state) => {
      state.categoriesList = initialState.categoriesList;
    },
    // очистка статуса добавления категории
    clearAddingCategory: (state) => {
      state.addingCategory = initialState.addingCategory;
    },
    // очистка статуса изменения категории
    clearEditingCategory: (state) => {
      state.editingCategory = initialState.editingCategory;
    },
    // очистка статуса удаления категории
    clearDeletingCategory: (state) => {
      state.deletingCategory = initialState.deletingCategory;
    },
    // очистка списка каналов
    clearChannelList: (state) => {
      state.channelList = initialState.channelList;
    },
    // очистка статуса добавления канала
    clearAddingChannel: (state) => {
      state.addingChannel = initialState.addingChannel;
    },
    // очистка статуса изменения канала
    clearEditingChannel: (state) => {
      state.editingChannel = initialState.editingChannel;
    },
    // очистка статуса удаления канала
    clearDeletingChannel: (state) => {
      state.deletingChannel = initialState.deletingChannel;
    },
    // очистка статуса старта генерации ответов
    clearAnswerMakerStart: (state) => {
      state.answerMakerStart = initialState.answerMakerStart;
    },
    // очистка статуса остановки генерации ответов
    clearAnswerMakerStop: (state) => {
      state.answerMakerStop = initialState.answerMakerStop;
    },
    // очистка статуса процесса генерации ответов
    clearAnswerMakerStatus: (state) => {
      state.answerMakerStatus = initialState.answerMakerStatus;
    },
    // очистка статуса старта генерации вопросов
    clearQuestionMakerStart: (state) => {
      state.questionMakerStart = initialState.questionMakerStart;
    },
    // очистка статуса остановки генерации вопросов
    clearQuestionMakerStop: (state) => {
      state.questionMakerStop = initialState.questionMakerStop;
    },
    // очистка статуса процесса генерации вопросов
    clearQuestionMakerStatus: (state) => {
      state.questionMakerStatus = initialState.questionMakerStatus;
    },
    // очистка статуса старта массовой обработки вопросов
    clearProcessingQuestionsStart: (state) => {
      state.processingQuestionsStart = initialState.processingQuestionsStart;
    },
    // очистка статуса остановки массовой обработки вопросов
    clearProcessingQuestionsStop: (state) => {
      state.processingQuestionsStop = initialState.processingQuestionsStop;
    },
    // очистка статуса процесса массовой обработки вопросов
    clearProcessingQuestionsStatus: (state) => {
      state.processingQuestionsStatus = initialState.processingQuestionsStatus;
    },
    // очистка логов массовой обработки вопросов
    clearProcessingQuestionsLog: (state) => {
      state.processingQuestionsLog = initialState.processingQuestionsLog;
    },
    // очистка списка моделей gpt
    clearGptList: (state) => {
      state.gptList = initialState.gptList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFilesList.pending, (state) => {
        state.filesList.status = RequestStatus.LOADING;
      })
      .addCase(getFilesList.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'docs' in action.payload && Array.isArray(action.payload.docs)) {
          state.filesList.status = RequestStatus.IDLE;
          state.filesList.data = action.payload.docs.sort((a, b) => {
            if (a.title > b.title) return 1;
            else if (a.title < b.title) return -1;
            else return 0;
          });
          // поиск уникальных категорий
          const categories: string[] = [];
          action.payload.docs.forEach(fileItem => {
            fileItem.category !== null && !categories.includes(fileItem.category) && categories.push(fileItem.category);
          });
          state.filesList.categories = categories;
        } else state.filesList.status = RequestStatus.FAILED;
      })
      .addCase(getFilesList.rejected, (state, action: PayloadAction<unknown>) => {
        state.filesList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.filesList.data = action.payload.response?.data;
        }
      })
      .addCase(getFile.pending, (state) => {
        state.document.status = RequestStatus.LOADING;
      })
      .addCase(getFile.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'text' in action.payload) {
          state.document.status = RequestStatus.IDLE;
          state.document.data = action.payload.text;
          state.document.glossary = action.payload.glossary;
        } else state.document.status = RequestStatus.FAILED;
      })
      .addCase(getFile.rejected, (state, action: PayloadAction<unknown>) => {
        state.document.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.document.data = action.payload.response?.data;
        }
      })
      .addCase(addFile.pending, (state) => {
        state.addingFile.status = RequestStatus.LOADING;
      })
      .addCase(addFile.fulfilled, (state, action) => {
        state.addingFile.status = RequestStatus.IDLE;
        if (action.payload) {
          state.addingFile.error = action.payload.error;
          state.addingFile.message = action.payload.message;
          if (action.payload.id) state.addingFile.id = action.payload.id;
        }
      })
      .addCase(addFile.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingFile.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingFile.error = action.payload.response?.data.error;
          state.addingFile.message = action.payload.response?.data.message;
        }
      })
      .addCase(editFile.pending, (state) => {
        state.editingFile.status = RequestStatus.LOADING;
      })
      .addCase(editFile.fulfilled, (state, action) => {
        state.editingFile.status = RequestStatus.IDLE;
        if (action.payload) {
          state.editingFile.error = action.payload.error;
          state.editingFile.message = action.payload.message;
        }
      })
      .addCase(editFile.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingFile.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingFile.error = action.payload.response?.data.error;
          state.editingFile.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteFile.pending, (state) => {
        state.deletingFile.status = RequestStatus.LOADING;
      })
      .addCase(deleteFile.fulfilled, (state, action) => {
        state.deletingFile.status = RequestStatus.IDLE;
        if (action.payload) {
          state.deletingFile.error = action.payload.error;
          state.deletingFile.message = action.payload.message;
        }
      })
      .addCase(deleteFile.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingFile.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingFile.error = action.payload.response?.data.error;
          state.deletingFile.message = action.payload.response?.data.message;
        }
      })
      .addCase(replaceTextInAllDocuments.pending, (state) => {
        state.replaceInDocument.status = RequestStatus.LOADING;
      })
      .addCase(replaceTextInAllDocuments.fulfilled, (state, action) => {
        if (action.payload) {
          state.replaceInDocument.status = RequestStatus.IDLE;
          state.replaceInDocument.error = action.payload.error;
          state.replaceInDocument.message = action.payload.message;
          if ('replacements' in action.payload && typeof action.payload.replacements === 'number') {
            state.replaceInDocument.replacements = action.payload.replacements;
          }
        } else state.replaceInDocument.status = RequestStatus.FAILED;
      })
      .addCase(replaceTextInAllDocuments.rejected, (state, action: PayloadAction<unknown>) => {
        state.replaceInDocument.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.replaceInDocument.error = action.payload.response?.data.error;
          state.replaceInDocument.message = action.payload.response?.data.message;
        }
      })
      // .addCase(rewriteFile.fulfilled, (state, action) => {
      //   state.rewriteFile.status = RequestStatus.IDLE;
      //   if (action.payload) {
      //     state.rewriteFile.error = action.payload.error;
      //     state.rewriteFile.message = action.payload.message;
      //     if (action.payload.id) state.rewriteFile.id = action.payload.id;
      //     // скачивание резервной копии
      //     else typeof state.document.data === 'string' && state.document.fileName && downloadBackupCopy(state.document.data, state.document.fileName);
      //   }
      // })
      .addCase(getAnswer.pending, (state) => {
        state.answerToQuestion.status = RequestStatus.LOADING;
      })
      .addCase(getAnswer.fulfilled, (state, action) => {
        state.answerToQuestion.status = RequestStatus.IDLE;
        if (action.payload) state.answerToQuestion.data = action.payload;
      })
      .addCase(getAnswer.rejected, (state, action: PayloadAction<unknown>) => {
        state.answerToQuestion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError && typeof action.payload.response?.data !== 'string') {
          state.answerToQuestion.data = action.payload.response?.data;
        }
      })
      .addCase(getFragment.pending, (state) => {
        state.fragmentToQuestion.status = RequestStatus.LOADING;
      })
      .addCase(getFragment.fulfilled, (state, action) => {
        state.fragmentToQuestion.status = RequestStatus.IDLE;
        if (action.payload && typeof action.payload === 'object') state.fragmentToQuestion.data = action.payload;
      })
      .addCase(getFragment.rejected, (state, action: PayloadAction<unknown>) => {
        state.fragmentToQuestion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.fragmentToQuestion.data = action.payload.response?.data;
        }
      })
      .addCase(getQuestionsList.pending, (state) => {
        state.questionsList.status = RequestStatus.LOADING;
      })
      .addCase(getQuestionsList.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'questions' in action.payload) {
          state.questionsList.status = RequestStatus.IDLE;
          // если изменилось количество вопросов или нет флага обновления после запроса ask (поиск ответа)
          if (state.questionsList.data.length !== action.payload.questions.length || !state.questionsList.update) {
            state.questionsList.data = action.payload.questions.sort((a, b) => {
              if (a.question > b.question) return 1;
              else if (a.question < b.question) return -1;
              else return 0;
            });
          }
          state.questionsList.update = false;
        } else state.questionsList.status = RequestStatus.FAILED;
      })
      .addCase(getQuestionsList.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionsList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionsList.error = action.payload.response?.data.error;
          state.questionsList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getQuestion.pending, (state) => {
        state.question.status = RequestStatus.LOADING;
      })
      .addCase(getQuestion.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'answers' in action.payload) {
          state.question.status = RequestStatus.IDLE;
          // если нет ответов - замена с учетом случая не сохраненных ответов
          if (action.payload.answers.length === 0) state.question.answers = [{ answer: '', channel: '0', checked: false }];
          else state.question.answers = action.payload.answers;
          state.question.aliases = action.payload.aliases;
          state.question.candidates = action.payload.candidates;
        } else state.question.status = RequestStatus.FAILED;
      })
      .addCase(getQuestion.rejected, (state, action: PayloadAction<unknown>) => {
        state.question.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.question.error = action.payload.response?.data.error;
          state.question.message = action.payload.response?.data.message;
        }
      })
      .addCase(addQuestion.pending, (state) => {
        state.addingQuestion.status = RequestStatus.LOADING;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        if (action.payload) {
          state.addingQuestion.status = RequestStatus.IDLE;
          state.addingQuestion.error = action.payload.error;
          state.addingQuestion.message = action.payload.message;
          if (action.payload.id) state.addingQuestion.id = action.payload.id;
        } else state.addingQuestion.status = RequestStatus.FAILED;
      })
      .addCase(addQuestion.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingQuestion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingQuestion.error = action.payload.response?.data.error;
          state.addingQuestion.message = action.payload.response?.data.message;
        }
      })
      .addCase(editQuestion.pending, (state) => {
        state.editingQuestion.status = RequestStatus.LOADING;
      })
      .addCase(editQuestion.fulfilled, (state, action) => {
        if (action.payload) {
          state.editingQuestion.status = RequestStatus.IDLE;
          state.editingQuestion.error = action.payload.error;
          state.editingQuestion.message = action.payload.message;
        } else state.editingQuestion.status = RequestStatus.FAILED;
      })
      .addCase(editQuestion.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingQuestion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingQuestion.error = action.payload.response?.data.error;
          state.editingQuestion.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteQuestion.pending, (state) => {
        state.deletingQuestion.status = RequestStatus.LOADING;
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingQuestion.status = RequestStatus.IDLE;
          state.deletingQuestion.error = action.payload.error;
          state.deletingQuestion.message = action.payload.message;
        } else state.deletingQuestion.status = RequestStatus.FAILED;
      })
      .addCase(deleteQuestion.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingQuestion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingQuestion.error = action.payload.response?.data.error;
          state.deletingQuestion.message = action.payload.response?.data.message;
        }
      })
      .addCase(replaceTextInAllAnswers.pending, (state) => {
        state.replaceInQuestion.status = RequestStatus.LOADING;
      })
      .addCase(replaceTextInAllAnswers.fulfilled, (state, action) => {
        if (action.payload) {
          state.replaceInQuestion.status = RequestStatus.IDLE;
          state.replaceInQuestion.error = action.payload.error;
          state.replaceInQuestion.message = action.payload.message;
          if ('replacements' in action.payload && typeof action.payload.replacements === 'number') {
            state.replaceInQuestion.replacements = action.payload.replacements;
          }
        } else state.replaceInQuestion.status = RequestStatus.FAILED;
      })
      .addCase(replaceTextInAllAnswers.rejected, (state, action: PayloadAction<unknown>) => {
        state.replaceInQuestion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.replaceInQuestion.error = action.payload.response?.data.error;
          state.replaceInQuestion.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteQuestionsList.pending, (state) => {
        state.deletingQuestion.status = RequestStatus.LOADING;
      })
      .addCase(deleteQuestionsList.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingQuestion.status = RequestStatus.IDLE;
          state.deletingQuestion.error = action.payload.error;
          state.deletingQuestion.message = action.payload.message;
        } else state.deletingQuestion.status = RequestStatus.FAILED;
      })
      .addCase(deleteQuestionsList.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingQuestion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingQuestion.error = action.payload.response?.data.error;
          state.deletingQuestion.message = action.payload.response?.data.message;
        }
      })
      .addCase(getQuestionsListForMoving.pending, (state) => {
        state.questionManipulation.questionsList.status = RequestStatus.LOADING;
      })
      .addCase(getQuestionsListForMoving.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'questions' in action.payload) {
          state.questionManipulation.questionsList.status = RequestStatus.IDLE;
          state.questionManipulation.questionsList.data = action.payload.questions.sort((a, b) => {
            if (a.question > b.question) return 1;
            else if (a.question < b.question) return -1;
            else return 0;
          });
        } else state.questionsList.status = RequestStatus.FAILED;
      })
      .addCase(getQuestionsListForMoving.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionManipulation.questionsList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionManipulation.questionsList.error = action.payload.response?.data.error;
          state.questionManipulation.questionsList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getQuestionForMoving.pending, (state) => {
        state.questionManipulation.question.status = RequestStatus.LOADING;
      })
      .addCase(getQuestionForMoving.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'answers' in action.payload) {
          state.questionManipulation.question.status = RequestStatus.IDLE;
          state.questionManipulation.question.answers = action.payload.answers;
          state.questionManipulation.question.aliases = action.payload.aliases;
          state.questionManipulation.question.candidates = action.payload.candidates;
        } else state.questionManipulation.question.status = RequestStatus.FAILED;
      })
      .addCase(getQuestionForMoving.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionManipulation.question.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionManipulation.question.error = action.payload.response?.data.error;
          state.questionManipulation.question.message = action.payload.response?.data.message;
        }
      })
      .addCase(editQuestionForMoving.pending, (state) => {
        state.questionManipulation.editingQuestion.status = RequestStatus.LOADING;
      })
      .addCase(editQuestionForMoving.fulfilled, (state, action) => {
        if (action.payload) {
          state.questionManipulation.editingQuestion.status = RequestStatus.IDLE;
          state.questionManipulation.editingQuestion.error = action.payload.error;
          state.questionManipulation.editingQuestion.message = action.payload.message;
        } else state.questionManipulation.editingQuestion.status = RequestStatus.FAILED;
      })
      .addCase(editQuestionForMoving.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionManipulation.editingQuestion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionManipulation.editingQuestion.error = action.payload.response?.data.error;
          state.questionManipulation.editingQuestion.message = action.payload.response?.data.message;
        }
      })
      .addCase(appendAliasList.pending, (state) => {
        state.questionManipulation.appendAliasList.status = RequestStatus.LOADING;
      })
      .addCase(appendAliasList.fulfilled, (state, action) => {
        if (action.payload) {
          state.questionManipulation.appendAliasList.status = RequestStatus.IDLE;
          state.questionManipulation.appendAliasList.error = action.payload.error;
          state.questionManipulation.appendAliasList.message = action.payload.message;
        } else state.questionManipulation.appendAliasList.status = RequestStatus.FAILED;
      })
      .addCase(appendAliasList.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionManipulation.appendAliasList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionManipulation.appendAliasList.error = action.payload.response?.data.error;
          state.questionManipulation.appendAliasList.message = action.payload.response?.data.message;
        }
      })
      .addCase(approveCandidateList.pending, (state) => {
        state.questionManipulation.approveCandidateList.status = RequestStatus.LOADING;
      })
      .addCase(approveCandidateList.fulfilled, (state, action) => {
        if (action.payload) {
          state.questionManipulation.approveCandidateList.status = RequestStatus.IDLE;
          state.questionManipulation.approveCandidateList.error = action.payload.error;
          state.questionManipulation.approveCandidateList.message = action.payload.message;
        } else state.questionManipulation.approveCandidateList.status = RequestStatus.FAILED;
      })
      .addCase(approveCandidateList.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionManipulation.approveCandidateList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionManipulation.approveCandidateList.error = action.payload.response?.data.error;
          state.questionManipulation.approveCandidateList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getCategoriesList.pending, (state) => {
        state.categoriesList.status = RequestStatus.LOADING;
      })
      .addCase(getCategoriesList.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'categories' in action.payload) {
          state.categoriesList.status = RequestStatus.IDLE;
          state.categoriesList.data = action.payload.categories.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
          const dictionary: Record<string, string> = {}; // создаем словарь id-name
          action.payload.categories.forEach(categoryElem => dictionary[categoryElem.id] = categoryElem.name);
          state.categoriesList.dictionary = dictionary;
        } else state.categoriesList.status = RequestStatus.FAILED;
      })
      .addCase(getCategoriesList.rejected, (state, action: PayloadAction<unknown>) => {
        state.categoriesList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.categoriesList.error = action.payload.response?.data.error;
          state.categoriesList.message = action.payload.response?.data.message;
        }
      })
      .addCase(addCategory.pending, (state) => {
        state.addingCategory.status = RequestStatus.LOADING;
      })
      .addCase(addCategory.fulfilled, (state, action) => {
        if (action.payload) {
          state.addingCategory.status = RequestStatus.IDLE;
          state.addingCategory.error = action.payload.error;
          state.addingCategory.message = action.payload.message;
          if (action.payload.id) state.addingCategory.id = action.payload.id;
        } else state.addingCategory.status = RequestStatus.FAILED;
      })
      .addCase(addCategory.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingCategory.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingCategory.error = action.payload.response?.data.error;
          state.addingCategory.message = action.payload.response?.data.message;
        }
      })
      .addCase(editCategory.pending, (state) => {
        state.editingCategory.status = RequestStatus.LOADING;
      })
      .addCase(editCategory.fulfilled, (state, action) => {
        if (action.payload) {
          state.editingCategory.status = RequestStatus.IDLE;
          state.editingCategory.error = action.payload.error;
          state.editingCategory.message = action.payload.message;
        } else state.editingQuestion.status = RequestStatus.FAILED;
      })
      .addCase(editCategory.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingCategory.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingCategory.error = action.payload.response?.data.error;
          state.editingCategory.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteCategory.pending, (state) => {
        state.deletingCategory.status = RequestStatus.LOADING;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingCategory.status = RequestStatus.IDLE;
          state.deletingCategory.error = action.payload.error;
          state.deletingCategory.message = action.payload.message;
        } else state.deletingCategory.status = RequestStatus.FAILED;
      })
      .addCase(deleteCategory.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingCategory.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingCategory.error = action.payload.response?.data.error;
          state.deletingCategory.message = action.payload.response?.data.message;
        }
      })
      .addCase(getChannelList.pending, (state) => {
        state.channelList.status = RequestStatus.LOADING;
      })
      .addCase(getChannelList.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'channels' in action.payload) {
          state.channelList.status = RequestStatus.IDLE;
          state.channelList.data = action.payload.channels.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
          const dictionary: Record<string, string> = {}; // создаем словарь id-name
          action.payload.channels.forEach(channelElem => dictionary[channelElem.id] = channelElem.name);
          state.channelList.dictionary = dictionary;
        } else state.channelList.status = RequestStatus.FAILED;
      })
      .addCase(getChannelList.rejected, (state, action: PayloadAction<unknown>) => {
        state.channelList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.channelList.error = action.payload.response?.data.error;
          state.channelList.message = action.payload.response?.data.message;
        }
      })
      .addCase(addChannel.pending, (state) => {
        state.addingChannel.status = RequestStatus.LOADING;
      })
      .addCase(addChannel.fulfilled, (state, action) => {
        if (action.payload) {
          state.addingChannel.status = RequestStatus.IDLE;
          state.addingChannel.error = action.payload.error;
          state.addingChannel.message = action.payload.message;
        } else state.addingChannel.status = RequestStatus.FAILED;
      })
      .addCase(addChannel.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingChannel.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingChannel.error = action.payload.response?.data.error;
          state.addingChannel.message = action.payload.response?.data.message;
        }
      })
      .addCase(editChannel.pending, (state) => {
        state.editingChannel.status = RequestStatus.LOADING;
      })
      .addCase(editChannel.fulfilled, (state, action) => {
        if (action.payload) {
          state.editingChannel.status = RequestStatus.IDLE;
          state.editingChannel.error = action.payload.error;
          state.editingChannel.message = action.payload.message;
        } else state.editingChannel.status = RequestStatus.FAILED;
      })
      .addCase(editChannel.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingChannel.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingChannel.error = action.payload.response?.data.error;
          state.editingChannel.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteChannel.pending, (state) => {
        state.deletingChannel.status = RequestStatus.LOADING;
      })
      .addCase(deleteChannel.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingChannel.status = RequestStatus.IDLE;
          state.deletingChannel.error = action.payload.error;
          state.deletingChannel.message = action.payload.message;
        } else state.deletingChannel.status = RequestStatus.FAILED;
      })
      .addCase(deleteChannel.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingChannel.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingChannel.error = action.payload.response?.data.error;
          state.deletingChannel.message = action.payload.response?.data.message;
        }
      })
      .addCase(generateAnswers.pending, (state) => {
        state.answerMakerStart.status = RequestStatus.LOADING;
      })
      .addCase(generateAnswers.fulfilled, (state, action) => {
        if (action.payload) {
          state.answerMakerStart.status = RequestStatus.IDLE;
          state.answerMakerStart.error = action.payload.error;
          state.answerMakerStart.message = action.payload.message;
        } else state.answerMakerStart.status = RequestStatus.FAILED;
      })
      .addCase(generateAnswers.rejected, (state, action: PayloadAction<unknown>) => {
        state.answerMakerStart.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.answerMakerStart.error = action.payload.response?.data.error;
          state.answerMakerStart.message = action.payload.response?.data.message;
        }
      })
      .addCase(stopGenerateAnswers.pending, (state) => {
        state.answerMakerStop.status = RequestStatus.LOADING;
      })
      .addCase(stopGenerateAnswers.fulfilled, (state, action) => {
        if (action.payload) {
          state.answerMakerStop.status = RequestStatus.IDLE;
          state.answerMakerStop.error = action.payload.error;
          state.answerMakerStop.message = action.payload.message;
        } else state.answerMakerStop.status = RequestStatus.FAILED;
      })
      .addCase(stopGenerateAnswers.rejected, (state, action: PayloadAction<unknown>) => {
        state.answerMakerStop.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.answerMakerStop.error = action.payload.response?.data.error;
          state.answerMakerStop.message = action.payload.response?.data.message;
        }
      })
      .addCase(getStatusGenerateAnswers.pending, (state) => {
        state.answerMakerStatus.requestStatus = RequestStatus.LOADING;
      })
      .addCase(getStatusGenerateAnswers.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.answerMakerStatus.requestStatus = RequestStatus.IDLE;
          state.answerMakerStatus.error = action.payload.error;
          state.answerMakerStatus.responseStatus = action.payload.status;
          state.answerMakerStatus.progress = action.payload.progress;
        } else state.answerMakerStatus.requestStatus = RequestStatus.FAILED;
      })
      .addCase(getStatusGenerateAnswers.rejected, (state, action: PayloadAction<unknown>) => {
        state.answerMakerStatus.requestStatus = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.answerMakerStatus.error = action.payload.response?.data.error;
        }
      })
      .addCase(generateQuestions.pending, (state) => {
        state.questionMakerStart.status = RequestStatus.LOADING;
      })
      .addCase(generateQuestions.fulfilled, (state, action) => {
        if (action.payload) {
          state.questionMakerStart.status = RequestStatus.IDLE;
          state.questionMakerStart.error = action.payload.error;
          state.questionMakerStart.message = action.payload.message;
        } else state.questionMakerStart.status = RequestStatus.FAILED;
      })
      .addCase(generateQuestions.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionMakerStart.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionMakerStart.error = action.payload.response?.data.error;
          state.questionMakerStart.message = action.payload.response?.data.message;
        }
      })
      .addCase(stopGenerateQuestions.pending, (state) => {
        state.questionMakerStop.status = RequestStatus.LOADING;
      })
      .addCase(stopGenerateQuestions.fulfilled, (state, action) => {
        if (action.payload) {
          state.questionMakerStop.status = RequestStatus.IDLE;
          state.questionMakerStop.error = action.payload.error;
          state.questionMakerStop.message = action.payload.message;
        } else state.questionMakerStop.status = RequestStatus.FAILED;
      })
      .addCase(stopGenerateQuestions.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionMakerStop.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionMakerStop.error = action.payload.response?.data.error;
          state.questionMakerStop.message = action.payload.response?.data.message;
        }
      })
      .addCase(getStatusGenerateQuestions.pending, (state) => {
        state.questionMakerStatus.requestStatus = RequestStatus.LOADING;
      })
      .addCase(getStatusGenerateQuestions.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.questionMakerStatus.requestStatus = RequestStatus.IDLE;
          state.questionMakerStatus.error = action.payload.error;
          state.questionMakerStatus.responseStatus = action.payload.status;
          state.questionMakerStatus.progress = action.payload.progress;
        } else state.questionMakerStatus.requestStatus = RequestStatus.FAILED;
      })
      .addCase(getStatusGenerateQuestions.rejected, (state, action: PayloadAction<unknown>) => {
        state.questionMakerStatus.requestStatus = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.questionMakerStatus.error = action.payload.response?.data.error;
        }
      })
      .addCase(processQuestions.pending, (state) => {
        state.processingQuestionsStart.status = RequestStatus.LOADING;
      })
      .addCase(processQuestions.fulfilled, (state, action) => {
        if (action.payload) {
          state.processingQuestionsStart.status = RequestStatus.IDLE;
          state.processingQuestionsStart.error = action.payload.error;
          state.processingQuestionsStart.message = action.payload.message;
        } else state.processingQuestionsStart.status = RequestStatus.FAILED;
      })
      .addCase(processQuestions.rejected, (state, action: PayloadAction<unknown>) => {
        state.processingQuestionsStart.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.processingQuestionsStart.error = action.payload.response?.data.error;
          state.processingQuestionsStart.message = action.payload.response?.data.message;
        }
      })
      .addCase(stopProcessingQuestions.pending, (state) => {
        state.processingQuestionsStop.status = RequestStatus.LOADING;
      })
      .addCase(stopProcessingQuestions.fulfilled, (state, action) => {
        if (action.payload) {
          state.processingQuestionsStop.status = RequestStatus.IDLE;
          state.processingQuestionsStop.error = action.payload.error;
          state.processingQuestionsStop.message = action.payload.message;
        } else state.processingQuestionsStop.status = RequestStatus.FAILED;
      })
      .addCase(stopProcessingQuestions.rejected, (state, action: PayloadAction<unknown>) => {
        state.processingQuestionsStop.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.processingQuestionsStop.error = action.payload.response?.data.error;
          state.processingQuestionsStop.message = action.payload.response?.data.message;
        }
      })
      .addCase(getStatusProcessingQuestions.pending, (state) => {
        state.processingQuestionsStatus.requestStatus = RequestStatus.LOADING;
      })
      .addCase(getStatusProcessingQuestions.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.processingQuestionsStatus.requestStatus = RequestStatus.IDLE;
          state.processingQuestionsStatus.error = action.payload.error;
          state.processingQuestionsStatus.responseStatus = action.payload.status;
          state.processingQuestionsStatus.progress = action.payload.progress;
        } else state.processingQuestionsStatus.requestStatus = RequestStatus.FAILED;
      })
      .addCase(getStatusProcessingQuestions.rejected, (state, action: PayloadAction<unknown>) => {
        state.processingQuestionsStatus.requestStatus = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.processingQuestionsStatus.error = action.payload.response?.data.error;
        }
      })
      .addCase(getLogProcessingQuestions.pending, (state) => {
        state.processingQuestionsLog.status = RequestStatus.LOADING;
      })
      .addCase(getLogProcessingQuestions.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'answers' in action.payload && Array.isArray(action.payload.answers)) {
          state.processingQuestionsLog.status = RequestStatus.IDLE;
          state.processingQuestionsLog.data = action.payload.answers;
        } else state.processingQuestionsLog.status = RequestStatus.FAILED;
      })
      .addCase(getLogProcessingQuestions.rejected, (state, action: PayloadAction<unknown>) => {
        state.processingQuestionsStart.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.processingQuestionsLog.error = action.payload.response?.data.error;
          state.processingQuestionsLog.message = action.payload.response?.data.message;
        }
      })
      .addCase(getGptList.pending, (state) => {
        state.gptList.status = RequestStatus.LOADING;
      })
      .addCase(getGptList.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'models' in action.payload && Array.isArray(action.payload.models)) {
          state.gptList.status = RequestStatus.IDLE;
          state.gptList.data = action.payload.models;
        } else state.gptList.status = RequestStatus.FAILED;
      })
      .addCase(getGptList.rejected, (state, action: PayloadAction<unknown>) => {
        state.gptList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.gptList.error = action.payload.response?.data.error;
          state.gptList.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { changeCategoryFilter, changeQuestionFilter, changeAnswerFilter, changeAnswerTypeFilter, changeProgressAddingFile, addActiveFileId, changeActiveFileName, changeActiveFileCategory, editDocument, editGlossaryDataCellAbbreviation, editGlossaryDataCellTranscript, addRow, deleteRows, changeDataInFilesList, addFragmentForSelection, addActiveQuestionId, editingAnswerAlias, addAlias, deleteAlias, deleteAliases, moveCandidatesToAliases, editingAnswerChecked, editingAnswerChannel, editingQuestionInQuestionsList, setupUpdateQuestionsList, addChannelsIdAndCountAnswers, editingAnswerText, addAnswer, copyAnswer, moveAnswer, deleteAnswer, addCategoryInList, deleteCategoryFromList, changePlaceAddingCategory, specifyDeletionOnlyAnswers, setupDeletionOnlyCandidates, addFlagAutosaveQuestion, clearState, clearFilesList, clearDocument, clearAddFileStatus, clearEditingFileStatus, clearDeletingFileStatus, clearReplaceInDocument, clearAnswerToQuestion, clearFragmentToQuestion, clearFragmentForSelection, clearQuestionsList, clearQuestion, clearAddingQuestion, clearEditingQuestion, clearDeletingQuestion, clearReplaceInQuestion, clearQuestionManipulation, clearCategoriesList, clearAddingCategory, clearEditingCategory, clearDeletingCategory, clearChannelList, clearAddingChannel, clearEditingChannel, clearDeletingChannel, clearAnswerMakerStart, clearAnswerMakerStop, clearAnswerMakerStatus, clearQuestionMakerStart, clearQuestionMakerStop, clearQuestionMakerStatus, clearProcessingQuestionsStart, clearProcessingQuestionsStop, clearProcessingQuestionsStatus, clearProcessingQuestionsLog, clearGptList } = qasSlice.actions;

export const selectFilter = (state: RootState) => state.qas.filter;

export const selectFilesList = (state: RootState) => state.qas.filesList;
export const selectDocument = (state: RootState) => state.qas.document;
export const selectAddingFileStatus = (state: RootState) => state.qas.addingFile;
export const selectEditingFileStatus = (state: RootState) => state.qas.editingFile;
export const selectDeletingFileStatus = (state: RootState) => state.qas.deletingFile;
export const selectReplaceInDocument = (state: RootState) => state.qas.replaceInDocument;

export const selectAnswerToQuestion = (state: RootState) => state.qas.answerToQuestion;
export const selectFragmentToQuestion = (state: RootState) => state.qas.fragmentToQuestion;

export const selectQuestionsList = (state: RootState) => state.qas.questionsList;
export const selectQuestion = (state: RootState) => state.qas.question;
export const selectAddingQuestion = (state: RootState) => state.qas.addingQuestion;
export const selectEditingQuestion = (state: RootState) => state.qas.editingQuestion;
export const selectDeletingQuestion = (state: RootState) => state.qas.deletingQuestion;
export const selectReplaceInQuestion = (state: RootState) => state.qas.replaceInQuestion;

export const selectQuestionsListForMoving = (state: RootState) => state.qas.questionManipulation.questionsList;
export const selectQuestionsForMoving = (state: RootState) => state.qas.questionManipulation.question;
export const selectEditStatusForMoving = (state: RootState) => state.qas.questionManipulation.editingQuestion;
export const selectAppendAliasList = (state: RootState) => state.qas.questionManipulation.appendAliasList;
export const selectApproveCandidateList = (state: RootState) => state.qas.questionManipulation.approveCandidateList;

export const selectCategoriesList = (state: RootState) => state.qas.categoriesList;
export const selectAddingCategory = (state: RootState) => state.qas.addingCategory;
export const selectEditingCategory = (state: RootState) => state.qas.editingCategory;
export const selectDeletingCategory = (state: RootState) => state.qas.deletingCategory;

export const selectChannelList = (state: RootState) => state.qas.channelList;
export const selectAddingChannel = (state: RootState) => state.qas.addingChannel;
export const selectEditingChannel = (state: RootState) => state.qas.editingChannel;
export const selectDeletingChannel = (state: RootState) => state.qas.deletingChannel;

export const selectGeneratingAnswersStartStatus = (state: RootState) => state.qas.answerMakerStart;
export const selectGeneratingAnswersStopStatus = (state: RootState) => state.qas.answerMakerStop;
export const selectGeneratingAnswersProcessStatus = (state: RootState) => state.qas.answerMakerStatus;

export const selectGeneratingQuestionsStartStatus = (state: RootState) => state.qas.questionMakerStart;
export const selectGeneratingQuestionsStopStatus = (state: RootState) => state.qas.questionMakerStop;
export const selectGeneratingQuestionsProcessStatus = (state: RootState) => state.qas.questionMakerStatus;

export const selectProcessingQuestionsStartStatus = (state: RootState) => state.qas.processingQuestionsStart;
export const selectProcessingQuestionsStopStatus = (state: RootState) => state.qas.processingQuestionsStop;
export const selectProcessingQuestionsProcessStatus = (state: RootState) => state.qas.processingQuestionsStatus;
export const selectProcessingQuestionsLog = (state: RootState) => state.qas.processingQuestionsLog;

export const selectGptList = (state: RootState) => state.qas.gptList;

export default qasSlice.reducer;
