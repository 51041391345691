import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileWord } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormExportDocFile from '../../../Forms/FormExportDocFile/FormExportDocFile';
import { IExportToDocProps } from './ExportToDoc.props';

const ExportToDoc = ({ isAvailable, exportDocxFileHandler, exportTxtFileHandler }: IExportToDocProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы выбора типа документа

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_exportDocFile')}>
				<FontAwesomeIcon icon={faFileWord} size="xl" color={backgroundColor} />
			</button>

			{showModal &&
				<FormExportDocFile
					showModal={showModal}
					setShowModal={setShowModal}
					exportDocxFileHandler={exportDocxFileHandler}
					exportTxtFileHandler={exportTxtFileHandler}
				/>
			}
		</>
	);
};

export default ExportToDoc;
