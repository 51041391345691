import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { downloadFile } from '../helpers/downloadFile';
import { ENDPOINTS_SES, URL_SES } from '../constants/api_endpoints';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';
import { ActiveRobotVersionType, DaysType, ExceptionType, IAddingRobotAction, IAddingRobotException, IAddingRobotResponse, IChangeServiceExceptionAsk, IChangeServiceExceptionChat, IChangeServiceRobotAsk, IChangeServiceRobotChat, IDataRobotList, IEditRobotProps, IExportRobotProps, IImportRobotProps, IModelTtsConfig, IResponse, IRobotResponse, ISesRobotState, RobotActionEventType, ServiceDataType } from '../types/sesRobotTypes';
import { Action, ActionType, ActionVariantType, ConditionType, ConditionVariantType, IActionInternalAsk, IActionInternalChat, IActionPhrase, IActionTransfer, IConditionData, IConditionVariable } from '../types/sesTypes';
import { ServiceTypeModelRobot, ServiceTypeRobot } from '../types/cloudTypes';

// получение постфикса id робота в зависимости от версии робота
export const getPostfixRobotId = (state: RootState, roboId: string): string => {
  if (state.sesRobot.robotList.activeRobotVersion === 'prod') return roboId + '_prod';
  else if (state.sesRobot.robotList.activeRobotVersion === 'backup') return roboId + '_backup';
  else return roboId;
};

const initialState: ISesRobotState = {
  robotList: {
    data: [],
    activeRobotId: null,
    activeRobotVersion: 'draft',
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  robot: {
    data: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    hasProd: false,
    hasBackup: false,
  },
  addingRobot: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
  },
  editingRobot: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  deletingRobot: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  commitConfigRobot: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  applyingRobot: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  restorationRobot: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  clearDraftingRobot: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  importRobot: {
    progress: 0,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  exportRobot: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// список роботов
export const getRobotList = createAsyncThunk(
  'ses/getRobotList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IDataRobotList[] | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ROBOT_LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// данные робота
export const getRobot = createAsyncThunk(
  'ses/getRobot',
  async (robotId: string, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IRobotResponse | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ROBOT_GET}/${getPostfixRobotId(getState() as RootState, robotId)}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление робота
export const addRobot = createAsyncThunk(
  'ses/addRobot',
  async (robotName: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IAddingRobotResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.ROBOT_ADD}`, {
        name: robotName,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение данных робота
export const editRobot = createAsyncThunk(
  'ses/editRobot',
  async ({ robotId, data }: IEditRobotProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.ROBOT_EDIT}/${robotId}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление робота
export const deleteRobot = createAsyncThunk(
  'ses/deleteRobot',
  async (robotId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_SES}/${ENDPOINTS_SES.ROBOT_DELETE}/${robotId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// применение настроек робота
export const commitConfigRobot = createAsyncThunk(
  'ses/commitConfigRobot',
  async (robotId: string, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ROBOT_COMMIT_CONFIG}/${getPostfixRobotId(getState() as RootState, robotId)}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// применение черновика робота в прод
export const applyRobot = createAsyncThunk(
  'ses/applyRobot',
  async (robotId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ROBOT_APPLY}/${robotId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// восстановление робота из бэкапа
export const restoreRobot = createAsyncThunk(
  'ses/restoreRobot',
  async (robotId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ROBOT_RESTORE}/${robotId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// обнуление черновика роботом прода
export const clearDraftRobot = createAsyncThunk(
  'ses/clearDraftRobot',
  async (robotId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ROBOT_CLEAR_DRAFT}/${robotId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// импорт робота из .zip файла
export const importRobot = createAsyncThunk(
  'ses/importRobot',
  async ({ robotId, formData }: IImportRobotProps, { dispatch, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.ROBOT_IMPORT}/${robotId}`, {
        zip: formData.get('file'),
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          if (progressEvent.progress) {
            const progress = Math.round(progressEvent.progress * 100);
            dispatch(changeProgressImportRobot(progress));
          }
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// экспорт робота (.zip файл)
export const exportRobot = createAsyncThunk(
  'ses/exportRobot',
  async ({ robotId, robotName }: IExportRobotProps, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<Blob | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ROBOT_EXPORT}/${getPostfixRobotId(getState() as RootState, robotId)}`, {
        responseType: 'blob',
      });
      if (response.data instanceof Blob) {
        downloadFile(response.data, `${robotName}.zip`);
      } else return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const sesRobotSlice = createSlice({
  name: 'ses',
  initialState,
  reducers: {
    // добавление id активного робота
    addingActiveRobotId: (state, action: PayloadAction<string>) => {
      state.robotList.activeRobotId = action.payload;
    },
    // изменение активной версии робота
    changeActiveRobotVersion: (state, action: PayloadAction<ActiveRobotVersionType>) => {
      state.robotList.activeRobotVersion = action.payload;
    },
    // добавление возможных версий робота
    addingRobotPossibleVersions: (state, action: PayloadAction<{ hasProd: boolean, hasBackup: boolean }>) => {
      state.robot.hasProd = action.payload.hasProd;
      state.robot.hasBackup = action.payload.hasBackup;
    },
    // изменение возможных версий робота в списке
    changeRobotPossibleVersionsInList: (state, action: PayloadAction<{ hasProd?: boolean, hasBackup?: boolean }>) => {
      state.robotList.data = state.robotList.data.map((robotItem) => {
        if (robotItem.id === state.robotList.activeRobotId) {
          return {
            ...robotItem,
            hasProd: action.payload.hasProd !== undefined ? action.payload.hasProd : robotItem.hasProd,
            hasBackup: action.payload.hasBackup !== undefined ? action.payload.hasBackup : robotItem.hasBackup,
          };
        } else return robotItem;
      });
    },
    // изменение имени робота
    editRobotName: (state, action: PayloadAction<{ robotId: string, robotName: string }>) => {
      state.robotList.data = state.robotList.data.map(robot => {
        if (robot.id === action.payload.robotId) return { ...robot, name: action.payload.robotName };
        else return robot;
      });
    },
    // -------------------------------------------------------------------------
    // добавление действия робота
    addingRobotAction: (state, action: PayloadAction<IAddingRobotAction>) => {
      if (state.robot.data) {
        // если есть событие действия
        if (action.payload.actionEvent in state.robot.data.actions) {
          // и если есть канал
          if (action.payload.channel in state.robot.data.actions[action.payload.actionEvent]) {
            state.robot.data.actions[action.payload.actionEvent][action.payload.channel].push(action.payload.action); // добавляем действие
          }
          // если нет канала - создаем
          else {
            state.robot.data.actions[action.payload.actionEvent][action.payload.channel] = [action.payload.action];
          }
        }
        // если нет события действия - создаем событие и канал
        else {
          state.robot.data.actions[action.payload.actionEvent] = {
            [action.payload.channel]: [action.payload.action],
          };
        }
      }
    },
    // изменение действия робота
    changeActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, idx: number, action: Action }>) => {
      if (state.robot.data) {
        const robotAction = state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx];
        if (action.payload.action === 'say') {
          state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
            action: 'say',
            type: 'linear',
            text: [''],
            conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
          };
        } else if (action.payload.action === 'end') {
          state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
            action: 'end',
            conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
          };
        } else {
          state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
            action: 'transfer',
            destination: '',
            conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
          };
        }
      }
    },
    // удаление действия робота
    deleteActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, idx: number }>) => {
      state.robot.data?.actions[action.payload.actionEvent][action.payload.channel].splice(action.payload.idx, 1);
    },
    // изменение последовательности действий робота
    replaceRobotActionList: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, actionList: ActionVariantType[] }>) => {
      if (state.robot.data) {
        state.robot.data.actions[action.payload.actionEvent][action.payload.channel] = action.payload.actionList;
      }
    },
    // изменение типа действия робота
    changeActionTypeRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, idx: number, action: Action, actionType: ActionType, text?: string[] }>) => {
      if (state.robot.data) {
        const robotAction = state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx];
        if (action.payload.action === 'say') {
          switch (action.payload.actionType) {
            case 'linear':
            case 'random':
              state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                text: action.payload.text || [''],
                conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
              };
              break;
            case 'external':
              state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                script: '',
                conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
              };
              break;
            case 'internal':
              state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                service: 'qas-ask',
                categories: {},
                useCache: 'checked',
                useGPT: 'no',
                creative: 'no',
                addAlias: 'no',
                conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
                model: 'default',
              };
              break;
            default:
              break;
          }
        }
      }
    },
    // добавление фразы в действие робота (по факту - перезапись с добавлением)
    addPhraseInActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, idx: number, actionObj: IActionPhrase }>) => {
      if (state.robot.data) {
        state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = action.payload.actionObj;
      }
    },
    // изменение фразы в действии робота
    changePhraseInActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, idxAction: number, idxPhrase: number, text: string }>) => {
      if (state.robot.data) {
        (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idxAction] as IActionPhrase).text[action.payload.idxPhrase] = action.payload.text;
      }
    },
    // удаление фразы в действии робота
    deletePhraseInActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, idxAction: number, idxPhrase: number }>) => {
      if (state.robot.data) {
        (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idxAction] as IActionPhrase).text.splice(action.payload.idxPhrase, 1);
      }
    },
    // изменение скрипта действия робота
    changeScriptRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, idx: number, script: string }>) => {
      if (state.robot.data) {
        const robotAction = state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx];
        state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
          action: state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
          type: 'external',
          script: action.payload.script,
          conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
        };
      }
    },
    // изменение данных сервиса действия робота
    changeServiceRobot: (state, action: PayloadAction<IChangeServiceRobotAsk | IChangeServiceRobotChat>) => {
      if (state.robot.data) {
        if ('depth' in action.payload) {
          state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
            action: state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
            type: 'internal',
            service: action.payload.service,
            depth: action.payload.depth,
            model: action.payload.model,
          };
        } else {
          state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
            action: state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
            type: 'internal',
            service: action.payload.service,
            categories: (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] as IActionInternalAsk).categories,
            useCache: action.payload.useCache,
            useGPT: action.payload.useGPT,
            creative: action.payload.creative,
            addAlias: action.payload.addAlias,
            conditions: (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] as IActionInternalAsk).conditions,
            model: action.payload.model,
          };
        }
      }
    },
    // изменение места назначения действия робота
    changeDestinationRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, idxAction: number, destination: string }>) => {
      if (state.robot.data) {
        (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.idxAction] as IActionTransfer).destination = action.payload.destination;
      }
    },
    // добавление данных категории робота
    addCategoryDataRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, data: IConditionData | IConditionVariable }>) => {
      if (state.robot.data) {
        const actionInternal = state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternalAsk;
        if ('categories' in actionInternal) {
          if (action.payload.categoryId in actionInternal.categories) {
            if (actionInternal.categories[action.payload.categoryId].length > action.payload.categoryBlockIdx) {
              actionInternal.categories[action.payload.categoryId][action.payload.categoryBlockIdx].push(action.payload.data);
            } else {
              actionInternal.categories[action.payload.categoryId].push([action.payload.data]);
            }
          } else {
            actionInternal.categories[action.payload.categoryId] = [[action.payload.data]];
          }
        } else {
          (actionInternal as IActionInternalAsk).categories = {
            [action.payload.categoryId]: [[action.payload.data]]
          };
        }
      }
    },
    // изменение типа данных категории робота
    changeTypeCategoryRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number, conditionType: Extract<ConditionType, 'data' | 'variable'> }>) => {
      if (state.robot.data) {
        const creatingObjectWithCondition = (type: Extract<ConditionType, 'data' | 'variable'>): IConditionData | IConditionVariable => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            // case 'interval':
            //   return {
            //     type,
            //     id: '',
            //   };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternalAsk).categories[action.payload.categoryId][action.payload.categoryBlockIdx].splice(action.payload.categoryIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение данных категории робота
    changeCategoryDataRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number, data: IConditionData }>) => {
      if (state.robot.data) {
        (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternalAsk).categories[action.payload.categoryId][action.payload.categoryBlockIdx][action.payload.categoryIdx] = action.payload.data;
      }
    },
    // удаление данных категории робота
    deleteCategoryDataRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number }>) => {
      if (state.robot.data) {
        const actionInternalCategoryId = (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternalAsk).categories[action.payload.categoryId];
        if (actionInternalCategoryId[action.payload.categoryBlockIdx].length > 1) {
          actionInternalCategoryId[action.payload.categoryBlockIdx].splice(action.payload.categoryIdx, 1);
        } else {
          if (actionInternalCategoryId.length > 1) {
            actionInternalCategoryId.splice(action.payload.categoryBlockIdx, 1);
          } else {
            delete (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternalAsk).categories[action.payload.categoryId];
          }
        }
      }
    },
    // -------------------------------------------------------------------------
    // добавление условия запуска действия робота
    addConditionRunActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, channelIdx: number, conditionBlockIdx: number, data: ConditionVariantType }>) => {
      if (state.robot.data) {
        const conditions = (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>).conditions; // условия запуска действия
        if (Array.isArray(conditions)) {
          if (conditions[action.payload.conditionBlockIdx]) conditions[action.payload.conditionBlockIdx].push(action.payload.data);
          else conditions.push([action.payload.data]);
        } else {
          state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] = {
            ...(state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>),
            conditions: [[action.payload.data]],
          };
        }
      }
    },
    // изменение типа условия запуска действия робота
    changeTypeConditionRunActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, conditionType: ConditionType }>) => {
      if (state.robot.data) {
        const creatingObjectWithCondition = (type: ConditionType): ConditionVariantType => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'interval':
              return {
                type,
                id: '',
              };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>).conditions?.[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение значений условия запуска действия робота
    changeValuesConditionRunActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, data: ConditionVariantType }>) => {
      if (state.robot.data) {
        (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>).conditions?.[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, action.payload.data);
      }
    },
    // удаление условия запуска действия робота
    deleteConditionRunActionRobot: (state, action: PayloadAction<{ actionEvent: RobotActionEventType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, }>) => {
      if (state.robot.data) {
        const conditions = (state.robot.data.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>).conditions; // условия
        if (conditions && conditions[action.payload.conditionBlockIdx].length > 1) conditions[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1);
        else conditions?.splice(action.payload.conditionBlockIdx, 1);
      }
    },
    // -------------------------------------------------------------------------
    // изменение максимального количества исключений подряд
    changeMaxNumberException: (state, action: PayloadAction<{ exception: ExceptionType, max: number }>) => {
      if (state.robot.data) {
        // если есть исключение
        if (action.payload.exception in state.robot.data.exceptions) {
          // если есть поле max - меняем значение
          if ('max' in state.robot.data.exceptions[action.payload.exception]) {
            state.robot.data.exceptions[action.payload.exception].max = action.payload.max;
          }
          // иначе создаем поле max
          else {
            state.robot.data.exceptions[action.payload.exception] = {
              ...state.robot.data.exceptions[action.payload.exception],
              max: action.payload.max,
            };
          }
        }
        // иначе создаем исключение и поле max
        else {
          state.robot.data.exceptions = {
            ...state.robot.data.exceptions,
            [action.payload.exception]: {
              max: action.payload.max,
            },
          };
        }
      }
    },
    // добавление исключения робота
    addingRobotException: (state, action: PayloadAction<IAddingRobotException>) => {
      if (state.robot.data) {
        // если есть исключение
        if (action.payload.exception in state.robot.data.exceptions) {
          // и если есть канал
          if (state.robot.data.exceptions[action.payload.exception].actions && state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel]) {
            state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel].push(action.payload.action); // добавляем действие
          }
          // если нет канала - создаем
          else {
            state.robot.data.exceptions[action.payload.exception].actions = {
              ...state.robot.data.exceptions[action.payload.exception].actions,
              [action.payload.channel]: [action.payload.action],
            };
          }
        }
        // если нет события действия - создаем событие, канал
        else {
          state.robot.data.exceptions = {
            ...state.robot.data.exceptions,
            [action.payload.exception]: {
              actions: {
                [action.payload.channel]: [action.payload.action],
              },
            },
          };
        }
      }
    },
    // изменение действия исключения
    changeActionException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, idx: number, action: Action }>) => {
      if (state.robot.data) {
        const robotAction = state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx];
        if (action.payload.action === 'say') {
          state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
            action: 'say',
            type: 'linear',
            text: [''],
            conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
          };
        } else if (action.payload.action === 'end') {
          state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
            action: 'end',
            conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
          };
        } else {
          state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
            action: 'transfer',
            destination: '',
            conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
          };
        }
      }
    },
    // изменение типа действия исключения
    changeActionTypeException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, idx: number, action: Action, actionType: ActionType, text?: string[] }>) => {
      if (state.robot.data) {
        const robotAction = state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx];
        if (action.payload.action === 'say') {
          switch (action.payload.actionType) {
            case 'linear':
            case 'random':
              state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                text: action.payload.text || [''],
                conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
              };
              break;
            case 'external':
              state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                script: '',
                conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
              };
              break;
            case 'internal':
              state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                service: 'qas-ask',
                categories: {},
                useCache: 'checked',
                useGPT: 'no',
                creative: 'no',
                addAlias: 'no',
                conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
                model: 'default',
              };
              break;
            default:
              break;
          }
        }
      }
    },
    // удаление исключения робота
    deleteRobotException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, idx: number }>) => {
      state.robot.data?.exceptions[action.payload.exception].actions[action.payload.channel].splice(action.payload.idx, 1);
    },
    // изменение последовательности исключений робота
    replaceRobotExceptionList: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, actionList: ActionVariantType[] }>) => {
      if (state.robot.data) {
        state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel] = action.payload.actionList;
      }
    },
    // добавление фразы исключения (по факту перезапись с добавлением)
    addPhraseInException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, idx: number, actionObj: IActionPhrase }>) => {
      if (state.robot.data) {
        state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = action.payload.actionObj;
      }
    },
    // изменение фразы исключения
    changePhraseInException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, idxAction: number, idxPhrase: number, text: string }>) => {
      if (state.robot.data) {
        (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idxAction] as IActionPhrase).text[action.payload.idxPhrase] = action.payload.text;
      }
    },
    // удаление фразы в исключения
    deletePhraseInException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, idxAction: number, idxPhrase: number }>) => {
      if (state.robot.data) {
        (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idxAction] as IActionPhrase).text.splice(action.payload.idxPhrase, 1);
      }
    },
    // изменение скрипта исключения
    changeScriptException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, idx: number, script: string }>) => {
      if (state.robot.data) {
        const robotAction = state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx];
        state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
          action: state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
          type: 'external',
          script: action.payload.script,
          conditions: 'conditions' in robotAction ? robotAction.conditions : undefined,
        };
      }
    },
    // изменение данных сервиса исключения
    changeServiceException: (state, action: PayloadAction<IChangeServiceExceptionAsk | IChangeServiceExceptionChat>) => {
      if (state.robot.data) {
        if ('depth' in action.payload) {
          state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
            action: state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
            type: 'internal',
            service: action.payload.service,
            depth: action.payload.depth,
            model: action.payload.model,
          };
        } else {
          state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] = {
            action: state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
            type: 'internal',
            service: action.payload.service,
            categories: (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] as IActionInternalAsk).categories,
            useCache: action.payload.useCache,
            useGPT: action.payload.useGPT,
            creative: action.payload.creative,
            addAlias: action.payload.addAlias,
            conditions: (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idx] as IActionInternalAsk).conditions,
            model: action.payload.model,
          };
        }
      }
    },
    // изменение места назначения исключения
    changeDestinationException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, idxAction: number, destination: string }>) => {
      if (state.robot.data) {
        (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.idxAction] as IActionTransfer).destination = action.payload.destination;
      }
    },
    // добавление данных категории исключения
    addCategoryDataException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, data: IConditionData | IConditionVariable }>) => {
      if (state.robot.data) {
        const actionExeptionInternal = state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as IActionInternalAsk;
        if ('categories' in actionExeptionInternal) {
          if (action.payload.categoryId in actionExeptionInternal.categories) {
            if (actionExeptionInternal.categories[action.payload.categoryId].length > action.payload.categoryBlockIdx) {
              actionExeptionInternal.categories[action.payload.categoryId][action.payload.categoryBlockIdx].push(action.payload.data);
            } else {
              actionExeptionInternal.categories[action.payload.categoryId].push([action.payload.data]);
            }
          } else {
            actionExeptionInternal.categories[action.payload.categoryId] = [[action.payload.data]];
          }
        } else {
          (actionExeptionInternal as IActionInternalAsk).categories = {
            [action.payload.categoryId]: [[action.payload.data]]
          };
        }
      }
    },
    // изменение типа данных категории исключения
    changeTypeCategoryException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number, conditionType: Extract<ConditionType, 'data' | 'variable'> }>) => {
      if (state.robot.data) {
        const creatingObjectWithCondition = (type: Extract<ConditionType, 'data' | 'variable'>): IConditionData | IConditionVariable => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            // case 'interval':
            //   return {
            //     type,
            //     id: '',
            //   };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as IActionInternalAsk).categories[action.payload.categoryId][action.payload.categoryBlockIdx].splice(action.payload.categoryIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение данных категории исключения
    changeCategoryDataException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number, data: IConditionData }>) => {
      if (state.robot.data) {
        (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as IActionInternalAsk).categories[action.payload.categoryId][action.payload.categoryBlockIdx][action.payload.categoryIdx] = action.payload.data;
      }
    },
    // удаление данных категории исключения
    deleteCategoryDataException: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number }>) => {
      if (state.robot.data) {
        const actionInternalCategoryId = (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as IActionInternalAsk).categories[action.payload.categoryId];
        if (actionInternalCategoryId[action.payload.categoryBlockIdx].length > 1) {
          actionInternalCategoryId[action.payload.categoryBlockIdx].splice(action.payload.categoryIdx, 1);
        } else {
          if (actionInternalCategoryId.length > 1) {
            actionInternalCategoryId.splice(action.payload.categoryBlockIdx, 1);
          } else {
            delete (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as IActionInternalAsk).categories[action.payload.categoryId];
          }
        }
      }
    },
    // -------------------------------------------------------------------------
    // добавление условия запуска действия исключения робота
    addConditionRunActionExceptionRobot: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, channelIdx: number, conditionBlockIdx: number, data: ConditionVariantType }>) => {
      if (state.robot.data) {
        const conditions = (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>).conditions; // условия запуска действия
        if (Array.isArray(conditions)) {
          if (conditions[action.payload.conditionBlockIdx]) conditions[action.payload.conditionBlockIdx].push(action.payload.data);
          else conditions.push([action.payload.data]);
        } else {
          state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] = {
            ...(state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>),
            conditions: [[action.payload.data]],
          };
        }
      }
    },
    // изменение типа условия запуска действия исключения робота
    changeTypeConditionRunActionExceptionRobot: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, conditionType: ConditionType }>) => {
      if (state.robot.data) {
        const creatingObjectWithCondition = (type: ConditionType): ConditionVariantType => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'interval':
              return {
                type,
                id: '',
              };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>).conditions?.[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение значений условия запуска действия исключения робота
    changeValuesConditionRunActionExceptionRobot: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, data: ConditionVariantType }>) => {
      if (state.robot.data) {
        (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>).conditions?.[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, action.payload.data);
      }
    },
    // удаление условия запуска действия исключения робота
    deleteConditionRunActionExceptionRobot: (state, action: PayloadAction<{ exception: ExceptionType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, }>) => {
      if (state.robot.data) {
        const conditions = (state.robot.data.exceptions[action.payload.exception].actions[action.payload.channel][action.payload.channelIdx] as Exclude<ActionVariantType, IActionInternalChat>).conditions; // условия
        if (conditions && conditions[action.payload.conditionBlockIdx].length > 1) conditions[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1);
        else conditions?.splice(action.payload.conditionBlockIdx, 1);
      }
    },
    // -------------------------------------------------------------------------
    // добавление сервисных данных робота
    addServiceData: (state, action: PayloadAction<{ serviceDataName: ServiceDataType, type: ServiceTypeRobot }>) => {
      if (state.robot.data) {
        state.robot.data.servicedata[action.payload.serviceDataName] = {
          type: action.payload.type,
          model: '',
          param: '',
        };
      }
    },
    // изменение типа сервисных данных робота
    changeTypeServiceData: (state, action: PayloadAction<{ serviceDataName: ServiceDataType, type: ServiceTypeRobot }>) => {
      if (state.robot.data) {
        state.robot.data.servicedata[action.payload.serviceDataName].type = action.payload.type;
      }
    },
    // изменение модели сервисных данных робота
    changeModelServiceData: (state, action: PayloadAction<{ serviceDataName: ServiceDataType, model: string }>) => {
      if (state.robot.data) {
        state.robot.data.servicedata[action.payload.serviceDataName].model = action.payload.model;
      }
    },
    // изменение класса/сущности сервисных данных робота
    changeParamServiceData: (state, action: PayloadAction<{ serviceDataName: ServiceDataType, param: string }>) => {
      if (state.robot.data) {
        state.robot.data.servicedata[action.payload.serviceDataName].param = action.payload.param;
      }
    },
    // удаление сервисных данных робота
    deleteServiceData: (state, action: PayloadAction<{ serviceDataName: ServiceDataType }>) => {
      if (state.robot.data) {
        delete state.robot.data.servicedata[action.payload.serviceDataName];
      }
    },
    // -------------------------------------------------------------------------
    // изменение порога доверия в настройках модели робота
    changeThresholdInModelConfig: (state, action: PayloadAction<{ serviceType: ServiceTypeModelRobot, modelName: string, threshold: number }>) => {
      if (state.robot.data) {
        if (action.payload.serviceType === 'smc') {
          state.robot.data.models.smc[action.payload.modelName].confidenceThreshold = action.payload.threshold;
        } else if (action.payload.serviceType === 'see') {
          state.robot.data.models.see[action.payload.modelName].threshold = action.payload.threshold;
        }
      }
    },
    // изменение флага включения коррекции (smc)
    changeCorrectionInModelConfig: (state, action: PayloadAction<{ modelName: string, correction: 0 | 1 }>) => {
      if (state.robot.data) {
        state.robot.data.models.smc[action.payload.modelName].correction = action.payload.correction;
      }
    },
    // изменение настроек голоса в настройках модели робота
    changeSettingVoiceInModelConfig: (state, action: PayloadAction<{ serviceType: ServiceTypeModelRobot, modelName: string, setting: IModelTtsConfig }>) => {
      if (state.robot.data) {
        state.robot.data.models.tts[action.payload.modelName] = action.payload.setting;
      }
    },
    // изменение модели в настройках модели робота
    changeModelnameInModelConfig: (state, action: PayloadAction<{ serviceType: ServiceTypeModelRobot, oldModelName: string, newModelName: string }>) => {
      if (state.robot.data) {
        state.robot.data.models[action.payload.serviceType][action.payload.newModelName] = state.robot.data.models[action.payload.serviceType][action.payload.oldModelName];
        delete state.robot.data.models[action.payload.serviceType][action.payload.oldModelName];
      }
    },
    // добавление настроек модели робота
    addModelConfig: (state, action: PayloadAction<{ serviceType: ServiceTypeModelRobot, modelName: string }>) => {
      if (state.robot.data) {
        if (action.payload.serviceType === 'smc') {
          state.robot.data.models[action.payload.serviceType] = {
            ...state.robot.data.models[action.payload.serviceType],
            [action.payload.modelName]: { confidenceThreshold: 0, correction: 1 },
          };
        } else if (action.payload.serviceType === 'see') {
          state.robot.data.models[action.payload.serviceType] = {
            ...state.robot.data.models[action.payload.serviceType],
            [action.payload.modelName]: { threshold: 0 },
          };
        } else if (action.payload.serviceType === 'tts') {
          state.robot.data.models[action.payload.serviceType] = {
            ...state.robot.data.models[action.payload.serviceType],
            [action.payload.modelName]: { pitch: 100, rate: 100, volume: 100, frequency: 22050 },
          };
        }
      }
    },
    // удаление настроек модели робота
    deleteModelConfig: (state, action: PayloadAction<{ serviceType: ServiceTypeModelRobot, modelName: string }>) => {
      if (state.robot.data) {
        delete state.robot.data.models[action.payload.serviceType][action.payload.modelName];
      }
    },
    // -------------------------------------------------------------------------
    // изменение времени жизни сессии робота
    changeSessionLifeTime: (state, action: PayloadAction<number>) => {
      if (state.robot.data) {
        state.robot.data.session.lifetime = action.payload;
      }
    },
    // -------------------------------------------------------------------------
    // добавление фразы/действия фразы остановки робота
    addRobotStopPhrase: (state, action: PayloadAction<{ isPhrase: 'stopActionPhrases' | 'stopWordList', phrase: string }>) => {
      if (state.robot.data) {
        state.robot.data[action.payload.isPhrase].push(action.payload.phrase);
      }
    },
    // изменение фразы/действия фразы остановки робота
    changeRobotStopPhrase: (state, action: PayloadAction<{ isPhrase: 'stopActionPhrases' | 'stopWordList', phrase: string, indexPhrase: number }>) => {
      if (state.robot.data) {
        state.robot.data[action.payload.isPhrase][action.payload.indexPhrase] = action.payload.phrase;
      }
    },
    // удаление фразы/действия фразы остановки робота
    deleteRobotStopPhrase: (state, action: PayloadAction<{ isPhrase: 'stopActionPhrases' | 'stopWordList', indexPhrase: number }>) => {
      if (state.robot.data) {
        state.robot.data[action.payload.isPhrase].splice(action.payload.indexPhrase, 1);
      }
    },
    // -------------------------------------------------------------------------
    // добавление интервала робота
    addInterval: (state, action: PayloadAction<string>) => {
      if (state.robot.data) {
        state.robot.data.timeintervals[action.payload] = {
          days: 'all',
          weekdays: new Array(7).fill(1).map((item, idx) => item + idx),
          months: new Array(12).fill(1).map((item, idx) => item + idx),
          dates: new Array(31).fill(1).map((item, idx) => item + idx),
          intervals: [[0, 1440]],
        };
      }
    },
    // изменение имени интервала
    changeNameInterval: (state, action: PayloadAction<{ oldName: string, newName: string }>) => {
      if (state.robot.data) {
        state.robot.data.timeintervals[action.payload.newName] = state.robot.data.timeintervals[action.payload.oldName];
        delete state.robot.data.timeintervals[action.payload.oldName];
      }
    },
    // изменение дней по производственному календарю
    changeDaysInterval: (state, action: PayloadAction<{ intervalName: string, days: DaysType }>) => {
      if (state.robot.data) {
        state.robot.data.timeintervals[action.payload.intervalName].days = action.payload.days;
      }
    },
    // изменение дней недели/месяцев/дней месяца
    changeDataInterval: (state, action: PayloadAction<{ intervalName: string, data: number[], objectOfChange: 'weekdays' | 'months' | 'dates' }>) => {
      if (state.robot.data) {
        state.robot.data.timeintervals[action.payload.intervalName][action.payload.objectOfChange] = action.payload.data;
      }
    },
    // добавление времени интервала
    addTimeInterval: (state, action: PayloadAction<string>) => {
      if (state.robot.data) {
        state.robot.data.timeintervals[action.payload].intervals.push([0, 1440]);
      }
    },
    // изменение времени интервала
    changeTimeInterval: (state, action: PayloadAction<{ intervalName: string, data: [number, number], idx: number }>) => {
      if (state.robot.data) {
        state.robot.data.timeintervals[action.payload.intervalName].intervals[action.payload.idx] = action.payload.data;
      }
    },
    // удаление времени интервала
    deleteTimeInterval: (state, action: PayloadAction<{ intervalName: string, idx: number }>) => {
      if (state.robot.data) {
        state.robot.data.timeintervals[action.payload.intervalName].intervals.splice(action.payload.idx, 1);
      }
    },
    // удаление интервала робота
    deleteInterval: (state, action: PayloadAction<string>) => {
      if (state.robot.data) {
        delete state.robot.data.timeintervals[action.payload];
      }
    },
    // -------------------------------------------------------------------------
    // изменение прогресса импорта робота в %
    changeProgressImportRobot: (state, action: PayloadAction<number>) => {
      state.importRobot.progress = action.payload;
    },
    // -------------------------------------------------------------------------
    // очистка state
    clearState: (state) => {
      state.robotList = initialState.robotList;
      state.robot = initialState.robot;
      state.addingRobot = initialState.addingRobot;
      state.editingRobot = initialState.editingRobot;
      state.deletingRobot = initialState.deletingRobot;
      state.commitConfigRobot = initialState.commitConfigRobot;
      state.applyingRobot = initialState.applyingRobot;
      state.restorationRobot = initialState.restorationRobot;
      state.clearDraftingRobot = initialState.clearDraftingRobot;
      state.importRobot = initialState.importRobot;
      state.exportRobot = initialState.exportRobot;
    },
    // очистка списка роботов
    clearRobotList: (state) => {
      state.robotList = initialState.robotList;
    },
    // очистка данных робота
    clearRobot: (state) => {
      state.robot = initialState.robot;
    },
    // очистка данных робота, кроме статусов версионности
    clearRobotExceptVersions: (state) => {
      state.robot.data = initialState.robot.data;
      state.robot.error = initialState.robot.error;
      state.robot.message = initialState.robot.message;
      state.robot.status = initialState.robot.status;
    },
    // очистка статуса добавления робота
    clearAddingRobot: (state) => {
      state.addingRobot = initialState.addingRobot;
    },
    // очистка статуса изменения данных робота
    clearEditingRobot: (state) => {
      state.editingRobot = initialState.editingRobot;
    },
    // очистка статуса удаления робота
    clearDeletingRobot: (state) => {
      state.deletingRobot = initialState.deletingRobot;
    },
    // очистка статуса применения настроек робота
    clearCommitConfigRobot: (state) => {
      state.commitConfigRobot = initialState.commitConfigRobot;
    },
    // очистка статуса применения робота в прод
    clearApplyingRobot: (state) => {
      state.applyingRobot = initialState.applyingRobot;
    },
    // очистка статуса восстановления модели
    clearRestorationRobot: (state) => {
      state.restorationRobot = initialState.restorationRobot;
    },
    // очистка статуса обнуления черновика продовым роботом
    clearClearDraftingRobot: (state) => {
      state.clearDraftingRobot = initialState.clearDraftingRobot;
    },
    // очистка статуса импорта робота
    clearImportRobot: (state) => {
      state.importRobot = initialState.importRobot;
    },
    // очистка статуса импорта робота
    clearExportRobot: (state) => {
      state.exportRobot = initialState.exportRobot;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRobotList.pending, (state) => {
        state.robotList.status = RequestStatus.LOADING;
      })
      .addCase(getRobotList.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.robotList.status = RequestStatus.IDLE;
          state.robotList.data = action.payload.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
        } else state.robotList.status = RequestStatus.FAILED;
      })
      .addCase(getRobotList.rejected, (state, action: PayloadAction<unknown>) => {
        state.robotList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.robotList.error = action.payload.response?.data.error;
          state.robotList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getRobot.pending, (state) => {
        state.robot.status = RequestStatus.LOADING;
      })
      .addCase(getRobot.fulfilled, (state, action) => {
        if (action.payload && 'data' in action.payload) {
          state.robot.status = RequestStatus.IDLE;
          state.robot.data = action.payload.data;
        } else state.robot.status = RequestStatus.FAILED;
      })
      .addCase(getRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.robot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.robot.error = action.payload.response?.data.error;
          state.robot.message = action.payload.response?.data.message;
        }
      })
      .addCase(addRobot.pending, (state) => {
        state.addingRobot.status = RequestStatus.LOADING;
      })
      .addCase(addRobot.fulfilled, (state, action) => {
        if (action.payload) {
          state.addingRobot.status = RequestStatus.IDLE;
          state.addingRobot.error = action.payload.error;
          state.addingRobot.message = action.payload.message;
          if (action.payload.id) {
            state.addingRobot.id = action.payload.id;
          }
        } else state.addingRobot.status = RequestStatus.FAILED;
      })
      .addCase(addRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingRobot.error = action.payload.response?.data.error;
          state.addingRobot.message = action.payload.response?.data.message;
        }
      })
      .addCase(editRobot.pending, (state) => {
        state.editingRobot.status = RequestStatus.LOADING;
      })
      .addCase(editRobot.fulfilled, (state, action) => {
        if (action.payload) {
          state.editingRobot.status = RequestStatus.IDLE;
          state.editingRobot.error = action.payload.error;
          state.editingRobot.message = action.payload.message;
        } else state.editingRobot.status = RequestStatus.FAILED;
      })
      .addCase(editRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingRobot.error = action.payload.response?.data.error;
          state.editingRobot.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteRobot.pending, (state) => {
        state.deletingRobot.status = RequestStatus.LOADING;
      })
      .addCase(deleteRobot.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingRobot.status = RequestStatus.IDLE;
          state.deletingRobot.error = action.payload.error;
          state.deletingRobot.message = action.payload.message;
        } else state.deletingRobot.status = RequestStatus.FAILED;
      })
      .addCase(deleteRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingRobot.error = action.payload.response?.data.error;
          state.deletingRobot.message = action.payload.response?.data.message;
        }
      })
      .addCase(commitConfigRobot.pending, (state) => {
        state.commitConfigRobot.status = RequestStatus.LOADING;
      })
      .addCase(commitConfigRobot.fulfilled, (state, action) => {
        if (action.payload) {
          state.commitConfigRobot.status = RequestStatus.IDLE;
          state.commitConfigRobot.error = action.payload.error;
          state.commitConfigRobot.message = action.payload.message;
        } else state.commitConfigRobot.status = RequestStatus.FAILED;
      })
      .addCase(commitConfigRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.commitConfigRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.commitConfigRobot.error = action.payload.response?.data.error;
          state.commitConfigRobot.message = action.payload.response?.data.message;
        }
      })
      .addCase(applyRobot.pending, (state) => {
        state.applyingRobot.status = RequestStatus.LOADING;
      })
      .addCase(applyRobot.fulfilled, (state, action) => {
        if (action.payload) {
          state.applyingRobot.status = RequestStatus.IDLE;
          state.applyingRobot.error = action.payload.error;
          state.applyingRobot.message = action.payload.message;
        } else state.applyingRobot.status = RequestStatus.FAILED;
      })
      .addCase(applyRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.applyingRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.applyingRobot.error = action.payload.response?.data.error;
          state.applyingRobot.message = action.payload.response?.data.message;
        }
      })
      .addCase(restoreRobot.pending, (state) => {
        state.restorationRobot.status = RequestStatus.LOADING;
      })
      .addCase(restoreRobot.fulfilled, (state, action) => {
        if (action.payload) {
          state.restorationRobot.status = RequestStatus.IDLE;
          state.restorationRobot.error = action.payload.error;
          state.restorationRobot.message = action.payload.message;
        } else state.restorationRobot.status = RequestStatus.FAILED;
      })
      .addCase(restoreRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.restorationRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.restorationRobot.error = action.payload.response?.data.error;
          state.restorationRobot.message = action.payload.response?.data.message;
        }
      })
      .addCase(clearDraftRobot.pending, (state) => {
        state.clearDraftingRobot.status = RequestStatus.LOADING;
      })
      .addCase(clearDraftRobot.fulfilled, (state, action) => {
        if (action.payload) {
          state.clearDraftingRobot.status = RequestStatus.IDLE;
          state.clearDraftingRobot.error = action.payload.error;
          state.clearDraftingRobot.message = action.payload.message;
        } else state.clearDraftingRobot.status = RequestStatus.FAILED;
      })
      .addCase(clearDraftRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.clearDraftingRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.clearDraftingRobot.error = action.payload.response?.data.error;
          state.clearDraftingRobot.message = action.payload.response?.data.message;
        }
      })
      .addCase(importRobot.pending, (state) => {
        state.importRobot.status = RequestStatus.LOADING;
      })
      .addCase(importRobot.fulfilled, (state, action) => {
        if (action.payload) {
          state.importRobot.status = RequestStatus.IDLE;
          state.importRobot.error = action.payload.error;
          state.importRobot.message = action.payload.message;
        } else state.importRobot.status = RequestStatus.FAILED;
      })
      .addCase(importRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.importRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.importRobot.error = action.payload.response?.data.error;
          state.importRobot.message = action.payload.response?.data.message;
        }
      })
      .addCase(exportRobot.pending, (state) => {
        state.exportRobot.status = RequestStatus.LOADING;
      })
      .addCase(exportRobot.fulfilled, (state, action) => {
        state.exportRobot.status = RequestStatus.IDLE;
        if (action.payload) {
          state.exportRobot.error = action.payload.error;
          state.exportRobot.message = action.payload.message;
        }
      })
      .addCase(exportRobot.rejected, (state, action: PayloadAction<unknown>) => {
        state.exportRobot.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.exportRobot.error = action.payload.response?.data.error;
          state.exportRobot.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { addingActiveRobotId, changeActiveRobotVersion, addingRobotPossibleVersions, changeRobotPossibleVersionsInList, editRobotName, addingRobotAction, addServiceData, changeTypeServiceData, changeModelServiceData, changeParamServiceData, deleteServiceData, changeThresholdInModelConfig, changeCorrectionInModelConfig, changeSettingVoiceInModelConfig, changeModelnameInModelConfig, addModelConfig, deleteModelConfig, changeSessionLifeTime, addRobotStopPhrase, changeRobotStopPhrase, deleteRobotStopPhrase, addInterval, changeNameInterval, changeDaysInterval, changeDataInterval, addTimeInterval, changeTimeInterval, deleteTimeInterval, deleteInterval, changeActionRobot, deleteActionRobot, replaceRobotActionList, changeActionTypeRobot, addPhraseInActionRobot, changePhraseInActionRobot, deletePhraseInActionRobot, changeScriptRobot, changeServiceRobot, changeActionTypeException, replaceRobotExceptionList, addPhraseInException, changePhraseInException, deletePhraseInException, changeScriptException, changeDestinationException, changeServiceException, addCategoryDataException, changeTypeCategoryException, changeCategoryDataException, deleteCategoryDataException, addingRobotException, changeDestinationRobot, addCategoryDataRobot, changeTypeCategoryRobot, changeCategoryDataRobot, deleteCategoryDataRobot, addConditionRunActionRobot, changeTypeConditionRunActionRobot, changeValuesConditionRunActionRobot, deleteConditionRunActionRobot, changeMaxNumberException, changeActionException, deleteRobotException, addConditionRunActionExceptionRobot, changeTypeConditionRunActionExceptionRobot, changeValuesConditionRunActionExceptionRobot, deleteConditionRunActionExceptionRobot, changeProgressImportRobot, clearState, clearRobotList, clearRobot, clearRobotExceptVersions, clearAddingRobot, clearEditingRobot, clearDeletingRobot, clearCommitConfigRobot, clearApplyingRobot, clearRestorationRobot, clearClearDraftingRobot, clearImportRobot, clearExportRobot } = sesRobotSlice.actions;

export const selectRobotList = (state: RootState) => state.sesRobot.robotList;
export const selectActiveRobotId = (state: RootState) => state.sesRobot.robotList.activeRobotId;
export const selectActiveRobotVersion = (state: RootState) => state.sesRobot.robotList.activeRobotVersion;
export const selectRobot = (state: RootState) => state.sesRobot.robot;
export const selectAddingRobot = (state: RootState) => state.sesRobot.addingRobot;
export const selectEditingRobot = (state: RootState) => state.sesRobot.editingRobot;
export const selectDeletingRobot = (state: RootState) => state.sesRobot.deletingRobot;
export const selectCommitConfigRobot = (state: RootState) => state.sesRobot.commitConfigRobot;
export const selectApplyingRobot = (state: RootState) => state.sesRobot.applyingRobot;
export const selectRestorationRobot = (state: RootState) => state.sesRobot.restorationRobot;
export const selectClearDraftingRobot = (state: RootState) => state.sesRobot.clearDraftingRobot;
export const selectImportRobot = (state: RootState) => state.sesRobot.importRobot;
export const selectExportRobot = (state: RootState) => state.sesRobot.exportRobot;

export default sesRobotSlice.reducer;
