import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import { clearCopyResponseCorpus, selectCorpus, selectCorpusCopyStatus } from '../../../../store/corpusSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormCopy from '../../../Forms/FormCopy/FormCopy';
import Notification from '../../../Notification/Notification';
import { ICopyProps } from './Copy.props';

const Copy = ({ isAvailable, serviceType }: ICopyProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы импорта
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	const corpus = useAppSelector(selectCorpus); // store - корпус

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_copy')}>
				<FontAwesomeIcon icon={faCopy} size='xl' color={backgroundColor} />
			</button>

			{showModal &&
				<FormCopy
					showModal={showModal}
					setShowModal={setShowModal}
					corpusName={corpus.corpusName}
					serviceType={serviceType}
					setShowNotification={setShowNotification}
				/>
			}

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={selectCorpusCopyStatus}
					clearDataResponse={clearCopyResponseCorpus}
					titleFailed='noticeCopy_failed'
					titleSuccess='noticeCopy_success'
				/>
			}
		</>
	);
};

export default Copy;
