import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IManualCheckProps } from './ManualCheck.props';

const ManualCheck = ({ isAvailable, handler }: IManualCheckProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<button
			onClick={e => handler(e)}
			disabled={!isAvailable}
			title={translate('buttonTitle_manualCheck')}
		>
			<FontAwesomeIcon icon={faCheck} size="xl" color={backgroundColor} />
		</button>
	);
};

export default ManualCheck;
