import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearModels, selectAllModels } from '../../store/modelSlice';
import { clearAutomarkingStatus, clearDeleteResponseCorpus, clearRenameResponseCorpus, clearState, getCorpusList, getStatusAutomark, selectAutoMarkingStatus, selectAutoMarkingStop, selectCorpus, selectCorpusDeleteStatus, selectCorpusList, selectCorpusPutStatus, selectCorpusRenameStatus } from '../../store/corpusSlice';
import { clearMarksList, getMarksList } from '../../store/marksSlice';
import { getDataServers } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { findServiceRightByType } from '../../helpers/findServiceRightByType';
import { CORPUS, MARKS, SERVER } from '../../constants/accessRights';
import { AUTO_MARK_TASK_ID } from '../../constants/cookieNames';
import { ICorpusData } from '../../types/corpusTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import CorpusNavBar from '../../components/Navbars/CorpusNavBar/CorpusNavBar';
import CorpusControl from '../../components/Controls/CorpusControl/CorpusControl';
import CorpusTags from '../../components/Tags/CorpusTags/CorpusTags';
import CorpusInfo from '../../components/CorpusInfo/CorpusInfo';
import CorpusTable from '../../components/Tables/Corpus/Corpus';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import ScreenLock from '../../components/ScreenLock/ScreenLock';
import Notification from '../../components/Notification/Notification';
import { ICorpusProps } from './Corpus.props';
import styles from './Corpus.module.scss';

const Corpus = ({ serviceType }: ICorpusProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных в корпусе и возможности сохранить эти изменения
	const [showNotificationRename, setShowNotificationRename] = useState<boolean>(false); // показ уведомления о переименовании
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления об удалении корпуса
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [showSidebar, setShowSidebar] = useState<boolean>(true); // показ боковой панели
	const [visibleTable, setVisibleTable] = useState<keyof ICorpusData>('data'); // отображение таблицы по табам

	const dispatch = useAppDispatch();
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов
	const corpus = useAppSelector(selectCorpus); // store - корпус
	const putStatus = useAppSelector(selectCorpusPutStatus); // store - статус об изменении корпуса
	const renameStatus = useAppSelector(selectCorpusRenameStatus); // store - статус о переименовании корпуса
	const allModels = useAppSelector(selectAllModels); // store - все модели
	const autoMarkingStop = useAppSelector(selectAutoMarkingStop); // store - статус остановки авторазметки корпуса
	const autoMarkingStatus = useAppSelector(selectAutoMarkingStatus); // store - статус авторазметки корпуса

	const [cookies] = useCookies([AUTO_MARK_TASK_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(CORPUS.LIST) && dispatch(getCorpusList({ serviceType })); // если есть доступ - получаем список корпусов
		isAccess(MARKS.LIST) && serviceType === 'smc' && dispatch(getMarksList()); // если есть доступ - получаем список словарей меток
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			allModels.models !== null && dispatch(clearModels()); // очищаем список моделей, если есть
			dispatch(clearState()); // очищаем state
			serviceType === 'smc' && dispatch(clearMarksList()); // очищаем список словарей меток
		};
	}, []);

	// следим за именем корпуса - и сбрасываем табы
	useEffect(() => {
		visibleTable !== 'data' && setVisibleTable('data');
	}, [corpus.corpusName]);

	// следим за статусом изменения/переименования корпуса, остановки авторазметки
	useEffect(() => {
		// если идет сохранения/переименовывание - включаем экран блокировки с подписью
		if (putStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
		else if (renameStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_renaming' });
		else if (autoMarkingStop.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_stop' });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если сохранилось успешно - выключаем флаг сохранения данных
		putStatus.status === RequestStatus.IDLE && putStatus.error === ResponseStatus.SUCCESS && putStatus.message === 'success' && setChangeFlg(false);
		// если остановка авторазметки прошла успешно - очищаем статус 
		if (autoMarkingStop.status === RequestStatus.IDLE && autoMarkingStop.error === ResponseStatus.SUCCESS && autoMarkingStop.message !== '') dispatch(clearAutomarkingStatus());
	}, [putStatus, renameStatus, autoMarkingStop]);

	// следим за статусом авторазметки
	useEffect(() => {
		// автозапрос статуса авторазметки каждые 5 сек
		const interval = setInterval(() => {
			isAccess([CORPUS.AUTOMARK_START, CORPUS.AUTOMARK_STOP, CORPUS.AUTOMARK_STATUS]) && cookies.autoMarkTaskId && dispatch(getStatusAutomark(cookies.autoMarkTaskId));
		}, 5000);

		// если остановлена авторазметка
		if (autoMarkingStatus.responseStatus === 'stopped') {
			isAccess([CORPUS.AUTOMARK_START, CORPUS.AUTOMARK_STOP, CORPUS.AUTOMARK_STATUS]) && cookies.autoMarkTaskId && dispatch(getStatusAutomark(cookies.autoMarkTaskId)); // получаем статус авторазметки
			clearInterval(interval); // удаляем автозапрос
			dispatch(clearAutomarkingStatus()); // очищаем данные
		}

		// если авторазметка закончена
		if (autoMarkingStatus.responseStatus === 'finished') {
			clearInterval(interval); // удаляем автозапрос
		}

		return () => {
			clearInterval(interval); // удаляем автозапрос получения статуса процесса генерации ответов
		};
	}, [autoMarkingStatus.responseStatus]);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[findServiceRightByType(serviceType), CORPUS.LIST]}>
			<>
				<CorpusControl changeFlg={changeFlg} setChangeFlg={setChangeFlg} serviceType={serviceType} setShowPage={setShowPage} />
				<CorpusTags serviceType={serviceType} />

				<div className={styles.bottomContainer}>
					<CorpusNavBar
						changeFlg={changeFlg}
						setChangeFlg={setChangeFlg}
						serviceType={serviceType}
						showSidebar={showSidebar}
						setShowSidebar={setShowSidebar}
						setShowPage={setShowPage}
					/>
					<div className={cn(styles.wrapper, {
						[styles.wrapperFullWidth]: !showSidebar,
					})}>
						{corpusList.data.length > 0 && Array.isArray(corpus.data.data) ?
							<>
								{(corpus.status === RequestStatus.LOADING || corpus.corpusName === null) &&
									<div className={styles.loading}>
										<ProgressCircle title={translate('spinnerTitle_loading')} />
									</div>
								}
								<CorpusInfo setShowNotificationRename={setShowNotificationRename} setShowNotificationDelete={setShowNotificationDelete} serviceType={serviceType} setChangeFlg={setChangeFlg} visibleTable={visibleTable} setVisibleTable={setVisibleTable} />
								{(corpus.corpusName && corpus.status === RequestStatus.IDLE) &&
									<Fade in={true} timeout={500}>
										<div className={styles.wrapperTable}>
											{visibleTable === 'data' && <CorpusTable setChangeFlg={setChangeFlg} serviceType={serviceType} typeCorpusData='data' />}
											{visibleTable === 'groups' && <CorpusTable setChangeFlg={setChangeFlg} serviceType={serviceType} typeCorpusData='groups' />}
										</div>
									</Fade>
								}
								{corpus.status === RequestStatus.FAILED &&
									<div className={styles.notFound}><div>{translate(corpus.message || 'title_notFound')}</div></div>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</div>

				{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
				{showNotificationRename && <Notification showNotification={showNotificationRename} setShowNotification={setShowNotificationRename} selectDataResponse={selectCorpusRenameStatus} clearDataResponse={clearRenameResponseCorpus} titleFailed='noticeRename_failed' titleSuccess='noticeRename_success' />}
				{showNotificationDelete && <Notification showNotification={showNotificationDelete} setShowNotification={setShowNotificationDelete} selectDataResponse={selectCorpusDeleteStatus} clearDataResponse={clearDeleteResponseCorpus} titleFailed='noticeDeletion_failed' titleSuccess='noticeDeletion_success' />}
			</>
		</PageWrapper>
	);
};

export default Corpus;
