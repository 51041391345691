import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import Notification from '../../../Notification/Notification';
import { IInstallProps } from './Install.props';

const Install = ({ isAvailable, dataResponse, clearDataResponse, submitHandler, buttonTitle, buttonIcon, name, dialogTitle, dialogConfirm }: IInstallProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	// обработчик установки
	const installHandler = (): void => {
		setShowNotification(true); // включаем уведомление
		setShowAlertDialog(false); // выключаем диалоговое окно
		submitHandler(); // установка
	};

	return (
		<>
			<button
				onClick={() => setShowAlertDialog(true)}
				disabled={!isAvailable}
				title={translate(buttonTitle)}>
				<FontAwesomeIcon icon={buttonIcon} size="xl" color={backgroundColor} />
			</button>

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={dataResponse}
					clearDataResponse={clearDataResponse}
					titleFailed='noticeApplying_failed'
					titleSuccess='noticeApplying_success'
				/>
			}

			<AlertDialog
				showAlertDialog={showAlertDialog}
				setShowAlertDialog={setShowAlertDialog}
				submitHandler={installHandler}
				title={dialogTitle}
				description={dialogConfirm}
				name={name}
			/>
		</>
	);
};

export default Install;
