import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../store/hooks';
import { selectDebuggerAnswer } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { ROBOT_AUDIO_ANSWERS, ROBOT_AUTO_PLAY } from '../../../constants/cookieNames';
import { RequestStatus } from '../../../types/statusTypes';
import VariablesBlock from './VariablesBlock/VariablesBlock';
import { ISettingProps } from './Setting.props';
import styles from './Setting.module.scss';

const Setting = ({ variablesData, setVariablesData, audioAnswers, setAudioAnswers, autoPlay, setAutoPlay }: ISettingProps): JSX.Element => {
	const [showAdditionalSetting, setShowAdditionalSetting] = useState<boolean>(false); // показ доп. настроек

	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота

	const [cookies, setCookie] = useCookies([ROBOT_AUDIO_ANSWERS, ROBOT_AUTO_PLAY]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		// расставляем флаги из cookies
		(!cookies.robotAudioAnswers || cookies.robotAudioAnswers === 'false') ? setAudioAnswers(false) : setAudioAnswers(true);
		(!cookies.robotAutoPlay || cookies.robotAutoPlay === 'false') ? setAutoPlay(false) : setAutoPlay(true);
	}, []);

	// следим за флагом аудио-ответов
	useEffect(() => {
		// если аудио-ответы выключены, а флаг автовоспроизведения включен - выключаем последний
		!audioAnswers && autoPlay && setAutoPlay(false);
		// если аудио-ответы включены, а флаг автовоспроизведения выключен - включаем последний
		// audioAnswers && !autoPlay && setAutoPlay(true);
		setCookie(ROBOT_AUDIO_ANSWERS, audioAnswers, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	}, [audioAnswers]);

	// следим за флагом автовоспроизведения
	useEffect(() => {
		setCookie(ROBOT_AUTO_PLAY, autoPlay, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	}, [autoPlay]);

	return (
		<div className={cn(styles.setting, {
			[styles.settingFullSize]: showAdditionalSetting,
		})}>
			{/* кнопка показа доп.настроек */}
			<button className={styles.settingShowButton} onClick={() => setShowAdditionalSetting(prev => !prev)}>
				<FontAwesomeIcon icon={showAdditionalSetting ? faChevronDown : faChevronUp} />
			</button>

			{/* доп. настройки */}
			<div className={cn(styles.settingBlock, {
				[styles.settingBlockVisible]: showAdditionalSetting,
			})}>
				<div className={styles.settingBlockInner}>
					{/* аудио ответы */}
					<label className={styles.settingCheckbox}>
						<input
							type="checkbox"
							checked={audioAnswers}
							onChange={e => setAudioAnswers(e.target.checked)}
							disabled={debuggerAnswer.status === RequestStatus.LOADING}
						/>
						{translate('checkbox_audioAnswers')}
					</label>
					{/* автооспроизведение */}
					<label className={styles.settingCheckbox}>
						<input
							type="checkbox"
							checked={autoPlay}
							onChange={e => setAutoPlay(e.target.checked)}
							disabled={!audioAnswers}
						/>
						{translate('checkbox_autoplay')}
					</label>
				</div>

				<div className={cn(styles.settingBlockInner, styles.settingBlockInnerHeader)}>{translate('title_setVariables')}</div>

				{variablesData.map(([variable, value, key], idx) => (
					<div className={styles.settingBlockInner} key={key}>
						<VariablesBlock
							variable={variable}
							value={value}
							index={idx}
							variablesData={variablesData}
							setVariablesData={setVariablesData}
						/>
					</div>
				))}

			</div>
		</div>
	);
};

export default Setting;
