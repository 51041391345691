import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearRecognitionAsyncMode, getAsyncResult, getAudio, getAudioPeaks, getQueue, selectAudio, selectQueue } from '../../../store/sprSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { TRANSCRIPT } from '../../../constants/routes';
import { SERVICE, SPR, TRANSCRIPTION } from '../../../constants/accessRights';
import { SPR_TASK_ID } from '../../../constants/cookieNames';
import { RequestStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IQueueNavbarProps } from './QueueNavbar.props';
import styles from './QueueNavbar.module.scss';

const QueueNavbar = ({ setShowPage }: IQueueNavbarProps): JSX.Element => {
	const [taskList, setTaskList] = useState<string[]>([]); // список задач
	const [activeTask, setActiveTask] = useState<string>(''); // активная задача
	const [promiseGetAudio, setPromiseGetAudio] = useState<Promise<any> | null>(null); // промис для отмены запроса получения аудио-файла
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список задач

	const dispatch = useAppDispatch();
	const queue = useAppSelector(selectQueue); // store - очередь распознавания
	const audio = useAppSelector(selectAudio); // store - аудио-файл асинхронного распознавания речи

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const navigate = useNavigate(); // hook для навигации
	const translate = useTranslate(); // hook для перевода текста
	const [cookies, setCookie] = useCookies([SPR_TASK_ID]); // hook для работы с cookie

	// следим за получением очереди распознавания
	useEffect(() => {
		// если есть данные и не в процессе загрузки - записываем в state список задач
		(queue.data !== null && queue.status !== RequestStatus.LOADING) && setTaskList(Object.keys(queue.data).reverse());
	}, [queue]);

	// следим за промисом
	useEffect(() => {
		// при уходе со страницы останавливаем запрос аудио-файла, если был в процессе
		return () => {
			// @ts-ignore
			promiseGetAudio && promiseGetAudio.abort();
		};
	}, [promiseGetAudio]);

	// следим за списком задач
	useEffect(() => {
		// только если список задач не пустой
		if (taskList.length > 0) {
			// если есть запись в cookie и список задач содержит эту запись, то передаем ее в обработчик выбора активной задачи, иначе выбираем первую запись из списка задач
			taskList.includes(cookies.sprTaskId) && cookies.sprTaskId ?
				taskHandler(cookies.sprTaskId)
				:
				taskHandler(taskList[0]);
		}
	}, [taskList]);

	// следим за статусом загрузки аудио-файла
	useEffect(() => {
		// если закончилась загрузка аудио - сбрасываем промис для отмены запроса
		audio.audioStatus !== RequestStatus.LOADING && setPromiseGetAudio(null);
		// если ошибка (случай с отменой запроса) - запрашиваем другой аудио-файл
		audio.audioStatus === RequestStatus.FAILED && taskHandler(activeTask);
	}, [audio.audioStatus]);

	// следим за активной задачей
	useEffect(() => {
		activeTask && listRef.current?.children[taskList.indexOf(activeTask)]?.scrollIntoView({ block: "center" }); // показ активной задачи в центре списка с имеющейся полосой прокрутки
	}, [activeTask]);

	// обработчик выбора активной задачи
	const taskHandler = (taskId: string): void => {
		setActiveTask(taskId); // устанавливаем имя активной задачи
		// если сохранен промис для отмены запроса
		if (promiseGetAudio) {
			// @ts-ignore
			promiseGetAudio.abort(); // прерываем запрос
			setPromiseGetAudio(null); // обнуляем промис
			return;
		}
		dispatch(clearRecognitionAsyncMode()); // очищаем все данные распознавания async режима
		isAccess(SPR.WAVEFORM) && dispatch(getAudioPeaks(taskId)); // получаем пики аудио-файла
		if (isAccess(SPR.AUDIO)) {
			const promise = dispatch(getAudio(taskId)); // получаем аудио-файл асинхронного распознавания
			setPromiseGetAudio(promise); // устанавливаем промис для случая с отменой запроса
		}
		isAccess(SPR.ASYNC_RESULT) && dispatch(getAsyncResult(taskId)); // получаем результат асинхронного распознавания
		setCookie(SPR_TASK_ID, taskId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (route: string): void => {
		setShowPage && setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(route);
		}, 500);
	};

	return (
		<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
			<div className={styles.sidebar}>
				<div className={styles.navbar}>
					{/* ошибка загрузки списка очереди */}
					{(queue.status === RequestStatus.FAILED) &&
						<div className={styles.navbarFailedText}>
							<span>{translate(queue.message || 'title_loadFailed')}</span>
							{isAccess(SPR.QUEUE) && <span className={styles.navbarFailedUpdate} onClick={() => dispatch(getQueue())}>{translate('link_update')}</span>}
						</div>
					}

					{/* пустой список очереди */}
					{(queue.status === RequestStatus.IDLE && queue.data && taskList.length === 0) &&
						<div className={styles.navbarNoTasks}>{translate('title_emptyList')}</div>
					}

					{/* загрузка списка очереди */}
					{(queue.status === RequestStatus.LOADING) &&
						<div className={cn(styles.navbarLoading, {
							[styles.navbarLoadingShort]: isAccess(SERVICE.SPR) || isAccess([SERVICE.SPR, TRANSCRIPTION.LIST, TRANSCRIPTION.GET_DATA, TRANSCRIPTION.GET_AUDIO])
						})}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
					}

					{/* список очереди*/}
					<ul className={styles.navbarTaskList} ref={listRef}>
						{taskList.map((taskId) => (
							<li
								className={styles.navbarTask}
								key={taskId}
							>
								<Fade in={queue.status !== RequestStatus.LOADING || queue.data !== null} timeout={500}>
									<div
										className={cn({
											[styles.navbarTaskLink]: activeTask !== taskId,
											[styles.navbarTaskLinkActive]: activeTask === taskId
										})}
										onClick={() => taskId !== activeTask && taskHandler(taskId)}
									>
										<div className={styles.navbarTaskLinkLeftBlock} title={taskId}>
											{queue.data && queue.data[taskId]?.created}
										</div>
										<FontAwesomeIcon icon={faAngleRight} />
									</div>
								</Fade>
							</li>
						))}
					</ul>

					<div className={styles.functionButtons}>
						{/* табы */}
						{isAccess([SERVICE.SPR, TRANSCRIPTION.LIST]) &&
							<div className={styles.functionButtonsTabs}>
								<div className={styles.functionButtonsTab}>{translate('tab_queue')}</div>
								{isAccess([SERVICE.SPR, TRANSCRIPTION.LIST]) &&
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => delayToHidePage(TRANSCRIPT)}>
										{translate('tab_transcripts')}
									</div>
								}
							</div>
						}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default QueueNavbar;
