import { useState } from 'react';
import useAccessRight from '../../../hooks/useAccessRight';
import { CORPUS, SERVER } from '../../../constants/accessRights';
import TagsWrapper from '../../../HOC/TagsWrapper/TagsWrapper';
import AutoMark from '../../AutoMark/AutoMark';
import ServerStatus from '../../ServerStatus/ServerStatus';
import Tag from '../Tag/Tag';
import { ICorpusTagsProps } from './CorpusTags.props';

const CorpusTags = ({ serviceType }: ICorpusTagsProps): JSX.Element => {
	const [showAutoMark, setShowAutoMark] = useState<boolean>(false); // показ вкладки авторазметки
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	return (
		<>
			<TagsWrapper>
				<>
					{isAccess(CORPUS.AUTOMARK_LIST) && serviceType === 'smc' &&
						<Tag setShowTag={setShowAutoMark} tagName='tag_automark' smallTitleSize />
					}
					{isAccess(SERVER.ADDRESSES) &&
						<Tag setShowTag={setShowServers} tagName='tag_servers' serverStatus />
					}
				</>
			</TagsWrapper>

			<AutoMark showAutoMark={showAutoMark} setShowAutoMark={setShowAutoMark} />
			<ServerStatus showServers={showServers} setShowServers={setShowServers} />
		</>
	);
};

export default CorpusTags;
