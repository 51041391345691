import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartColumn } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import { selectModelName } from '../../../../store/modelSlice';
import { clearTestModelSmc, selectTestModelSmc } from '../../../../store/smcSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormTest from '../../../Forms/FormTest/FormTest';
import Notification from '../../../Notification/Notification';
import { ITestProps } from './Test.props';

const Test = ({ isAvailable }: ITestProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	const modelName = useAppSelector(selectModelName); // store - имя активной модели

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_test')}>
				<FontAwesomeIcon icon={faChartColumn} size='xl' color={backgroundColor} />
			</button>

			{showModal &&
				<FormTest
					showModal={showModal}
					setShowModal={setShowModal}
					modelName={modelName}
					serviceType='smc'
					setShowNotification={setShowNotification}
				/>
			}

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={selectTestModelSmc}
					clearDataResponse={clearTestModelSmc}
					titleFailed='noticeStart_failed'
					titleSuccess='noticeStart_success'
				/>
			}
		</>
	);
};

export default Test;
