import { FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, FormControl, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addTemplate, clearTemplatesList, getTemplatesList, selectAddingTemplate, selectTemplatesList } from '../../../store/qasSummareSlice';
import useTranslate from '../../../hooks/useTranslate';
import { QAS_TEMPLATE_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import RulesBlock from './RulesBlock/RulesBlock';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormAddingTemplateProps } from './FormAddingTemplate.props';
import styles from './FormAddingTemplate.module.scss';

const FormAddingTemplate = ({ showModal, setShowModal, setShowNotification }: IFormAddingTemplateProps): JSX.Element => {
	const [inputName, setInputName] = useState<string>(''); // название шаблона
	const [showErrorExistingTemplate, setShowErrorExistingTemplate] = useState<boolean>(false); // флаг существующего имени шаблона
	const [dataRules, setDataRules] = useState<[string, string, number][]>([['', '', 0]]); // данные для блока правил

	const dispatch = useAppDispatch();
	const templatesList = useAppSelector(selectTemplatesList); // store - список шаблонов
	const addingTemplateStatus = useAppSelector(selectAddingTemplate); // store - статус добавления шаблона

	const [_cookies, setCookie] = useCookies([QAS_TEMPLATE_ID]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления шаблона
	useEffect(() => {
		// если добавление прошло успешно 
		if (addingTemplateStatus.error === ResponseStatus.SUCCESS && addingTemplateStatus.status === RequestStatus.IDLE && addingTemplateStatus.message !== '') {
			setCookie(QAS_TEMPLATE_ID, addingTemplateStatus.id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			dispatch(clearTemplatesList()); // очищаем список шаблонов
			dispatch(getTemplatesList()); // получаем заново список шаблонов
		}
		// если были изменения в статусе добавления
		if (addingTemplateStatus.error === ResponseStatus.FAILED || addingTemplateStatus.status === RequestStatus.FAILED || addingTemplateStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [addingTemplateStatus]);

	// обработчик добавления шаблона
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const existingTemplateName = templatesList.data?.find(teplateItem => teplateItem.name === inputName); // существующее имя шаблона
		if (existingTemplateName) {
			setShowErrorExistingTemplate(true); // подсвечиваем ошибку
		} else {
			showErrorExistingTemplate && setShowErrorExistingTemplate(false); // убираем ошибку, если была
			// переводим правила в объект
			const rulesObj: Record<string, string> = {};
			dataRules.forEach(([chapter, desc]) => {
				if (chapter !== '' && desc !== '') rulesObj[chapter] = desc;
			});
			dispatch(addTemplate({ name: inputName, options: rulesObj })); // добавление шаблона
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет добавление - запрещаем покидать форму
		if (addingTemplateStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_addingTemplate' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				<FormControl fullWidth margin='dense'>
					<TextField
						autoFocus
						required
						id="templateName"
						label={translate('input_name')}
						variant="outlined"
						value={inputName}
						onChange={(e) => setInputName(e.target.value)}
						disabled={addingTemplateStatus.status === RequestStatus.LOADING}
						error={showErrorExistingTemplate}
						helperText={showErrorExistingTemplate ? translate('title_templateExists') : ''}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>

				<div className={styles.title}>{translate('title_rules')}</div>

				{dataRules.map(([chapter, desc, key], idx) => (
					<div className={styles.rulesBlock} key={key}>
						<RulesBlock
							chapter={chapter}
							desc={desc}
							index={idx}
							dataRules={dataRules}
							setDataRules={setDataRules}
						/>
					</div>
				))}

				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={addingTemplateStatus.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{addingTemplateStatus.status === RequestStatus.LOADING ?
							<>
								{translate('button_add')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('button_add')
						}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormAddingTemplate;
