import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import cn from 'classnames';
import { useAppDispatch } from '../../store/hooks';
import useTranslate from '../../hooks/useTranslate';
import { changeTimestampAsync, changeTimestampSync, getAsyncResult } from '../../store/sprSlice';
import useAccessRight from '../../hooks/useAccessRight';
import { colorizeWord } from '../../helpers/colorizeWord';
import { SPR } from '../../constants/accessRights';
import { COLORING_CONFIDENCE, COLORING_CONFIDENCE_THRESHOLD, SPR_TASK_ID } from '../../constants/cookieNames';
import { RequestStatus } from '../../types/statusTypes';
import Replica from '../Replica/Replica';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IResultRecognitionProps } from './ResultRecognition.props';
import styles from './ResultRecognition.module.scss';

const ResultRecognition = ({ requestStatus, responseStatus, data, timestamp, speakerList = [], mode = 'async' }: IResultRecognitionProps): JSX.Element => {
	const [activeLineIndexList, setActiveLineIndexList] = useState<number[]>([0]); // список индексов активных реплик
	const listRef = useRef<HTMLUListElement>(null); // ссылка на список реплик

	const dispatch = useAppDispatch();

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const [cookies] = useCookies([SPR_TASK_ID, COLORING_CONFIDENCE, COLORING_CONFIDENCE_THRESHOLD]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком индексов активной реплики и ставим в центр страницы последнюю
	useEffect(() => {
		listRef.current?.children[activeLineIndexList[activeLineIndexList.length - 1]]?.scrollIntoView({ block: "center", behavior: 'smooth' });
	}, [activeLineIndexList]);

	// сбрасываем список активных индексов при смене статуса
	useEffect(() => {
		setActiveLineIndexList([]);
	}, [requestStatus]);

	return (
		<div className={styles.checkResult}>
			{requestStatus === RequestStatus.LOADING &&
				<div className={styles.loading}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
			}

			{requestStatus === RequestStatus.FAILED &&
				<p className={styles.failedText}>{translate('title_noData')}</p>
			}

			{responseStatus && requestStatus === RequestStatus.IDLE &&
				<div className={styles.failedText}>
					<p>{translate('title_status')}: {translate(responseStatus)}</p>
					{isAccess(SPR.ASYNC_RESULT) &&
						<button className={styles.btnUpdate} onClick={() => dispatch(getAsyncResult(cookies.sprTaskId))}>
							{translate('link_update')}
						</button>
					}
				</div>
			}

			{data !== null &&
				<>
					{'message' in data ?
						<p className={styles.failedText}>
							{translate('title_error')}: {translate(data.message || 'title_loadFailed')}
						</p>
						:
						<div className={styles.checkResultEditorWrapper}>
							<ul className={styles.wrapper} ref={listRef}>
								{data && typeof data === 'object' && 'splitted' in data && Array.isArray(data.splitted) ?
									data.splitted.map((fragment, idx, array) => {
										// если список не содержал активную запись и временная метка подходит для этого фрагмента - записывааем индекс в state
										if (!activeLineIndexList.includes(idx) && timestamp >= fragment.start_ms && timestamp <= fragment.stop_ms) {
											activeLineIndexList.pop(); // ??
											setActiveLineIndexList(prev => [...prev, idx]);
										}
										return (
											<Replica
												fragment={fragment}
												fragmentIdx={idx}
												fragmentsArray={array}
												speakerList={speakerList}
												timestamp={fragment.start_ms <= timestamp && timestamp <= fragment.stop_ms ? timestamp : -1} // для кэширования (не перерисовывается)
												from='recognition'
												key={idx}
											/>
										);
									})
									:
									<>
										{data && typeof data === 'object' && 'words' in data && Array.isArray(data.words) &&
											<li className={cn(styles.line, {
												[styles.lineActive]: timestamp > 0,
												[styles.lineToxicity]: data.toxicity && cookies.coloringConfidence !== 'false' && (data.toxicity?.insult > 0.5 || data.toxicity?.obscenity > 0.5 || data.toxicity?.politeness < 0.5 || data.toxicity?.threat > 0.5),
											})}>
												<div className={styles.speakerWrapper}>
													{(data.toxicity || data.emotion || data.voice_analysis) &&
														<div className={styles.speakerInfo}>
															{((data.toxicity && 'politeness' in data.toxicity) || (data.emotion && Object.keys(data.emotion).length > 0)) &&
																<>
																	<p>{translate('title_textAnalysis')}:</p> {/* анализ текста */}
																	{data.toxicity && 'politeness' in data.toxicity && <p>- {translate('title_politeness').toLowerCase()}: {data.toxicity.politeness * 100}%</p>} {/* вежливость */}
																	{data.emotion && Object.keys(data.emotion).length > 0 &&
																		<p>- {translate(Object.keys(data.emotion || {})[0] || '')}: {Math.round(data.emotion[Object.keys(data.emotion || {})[0]] * 100)}%</p> /* эмоция */
																	}
																</>
															}
															{data.toxicity && 'insult' in data.toxicity &&
																<>
																	<p className={styles.speakerInfoHeader}>{translate('title_toxicityAnalysis')}:</p> {/* анализ токсичности */}
																	<p>- {translate('title_insult').toLowerCase()}: {data.toxicity?.insult * 100}%</p>
																	<p>- {translate('title_obscenity').toLowerCase()}: {data.toxicity?.obscenity * 100}%</p>
																	<p>- {translate('title_threat').toLowerCase()}: {data.toxicity?.threat * 100}%</p>
																</>
															}
															{data.voice_analysis && data.voice_analysis.emotion &&
																<>
																	<p className={cn(styles.speakerInfoHeader, styles.speakerInfoHeaderBorder)}>{translate('title_voiceAnalysis')}:</p> {/* голосовой анализ эмоций */}
																	<p>- {translate(data.voice_analysis?.emotion.class)}: {Math.round(data.voice_analysis?.emotion.confidence * 100)}%</p>
																</>
															}
														</div>
													}
													{mode === 'async' && <div>{translate('title_unknown')}</div>}
												</div>
												{data.words.map((word, idx) =>
													<span
														key={idx}
														className={cn(styles.lineWord, {
															[styles.lineWordActiveRed]: timestamp >= word.start &&
																timestamp <= word.stop &&
																typeof word.confidence === 'number' &&
																word.confidence <= +(cookies.coloringConfidenceThreshold || 0.7) &&
																cookies.coloringConfidence !== 'false',
															[styles.lineWordActiveBlue]: timestamp >= word.start &&
																timestamp <= word.stop &&
																(!word.confidence || (typeof word.confidence === 'number' && word.confidence > +(cookies.coloringConfidenceThreshold || 0.7)) || cookies.coloringConfidence === 'false')
														})}
														onClick={() => mode === 'async' ? dispatch(changeTimestampAsync(word.start + 1)) : dispatch(changeTimestampSync(word.start + 1))}
														style={typeof word.confidence === 'number' &&
															word.confidence <= +(cookies.coloringConfidenceThreshold || 0.7) &&
															(cookies.coloringConfidence !== 'false') ? { color: colorizeWord(word.confidence, +(cookies.coloringConfidenceThreshold || 0.7)) } : undefined
														}
														title={`${translate('title_startWord')}: ${word.start}`}
													>
														{word.word}
													</span>
												)}
											</li>
										}
									</>
								}
							</ul>
						</div>
					}
				</>
			}
		</div>
	);
};

export default ResultRecognition;
