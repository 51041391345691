import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearModel, selectModelName } from '../../store/modelSlice';
import { clearClusterServer, clearDataServers, getClusterServerModels, getDataServers, selectClusterServer } from '../../store/serverSlice';
import { clearState, selectPutDictionaryStatus, selectRevoiceData, selectSynthesisData } from '../../store/ttsSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVER, SERVICE, TTS } from '../../constants/accessRights';
import { FormatType } from '../../types/ttsTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import ModelNavBar from '../../components/Navbars/ModelNavBar/ModelNavBar';
import SynthesisControl from '../../components/Controls/SynthesisControl/SynthesisControl';
import SingleServerTag from '../../components/Tags/SingleServerTag/SingleServerTag';
import BlockInfo from '../../HOC/BlockInfo/BlockInfo';
import FormSynthesis from '../../components/Forms/FormSynthesis/FormSynthesis';
import Revoice from '../../components/Revoice/Revoice';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import StressDictionary from '../../components/Tables/StressDictionary/StressDictionary';
import ScreenLock from '../../components/ScreenLock/ScreenLock';
import { ISynthesisProps } from './Synthesis.props';
import styles from './Synthesis.module.scss';

const Synthesis = ({ serviceType }: ISynthesisProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных в словаре ударений и возможности сохранить эти изменения
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись
	const [whatsPlaying, setWhatsPlaying] = useState<'synthesis' | 'distortion'>('synthesis'); // обозначения для ссылки на аудио в плеере
	const [format, setFormat] = useState<FormatType>('wav'); // формат файла

	const dispatch = useAppDispatch();
	const clusterServer = useAppSelector(selectClusterServer); // store - все продовые сервера с моделями
	const modelName = useAppSelector(selectModelName); // store - имя модели
	const synthesisData = useAppSelector(selectSynthesisData); // store - данные синтеза речи
	const putStatus = useAppSelector(selectPutDictionaryStatus); // store - статус об изменении словаря ударений
	const revoiceData = useAppSelector(selectRevoiceData); // store - искажение голоса

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(SERVER.MODEL_LIST) && dispatch(getClusterServerModels({ serviceType })); // получаем продовые модели
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearClusterServer()); // очищаем список продовых моделей
			dispatch(clearModel()); // очищаем модель
			dispatch(clearState()); // очищаем state tts
		};
	}, []);

	// следим за статусом изменения словаря ударений
	useEffect(() => {
		// если идет сохранение словаря ударений - включаем экран блокировки с подписью
		if (putStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если сохранилось успешно - выключаем флаг сохранения данных
		putStatus.status === RequestStatus.IDLE && putStatus.error === ResponseStatus.SUCCESS && putStatus.message === 'success' && setChangeFlg(false);
	}, [putStatus]);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.TTS, SERVER.MODEL_LIST]}>
			<>
				<ModelNavBar serviceType={serviceType} />
				<SynthesisControl changeFlg={changeFlg} setShowPage={setShowPage} />
				<SingleServerTag />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0 && modelName ?
							<>
								{(isAccess(TTS.SYNTHESIS) || isAccess([TTS.REVOICERS_LIST, TTS.REVOICE])) &&
									<Fade in={true} timeout={500}>
										<div className={styles.wrapperSynthesis}>
											{isAccess(TTS.SYNTHESIS) &&
												<BlockInfo flexGrow={1}>
													<FormSynthesis
														setWhatsPlaying={setWhatsPlaying}
														format={format}
														setFormat={setFormat}
													/>
												</BlockInfo>
											}
											{isAccess([TTS.REVOICERS_LIST, TTS.REVOICE]) &&
												<BlockInfo flexShrink={0}>
													<Revoice setWhatsPlaying={setWhatsPlaying} />
												</BlockInfo>
											}
											<BlockInfo flexShrink={0}>
												<AudioPlayer
													url={whatsPlaying === 'synthesis' ? synthesisData.dataUrl : revoiceData.dataUrl}
													audioFormat={format}
												/>
											</BlockInfo>
										</div>
									</Fade>
								}

								{isAccess(TTS.DICTIONARY_GET) &&
									<Fade in={true} timeout={500}>
										<div className={styles.wrapperStressDictionary}>
											<BlockInfo height='100%'>
												<StressDictionary setChangeFlg={setChangeFlg} />
											</BlockInfo>
										</div>
									</Fade>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</Fade>

				{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
			</>
		</PageWrapper>
	);
};

export default Synthesis;
