import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeActiveFileCategory, addActiveFileId, changeActiveFileName, clearDocument, clearState, getCategoriesList, getFile, getFilesList, selectAddingFileStatus, selectCategoriesList, selectDocument, selectFilesList, getChannelList, selectChannelList, selectGeneratingQuestionsProcessStatus, clearQuestionMakerStatus, getStatusGenerateQuestions, selectFilter, getGptList } from '../../store/qasSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { QAS, SERVER, SERVICE } from '../../constants/accessRights';
import { QAS_FILE_ID } from '../../constants/cookieNames';
import { IFileData } from '../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import QuestionnarieControls from '../../components/Controls/QuestionnarieControls/QuestionnarieControls';
import QuestionnarieTags from '../../components/Tags/QuestionnarieTags/QuestionnarieTags';
import FileNavBar from '../../components/Navbars/FileNavBar/FileNavBar';
import DocumentEditor from '../../components/DocumentEditor/DocumentEditor';
import FragmentToQuestion from '../../components/FragmentToQuestion/FragmentToQuestion';
import NoticeSingleAction from '../../components/Notification/NoticeSingleAction/NoticeSingleAction';
import { IQuestionnaireProps } from './Questionnaire.props';
import styles from './Questionnaire.module.scss';

const Questionnaire = ({ serviceType }: IQuestionnaireProps): JSX.Element => {
	const [searchDocument, setSearchDocument] = useState<string>(''); // поиск документа
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных файла и возможности сохранить эти изменения
	const [promiseGeFile, setPromiseGetFile] = useState<Promise<any> | null>(null); // промис для отмены запроса получения документа
	const [showSidebar, setShowSidebar] = useState<boolean>(true); // показ боковой панели
	const [showNotificationCategory, setShowNotificationCategory] = useState<boolean>(false); // показ уведомлений статуса загрузки списка категорий
	const [showNotificationChannel, setShowNotificationChannel] = useState<boolean>(false); // показ уведомлений статуса загрузки списка каналов
	const [showAlertDialogSave, setShowAlertDialogSave] = useState<boolean>(false); // показ диалогового окна для сохранения документа
	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна для удаления документа

	const dispatch = useAppDispatch();
	const filter = useAppSelector(selectFilter); // store - фильтр
	const filesList = useAppSelector(selectFilesList); // store - список файлов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const documentFile = useAppSelector(selectDocument); // store - документ (файл)
	const addingFileStatus = useAppSelector(selectAddingFileStatus); // store - статус добавления документа
	const processGenerateQuestionsStatus = useAppSelector(selectGeneratingQuestionsProcessStatus); // store - статус процесса генерации вопросов

	const [_cookies, setCookie] = useCookies([QAS_FILE_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(QAS.DOC_LIST) && dispatch(getFilesList()); // получаем список документов
		isAccess(QAS.CATEGORY_LIST) && dispatch(getCategoriesList()); // получаем список категорий
		isAccess(QAS.CHANNEL_LIST) && dispatch(getChannelList()); // получаем список каналов
		isAccess(QAS.GPT_LIST) && dispatch(getGptList()); // получаем список моделей gpt
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		return () => {
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очистка всего state
		};
	}, []);

	// следим за статусом получения списка категорий и в случае ошибок - включаем уведомление
	useEffect(() => {
		if (categoriesList.status === RequestStatus.FAILED || categoriesList.error === ResponseStatus.FAILED) setShowNotificationCategory(true);
	}, [categoriesList.status]);

	// следим за статусом получения списка каналов и в случае ошибок - включаем уведомление
	useEffect(() => {
		if (channelList.status === RequestStatus.FAILED || channelList.error === ResponseStatus.FAILED) setShowNotificationChannel(true);
	}, [channelList.status]);

	// следим за статусом получения документа
	useEffect(() => {
		// если закончилась загрузка документа - сбрасываем промис для отмены запроса
		documentFile.status !== RequestStatus.LOADING && setPromiseGetFile(null);
		// если ошибка (случай с отменой запроса) - запрашиваем другой документ
		documentFile.status === RequestStatus.FAILED && documentFile.fileId && fileHandler(documentFile.fileId);
	}, [documentFile.status]);

	// следим за статусом добавления файла
	useEffect(() => {
		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// если идет добавление - удаляем автозапрос получения данных о серверах
		addingFileStatus.status === RequestStatus.LOADING && clearInterval(interval);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
		};
	}, [addingFileStatus.status]);

	// следим за статусом процесса генерации вопросов
	useEffect(() => {
		// автозапрос статуса процесса генерации вопросов каждые 5 сек
		const interval = setInterval(() => {
			isAccess([QAS.QUESTION_MAKER_START, QAS.QUESTION_MAKER_STOP, QAS.QUESTION_MAKER_STATUS]) && dispatch(getStatusGenerateQuestions());
		}, 5000);

		// если остановлена генерация
		if (processGenerateQuestionsStatus.responseStatus === 'stopped') {
			isAccess([QAS.QUESTION_MAKER_START, QAS.QUESTION_MAKER_STOP, QAS.QUESTION_MAKER_STATUS]) && dispatch(getStatusGenerateQuestions()); // получаем статус процесса генерации вопросов
			clearInterval(interval); // удаляем автозапрос
			dispatch(clearQuestionMakerStatus()); // очищаем данные
		}

		return () => {
			clearInterval(interval); // удаляем автозапрос получения статуса процесса генерации вопросов
		};
	}, [processGenerateQuestionsStatus.responseStatus]);

	// обработчик выбора активного файла
	const fileHandler = (fileId: string): void => {
		if (fileId === documentFile.fileId) return; // выходим, если не менялся id документа после фильтрации
		documentFile.data && dispatch(clearDocument()); // очищаем все данные о файле, если есть
		dispatch(addActiveFileId(fileId)); // добавляем id активного файла в store
		// добавляем имя и категорию активного файла в store
		Array.isArray(filesList.data) && filesList.data.forEach(fileItem => {
			if (fileItem.id === fileId) {
				dispatch(changeActiveFileName({ fileName: fileItem.title, change: false }));
				fileItem.category && dispatch(changeActiveFileCategory({ categoryName: fileItem.category, change: false }));
			}
		});
		// если сохранен промис для отмены запроса
		if (promiseGeFile) {
			// @ts-ignore
			promiseGeFile.abort(); // прерываем запрос
			setPromiseGetFile(null); // обнуляем промис
			return;
		}
		setChangeFlg(false); // сбрасываем флаг о не сохраненных данных
		if (isAccess(QAS.DOC_GET)) {
			const promise = dispatch(getFile(fileId)); // получаем данные файла
			setPromiseGetFile(promise); // устанавливаем промис для случая с отменой запроса
		}
		setCookie(QAS_FILE_ID, fileId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	// функция фильтрации списка документов
	const filterFunction = (document: IFileData): boolean => {
		return document.title.toLowerCase().includes(searchDocument.toLowerCase()) && (document.category === filter.category || filter.category === '');
	};

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.QAS, QAS.DOC_LIST]}>
			<>
				<QuestionnarieControls
					changeFlg={changeFlg}
					setChangeFlg={setChangeFlg}
					setShowPage={setShowPage}
					showAlertDialogSave={showAlertDialogSave}
					setShowAlertDialogSave={setShowAlertDialogSave}
					showAlertDialogDelete={showAlertDialogDelete}
					setShowAlertDialogDelete={setShowAlertDialogDelete}
					searchDocument={searchDocument}
				/>
				<QuestionnarieTags />

				<div className={styles.bottomContainer}>
					<FileNavBar
						fileHandler={fileHandler}
						showSidebar={showSidebar}
						setShowSidebar={setShowSidebar}
						setShowPage={setShowPage}
						filterByCategory={filter.category}
						searchDocument={searchDocument}
						setSearchDocument={setSearchDocument}
					/>

					<div className={cn(styles.wrapper, {
						[styles.wrapperFullWidth]: !showSidebar,
					})}>
						{Array.isArray(filesList.data) && filesList.data.filter(filterFunction).length > 0 ?
							<>
								<DocumentEditor
									changeFlg={changeFlg}
									setChangeFlg={setChangeFlg}
									setShowAlertDialogSave={setShowAlertDialogSave}
									setShowAlertDialogDelete={setShowAlertDialogDelete}
								/>
								<FragmentToQuestion fileHandler={fileHandler} />
							</>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</div>

				{showNotificationCategory &&
					<NoticeSingleAction
						showNotification={showNotificationCategory}
						setShowNotification={setShowNotificationCategory}
						title={translate(categoriesList.message || 'noticeLoadingCategories_failed')}
					/>
				}
				{showNotificationChannel &&
					<NoticeSingleAction
						showNotification={showNotificationChannel}
						setShowNotification={setShowNotificationChannel}
						title={translate(channelList.message || 'noticeLoadingChannels_failed')}
					/>
				}
			</>
		</PageWrapper>
	);
};

export default Questionnaire;
