import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import AlertDialogTripleChoice from '../../../AlertDialogTripleChoice/AlertDialogTripleChoice';
import Notification from '../../../Notification/Notification';
import { IDeleteProps } from './Delete.props';

const Delete = ({ isAvailable, dataResponse, clearDataResponse, submitHandler, submitTripleChoiceHandler, name, buttonTitle = 'buttonTitle_delete', iconSize = 'xl', dialogTitle, dialogConfirm }: IDeleteProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	// обработчик удаления
	const deleteHandler = (): void => {
		setShowNotification(true); // включаем уведомление
		setShowAlertDialog(false); // выключаем диалоговое окно
		submitHandler(); // удаление
	};

	return (
		<>
			<button
				onClick={() => setShowAlertDialog(true)}
				disabled={!isAvailable}
				title={translate(buttonTitle)}>
				<FontAwesomeIcon icon={faTrashCan} size={iconSize} color={backgroundColor} />
			</button>

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={dataResponse}
					clearDataResponse={clearDataResponse}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}

			{submitTripleChoiceHandler ?
				<AlertDialogTripleChoice
					showAlertDialog={showAlertDialog}
					setShowAlertDialog={setShowAlertDialog}
					submitHandler={submitTripleChoiceHandler}
					accompanyingHandler={deleteHandler}
					title={dialogTitle}
					description={dialogConfirm}
					name={name}
				/>
				:
				<AlertDialog
					showAlertDialog={showAlertDialog}
					setShowAlertDialog={setShowAlertDialog}
					submitHandler={deleteHandler}
					title={dialogTitle}
					description={dialogConfirm}
					name={name}
				/>
			}
		</>
	);
};

export default Delete;
