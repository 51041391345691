import { ChangeEvent, FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Autocomplete, Button, FormControl, TextField } from '@mui/material';
import Resumable from 'resumablejs';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { importModel, getAllModels, selectImportStatus, getInfoModel, createModel, addImportModelName, changeProgressImportModel, loadingImportModel, successImportModel, failedImportModel, getHandler, selectModelName, selectActiveType, selectInstallStatus, selectApplyStatus, installModel, applyModel, clearImportResponse, clearApplyResponse, clearInstallResponse } from '../../../store/modelSlice';
import { getClusterServerModels, selectClusterServer } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { ENDPOINTS_MODEL, URL_MODEL } from '../../../constants/api_endpoints';
import { HANDLER, SERVER } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import ProgressLinear from '../../ProgressLinear/ProgressLinear';
import { IFormImportModelProps } from './FormImportModel.props';
import styles from './FormImportModel.module.scss';

const FormImportModel = ({ showModal, setShowModal, create = false, name, serviceType, setShowNotification }: IFormImportModelProps): JSX.Element => {
	const [nameInput, setNameInput] = useState<string>(''); // имя модели
	const [modelList, setModelList] = useState<string[]>([]); // список продовых моделей
	const [file, setFile] = useState<File>(); // файл для импортирования модели
	const inputRef = useRef(null); // ссылка на input file

	const r = useMemo(() => {
		return new Resumable({
			target: `${URL_MODEL}/${ENDPOINTS_MODEL.IMPORT}/${serviceType}/${name}`,
			// headers: { 'x-access-token': new Cookies().get(ACCESS_TOKEN)},
		});
	}, []); // возобновляемая загрузка

	const dispatch = useAppDispatch();
	const clusterServer = useAppSelector(selectClusterServer); // store - все продовые сервера с моделями
	const modelName = useAppSelector(selectModelName); // store - имя модели
	const activeType = useAppSelector(selectActiveType); // store - активный типа модели
	const importStatusModel = useAppSelector(selectImportStatus); // state - статус об импортировании модели
	const installStatus = useAppSelector(selectInstallStatus); // store - статус установки модели
	const applyStatus = useAppSelector(selectApplyStatus); // store - статус применения модели

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком продовых моделей
	useEffect(() => {
		// только для spr и если нет ошибок и есть данные
		if ((serviceType === 'spr') && clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0) {
			const clusterServers = Object.keys(clusterServer.data); // пишем сервера cluster'а
			const arrModelNames: string[] = [];
			clusterServers.forEach(server => {
				clusterServer.data && clusterServer.data[server]?.forEach(modelName => {
					!arrModelNames.includes(modelName) && arrModelNames.push(modelName); // пишем все уникальные модели
				});
			});
			setModelList(arrModelNames); // пишем список моделей в state
		}
	}, [clusterServer]);

	// следим за ссылкой на input file
	useEffect(() => {
		if (inputRef.current) {
			file && r.addFile(file); // если файл есть - добавляем в Resumable
			r.assignBrowse(inputRef.current, false); // предоставление Resumable input'а

			// событие при старте загрузки
			r.on("uploadStart", () => {
				dispatch(loadingImportModel());
			});

			// событие прогресса загрузки
			r.on("fileProgress", (file) => {
				dispatch(changeProgressImportModel(file.progress(false)));
			});

			// событие успеха загрузки
			r.on("fileSuccess", () => {
				dispatch(successImportModel());
			});

			// событие ошибки загрузки
			r.on("fileError", (_, msg) => {
				dispatch(failedImportModel(msg));
			});
		}
	}, [inputRef.current]);

	// следим за статусом импорта модели
	useEffect(() => {
		// если модель создается и нет ошибок - получаем заново все модели
		if (create) {
			importStatusModel.error === ResponseStatus.SUCCESS && importStatusModel.status === RequestStatus.IDLE && importStatusModel.message !== '' && dispatch(getAllModels({ serviceType }));
		} else {
			// иначе если нет ошибок
			if (importStatusModel.error === ResponseStatus.SUCCESS && importStatusModel.status === RequestStatus.IDLE && importStatusModel.message !== '') {
				// для spr
				if (serviceType === 'spr') {
					dispatch(installModel({ modelName: nameInput, serviceType })); // установка модели
				} else {
					name && dispatch(getInfoModel({ modelName: name, serviceType })); // обновляем информацию о модели
					isAccess(HANDLER.GET) && modelName && dispatch(getHandler({ modelName, serviceType, modelType: activeType })); // получаем handler.py
				}
			}
		}
		// если изменился статус импорта модели и не SPR
		if ((importStatusModel.error !== ResponseStatus.SUCCESS || importStatusModel.status === RequestStatus.FAILED || importStatusModel.message !== '') && serviceType !== 'spr') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [importStatusModel]);

	// следим за статусом установки модели
	useEffect(() => {
		// если успешно
		if (installStatus.status === RequestStatus.IDLE && installStatus.error === ResponseStatus.SUCCESS && installStatus.message !== '') {
			dispatch(applyModel({ modelName: nameInput, serviceType })); // применение модели
		}
	}, [installStatus]);

	// следим за статусом применения модели
	useEffect(() => {
		// если успешно
		if (applyStatus.status === RequestStatus.IDLE && applyStatus.error === ResponseStatus.SUCCESS && applyStatus.message !== '') {
			isAccess(SERVER.MODEL_LIST) && dispatch(getClusterServerModels({ serviceType })); // получаем продовые модели
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
	}, [applyStatus]);

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет импорт/установка/применение модели - запрещаем покидать форму
		if (importStatusModel.status === RequestStatus.LOADING || installStatus.status === RequestStatus.LOADING || applyStatus.status === RequestStatus.LOADING) return;
		// для SPR очистка статусов, если остались
		if (serviceType === 'spr') {
			if (importStatusModel.error !== ResponseStatus.SUCCESS || importStatusModel.status === RequestStatus.FAILED || importStatusModel.message !== '') dispatch(clearImportResponse());
			if (installStatus.error !== ResponseStatus.SUCCESS || installStatus.status === RequestStatus.FAILED || installStatus.message !== '') dispatch(clearInstallResponse());
			if (applyStatus.error !== ResponseStatus.SUCCESS || applyStatus.status === RequestStatus.FAILED || applyStatus.message !== '') dispatch(clearApplyResponse());
		}
		setShowModal(false);
	};

	// обработчик импортирования модели
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();

		if (create) {
			dispatch(createModel({ modelName: nameInput, serviceType })); // если модель создается - создаем модель
			dispatch(addImportModelName(nameInput)); // добавляем в store имя импортируемой модели
		} else {
			// иначе импортируем в существующую модель Resumable'ом (возобновляемой загрузкой) если поддерживается, иначе axios'ом
			// if (r.support) r.upload();
			// else {
			const formData = new FormData();
			if (file) formData.append('file', file);
			if (serviceType === 'spr') {
				if (applyStatus.error !== ResponseStatus.SUCCESS || applyStatus.status === RequestStatus.FAILED) dispatch(applyModel({ modelName: nameInput, serviceType })); // применение
				else if (installStatus.error !== ResponseStatus.SUCCESS || installStatus.status === RequestStatus.FAILED) dispatch(installModel({ modelName: nameInput, serviceType })); // установка
				else {
					dispatch(addImportModelName(nameInput)); // добавляем в store имя импортируемой модели
					dispatch(importModel({ modelName: nameInput, formData, serviceType })); // импорт модели
				}
			} else {
				name && dispatch(importModel({ modelName: name, formData, serviceType }));
			}
			// 	console.warn('Возобновляемая загрузка не поддерживается!');
			// }
		}
	};

	return (
		<ModalFormWindow
			showModal={showModal}
			setShowModal={setShowModal}
			headerTitle={create
				? "formHeader_addingModel"
				: serviceType === 'spr'
					? 'formHeader_loadingModel'
					: `${translate("formHeader_importModel")} "${name}"`
			}
			close={handleClose}
		>
			<>
				<form onSubmit={(e) => submitHandler(e)}>
					{create ?
						<FormControl fullWidth margin='dense'>
							<TextField
								autoFocus
								required
								label={translate("input_name")}
								variant="outlined"
								value={nameInput}
								onChange={(e) => setNameInput(e.target.value)}
								disabled={importStatusModel.status === RequestStatus.LOADING}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
						:
						<>
							{serviceType === 'spr' &&
								<Autocomplete
									freeSolo
									options={modelList}
									value={nameInput}
									onChange={(_, value) => setNameInput(value ? value : '')}
									disabled={importStatusModel.status === RequestStatus.LOADING || installStatus.status === RequestStatus.LOADING || applyStatus.status === RequestStatus.LOADING}
									renderInput={(params) =>
										<TextField
											{...params}
											required
											label={translate('input_name')}
											onChange={(e) => setNameInput(e.target.value)}
											InputLabelProps={{
												style: {
													fontSize: 13,
												},
											}}
											sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
										/>
									}
									sx={{
										".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
										".MuiInputBase-input": { marginTop: -1 },
									}}
									getOptionLabel={option => option}
									renderOption={(props, option) => {
										return (
											<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
												{option}
											</span>
										);
									}}
								/>
							}
							<FormControl fullWidth margin='dense'>
								<TextField
									required
									// inputRef={inputRef}
									variant="outlined"
									size="small"
									type="file"
									onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
									disabled={importStatusModel.status === RequestStatus.LOADING || installStatus.status === RequestStatus.LOADING || applyStatus.status === RequestStatus.LOADING}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13
										},
										inputProps: { accept: "application/zip" }
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
						</>
					}
					<FormControl fullWidth margin='dense'>
						<Button
							id='import'
							variant="outlined"
							type="submit"
							sx={{ fontSize: 11 }}
							disabled={importStatusModel.status === RequestStatus.LOADING || installStatus.status === RequestStatus.LOADING || applyStatus.status === RequestStatus.LOADING}
						>
							{translate(create // если создается
								? 'button_add'
								: (installStatus.status === RequestStatus.LOADING || installStatus.status === RequestStatus.FAILED) // если установка или ошибка установки
									? 'button_installation'
									: (applyStatus.status === RequestStatus.LOADING || applyStatus.status === RequestStatus.FAILED) // если применение или ошибка применения
										? 'button_applying'
										: 'button_import' // импорт
							)}
							{(importStatusModel.status === RequestStatus.LOADING || installStatus.status === RequestStatus.LOADING || applyStatus.status === RequestStatus.LOADING) &&
								<ProgressCircle isBtnDisabled />
							}
						</Button>
					</FormControl>
				</form>

				{!create && importStatusModel.status === RequestStatus.LOADING &&
					<ProgressLinear value={importStatusModel.progress} />
				}

				{(importStatusModel.status === RequestStatus.FAILED || importStatusModel.error === ResponseStatus.FAILED) &&
					<div className={styles.errorMessage}>{translate(importStatusModel.message || 'title_modelImportError')}</div>
				}
				{(installStatus.status === RequestStatus.FAILED || installStatus.error === ResponseStatus.FAILED) &&
					<div className={styles.errorMessage}>{translate(installStatus.message || 'title_modelInstallationError')}</div>
				}
				{(applyStatus.status === RequestStatus.FAILED || applyStatus.error === ResponseStatus.FAILED) &&
					<div className={styles.errorMessage}>{translate(applyStatus.message || 'title_modelApplicationError')}</div>
				}
			</>
		</ModalFormWindow>
	);
};

export default FormImportModel;
