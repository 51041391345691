import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import cn from 'classnames';
import { useAppDispatch } from '../../store/hooks';
import { getCategoriesList, getChannelList, getGptList } from '../../store/qasSlice';
import { clearState, getTemplatesList } from '../../store/qasSummareSlice';
import { clearDataServers, getDataServers } from '../../store/serverSlice';
import useAccessRight from '../../hooks/useAccessRight';
import { QAS, SERVER, SERVICE } from '../../constants/accessRights';
import { QAS_MESSAGE_HISTORY_DEPTH } from '../../constants/cookieNames';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import InteractiveControls from '../../components/Controls/InteractiveControls/InteractiveControls';
import SingleServerTag from '../../components/Tags/SingleServerTag/SingleServerTag';
import InteractiveNavbar from '../../components/Navbars/InteractiveNavbar/InteractiveNavbar';
import Template from '../../components/Template/Template';
import FormCreatingProtocol from '../../components/Forms/FormCreatingProtocol/FormCreatingProtocol';
import Protocol from '../../components/Protocol/Protocol';
import ResizeBlock from '../../components/ResizeBlock/ResizeBlock';
import InfoBlock from '../../HOC/InfoBlock/InfoBlock';
import ChatWithGpt from '../../components/ChatWithGpt/ChatWithGpt';
import AnswerToQuestion from '../../components/AnswerToQuestion/AnswerToQuestion';
import { IChangeFlg, IInteractiveProps, ITabSwitch } from './Interactive.props';
import styles from './Interactive.module.scss';

const Interactive = ({ serviceType }: IInteractiveProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [showSidebar, setShowSidebar] = useState<boolean>(true); // показ боковой панели
	const [changeFlg, setChangeFlg] = useState<IChangeFlg>({ thisIs: false, listOfChanges: [] }); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения

	const [showAlertDialogSave, setShowAlertDialogSave] = useState<boolean>(false); // показ диалогового окна для сохранения шаблона
	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна для удаления шаблона
	const [fullHeightFormFlg, setFullHeightFormFlg] = useState(false); // флаг максимальной высоты формы для протоколирования
	const [tabSwitch, setTabSwitch] = useState<ITabSwitch>('summary'); // переключатель табов протокола/чата

	const [selectModelGPT, setSelectModelGPT] = useState<string>('default'); // модель gpt
	const [inputHistory, setInputHistory] = useState<number>(5); // глубина истории сообщений, пар

	const dispatch = useAppDispatch();

	const [cookies] = useCookies([QAS_MESSAGE_HISTORY_DEPTH]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа

	useEffect(() => {
		isAccess(QAS.TEMPLATE_LIST) && dispatch(getTemplatesList()); // получаем список шаблонов
		isAccess(QAS.CATEGORY_LIST) && dispatch(getCategoriesList()); // получаем список категорий
		isAccess(QAS.CHANNEL_LIST) && dispatch(getChannelList()); // получаем список каналов
		isAccess(QAS.GPT_LIST) && dispatch(getGptList()); // получаем список моделей gpt
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		cookies.qasMessageHistoryDepth && setInputHistory(+cookies.qasMessageHistoryDepth || 5); // расставляем флаги из cookies

		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearState()); // очистка всего state
		};
	}, []);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.QAS, QAS.SUMMARIZE]}>
			<>
				<InteractiveControls
					changeFlg={changeFlg}
					setChangeFlg={setChangeFlg}
					setShowPage={setShowPage}
					showAlertDialogSave={showAlertDialogSave}
					setShowAlertDialogSave={setShowAlertDialogSave}
					showAlertDialogDelete={showAlertDialogDelete}
					setShowAlertDialogDelete={setShowAlertDialogDelete}
					tabSwitch={tabSwitch}
					setTabSwitch={setTabSwitch}
				/>
				<SingleServerTag />

				<div className={styles.bottomContainer}>
					<InteractiveNavbar
						setShowPage={setShowPage}
						showSidebar={showSidebar}
						setShowSidebar={setShowSidebar}
						setChangeFlg={setChangeFlg}
						tabSwitch={tabSwitch}
						selectModelGPT={selectModelGPT}
						setSelectModelGPT={setSelectModelGPT}
						inputHistory={inputHistory}
						setInputHistory={setInputHistory}
					/>

					<div className={cn(styles.wrapper, {
						[styles.wrapperFullWidth]: !showSidebar,
					})}>
						{tabSwitch === 'summary' ?
							<>
								<div className={styles.wrapperLeftBlock}>
									<Template
										changeFlg={changeFlg}
										setChangeFlg={setChangeFlg}
										setShowAlertDialogSave={setShowAlertDialogSave}
										setShowAlertDialogDelete={setShowAlertDialogDelete}
										fullHeightFormFlg={fullHeightFormFlg}
									/>
									<div className={styles.spaceForButton}>
										<ResizeBlock fullHeightFormFlg={fullHeightFormFlg} setFullHeightFormFlg={setFullHeightFormFlg} />
									</div>

									<FormCreatingProtocol fullHeightFormFlg={fullHeightFormFlg} />
								</div>
								<Protocol />
							</>
							:
							<>
								<InfoBlock className={styles.wrapperChat}>
									<ChatWithGpt
										selectModelGPT={selectModelGPT}
										inputHistory={inputHistory}
									/>
								</InfoBlock>
								{isAccess(QAS.ASK) &&
									<InfoBlock className={styles.wrapperAnswerToQuestion}>
										<AnswerToQuestion />
									</InfoBlock>
								}
							</>
						}
					</div>
				</div>
			</>
		</PageWrapper>
	);
};

export default Interactive;
