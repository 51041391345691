import { useState } from 'react';
import useAccessRight from '../../../hooks/useAccessRight';
import { SBS, SERVER } from '../../../constants/accessRights';
import TagsWrapper from '../../../HOC/TagsWrapper/TagsWrapper';
import Analysis from '../../Analysis/Analysis';
import ServerStatus from '../../ServerStatus/ServerStatus';
import Tag from '../Tag/Tag';

const BiometryTags = (): JSX.Element => {
	const [showAnalysis, setShowAnalysis] = useState<boolean>(false); // показ вкладки анализа
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	return (
		<>
			<TagsWrapper>
				<>
					{isAccess(SBS.ANALYZE) &&
						<Tag setShowTag={setShowAnalysis} tagName='tag_analysis' />
					}
					{isAccess(SERVER.ADDRESSES) &&
						<Tag setShowTag={setShowServers} tagName='tag_servers' serverStatus />
					}
				</>
			</TagsWrapper>

			<Analysis showAnalysis={showAnalysis} setShowAnalysis={setShowAnalysis} />
			<ServerStatus showServers={showServers} setShowServers={setShowServers} />
		</>
	);
};

export default BiometryTags;
