import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearDatasetsList, clearRolesList, getDatasetsList, getRolesList } from '../../store/authSlice';
import { clearStateExceptCurrentUserInfo, getUserList, selectUserList } from '../../store/userSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { DATASET, ROLE, SERVICE, USER } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import UserControls from '../../components/Controls/UserControls/UserControls';
import UserTags from '../../components/Tags/UserTags/UserTags';
import UserSidebar from '../../components/Navbars/UserNavbar/UserNavbar';
import UserInfo from '../../components/UserInfo/UserInfo';
import styles from './Users.module.scss';

const Users = (): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы
	const [userName, setUserName] = useState<string>(''); // имя пользователя
	const [password, setPassword] = useState<string>(''); // пароль
	const [userRoleId, setUserRoleId] = useState<string>(''); // id роли пользователя
	const [datasetIdDefault, setDatasetIdDefault] = useState<string>(''); // id набора данных по умолчанию
	const [datasetsListAvailable, setDatasetsListAvailable] = useState<string[]>([]); // список id доступных наборов данных
	const [avatarBase64, setAvatarBase64] = useState<string>(''); // аватар в base64

	const [changeFlg, setChangeFlg] = useState<boolean>(false); // флаг, уведомляющий об изменении данных пользователя и возможности сохранить эти изменения

	const dispatch = useAppDispatch();
	const userList = useAppSelector(selectUserList); // store - список пользователей

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(USER.LIST) && dispatch(getUserList()); // получаем список пользователей
		isAccess(ROLE.LIST) && dispatch(getRolesList()); // получаем список ролей
		isAccess(DATASET.LIST) && dispatch(getDatasetsList()); // получаем список наборов данных

		return () => {
			dispatch(clearStateExceptCurrentUserInfo()); // очистка всего state пользователей, кроме инфо о текущем
			dispatch(clearRolesList()); // очистка списка ролей
			dispatch(clearDatasetsList()); // очистка списка наборов данных
		};
	}, []);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={[SERVICE.AUTH, USER.LIST]}>
			<>
				<UserSidebar
					setPassword={setPassword}
					setChangeFlg={setChangeFlg}
					setShowPage={setShowPage}
				/>
				<UserControls
					userName={userName}
					password={password}
					setPassword={setPassword}
					userRoleId={userRoleId}
					datasetIdDefault={datasetIdDefault}
					datasetsListAvailable={datasetsListAvailable}
					avatarBase64={avatarBase64}
					changeFlg={changeFlg}
					setChangeFlg={setChangeFlg}
					setShowPage={setShowPage}
				/>
				<UserTags />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={styles.wrapper}>
						{userList.status === RequestStatus.IDLE && Array.isArray(userList.data) && userList.data.length > 0 ?
							<UserInfo
								userName={userName}
								setUserName={setUserName}
								password={password}
								setPassword={setPassword}
								userRoleId={userRoleId}
								setUserRoleId={setUserRoleId}
								datasetIdDefault={datasetIdDefault}
								setDatasetIdDefault={setDatasetIdDefault}
								datasetsListAvailable={datasetsListAvailable}
								setDatasetsListAvailable={setDatasetsListAvailable}
								avatarBase64={avatarBase64}
								setAvatarBase64={setAvatarBase64}
								setChangeFlg={setChangeFlg}
							/>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</Fade>
			</>
		</PageWrapper>
	);
};

export default Users;
