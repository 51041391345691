import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearDeleteSpeaker, clearSpeakers, deleteSpeaker, getSpeakers, selectAddSpeaker, selectDeleteSpeaker, selectEditSpeaker, selectSearchSpeaker, selectSpeakers } from '../../../store/sbsSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SBS } from '../../../constants/accessRights';
import { SPEAKER_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import Controls from '../../../HOC/Controls/Controls';
import AdditionSpeaker from '../Buttons/AdditionSpeaker/AdditionSpeaker';
import Delete from '../Buttons/Delete/Delete';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { IBiometryControlsProps } from './BiometryControls.props';

const BiometryControls = ({ setShowPage }: IBiometryControlsProps): JSX.Element => {
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const speakersList = useAppSelector(selectSpeakers); // store - список спикеров
	const addSpeakerStatus = useAppSelector(selectAddSpeaker); // store - статус добавления спикера
	const editSpeakerStatus = useAppSelector(selectEditSpeaker); // store - статус дополнения спикера
	const deleteSpeakerStatus = useAppSelector(selectDeleteSpeaker); // store - статус удаления спикера
	const searchSpeakerData = useAppSelector(selectSearchSpeaker); // store - поиск спикера

	const [_cookies, setCookie] = useCookies([SPEAKER_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом добавления, дополнения, удаления, поиска спикера
	useEffect(() => {
		if (addSpeakerStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_addition' }); // если идет добавление спикера
		else if (editSpeakerStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_additionSpeaker' }); // если идет дополнение спикера
		else if (deleteSpeakerStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' }); // если идет удаление спикера
		else if (searchSpeakerData.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_search' }); // если идет удаление спикера
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если добавление прошло успешно
		if (addSpeakerStatus.status === RequestStatus.IDLE && addSpeakerStatus.error === ResponseStatus.SUCCESS && addSpeakerStatus.message !== '' && addSpeakerStatus.id) {
			setCookie(SPEAKER_ID, addSpeakerStatus.id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			dispatch(clearSpeakers()); // очищаем список спикеров
			isAccess(SBS.SPEAKERS) && dispatch(getSpeakers()); // получаем список спикеров
		}
		// если удаление прошло успешно
		if (deleteSpeakerStatus.status === RequestStatus.IDLE && deleteSpeakerStatus.error === ResponseStatus.SUCCESS && deleteSpeakerStatus.message !== '') {
			dispatch(clearSpeakers()); // очищаем список спикеров
			isAccess(SBS.SPEAKERS) && dispatch(getSpeakers()); // получаем список спикеров
		}
	}, [addSpeakerStatus, editSpeakerStatus, deleteSpeakerStatus, searchSpeakerData]);

	// обработчик удаления спикера
	const deleteHandler = (): void => {
		speakersList.activeSpeaker && dispatch(deleteSpeaker(speakersList.activeSpeaker.id));
	};

	return (
		<>
			<Controls
				header='sbs'
				wideHeader
				setShowPage={setShowPage}
				leftSection={
					<div>
						{isAccess(SBS.SPEAKER_EDIT) &&
							<AdditionSpeaker
								isAvailable={speakersList.activeSpeaker !== null}
							/>
						}
						{isAccess(SBS.SPEAKER_DELETE) &&
							<Delete
								isAvailable={speakersList.activeSpeaker !== null}
								dataResponse={selectDeleteSpeaker}
								clearDataResponse={clearDeleteSpeaker}
								submitHandler={deleteHandler}
								name={speakersList.activeSpeaker?.name || ''}
								buttonTitle='buttonTitle_deleteSpeaker'
								dialogTitle='dialog_deleteSpeaker'
								dialogConfirm='dialog_deleteSpeakerConfirm'
							/>
						}
					</div>
				}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
		</>
	);
};

export default BiometryControls;
