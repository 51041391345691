import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import { selectCorpus } from '../../../../store/corpusSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormImportCorpus from '../../../Forms/FormImportCorpus/FormImportCorpus';
import { IImportCorpusProps } from './ImportCorpus.props';

const ImportCorpus = ({ isAvailable, serviceType, setChangeFlg }: IImportCorpusProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы импорта

	const corpus = useAppSelector(selectCorpus); // store - корпус

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_import')}>
				<FontAwesomeIcon icon={faCloudArrowUp} size='xl' color={backgroundColor} />
			</button>

			{corpus.corpusName && showModal &&
				<FormImportCorpus
					showModal={showModal}
					setShowModal={setShowModal}
					name={corpus.corpusName}
					serviceType={serviceType}
					setChangeFlg={setChangeFlg}
				/>
			}
		</>
	);
};

export default ImportCorpus;
