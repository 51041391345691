import { useEffect, useState } from 'react';
import { Autocomplete, Button, ButtonGroup, Checkbox, Chip, createFilterOptions, Fade, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addConditionEntry, addConditionRun, addConditionRunBlock, addEndpoint, changeTimerInactivity, clearEndpointElem, clearEntry, deleteEndpoint, editEndpoint, editEndpointActions, editEndpointEntry, editEndpointName, editEndpointRun, editEndpointType, replaceEndpointActionList, replaceEndpointEntryActionList, selectDataList, selectEditingEndpoint, selectEndpointElem } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion, } from '../../store/sesRobotSlice';
import { selectChannelList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { ENPOINT_ENTRY_TYPES_LIST, ENPOINT_TYPES_LIST } from '../../constants/robotConfigLists';
import { colorPrimary, colorSecondary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ConditionRunBlock from '../ConditionRunBlock/ConditionRunBlock';
import ActionEvent from '../ActionEvent/ActionEvent';
import FormAddingConditionEntry from '../Forms/FormAddingConditionEntry/FormAddingConditionEntry';
import FormAddingConditionRun from '../Forms/FormAddingConditionRun/FormAddingConditionRun';
import FormAddingAction from '../Forms/FormAddingAction/FormAddingAction';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { EndpointType, EntryType, IConditionData, IConditionVariable, IEntryInactivity } from '../../types/sesTypes';
import { IConfigEndpointProps } from './ConfigEndpoint.props';
import styles from './ConfigEndpoint.module.scss';

const ConfigEndpoint = ({ showEndpointElem, setShowEndpointElem }: IConfigEndpointProps): JSX.Element => {
	const [inputEndpointName, setInputEndpointName] = useState<string>(''); // название конечной точки
	const [selectEndpointType, setSelectEndpointType] = useState<EndpointType | ''>(''); // тип конечной точки

	const [showComment, setShowComment] = useState<boolean>(false); // показ комментария
	const [inputComment, setInputComment] = useState<string>(''); // комментарий

	const [selectEntryType, setSelectEntryType] = useState<EntryType | ''>(''); // тип входа
	const [timer, setTimer] = useState<number>(0); // таймер неактивности
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал
	const [inputFilterByDataElem, setInputFilterByDataElem] = useState<string[]>([]); // значения для фильтрации по элементам
	const [selectFilterByEntryConditions, setSelectFilterByEntryConditions] = useState<string>(''); // фильтрация по условиям входа в условиях запуска

	const [visibleBlockConfig, setVisibleBlockConfig] = useState<'entry' | 'run' | 'actions'>('entry'); // отображение условий/действий
	const [changeFlg, setChangeFlg] = useState<{ thisIs: boolean, listOfChanges: string[] }>({ thisIs: false, listOfChanges: [] }); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showAlertDialogDel, setShowAlertDialogDel] = useState<boolean>(false); // показ диалогового окна при удалении конечной точки

	const [enableRunConditions, setEnableRunConditions] = useState<boolean>(false); // флаг включения условия запуска
	const [indexAndEntryList, setIndexAndEntryList] = useState<Record<number, string[]>>({}); // индекс блока - список условий входа (для условий запуска)
	const [showAlertDialogChangeEntryData, setShowAlertDialogChangeEntryData] = useState<{ isShow: boolean, entryType: EntryType }>({ isShow: false, entryType: 'conditions' }); // показ диалогового окна при смене данных входа
	const [showAlertDialogDisableRunConditions, setShowAlertDialogDisableRunConditions] = useState<boolean>(false); // показ диалогового окна при выключении условий запуска

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const endpointElem = useAppSelector(selectEndpointElem); // store - конечная точка
	const editingEndpoint = useAppSelector(selectEditingEndpoint); // store - статус изменения конечной точки
	const dataElemList = useAppSelector(selectDataList); // список элементов данных
	const channelList = useAppSelector(selectChannelList); // store - список каналов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за открытием вкладки
	useEffect(() => {
		// при закрытии
		if (!showEndpointElem) {
			dispatch(clearEndpointElem()); // очищаем данные конечной точки
			setVisibleBlockConfig('entry'); // сбрасываем tab
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг возможности сохранения
			// очищаем поля
			setInputEndpointName('');
			setSelectEndpointType('');
			setShowComment(false);
			setInputComment('');
			setSelectEntryType('');
			setEnableRunConditions(false);
			setTimer(0);
			setInputChannel('default');
			setInputFilterByDataElem([]);
			setSelectFilterByEntryConditions('');
		}
	}, [showEndpointElem]);

	// следим за получением конечной точки
	useEffect(() => {
		// если есть данные - вписываем в поля
		if (endpointElem.endpoint) {
			!inputEndpointName && setInputEndpointName(endpointElem.endpoint.name);
			!selectEndpointType && setSelectEndpointType(endpointElem.endpoint.type);
			!inputComment && endpointElem.endpoint.comment && setInputComment(endpointElem.endpoint.comment);
			if (!selectEntryType) {
				// если на вход условия и есть условия запуска
				endpointElem.endpoint.entryType === 'conditions' && endpointElem.endpoint.run.length > 0 && searchEntryConditionsInRunConditions();
				setSelectEntryType(endpointElem.endpoint.entryType);
			}
			endpointElem.endpoint.run.length > 0 && setEnableRunConditions(true); // если условия запуска есть - включаем флаг
		}
		// для отложенных сообщений
		if (visibleBlockConfig === 'entry' && endpointElem.endpoint?.entryType === 'inactivity') {
			setTimer((endpointElem.endpoint?.entry as IEntryInactivity[])?.find(entryData => entryData.channel === inputChannel)?.timer || 0); // пишем таймер
		}
	}, [endpointElem.endpoint]);

	// следим за условиями запуска
	useEffect(() => {
		// если есть условия запуска и тип входа "условия" - ищем и дописываем условия по умолчанию
		if (((endpointElem.endpoint && endpointElem.endpoint.run.length > 0) || enableRunConditions) && selectEntryType === 'conditions') searchEntryConditionsInRunConditions();
	}, [endpointElem.endpoint?.run]);

	// следим за каналом
	useEffect(() => {
		// для отложенных сообщений
		if (visibleBlockConfig === 'entry' && selectEntryType === 'inactivity') {
			setTimer((endpointElem.endpoint?.entry as IEntryInactivity[])?.find(entryData => entryData.channel === inputChannel)?.timer || 0); // пишем таймер
		}
	}, [inputChannel]);

	// следим за статусом сохранения конечной точки
	useEffect(() => {
		// если изменение конечной точки прошло успешно
		if (editingEndpoint.status === RequestStatus.IDLE && editingEndpoint.error === ResponseStatus.SUCCESS && editingEndpoint.message !== '') {
			if (endpointElem.endpoint) {
				changeFlg.listOfChanges.includes('name') && dispatch(editEndpointName({ endpointId: endpointElem.endpoint.id, endpointName: inputEndpointName })); // если изменилось имя - меняем в store
				changeFlg.listOfChanges.includes('type') && dispatch(editEndpointType({ endpointId: endpointElem.endpoint.id, endpointType: selectEndpointType as EndpointType })); // если изменился тип - меняем в store
				changeFlg.listOfChanges.includes('entry') && dispatch(editEndpointEntry({ endpointId: endpointElem.endpoint.id, conditionEntry: endpointElem.endpoint.entry, entryType: selectEntryType as EntryType })); // если изменились условия входа - меняем в store
				changeFlg.listOfChanges.includes('run') && dispatch(editEndpointRun({ endpointId: endpointElem.endpoint.id, conditionRun: endpointElem.endpoint.run })); // если изменились условия входа - меняем в store
				changeFlg.listOfChanges.includes('actions') && dispatch(editEndpointActions({ endpointId: endpointElem.endpoint.id, actions: endpointElem.endpoint.actions })); // если изменились условия входа - меняем в store
			}
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
		}
	}, [editingEndpoint]);

	// функция поиска условий входа в условиях вхождения и добавления их по умолчанию
	const searchEntryConditionsInRunConditions = (): void => {
		if (selectEntryType === 'inactivity') return; // если во входе настройка отложенных сообщений - выходим
		const indexAndList: Record<number, string[]> = {}; // индекс блока run условия - список id элементов данных
		// перебираем условия входа
		(endpointElem.endpoint?.entry as (IConditionData | IConditionVariable)[][])?.forEach(entryCondition => {
			const indexFound = endpointElem.endpoint?.run.findIndex((runCondition, idx) => {
				const modifyRunCondition = runCondition.map(condition => Object.entries(condition).sort()).toString();
				return entryCondition.filter(dataElem => modifyRunCondition.includes(Object.entries(dataElem).sort().toString())).length === entryCondition.length && !(idx in indexAndList);
			}); // найденный индекс блока условий запуска, в котором есть совпадение условия входа

			if (typeof indexFound === 'number' && indexFound >= 0) {
				!(indexFound in indexAndList) && (indexAndList[indexFound] = entryCondition.map(condition => condition.id));
			} else {
				(isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft') && dispatch(addConditionRunBlock(entryCondition)); // добавляем новый блок в run условия с этими entry условиями
				endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] })); // ставим флаг о несохраненных данных
				(indexAndList[Object.keys(indexAndList).length] = entryCondition.map(condition => condition.id));
			}
		});
		setIndexAndEntryList(indexAndList);
	};

	// обработчик изменения данных входа
	const changeEntryDataHandler = (): void => {
		setSelectEntryType(showAlertDialogChangeEntryData.entryType); // меняем тип входа
		dispatch(clearEntry()); // очистка данных входа
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entryType')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entryType'] })); // ставим флаг о несохраненных данных
		setShowAlertDialogChangeEntryData(prev => ({ ...prev, isShow: false })); // закрываем диалогове окно
	};

	// обработчик изменения флага включения условий запуска
	const changeEnableRunConditionsFlgHandler = (value: boolean): void => {
		setEnableRunConditions(value);
		if (value) {
			selectEntryType === 'conditions' && searchEntryConditionsInRunConditions(); // если включено и тип входа "условия" - ищем условия по умолчанию и дописываем при необходимости
		} else {
			setShowAlertDialogDisableRunConditions(false); // выключаем диалоговое окно
			endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
		}
	};

	// функция, определяющая отфильтрованные параметры, которые будут отображаться при поиске
	const filter = createFilterOptions<string>();

	// обработчик добавления конечной точки
	const addEndpointHandler = (): void => {
		activeRobotId && dispatch(addEndpoint({
			robotId: activeRobotId,
			data: {
				name: inputEndpointName,
				entryType: selectEntryType as EntryType,
				entry: endpointElem.endpoint?.entry || [],
				run: endpointElem.endpoint?.run || [],
				actions: endpointElem.endpoint?.actions || {},
				type: selectEndpointType as EndpointType,
				comment: inputComment,
			}
		})); // добавляем точку
	};

	// обработчик сохранения конечной точки
	const editEndpointHandler = (): void => {
		endpointElem.endpoint && activeRobotId && dispatch(editEndpoint({
			robotId: activeRobotId,
			endpointId: endpointElem.endpoint.id,
			data: {
				name: changeFlg.listOfChanges.includes('name') ? inputEndpointName : undefined,
				entryType: changeFlg.listOfChanges.includes('entryType') ? selectEntryType as EntryType : undefined,
				entry: changeFlg.listOfChanges.includes('entryType') || changeFlg.listOfChanges.includes('entry') ? endpointElem.endpoint.entry : undefined,
				run: changeFlg.listOfChanges.includes('run') ?
					enableRunConditions ? endpointElem.endpoint.run : []
					:
					undefined,
				actions: changeFlg.listOfChanges.includes('actions') ? endpointElem.endpoint.actions : undefined,
				type: changeFlg.listOfChanges.includes('type') ? selectEndpointType as EndpointType : undefined,
				comment: changeFlg.listOfChanges.includes('comment') ? inputComment : undefined,
			}
		})); // изменяем точку
	};

	// обработчик удаления конечной точки
	const deleteEndpointHandler = (): void => {
		setShowAlertDialogDel(false); // закрываем диалоговое окно
		endpointElem.endpoint && activeRobotId && dispatch(deleteEndpoint({ robotId: activeRobotId, endpointId: endpointElem.endpoint.id })); // удаление конечной точки
		closeHandler(true); // закрываем вкладку
	};

	// обработчик добавления условия входа
	const addConditionEntryHandler = (conditionBlockIdx: number): void => {
		dispatch(addConditionEntry({
			conditionBlockIdx, data: {
				type: 'data',
				depth: 5,
				id: '',
				operation: 'exists',
				value: '',
			}
		})); // добавляем условие
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик добавления условия запуска
	const addConditionRunHandler = (conditionBlockIdx: number): void => {
		dispatch(addConditionRun({
			conditionBlockIdx, data: {
				type: 'data',
				depth: 5,
				id: '',
				operation: 'exists',
				value: '',
			}
		})); // добавляем условие }));
		endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('run')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'run'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик перемещения действий
	const moveAction = (dragIndex: number, hoverIndex: number) => {
		if (endpointElem.endpoint) {
			dispatch(replaceEndpointActionList({
				channel: inputChannel,
				actionList: update(endpointElem.endpoint.actions[inputChannel], {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, endpointElem.endpoint.actions[inputChannel][dragIndex]],
					],
				}),
			})); // изменение последовательности действий
			endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] })); // ставим флаг о несохраненных данных
		}
	};

	// обработчик перемещения действий входа
	const moveActionEntry = (dragIndex: number, hoverIndex: number) => {
		if (endpointElem.endpoint) {
			const entryInactivityList = (endpointElem.endpoint.entry as IEntryInactivity[]);
			const indexInactivityItem = entryInactivityList.findIndex(inactivityItem => inactivityItem.channel === inputChannel);
			dispatch(replaceEndpointEntryActionList({
				channel: inputChannel,
				actionList: update(entryInactivityList[indexInactivityItem].actions, {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, entryInactivityList[indexInactivityItem].actions[dragIndex]],
					],
				}),
			})); // изменение последовательности действий
			endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] })); // ставим флаг о несохраненных данных
		}
	};

	// обработчик закрытия вкладки
	const closeHandler = (force: boolean = false): void => {
		// если нет изменений или принудительное закрытие
		(!changeFlg.thisIs || force) && setShowEndpointElem(false);
	};

	return (
		<Slide direction="up" in={showEndpointElem} mountOnEnter unmountOnExit>
			<div className={cn(styles.modal, {
				[styles.modalDarkBackground]: changeFlg.thisIs, // темный фон, когда есть изменения
			})} onMouseDown={() => closeHandler()}>
				<div className={styles.data} onMouseDown={(e) => e.stopPropagation()}>

					{/* загрузка конечной точки */}
					{endpointElem.status === RequestStatus.LOADING &&
						<div className={styles.loading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* ошибка получения конечной точки */}
					{(endpointElem.status === RequestStatus.FAILED || endpointElem.error === ResponseStatus.FAILED) &&
						<div className={styles.failed}>
							{translate(endpointElem.message || 'title_loadFailed')}
						</div>
					}

					{/* конечная точка */}
					{endpointElem.endpoint &&
						<Fade in={true} timeout={500}>
							<div className={styles.dataWrapper}>
								<div className={styles.dataTop}>
									<div className={styles.dataTopMain}>
										<div className={styles.dataTopMainBlock}>
											{/* название */}
											<FormControl fullWidth sx={{ marginTop: '8px' }}>
												<TextField
													required
													label={translate('input_name')}
													variant="outlined"
													disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
													value={inputEndpointName}
													onChange={(e) => {
														setInputEndpointName(e.target.value);
														endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('name')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'name'] }));  // ставим флаг о несохраненных данных;
													}}
													InputProps={{
														style: {
															height: 33,
															fontSize: 13,
															color: colorPrimary,
														},
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											</FormControl>

											{/* тип */}
											<FormControl sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, marginTop: '8px', flexShrink: 0, '.MuiSelect-select': { paddingBlock: 0 }, }}>
												<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
												<Select
													label={translate('select_type')}
													value={selectEndpointType}
													onChange={(e) => {
														setSelectEndpointType(e.target.value as EndpointType);
														endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('type')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'type'] })); // ставим флаг о несохраненных данных
													}}
													disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
													style={{ fontSize: 13, height: 33, color: colorPrimary }}
												>
													{ENPOINT_TYPES_LIST.map(({ type, translation }) =>
														<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
													)}
												</Select>
											</FormControl>

											{/* кнопка показа комментария */}
											<FontAwesomeIcon
												icon={inputComment ? faCommentDots : faComment}
												color={inputComment ? colorSecondary : colorPrimary}
												size="2xl"
												onClick={() => setShowComment(prev => !prev)}
												title={translate(showComment ? 'buttonTitle_hideComment' : 'buttonTitle_showComment')}
												style={{ cursor: 'pointer' }}
											/>
										</div>

										{/* комментарий */}
										{showComment &&
											<FormControl fullWidth>
												<TextField
													multiline
													maxRows={3}
													label={translate('input_comment')}
													variant="outlined"
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													value={inputComment}
													onChange={(e) => {
														setInputComment(e.target.value);
														endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('comment')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'comment'] }));  // ставим флаг о несохраненных данных
													}}
													InputProps={{
														style: {
															padding: '8px 13px',
															fontSize: 13,
															color: colorPrimary,
														},
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											</FormControl>
										}

										<ButtonGroup fullWidth>
											<Button
												variant={visibleBlockConfig === 'entry' ? "contained" : "outlined"}
												sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('entry')}
											>
												{translate('button_entryConditions')}
											</Button>
											<Button
												variant={visibleBlockConfig === 'run' ? "contained" : "outlined"}
												sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('run')}
											>
												{translate('button_runConditions')}
											</Button>
											<Button
												variant={visibleBlockConfig === 'actions' ? "contained" : "outlined"}
												sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }}
												onClick={() => setVisibleBlockConfig('actions')}
											>
												{translate('button_actions')}
											</Button>
										</ButtonGroup>

										{/* для таба "условия входа" */}
										{visibleBlockConfig === 'entry' &&
											<>
												<div className={styles.dataTopMainBlock}>
													{/* тип входа */}
													<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
														<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
														<Select
															label={translate('select_type')}
															value={selectEntryType}
															onChange={(e) => setShowAlertDialogChangeEntryData({ isShow: true, entryType: e.target.value as EntryType })}
															disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
															style={{ fontSize: 13, height: 33, color: colorPrimary }}
														>
															{ENPOINT_ENTRY_TYPES_LIST.map(({ type, translation }) =>
																<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
															)}
														</Select>
													</FormControl>
													{/* таймер */}
													{selectEntryType === 'inactivity' &&
														<FormControl>
															<TextField
																label={translate('input_timer')}
																variant="outlined"
																type='number'
																value={timer}
																onChange={(e) => setTimer(+e.target.value)}
																onBlur={e => {
																	dispatch(changeTimerInactivity({ channel: inputChannel, timer: +e.target.value }));
																	endpointElem.endpoint?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
																}}
																error={timer < 0}
																disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
																InputProps={{
																	style: {
																		height: 33,
																		fontSize: 13,
																		color: colorPrimary,
																	},
																	inputProps: { min: 0 },
																}}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														</FormControl>
													}
												</div>

												{/* фильтр условий входа */}
												{endpointElem.endpoint.id && selectEntryType === 'conditions' &&
													<div className={styles.dataTopMainBlock}>
														<Autocomplete
															multiple
															freeSolo
															autoHighlight
															openOnFocus
															options={dataElemList.data.map(dataElemItem => dataElemItem.name)}
															value={inputFilterByDataElem}
															onChange={(_, value) => setInputFilterByDataElem(value)}
															filterSelectedOptions
															filterOptions={(options, state) => {
																const filtered = filter(options, state);
																if (state.inputValue.length > 0 && options.findIndex(className => className === state.inputValue) === -1) filtered.push(state.inputValue);
																return filtered;
															}}
															renderInput={(params) =>
																<TextField
																	{...params}
																	label={translate('input_filterByDataElements')}
																	InputLabelProps={{
																		style: {
																			fontSize: 13,
																		},
																	}}
																	sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
																/>
															}
															sx={{
																".MuiInputBase-root": { minHeight: 33, fontSize: 13, color: colorPrimary },
																".MuiOutlinedInput-root": { padding: '0 0 0 3px', paddingRight: "30px!important" },
																flexGrow: 1,
															}}
															getOptionLabel={option => option}
															renderOption={(props, option, _state, ownerState) => {
																const match = ownerState.options.filter(classItem => classItem === option);
																return (
																	<li {...props} style={{ padding: '2px 5px', textAlign: 'left', fontSize: 12, color: colorPrimary }}>
																		{match.length === 0 ?
																			<>{translate('selectItem_addFilter')} "{option}"</>
																			:
																			<>{option}</>
																		}
																	</li>
																);
															}}
															renderTags={(value: readonly string[], getTagProps) =>
																value.map((option: string, index: number) => (
																	<Chip label={option} {...getTagProps({ index })} sx={{ height: 17, color: colorPrimary }} />
																))
															}
														/>
														<Button
															variant="outlined"
															sx={{ fontSize: 11 }}
															disabled={!enableRunConditions || !changeFlg.listOfChanges.includes('entry')}
															onClick={searchEntryConditionsInRunConditions}
														>
															{translate('button_updateRunConditions')}
														</Button>
													</div>
												}
												{/* канал */}
												{selectEntryType === 'inactivity' &&
													<FormControl fullWidth>
														<Autocomplete
															freeSolo={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
															options={Array.from(
																new Set(
																	((endpointElem.endpoint.entry as IEntryInactivity[]).map(entryData => entryData.channel))
																		.concat(channelList.data.map(channel => channel.name), 'default')
																))}
															value={inputChannel}
															onChange={(_, value) => setInputChannel(value ? value : '')}
															noOptionsText={<div className={styles.dataTopActionsNoDataTitle}>{translate('title_notFound')}</div>}
															renderInput={(params) =>
																<TextField
																	{...params}
																	label={translate('input_channel')}
																	onChange={(e) => isAccess(SES.ENDPOINT_EDIT) && setInputChannel(e.target.value)}
																	InputLabelProps={{
																		style: {
																			fontSize: 13,
																		},
																	}}
																	sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
																/>
															}
															sx={{
																".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
																".MuiInputBase-input": { marginTop: -1 },
															}}
															getOptionLabel={option => option}
															renderOption={(props, option) => {
																return (
																	<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																		{option}
																	</span>
																);
															}}
														/>
													</FormControl>
												}
											</>
										}

										{/* для таба "условия запуска" */}
										{visibleBlockConfig === 'run' &&
											<>
												<div className={styles.dataTopMainBlock}>
													{/* включение условий запуска */}
													<FormControlLabel sx={{ overflow: 'hidden', '.MuiTypography-root': { fontSize: 13 }, marginBlock: '-12px' }} control={
														<Checkbox
															checked={enableRunConditions}
															disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
															onChange={(e) => {
																e.target.checked ? changeEnableRunConditionsFlgHandler(true) : setShowAlertDialogDisableRunConditions(true);
															}}
															size='small'
														/>
													} label={translate('checkbox_enableRunConditions')} />
												</div>

												{/* фильтр условий запуска */}
												{endpointElem.endpoint.id && enableRunConditions && selectEntryType === 'conditions' &&
													<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
														<InputLabel sx={{ fontSize: 13 }}>{translate('select_filterByEntryCondition')}</InputLabel>
														<Select
															label={translate('select_filterByEntryCondition')}
															value={selectFilterByEntryConditions}
															onChange={(e) => setSelectFilterByEntryConditions(e.target.value)}
															style={{ fontSize: 13, height: 33, color: colorPrimary }}
														>
															<MenuItem value='' sx={{ fontSize: 13, color: colorPrimary }}>
																{translate('selectItem_notSelected')}
															</MenuItem>
															{(endpointElem.endpoint.entry as (IConditionData | IConditionVariable)[][]).map((entryConditionBlock, idx) => {
																const arrEntryConditionBlock = entryConditionBlock.map(condition => dataElemList.dictionary[condition.id] || condition.id);
																const entryConditionBlockStrValue = arrEntryConditionBlock.toString();
																const entryConditionBlockStrShow = arrEntryConditionBlock.join(', ');
																return (
																	<MenuItem key={entryConditionBlockStrValue + idx} value={entryConditionBlockStrValue} sx={{ fontSize: 13, color: colorPrimary }}>
																		{entryConditionBlockStrShow}
																	</MenuItem>
																);
															})}
														</Select>
													</FormControl>
												}
											</>
										}
									</div>

									{visibleBlockConfig === 'entry' &&
										<div className={styles.dataTopEntry}>
											{selectEntryType === 'conditions' &&
												<>
													{(endpointElem.endpoint.entry as (IConditionData | IConditionVariable)[][]).map((conditionDataBlock, conditionBlockIdx, arrayConditionsData) => {
														// фильтрация по элементам данных
														const suitableCondition = inputFilterByDataElem.filter(filterName => conditionDataBlock
															.map(condition => dataElemList.dictionary[condition.id] || condition.id) // перевод id -> name
															.find(dataElemName => dataElemName.toLowerCase().includes(filterName.toLowerCase()))
														).length === inputFilterByDataElem.length;
														if (suitableCondition) {
															return (
																// блоки ИЛИ
																<ConditionRunBlock
																	conditionDataBlock={conditionDataBlock}
																	conditionBlockIdx={conditionBlockIdx}
																	isDisable={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
																	isAvailable={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
																	changeFlg={changeFlg}
																	setChangeFlg={setChangeFlg}
																	conditionFor={{
																		for: 'endpointEntry',
																	}}
																	addConditionRunHandler={addConditionEntryHandler}
																	arrayConditionsData={arrayConditionsData}
																	key={conditionDataBlock.toString() + conditionBlockIdx}
																/>
															);
														} else return undefined;
													})}
													{endpointElem.endpoint.entry.length === 0 ?
														isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
														// форма добавления условия
														<fieldset className={styles.conditionDataBlock}>
															<legend>{translate('title_conditions')}</legend>
															<FormAddingConditionEntry
																conditionBlockIdx={endpointElem.endpoint?.entry?.length || 0}
																changeFlg={changeFlg}
																setChangeFlg={setChangeFlg}
															/>
														</fieldset>
														:
														isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
														// разделитель-кнопка ИЛИ
														<div className={styles.conditionDataBlockLineButton}>
															<span onClick={() => addConditionEntryHandler(endpointElem.endpoint?.entry.length || 0)}>{translate('title_or').toUpperCase()}</span>
														</div>
													}
												</>
											}
											{selectEntryType === 'inactivity' &&
												<div className={styles.dataTopActionsList}>
													<>
														<DndProvider backend={HTML5Backend}>
															{(endpointElem.endpoint.entry as IEntryInactivity[])?.length > 0 && (endpointElem.endpoint.entry as IEntryInactivity[]).find(entryData => entryData.channel === inputChannel)?.actions?.map((action, idx) => (
																<ActionEvent
																	key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}${action.action === 'transfer' && action.destination}`}
																	action={action}
																	idx={idx}
																	channel={inputChannel}
																	changeFlg={changeFlg}
																	setChangeFlg={setChangeFlg}
																	actionFor={{
																		for: 'endpointEntry',
																	}}
																	moveAction={moveActionEntry}
																/>
															))}
														</DndProvider>

														{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
															<FormAddingAction
																channel={inputChannel}
																changeFlg={changeFlg}
																setChangeFlg={setChangeFlg}
																actionFor={{
																	for: 'endpointEntry',
																}}
															/>
														}
													</>
												</div>
											}
										</div>
									}

									{visibleBlockConfig === 'run' &&
										<div className={styles.dataTopRun}>
											{enableRunConditions &&
												<>
													{endpointElem.endpoint.run.map((conditionDataBlock, conditionBlockIdx, arrayConditionsData) => {
														// фильтрация по условию входа
														const suitableCondition = selectFilterByEntryConditions === '' || selectFilterByEntryConditions
															.split(',')
															.filter(dataElemId => conditionDataBlock
																.map(condition => dataElemList.dictionary[condition.id] || condition.id)
																.includes(dataElemId)).length === selectFilterByEntryConditions.split(',').length;
														if (suitableCondition) {
															return (
																// блоки ИЛИ
																<ConditionRunBlock
																	conditionDataBlock={conditionDataBlock}
																	conditionBlockIdx={conditionBlockIdx}
																	isDisable={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
																	isAvailable={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
																	changeFlg={changeFlg}
																	setChangeFlg={setChangeFlg}
																	conditionFor={{
																		for: 'endpointRun',
																		entryList: conditionBlockIdx in indexAndEntryList ? indexAndEntryList[conditionBlockIdx] : undefined,
																	}}
																	addConditionRunHandler={addConditionRunHandler}
																	arrayConditionsData={arrayConditionsData}
																	key={conditionDataBlock.toString() + conditionBlockIdx}
																/>
															);
														} else return undefined;
													})
													}
													{endpointElem.endpoint.run.length === 0 ?
														isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
														// форма добавления условия
														<fieldset className={styles.conditionDataBlock}>
															<legend>{translate('title_conditions')}</legend>
															<FormAddingConditionRun
																conditionBlockIdx={endpointElem.endpoint?.run?.length || 0}
																changeFlg={changeFlg}
																setChangeFlg={setChangeFlg}
															/>
														</fieldset>
														:
														isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
														// разделитель-кнопка ИЛИ
														<div className={styles.conditionDataBlockLineButton}>
															<span onClick={() => addConditionRunHandler(endpointElem.endpoint?.run.length || 0)}>{translate('title_or').toUpperCase()}</span>
														</div>
													}
												</>
											}
										</div>
									}

									{visibleBlockConfig === 'actions' &&
										<div className={styles.dataTopActions}>
											<div className={styles.dataTopActionsBlock}>
												{/* канал */}
												<FormControl fullWidth>
													<Autocomplete
														freeSolo={isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft'}
														options={Array.from(
															new Set(
																(Object.keys(endpointElem.endpoint.actions || {}))
																	.concat(channelList.data.map(channel => channel.name), 'default')
															))}
														value={inputChannel}
														onChange={(_, value) => setInputChannel(value ? value : '')}
														noOptionsText={<div className={styles.dataTopActionsNoDataTitle}>{translate('title_notFound')}</div>}
														renderInput={(params) =>
															<TextField
																{...params}
																label={translate('input_channel')}
																onChange={(e) => isAccess(SES.ENDPOINT_EDIT) && setInputChannel(e.target.value)}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														}
														sx={{
															".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
															".MuiInputBase-input": { marginTop: -1 },
														}}
														getOptionLabel={option => option}
														renderOption={(props, option) => {
															return (
																<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																	{option}
																</span>
															);
														}}
													/>
												</FormControl>
											</div>

											{/* действия */}
											<div className={styles.dataTopActionsList}>
												<DndProvider backend={HTML5Backend}>
													{endpointElem.endpoint.actions[inputChannel]?.length > 0 && endpointElem.endpoint.actions[inputChannel].map((action, idx) => (
														<ActionEvent
															key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}${action.action === 'transfer' && action.destination}`}
															action={action}
															idx={idx}
															channel={inputChannel}
															changeFlg={changeFlg}
															setChangeFlg={setChangeFlg}
															actionFor={{
																for: 'endpoint',
															}}
															moveAction={moveAction}
														/>
													))}
												</DndProvider>

												{isAccess(SES.ENDPOINT_EDIT) && activeRobotVersion === 'draft' &&
													<FormAddingAction
														channel={inputChannel}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
														actionFor={{
															for: 'endpoint',
														}}
													/>
												}
											</div>
										</div>
									}
								</div>

								{activeRobotVersion === 'draft' &&
									<div className={styles.dataButtons}>
										{endpointElem.endpoint.id ?
											<>
												{/* сохранить */}
												{isAccess(SES.ENDPOINT_EDIT) &&
													<FormControl fullWidth>
														<Button
															variant="outlined"
															disabled={!changeFlg.thisIs}
															sx={{ fontSize: 11 }}
															onClick={editEndpointHandler}
														>
															{translate('button_save')}
														</Button>
													</FormControl>
												}
												{/* закрыть */}
												<FormControl fullWidth>
													<Button
														variant="outlined"
														sx={{ fontSize: 11 }}
														onClick={() => closeHandler(true)}
													>
														{translate('button_close')}
													</Button>
												</FormControl>
												{/* удалить */}
												{isAccess(SES.ENDPOINT_DELETE) &&
													<FormControl fullWidth>
														<Button
															variant="outlined"
															sx={{ fontSize: 11 }}
															onClick={() => setShowAlertDialogDel(true)}
															color='error'
														>
															{translate('button_delete')}
														</Button>
													</FormControl>
												}
											</>
											:
											// добавить
											isAccess(SES.ENDPOINT_ADD) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													sx={{ fontSize: 11 }}
													onClick={addEndpointHandler}
												>
													{translate('button_addEndpoint')}
												</Button>
											</FormControl>
										}
									</div>
								}
							</div>
						</Fade>
					}

					{/* смена типа условий входа */}
					<AlertDialog
						showAlertDialog={showAlertDialogChangeEntryData.isShow}
						closeHandler={() => setShowAlertDialogChangeEntryData(prev => ({ ...prev, isShow: false }))}
						submitHandler={changeEntryDataHandler}
						title='dialog_changeEntryType'
						description='dialog_changeEntryTypeConfirm'
						name={endpointElem.endpoint?.name}
					/>

					{/* выключение условий запуска */}
					<AlertDialog
						showAlertDialog={showAlertDialogDisableRunConditions}
						setShowAlertDialog={setShowAlertDialogDisableRunConditions}
						submitHandler={() => changeEnableRunConditionsFlgHandler(false)}
						title='dialog_disablingRunConditions'
						description='dialog_disablingRunConditionsConfirm'
						name={endpointElem.endpoint?.name}
					/>

					{/* удаление конечной точки */}
					<AlertDialog
						showAlertDialog={showAlertDialogDel}
						setShowAlertDialog={setShowAlertDialogDel}
						submitHandler={deleteEndpointHandler}
						title='dialog_deleteEndpoint'
						description='dialog_deleteEndpointConfirm'
						name={endpointElem.endpoint?.name}
					/>
				</div>
			</div>
		</Slide>
	);
};

export default ConfigEndpoint;
