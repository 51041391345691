import ReactJson from 'react-json-view';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppSelector } from '../../../store/hooks';
import { selectDebuggerSession } from '../../../store/sesSlice';
import useTranslate from '../../../hooks/useTranslate';
import { RequestStatus } from '../../../types/statusTypes';
import { ISessionDataProps } from './SessionData.props';
import styles from './SessionData.module.scss';

const SessionData = ({ showJsonBlock, deltaPosition }: ISessionDataProps): JSX.Element => {
	const debuggerSession = useAppSelector(selectDebuggerSession); // store - сессия робота
	const translate = useTranslate(); // hook для перевода текста

	// максимальная ширина JSON блока
	const getMaxWidthJsonBlock = (): number => {
		const windowWidth = window.innerWidth; // ширина окна
		const chatWidth = 400; // ширина чата
		const marginChat = 40; // отступ чата от края
		const marginJsonBock = 10; // отступ JSON блока от края
		const gap = 10; // интервал между чатом и JSON блоком
		const deltaPositionChat = deltaPosition.x < 0 ? Math.abs(deltaPosition.x) : -deltaPosition.x; // позиция сдвига чата
		if ((Math.abs(deltaPosition.x) + marginChat) > (window.innerWidth / 2 - chatWidth / 2)) {
			return windowWidth - (windowWidth - deltaPositionChat) + marginChat - gap - marginJsonBock; // JSON блок справа
		} else {
			return windowWidth - chatWidth - marginChat - gap - marginJsonBock - deltaPositionChat; // JSON блок слева
		}
	};

	return (
		<Fade in={showJsonBlock} mountOnEnter unmountOnExit timeout={300}>
			<div className={cn(styles.jsonBlock, {
				// абсолютное значение по Х со сдвигом от края > (половина ширины экрана - половина ширины блока чата)
				[styles.jsonBlockPositionRight]: (Math.abs(deltaPosition.x) + 40) > (window.innerWidth / 2 - 400 / 2),
			})} style={{ maxWidth: getMaxWidthJsonBlock() }}>
				<div className={styles.jsonBlockInner}>
					{(debuggerSession.sessionData || debuggerSession.status === RequestStatus.FAILED) ?
						<ReactJson
							src={debuggerSession.sessionData || (debuggerSession.status === RequestStatus.FAILED && { error: translate(debuggerSession.message || 'title_errorOccurred') }) || { data: null }}
							name={'root'} // имя root объекта
							displayObjectSize={false} // кол-во элементов
							enableClipboard={false} // значок копирования
							displayDataTypes={false} // тип данных
							shouldCollapse={(field) => field.name !== 'root' && !field.namespace.includes('current')} // ограничение на сворачивание объектов
						/>
						:
						<div className={styles.jsonBlockEmpty}>{translate('title_noData')}</div>
					}
				</div>
			</div>
		</Fade>
	);
};

export default SessionData;
