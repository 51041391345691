import { Slide } from '@mui/material';
import { ITagsWrapperProps } from './TagsWrapper.props';
import styles from './TagsWrapper.module.scss';

const TagsWrapper = ({ children }: ITagsWrapperProps): JSX.Element => {

	return (
		<Slide direction="left" in={true} mountOnEnter unmountOnExit timeout={1000}>
			<section className={styles.tags}>
				{children}
			</section>
		</Slide>
	);
};

export default TagsWrapper;
