import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormExportQuestions from '../../../Forms/FormExportQuestions/FormExportQuestions';
import { IExportQuestionsProps } from './ExportQuestions.props';

const ExportQuestions = ({ isAvailable, filteredQuestionsList }: IExportQuestionsProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы выбора настроек экспорта

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_exportQuestions')}
			>
				<FontAwesomeIcon icon={faFileCsv} size="xl" color={backgroundColor} />
			</button>

			{showModal &&
				<FormExportQuestions
					showModal={showModal}
					setShowModal={setShowModal}
					filteredQuestionsList={filteredQuestionsList}
				/>
			}
		</>
	);
};

export default ExportQuestions;
