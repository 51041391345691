import { ChangeEvent, useEffect, useState } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '../../../store/hooks';
import { addRule, changeRuleChapter, changeRuleDescription, deleteRule } from '../../../store/qasSummareSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../constants/colors';
import AlertDialog from '../../AlertDialog/AlertDialog';
import { IRulesBlockProps } from './RulesBlock.props';
import styles from './RulesBlock.module.scss';

const RulesBlock = ({ chapter: initialChapter, desc: initialDesc, id, index, arrayRules, changeFlg, setChangeFlg }: IRulesBlockProps): JSX.Element => {
	const [chapter, setChapter] = useState<string>(initialChapter); // раздел
	const [desc, setDesc] = useState<string>(initialDesc); // описание
	const [showAlertDialogDelete, setShowAlertDialogDelete] = useState<boolean>(false); // показ диалогового окна для удаления правила

	const dispatch = useAppDispatch();

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за значениями
	useEffect(() => {
		// если заполнены оба поля у последней пары - добавляем новую пару
		if (arrayRules.length - 1 === index && chapter !== '' && desc !== '') {
			dispatch(addRule({ chapter, desc, id }));
		}
	}, [chapter, desc]);

	// обработчик изменения названия раздела
	const changeChapterHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		setChapter(e.target.value);
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('options')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'options'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик сохранения названия раздела
	const saveChapterHandler = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
		// если были изменения и заполнено поле
		if (e.target.value !== initialChapter && e.target.value !== '' && initialChapter !== '') {
			dispatch(changeRuleChapter({ oldChapter: initialChapter, newChapter: e.target.value }));
		}
	};

	// обработчик изменения описания раздела
	const changeDescHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		setDesc(e.target.value);
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('options')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'options'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик сохранения описания раздела
	const saveDescHandler = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
		// если были изменения и заполнено поле
		if (e.target.value !== initialDesc && e.target.value !== '' && chapter !== '') {
			dispatch(changeRuleDescription({ chapter, newDesc: e.target.value }));
		}
	};

	// обработчик удаления правила
	const ruleDeleteHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		dispatch(deleteRule(chapter));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('options')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'options'] }));  // ставим флаг о несохраненных данных
	};

	return (
		<div className={styles.rulesBlock}>
			{/* раздел */}
			<FormControl sx={{ flexShrink: 0, maxWidth: '40%' }}>
				<TextField
					variant="outlined"
					placeholder={translate('input_chapter')}
					value={chapter}
					onChange={changeChapterHandler}
					onBlur={saveChapterHandler}
					disabled={!isAccess(QAS.TEMPLATE_EDIT)}
					error={arrayRules.length - 1 !== index && chapter === ''}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '0 14px' },
					}}
				/>
			</FormControl>

			{/* описание */}
			<FormControl fullWidth>
				<TextField
					variant="outlined"
					placeholder={translate('input_description')}
					multiline
					maxRows={4}
					value={desc}
					onChange={changeDescHandler}
					onBlur={saveDescHandler}
					disabled={!isAccess(QAS.TEMPLATE_EDIT)}
					error={arrayRules.length - 1 !== index && desc === ''}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								{isAccess(QAS.TEMPLATE_EDIT) && arrayRules.length - 1 !== index &&
									<div className={styles.deleteButton}>
										<FontAwesomeIcon icon={faTrashCan} color={colorRed} onClick={() => setShowAlertDialogDelete(true)} style={{ cursor: 'pointer' }} title={translate('buttonTitle_delete')} />
									</div>
								}
							</InputAdornment>
						),
						style: {
							padding: 8,
							fontSize: 12,
							color: colorPrimary,
						},
					}}
				/>
			</FormControl>

			{showAlertDialogDelete &&
				<AlertDialog
					showAlertDialog={showAlertDialogDelete}
					setShowAlertDialog={setShowAlertDialogDelete}
					submitHandler={ruleDeleteHandler}
					title='dialog_deleteRule'
					description='dialog_deleteRuleConfirm'
				/>
			}
		</div>
	);
};

export default RulesBlock;
