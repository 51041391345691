import { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Fade, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getDataList, getEndpointList } from '../../../store/sesSlice';
import { addingActiveRobotId, addingRobotPossibleVersions, changeActiveRobotVersion, clearAddingRobot, clearRobot, getRobot, getRobotList, selectAddingRobot, selectRobot, selectRobotList } from '../../../store/sesRobotSlice';
import { getScriptList } from '../../../store/sesScriptSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { ROBOT_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import FormAddingRobot from '../../Forms/FormAddingRobot/FormAddingRobot';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import styles from './RobotNavbar.module.scss';

const RobotNavbar = (): JSX.Element => {
	const [activeRobotId, setActiveRobotId] = useState<string>(''); // id активного робота
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления робота
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список роботов

	const dispatch = useAppDispatch();
	const robotList = useAppSelector(selectRobotList); // store - список роботов
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе

	const [cookies, setCookie] = useCookies([ROBOT_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком роботов и версией робота
	useEffect(() => {
		// только если список роботов не пустой
		if (robotList.data.length > 0) {
			// если есть запись в cookie и список роботов содержит эту запись, то передаем ее в обработчик выбора активного робота, иначе выбираем первого робота из списка
			const foundRobot = robotList.data.find(robot => robot.id === cookies.robotId);
			foundRobot && cookies.robotId ?
				robotHandler({ robotId: foundRobot.id, hasProd: foundRobot.hasProd, hasBackup: foundRobot.hasBackup })
				:
				robotHandler({ robotId: robotList.data[0].id, hasProd: robotList.data[0].hasProd, hasBackup: robotList.data[0].hasBackup });
		}
	}, [robotList.data, robotList.activeRobotVersion]);

	// следим за активным роботом
	useEffect(() => {
		listRef.current?.children[robotList.data.findIndex(robot => robot.id === activeRobotId)]?.scrollIntoView({ block: "center" }); // показ активного робота в центре списка с имеющейся полосой прокрутки
	}, [activeRobotId]);

	// обработчик выбора активного робота
	const robotHandler = ({ robotId, hasProd, hasBackup }: { robotId: string, hasProd: boolean, hasBackup: boolean }): void => {
		robotInfo.data && dispatch(clearRobot()); // очищаем данные о роботе, если есть
		setActiveRobotId(robotId); // устанавливаем id активного робота
		dispatch(addingActiveRobotId(robotId)); // добавляем id активного робота в store
		dispatch(addingRobotPossibleVersions({ hasProd, hasBackup })); // добавление возможных версий робота (прод, бэкап)
		setCookie(ROBOT_ID, robotId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц

		// если нет выбранной версии
		if ((robotList.activeRobotVersion === 'prod' && !hasProd) || (robotList.activeRobotVersion === 'backup' && !hasBackup)) {
			dispatch(changeActiveRobotVersion('draft')); // сбрасываем версию активного робота на черновик
			return; // выходим
		}

		isAccess(SES.ROBOT_GET) && dispatch(getRobot(robotId)); // получаем инфо о роботе
		isAccess(SES.DATA_LIST) && dispatch(getDataList(robotId)); // получаем список элементов данных
		isAccess(SES.ENDPOINT_LIST) && dispatch(getEndpointList(robotId)); // получаем список конечных точек
		isAccess(SES.SCRIPT_LIST) && dispatch(getScriptList(robotId)); // получаем список скриптов
		// setChangeFlg(false); // сбрасываем флаг о не сохраненных данных
	};

	return (
		<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
			<div className={styles.sidebar}>
				<div className={styles.navbar}>
					{/* ошибка загрузки списка роботов */}
					{(robotList.status === RequestStatus.FAILED || robotList.error === ResponseStatus.FAILED) &&
						<div className={styles.navbarFailedText}>
							<span>{translate(robotList.message || 'title_loadFailed')}</span>
							{isAccess(SES.ROBOT_LIST) &&
								<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getRobotList())}>{translate('link_update')}</span>
							}
						</div>
					}

					{/* пустой список роботов */}
					{robotList.status === RequestStatus.IDLE && robotList.data.length === 0 &&
						<div className={styles.navbarNoRobots}>{translate('title_emptyList')}</div>
					}

					{/* загрузка списка роботов */}
					{robotList.status === RequestStatus.LOADING &&
						<div className={styles.navbarLoading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* список роботов */}
					<ul className={styles.navbarRobotList} ref={listRef}>
						{robotList.data.map(({ id, name, hasProd, hasBackup }) => (
							<li className={styles.navbarRobot} key={id}>
								<Fade in={robotList.status !== RequestStatus.LOADING || robotList.data.length > 0} timeout={500}>
									<div
										className={cn({
											[styles.navbarRobotLink]: activeRobotId !== id,
											[styles.navbarRobotLinkActive]: activeRobotId === id,
										})}
										onClick={() => id !== activeRobotId && robotHandler({ robotId: id, hasProd, hasBackup })}
									>
										<div className={styles.navbarRobotLinkLeftBlock} title={name}>
											{name}
										</div>
										<FontAwesomeIcon icon={faAngleRight} />
									</div>
								</Fade>
							</li>
						))}
					</ul>

					<div className={styles.functionButtons}>
						{/* добавление робота */}
						{isAccess(SES.ROBOT_ADD) && robotList.status === RequestStatus.IDLE &&
							<Fade in={true} timeout={500}>
								<div className={styles.functionButtonsAddRobot} onClick={() => setShowModal(true)}>
									{translate('link_addRobot')}
								</div>
							</Fade>
						}
					</div>
				</div>

				{showModal && <FormAddingRobot showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />}
				{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectAddingRobot} clearDataResponse={clearAddingRobot} titleFailed={translate('noticeAddition_failed')} titleSuccess={translate('noticeAddition_success')} />}
			</div>
		</Slide>
	);
};

export default RobotNavbar;
