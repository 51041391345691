import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addRightsToRole, clearRoleDelete, clearRoleEdit, clearRolesList, deleteRole, editRole, getRolesList, selectRoleDeleteStatus, selectRoleEditStatus, selectRolesList } from '../../../store/authSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { ROLE } from '../../../constants/accessRights';
import Controls from '../../../HOC/Controls/Controls';
import Save from '../Buttons/Save/Save';
import Delete from '../Buttons/Delete/Delete';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import { IRoleControlsProps } from './RoleControls.props';

const RoleControls = ({ changeFlg, activeRole, setActiveRole, rolesRights, setChangeFlg, setShowPage }: IRoleControlsProps) => {
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const rolesList = useAppSelector(selectRolesList); // store - список ролей
	const editingRoleStatus = useAppSelector(selectRoleEditStatus); // store - статус изменения набора данных
	const deletingRoleStatus = useAppSelector(selectRoleDeleteStatus); // store - статус удаления набора данных

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления роли
	useEffect(() => {
		// если идет изменение/удаление - включаем экран блокировки с подписью
		if (editingRoleStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_saving') });
		else if (deletingRoleStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_deletion') });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если изменения сохранились
		if (editingRoleStatus.error === ResponseStatus.SUCCESS && editingRoleStatus.status === RequestStatus.IDLE && editingRoleStatus.message === 'success') {
			setChangeFlg(false); // выключаем флаг измененных данных
			dispatch(addRightsToRole({ rights: rolesRights, idx: activeRole.idx })); // добавляем права в store
		}
		// если удалилось успешно 
		if (deletingRoleStatus.error === ResponseStatus.SUCCESS && deletingRoleStatus.status === RequestStatus.IDLE && deletingRoleStatus.message !== '') {
			setActiveRole({ id: '', idx: 0 }); // сбрасываем активную роль
			dispatch(clearRolesList()); // очищаем список ролей
			dispatch(getRolesList()); // получаем заново список ролей
		}
	}, [editingRoleStatus, deletingRoleStatus]);

	// обработчик изменения роли
	const editRoleHandler = () => {
		dispatch(editRole({ roleId: activeRole.id, rights: rolesRights }));
	};

	// обработчик удаления роли
	const deleteRoleHandler = () => {
		dispatch(deleteRole(activeRole.id));
	};

	return (
		<>
			<Controls
				header='roles'
				setShowPage={setShowPage}
				rightSection={
					<div>
						{isAccess(ROLE.EDIT) &&
							<Save
								changeFlg={changeFlg}
								dataResponse={selectRoleEditStatus}
								clearDataResponse={clearRoleEdit}
								submitHandler={editRoleHandler}
								name={rolesList.data[activeRole.idx]?.name}
								dialogTitle='dialog_editRole'
								dialogConfirm='dialog_editRoleConfirm'
							/>
						}
						{isAccess(ROLE.DELETE) &&
							<Delete
								isAvailable={rolesList.data.length > 0 && activeRole.id !== '0'}
								dataResponse={selectRoleDeleteStatus}
								clearDataResponse={clearRoleDelete}
								submitHandler={deleteRoleHandler}
								name={rolesList.data[activeRole.idx]?.name || ''}
								buttonTitle='buttonTitle_deleteRole'
								dialogTitle='dialog_deleteRole'
								dialogConfirm='dialog_deleteRoleConfirm'
							/>
						}
					</div>
				}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
		</>
	);
};

export default RoleControls;
