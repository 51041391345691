import { Slide } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { selectQuestionsList } from '../../store/qasSlice';
import useTranslate from '../../hooks/useTranslate';
import { RequestStatus } from '../../types/statusTypes';
import CandidatesTable from '../Tables/Candidates/Candidates';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ILogQuestionsProps } from './Candidates.props';
import styles from './Candidates.module.scss';

const Candidates = ({ showCandidates, setShowCandidates }: ILogQuestionsProps): JSX.Element => {
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов

	const translate = useTranslate(); // hook для перевода текста

	return (
		<Slide direction="left" in={showCandidates} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={() => setShowCandidates(false)}>
				<div className={styles.wrapper} onMouseDown={(e) => e.stopPropagation()}>

					{questionsList.data.length > 0 ?
						<div className={styles.wrapperTable}>
							<CandidatesTable />
							{questionsList.status === RequestStatus.LOADING &&
								<div className={styles.wrapperTableScreenLock}>
									<ProgressCircle title='' />
								</div>
							}
						</div>
						:
						<div className={styles.noData}><div>{translate('title_noData')}</div></div>
					}

					<div className={styles.tagClose} onClick={() => setShowCandidates(false)}>
						{translate('tag_close')}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default Candidates;
