import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { clearDeletionTask, deleteTask, getQueue, selectDeletionTaskStatus, selectQueue, selectRecognitionAsyncData, selectRecognitionData } from '../../../store/sprSlice';
import { selectDeleteStatus } from '../../../store/modelSlice';
import { clearClusterServer, getClusterServerModels } from '../../../store/serverSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SERVER, SPR, TRANSCRIPTION } from '../../../constants/accessRights';
import { SPR_TASK_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import Controls from '../../../HOC/Controls/Controls';
import ToggleColoring from '../Buttons/ToggleColoring/ToggleColoring';
import ExportToDocSpr from '../Buttons/ExportToDocSpr/ExportToDocSpr';
import Delete from '../Buttons/Delete/Delete';
import NewTranscript from '../Buttons/NewTranscript/NewTranscript';
import FormSendAudioSpr from '../../Forms/FormSendAudioSpr/FormSendAudioSpr';
import ScreenLock from '../../ScreenLock/ScreenLock';
import NoticeSingleAction from '../../Notification/NoticeSingleAction/NoticeSingleAction';
import { IQueueControlsProps } from './QueueControls.props';

const QueueControls = ({ serviceType, setShowPage }: IQueueControlsProps): JSX.Element => {
	const [showNotificationSendForRecognition, setShowNotificationSendForRecognition] = useState<boolean>(false); // уведомление отправки на распознавание
	const [showNotificationErrorSendForRecognition, setShowNotificationErrorSendForRecognition] = useState<boolean>(false); // уведомление ошибки отправки на распознавание

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // экран блокировки

	const dispatch = useAppDispatch();
	const queue = useAppSelector(selectQueue); // store - очередь распознавания
	const recognitionData = useAppSelector(selectRecognitionData); // store - данные распознавания речи
	const recognitionAsyncData = useAppSelector(selectRecognitionAsyncData); // store - данные асинхронного режима
	const deletionTaskStatus = useAppSelector(selectDeletionTaskStatus); // store - статус удаления задачи

	const deleteModelStatus = useAppSelector(selectDeleteStatus); // store - статус удаления модели

	const [cookies, setCookie] = useCookies([SPR_TASK_ID, 'sprModelName']); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом удаления задачи
	useEffect(() => {
		if (recognitionData.status === RequestStatus.LOADING && recognitionData.asyncMode) setShowScreenLock({ isShow: true, title: 'spinnerTitle_sending' }); // если отправка на распознавание
		else if (deletionTaskStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' }); // если идет удаление задачи
		else if (deleteModelStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_deletion') }); // если идет удаление модели
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если ошибка отправки на распознавание - уведомление
		if (recognitionData.status === RequestStatus.FAILED || (recognitionData.data && 'message' in recognitionData.data)) setShowNotificationErrorSendForRecognition(true);
		// если удаление задачи прошло успешно - получаем заново список очереди
		if (deletionTaskStatus.status === RequestStatus.IDLE && deletionTaskStatus.error === ResponseStatus.SUCCESS && deletionTaskStatus.message !== '') dispatch(getQueue());
		// если удаление модели прошло успешно
		if (deleteModelStatus.error === ResponseStatus.SUCCESS && deleteModelStatus.status === RequestStatus.IDLE && deleteModelStatus.message !== '') {
			dispatch(clearClusterServer()); // очищаем список моделей сервера
			isAccess(SERVER.MODEL_LIST) && dispatch(getClusterServerModels({ serviceType })); // получаем продовые модели
		}
	}, [recognitionData.status, deletionTaskStatus, deleteModelStatus]);

	// следим за id задачи асинхронного распознавания
	useEffect(() => {
		if (recognitionAsyncData.taskId) {
			setShowNotificationSendForRecognition(true); // если есть задача - включаем уведомление
			setCookie(SPR_TASK_ID, recognitionAsyncData.taskId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
			isAccess(SPR.QUEUE) && dispatch(getQueue()); // обновляем очередь распознавания
		}
	}, [recognitionAsyncData.taskId]);

	// обработчик удаления задачи
	const taskDeletionHandler = (): void => {
		dispatch(deleteTask(cookies.sprTaskId));
	};

	return (
		<>
			<Controls
				header='spr'
				setShowPage={setShowPage}
				leftSection={
					<>
						<div>
							<ToggleColoring
								isAvailable={true}
							/>
						</div>
						<div>
							{(isAccess(TRANSCRIPTION.CREATE) || isAccess(TRANSCRIPTION.MODIFY)) &&
								<NewTranscript
									isAvailable={recognitionAsyncData.requestStatus === RequestStatus.IDLE && recognitionAsyncData.data !== null && 'speakers' in recognitionAsyncData.data}
								/>
							}
							<ExportToDocSpr
								isAvailable={(recognitionAsyncData.data !== null && 'splitted' in recognitionAsyncData.data && Array.isArray(recognitionAsyncData.data.splitted) && recognitionAsyncData.data.splitted.length > 0) || (recognitionAsyncData.data !== null && 'words' in recognitionAsyncData.data && Array.isArray(recognitionAsyncData.data.words) && recognitionAsyncData.data.words.length > 0)}
								fileName={recognitionAsyncData.data && 'processed' in recognitionAsyncData.data ? recognitionAsyncData.data.processed : 'document'}
								data={recognitionAsyncData.data !== null && 'splitted' in recognitionAsyncData.data && Array.isArray(recognitionAsyncData.data.splitted) ?
									{
										type: 'dialog',
										data: (recognitionAsyncData.data !== null && 'splitted' in recognitionAsyncData.data && Array.isArray(recognitionAsyncData.data.splitted) && recognitionAsyncData.data.splitted) || [],
									} : {
										type: 'monolog',
										data: (recognitionAsyncData.data !== null && 'words' in recognitionAsyncData.data && Array.isArray(recognitionAsyncData.data.words) && recognitionAsyncData.data.words) || [],
									}
								}
								speakerList={recognitionAsyncData.speakerList}
							/>
							{isAccess(SPR.DELETE_TASK) &&
								<Delete
									isAvailable={queue.status === RequestStatus.IDLE && queue.data !== null && Object.keys(queue.data).length > 0 && recognitionAsyncData.requestStatus !== RequestStatus.LOADING}
									dataResponse={selectDeletionTaskStatus}
									clearDataResponse={clearDeletionTask}
									submitHandler={taskDeletionHandler}
									buttonTitle='buttonTitle_deleteTask'
									dialogTitle='dialog_deleteTask'
									dialogConfirm='dialog_deleteTaskConfirm'
								/>
							}
						</div>
					</>
				}
				rightSection={
					<div>
						{isAccess([SPR.RECOGNITION, SERVER.MODEL_LIST]) &&
							<FormSendAudioSpr />
						}
					</div>
				}
			/>

			{/* уведомление для отправки аудио на распознавание в очередь */}
			{showNotificationSendForRecognition &&
				<NoticeSingleAction
					showNotification={showNotificationSendForRecognition}
					setShowNotification={setShowNotificationSendForRecognition}
					title='noticeAddingRecognitionQueue'
					severity='info'
				/>
			}

			{/* уведомление ошибки отправки аудио на распознавание */}
			{showNotificationErrorSendForRecognition &&
				<NoticeSingleAction
					showNotification={showNotificationErrorSendForRecognition}
					setShowNotification={setShowNotificationErrorSendForRecognition}
					title={recognitionData.data && 'message' in recognitionData.data
						? recognitionData.data.message
						: 'noticeRecognitionError'
					}
					severity='error'
				/>
			}

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
		</>
	);
};

export default QueueControls;
