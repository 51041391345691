import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRepeat } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import { selectRecognitionData } from '../../../../store/sprSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormReRecognition from '../../../Forms/FormReRecognition/FormReRecognition';
import NoticeSingleAction from '../../../Notification/NoticeSingleAction/NoticeSingleAction';
import { IReRecognitionProps } from './ReRecognition.props';

const ReRecognition = ({ isAvailable }: IReRecognitionProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы отправки на распознавание
	const [showNotificationSuccess, setShowNotificationSuccess] = useState<boolean>(false); // показ успешного уведомления
	const [showNotificationFailed, setShowNotificationFailed] = useState<boolean>(false); // показ уведомления с ошибкой

	const recognitionData = useAppSelector(selectRecognitionData); // store - распознавание речи

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_reRecognition')}>
				<FontAwesomeIcon icon={faRepeat} size="xl" color={backgroundColor} />
			</button>

			{showModal &&
				<FormReRecognition
					showModal={showModal}
					setShowModal={setShowModal}
					setShowNotificationSuccess={setShowNotificationSuccess}
					setShowNotificationFailed={setShowNotificationFailed}
				/>
			}

			{/* уведомление для отправки аудио на распознавание в очередь */}
			{showNotificationSuccess &&
				<NoticeSingleAction
					showNotification={showNotificationSuccess}
					setShowNotification={setShowNotificationSuccess}
					title='noticeAddingRecognitionQueue'
					severity='info'
				/>
			}

			{/* уведомление ошибки отправки аудио на распознавание */}
			{showNotificationFailed &&
				<NoticeSingleAction
					showNotification={showNotificationFailed}
					setShowNotification={setShowNotificationFailed}
					title={recognitionData.data && 'message' in recognitionData.data
						? recognitionData.data.message
						: 'noticeRecognitionError'
					}
					severity='error'
				/>
			}
		</>
	);
};

export default ReRecognition;
