import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearDeleteMarks, clearExportMarks, clearImportMarks, clearMarksList, clearPutMarks, deleteMarks, exportMarks, getMarksList, putMarks, selectDeleteMarks, selectExportMarks, selectImportMarks, selectMarks, selectMarksList, selectPutMarks } from '../../../store/marksSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { MARKS } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import Controls from '../../../HOC/Controls/Controls';
import Export from '../Buttons/Export/Export';
import Import from '../Buttons/Import/Import';
import Save from '../Buttons/Save/Save';
import Delete from '../Buttons/Delete/Delete';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { IMarksControlsProps } from './MarksControls.props';

const MarksControls = ({ changeFlg, setChangeFlg, setShowPage }: IMarksControlsProps): JSX.Element => {
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток
	const marks = useAppSelector(selectMarks); // store - словарь меток
	const putMarksStatus = useAppSelector(selectPutMarks); // store - статус сохранения изменений в словаре меток
	const deleteMarksStatus = useAppSelector(selectDeleteMarks); // store - статус удаления словаря меток

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления роли
	useEffect(() => {
		// если идет изменение/удаление - включаем экран блокировки с подписью
		if (putMarksStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_saving') });
		else if (deleteMarksStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: translate('spinnerTitle_deletion') });
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем

		// если изменения сохранились
		if (putMarksStatus.error === ResponseStatus.SUCCESS && putMarksStatus.status === RequestStatus.IDLE && putMarksStatus.message !== '') {
			setChangeFlg(false); // выключаем флаг измененных данных
		}
		// если удалилось успешно 
		if (deleteMarksStatus.error === ResponseStatus.SUCCESS && deleteMarksStatus.status === RequestStatus.IDLE && deleteMarksStatus.message !== '') {
			dispatch(clearMarksList()); // очищаем список словарей
			dispatch(getMarksList()); // получаем заново список словарей меток
		}
	}, [putMarksStatus, deleteMarksStatus]);

	// обработчик экспорта словаря меток
	const exportHandler = (): void => {
		marksList.activeMarksId && marksList.activeMarksName && dispatch(exportMarks({ marksId: marksList.activeMarksId, marksName: marksList.activeMarksName })); // экспорт словаря меток
	};

	// обработчик сохранения изменений
	const changeHandler = (): void => {
		marksList.activeMarksId && dispatch(putMarks({ marksId: marksList.activeMarksId, data: marks.data }));
	};

	// обработчик удаления словаря меток
	const deleteMarksHandler = (): void => {
		marksList.activeMarksId && dispatch(deleteMarks(marksList.activeMarksId));
	};

	return (
		<>
			<Controls
				header='smc'
				setShowPage={setShowPage}
				rightSection={
					<>
						<div>
							{isAccess(MARKS.EXPORT) &&
								<Export
									isAvailable={marksList.activeMarksId !== null && marks.status !== RequestStatus.LOADING}
									selectDataResponse={selectExportMarks}
									clearDataResponse={clearExportMarks}
									submitHandler={exportHandler}
								/>
							}
							{isAccess(MARKS.IMPORT) &&
								<Import
									isAvailable={marksList.activeMarksId !== null && marks.status !== RequestStatus.LOADING}
									selectDataResponse={selectImportMarks}
									clearDataResponse={clearImportMarks}
									placeOfImport='marks'
									serviceType='smc'
								/>
							}
						</div>
						<div>
							{isAccess(MARKS.PUT) &&
								<Save
									changeFlg={changeFlg}
									dataResponse={selectPutMarks}
									clearDataResponse={clearPutMarks}
									submitHandler={changeHandler}
									name={marksList.activeMarksName || ''}
									dialogTitle='dialog_saveMarks'
									dialogConfirm='dialog_saveMarksConfirm'
								/>
							}
							{isAccess(MARKS.DELETE) &&
								<Delete
									isAvailable={marksList.activeMarksId !== null && marks.status !== RequestStatus.LOADING}
									dataResponse={selectDeleteMarks}
									clearDataResponse={clearDeleteMarks}
									submitHandler={deleteMarksHandler}
									name={marksList.activeMarksName || ''}
									dialogTitle='dialog_deleteMarks'
									dialogConfirm='dialog_deleteMarksConfirm'
								/>
							}
						</div>
					</>
				}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
		</>
	);
};

export default MarksControls;
