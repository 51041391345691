import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IQuestionProps } from './Question.props';

const Question = ({ isAvailable, setShowAnswerToQuestion }: IQuestionProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<button
			onClick={() => setShowAnswerToQuestion(prev => !prev)}
			disabled={!isAvailable}
			title={translate('buttonTitle_askQuestion')}>
			<FontAwesomeIcon icon={faQuestion} size="xl" color={backgroundColor} />
		</button>
	);
};

export default Question;
