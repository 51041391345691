import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import userReducer from './userSlice';
import modelReducer from './modelSlice';
import serverReducer from './serverSlice';
import corpusSlice from './corpusSlice';
import marksSlice from './marksSlice';
import smcSlice from './smcSlice';
import seeSlice from './seeSlice';
import sprSlice from './sprSlice';
import transcriptionSlice from './transcriptionSlice';
import sbsSlice from './sbsSlice';
import ttsSlice from './ttsSlice';
import qasSlice from './qasSlice';
import qasSummareSlice from './qasSummareSlice';
import sesSlice from './sesSlice';
import resSlice from './resSlice';
import sesRobotSlice from './sesRobotSlice';
import sesScriptSlice from './sesScriptSlice';
import langSlice from './langSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    user: userReducer,
    model: modelReducer,
    server: serverReducer,
    corpus: corpusSlice,
    marks: marksSlice,
    smc: smcSlice,
    see: seeSlice,
    spr: sprSlice,
    transcription: transcriptionSlice,
    sbs: sbsSlice,
    tts: ttsSlice,
    qas: qasSlice,
    qasSummare: qasSummareSlice,
    ses: sesSlice,
    res: resSlice,
    sesRobot: sesRobotSlice,
    sesScript: sesScriptSlice,
    lang: langSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
