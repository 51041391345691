import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { ITabSwitchProps } from './TabSwitch.props';
import styles from './TabSwitch.module.scss';

const TabSwitch = ({ isAvailable, isActive, icon, title, onClickFunction }: ITabSwitchProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<button
			className={cn({
				[styles.active]: isActive,
			})}
			onClick={onClickFunction}
			disabled={!isAvailable}
			title={translate(title)}
		>
			<FontAwesomeIcon
				icon={icon}
				size="xl"
				color={backgroundColor}
			/>
		</button>
	);
};

export default TabSwitch;
