import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormProcessingDocuments from '../../../Forms/FormProcessingDocuments/FormProcessingDocuments';
import { IDocumentProcessingProps } from './DocumentProcessing.props';

const DocumentProcessing = ({ isAvailable, documentUpdateHandler }: IDocumentProcessingProps): JSX.Element => {
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы обработки документов

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<button
				onClick={() => setShowModal(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_processingDocuments')}
			>
				<FontAwesomeIcon icon={faFileCircleQuestion} size="xl" color={backgroundColor} />
			</button>

			{showModal &&
				<FormProcessingDocuments
					showModal={showModal}
					setShowModal={setShowModal}
					documentUpdateHandler={documentUpdateHandler}
				/>
			}
		</>
	);
};

export default DocumentProcessing;
