import { useEffect, useMemo, useState } from 'react';
import { useMicVAD, utils } from "@ricky0123/vad-react";
import { useAppSelector } from '../../../../store/hooks';
import { selectDebuggerAnswer } from '../../../../store/sesSlice';
import { IVadProps } from './Vad.props';

const vadDefaultSetting = {
	POSITIVE_SPEECH_THRESHOLD: 0.5, // определяет порог, при превышении которого считается вероятность, указывающая на наличие речи
	NEGATIVE_SPEECH_THRESHOLD: 0.35, // определяет порог, ниже которого считается вероятность, указывающая на отсутствие речи
	REDEMPTION_FRAMES: 4, // кол-во кадров с отрицательным значением речи, которое необходимо ожидать перед окончанием речевого сегмента
	FRAME_SAMPLES: 1536, // размер кадра в сэмплах
	PRE_SPEECH_PAD_FRAMES: 2, // кол-во звуковых кадров для добавления в сегмент речи (до старта обнаружения)
	MIN_SPEECH_FRAMES: 2, // минимальное кол-во позитивных кадров речи для сегмента речи
	MAX_LENGTH: 7000, // максимальная длина сегмента, мс
};

const Vad = ({ submitHandler, audioPlaybackStatusForVad, webSocketStatus, vadSetting, statusForVadSetting }: IVadProps): JSX.Element => {
	const [frameCounter, setFrameCounter] = useState<number>(0); // счетчик кадров
	const maxNumberOfFramesInSegment = useMemo(() => {
		const frameLength = 1000 / (16000 / (vadSetting.frameSamples || vadDefaultSetting.FRAME_SAMPLES)); // длина кадра, мс (96мс)
		return Math.round((vadSetting.maxLength || vadDefaultSetting.MAX_LENGTH) / frameLength);
	}, [audioPlaybackStatusForVad]); // max кол-во кадров в сегменте

	const debuggerAnswer = useAppSelector(selectDebuggerAnswer); // store - ответ робота

	// следим за текущим статусом воспроизведения
	useEffect(() => {
		// если статус совпадает для текущего экземпляра - стартуем, иначе пауза
		if (audioPlaybackStatusForVad === statusForVadSetting) vad.start();
		else vad.pause();
	}, [audioPlaybackStatusForVad]);

	// следим за счетчиком кадров
	useEffect(() => {
		if (frameCounter === maxNumberOfFramesInSegment) vad.pause(); // при переполнении - останавливаем vad
	}, [frameCounter]);

	const vad = useMicVAD({
		// onSpeechStart: () => { }, // старт обнаружения голоса
		onVADMisfire: () => {
			frameCounter > 0 && setFrameCounter(0); // сброс счетчика кадров
		}, // событие слишком короткого сегмента
		onFrameProcessed({ isSpeech }, _frame) {
			// если есть вероятность обнаружения речи или уже запущен подсчет кадров
			if (isSpeech > (vadSetting.positiveSpeechThreshold || vadDefaultSetting.POSITIVE_SPEECH_THRESHOLD) || frameCounter > 0) {
				setFrameCounter(prev => ++prev); // считаем кадры
			}
		}, // событие каждого кадра
		onSpeechEnd: (audio) => {
			// если есть учтенные кадры
			if (frameCounter > 0) {
				setFrameCounter(0); // сброс счетчика кадров
				vad.start(); // запуск vad
			}
			// отправка, если статус совпадает для текущего экземпляра
			if (audioPlaybackStatusForVad === statusForVadSetting) {
				// samples: Float32Array, format?: number (1 === PCM), sampleRate?: number, numChannels?: number, bitDepth?: number
				const wavBuffer = utils.encodeWAV(audio,/*  1, 8000, 1, 32 */);
				const file = new Blob([wavBuffer]);
				submitHandler({ audio: file });
			}
		}, // конец обнаружения голоса
		positiveSpeechThreshold: vadSetting.positiveSpeechThreshold || vadDefaultSetting.POSITIVE_SPEECH_THRESHOLD,
		negativeSpeechThreshold: vadSetting.negativeSpeechThreshold || vadDefaultSetting.NEGATIVE_SPEECH_THRESHOLD,
		redemptionFrames: vadSetting.redemptionFrames || vadDefaultSetting.REDEMPTION_FRAMES,
		frameSamples: vadSetting.frameSamples || vadDefaultSetting.FRAME_SAMPLES,
		preSpeechPadFrames: vadSetting.preSpeechPadFrames || vadDefaultSetting.PRE_SPEECH_PAD_FRAMES,
		minSpeechFrames: vadSetting.minSpeechFrames || vadDefaultSetting.MIN_SPEECH_FRAMES,
		submitUserSpeechOnPause: true, // срабатывание события onSpeechEnd при остановке vad
	});

	// следим за концом сессии и статусом соединения
	useEffect(() => {
		// если конец сессии или нет соединения
		if (debuggerAnswer.endOfSession || webSocketStatus !== 1) {
			vad.listening && vad.pause(); // выключаем микрофон, если включен
		}
		// если соединение восстановлено
		if (webSocketStatus === 1) {
			!vad.listening && !vad.loading && vad.start();
		}
	}, [debuggerAnswer.endOfSession, webSocketStatus]);

	return (
		<>
		</>
	);
};

export default Vad;
