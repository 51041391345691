import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileZipper, faGaugeHigh, faPenToSquare, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import { IVersionProps } from './Version.props';
import styles from './Version.module.scss';

const versionButtons: Record<string, { icon: IconDefinition, title: string }> = {
	draft: { icon: faPenToSquare, title: 'buttonTitle_futureVersion' },
	prod: { icon: faGaugeHigh, title: 'buttonTitle_currentVersion' },
	backup: { icon: faFileZipper, title: 'buttonTitle_previousVersion' },
};

const Version = ({ isAvailable, isActive, version, onClickFunction }: IVersionProps): JSX.Element => {
	const translate = useTranslate(); // hook для перевода текста

	return (
		<button
			className={cn({
				[styles.active]: isActive,
			})}
			onClick={onClickFunction}
			disabled={!isAvailable}
			title={translate(versionButtons[version].title)}
		>
			<FontAwesomeIcon
				icon={versionButtons[version].icon}
				size="xl"
				color={backgroundColor}
			/>
		</button>
	);
};

export default Version;
