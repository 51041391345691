import { useEffect, useRef, useState } from "react";
import { WaveSurfer, WaveForm } from "wavesurfer-react";
import { WaveSurfer as WaveSurferRef } from "wavesurfer-react/dist/utils/createWavesurfer";
import { FormControl, MenuItem, Select } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '../../store/hooks';
import { changeTimestampAsync, changeTimestampSync } from '../../store/sprSlice';
import { changeTimestamp as changeTimestampTranscript } from '../../store/transcriptionSlice';
import useTranslate from '../../hooks/useTranslate';
import { timeConversion } from '../../helpers/timeConversion';
import { backgroundColor, colorPrimary, colorRed, colorSecondary, colorSecondaryLight2 } from '../../constants/colors';
import { IAudioPlayerProps } from './AudioPlayer.props';
import styles from './AudioPlayer.module.scss';

const AudioPlayer = ({ url, sendTimestamp, timestamp = 0, timeline = false, downloadOption = true, audioFormat = 'wav', playbackSpeed = false, peaks, index = 0, setIndexPlayingAudio, play = false, smallSize = false, squarePlayIcon = false, onPlaybackEnd }: IAudioPlayerProps): JSX.Element => {
	const [isPlay, setIsPlay] = useState<boolean>(false); // флаг проигрывания аудио
	const [percentageLoading, setPercentageLoading] = useState<number>(0); // статус загрузки аудио в процентах
	const [loaded, setLoaded] = useState<boolean>(false); // флаг статуса загрузки аудио
	const wavesurferRef = useRef<WaveSurferRef | null>(null); // ссылка на WaveSurfer
	const [audioRate, setAudioRate] = useState<number>(1); // скорость воспроизведения

	const dispatch = useAppDispatch();
	const translate = useTranslate(); // hook для перевода текста

	// следим за командой воспроизведения снаружи
	useEffect(() => {
		// если разрешена команда или воспроизводится в момент остановки снаружи - переключаем воспроизведение
		(play || isPlay) && playPause();
	}, [play, index]);

	// следим за данными
	useEffect(() => {
		// если данных нет
		if (url === null) {
			isPlay && setIsPlay(false); // останавливаем воспроизведение
			// убираем статусы загрузки
			setPercentageLoading(0);
			setLoaded(false);
		}
	}, [url]);

	// следим за значением скорости и изменяем скорость воспроизведения
	useEffect(() => {
		wavesurferRef.current?.setPlaybackRate(audioRate);
	}, [audioRate]);

	// следим за временной меткой
	useEffect(() => {
		// и если аудио не воспроизводится, есть отправка временной метки и она больше 0мс
		if (!isPlay && sendTimestamp && wavesurferRef.current && timestamp > 0) {
			// продолжительность больше 0мс и есть разница между store и audio state
			if (wavesurferRef.current.getDuration() > 0 && +wavesurferRef.current.getCurrentTime().toFixed(3) * 1000 !== timestamp) {
				const duration = wavesurferRef.current.getDuration() * 1000; // продолжительность аудио в мс
				const positionProcent = timestamp * 100 / duration; // позиция временной метки в процентах
				// защита на входные параметры, т.к. seekTo принимает от 0 до 1 
				positionProcent <= 100 && wavesurferRef.current.seekTo(positionProcent / 100); // ставим курсор на аудиодорожке по временной метке
				// wavesurferRef.current.setTime(recognitionData.timestamp / 1000);
			}
		}
	}, [timestamp]);

	// обработчик монтирования WaveSurfer
	const handleWSMount = (waveSurfer: WaveSurferRef | null): void => {
		wavesurferRef.current = waveSurfer;

		if (wavesurferRef.current) {
			url && wavesurferRef.current.load(url, peaks);

			wavesurferRef.current.on('play', () => {
				setIsPlay(true);
			});

			wavesurferRef.current.on('pause', () => {
				setIsPlay(false);
			});

			wavesurferRef.current.on("loading", (percent: number) => {
				setPercentageLoading(percent);
			});

			wavesurferRef.current.on("ready", () => {
				setLoaded(true);
			});

			wavesurferRef.current.on('finish', () => {
				wavesurferRef.current?.seekTo(0); // курсор на старт
				setIndexPlayingAudio && setIndexPlayingAudio(index + 1); // переставляем воспроизведение на следующее аудио
				onPlaybackEnd && onPlaybackEnd();
			});

			// отслеживание при проигрывании
			sendTimestamp && wavesurferRef.current.on("audioprocess", (currentTime: number) => {
				const timestamp = +currentTime.toFixed(3) * 1000; // в миллисекундах
				sendTimestamp === 'recognitionSync' && dispatch(changeTimestampSync(timestamp));
				sendTimestamp === 'recognitionAsync' && dispatch(changeTimestampAsync(timestamp));
				sendTimestamp === 'transcript' && dispatch(changeTimestampTranscript(timestamp));
			});

			// отслеживание клика на дорожке
			sendTimestamp && wavesurferRef.current.on("seek", (_currentTime: number) => {
				if (wavesurferRef.current) {
					const timestamp = +wavesurferRef.current.getCurrentTime().toFixed(3) * 1000; // в миллисекундах
					sendTimestamp === 'recognitionSync' && dispatch(changeTimestampSync(timestamp));
					sendTimestamp === 'recognitionAsync' && dispatch(changeTimestampAsync(timestamp));
					sendTimestamp === 'transcript' && dispatch(changeTimestampTranscript(timestamp));
					// console.log(wavesurferRef.current.exportPCM()); // экспорт пиков
				}
			});

			// wavesurferRef.current.on("timeupdate", (currentTime: number) => {
			// 	console.log(currentTime, 'timeupdate');
			// });

			// if (window) {
			// 	window.surferidze = wavesurferRef.current;
			// }
		}
	};

	// функция воспроизведения/паузы аудио
	const playPause = (): void => {
		url !== null && wavesurferRef.current && wavesurferRef.current?.playPause();
	};

	// обработчик скачивания аудио
	const downloadHandler = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
		(url === null || !loaded) && e.preventDefault();
	};

	return (
		<div className={cn(styles.container, {
			[styles.containerSmallSize]: smallSize,
		})}>
			<button className={cn(styles.playBtn, {
				[styles.playBtnNotActive]: !loaded,
				[styles.playBtnSmallSize]: smallSize,
				[styles.playBtnSquare]: squarePlayIcon,
			})} onClick={playPause} type='button'>
				<FontAwesomeIcon
					icon={isPlay ? faPause : faPlay}
					size={smallSize ? "sm" : "lg"}
					color={backgroundColor}
					style={isPlay ? undefined : { marginLeft: '1px' }}
				/>
			</button>

			<div className={styles.audioPlayerWrapper}>
				{(url === null) ?
					<div className={styles.audioPlayerWrapperNoData}></div>
					:
					<WaveSurfer onMount={handleWSMount}>
						<WaveForm
							id={"waveform" + index}
							height={smallSize ? 20 : 40}
							barHeight={2} // A vertical scaling factor for the waveform
							cursorColor={colorRed}
							waveColor={colorSecondaryLight2}
							progressColor={colorSecondary}
							responsive // отзывчивость при масштабировании
							hideScrollbar
							backend='MediaElementWebAudio' // отдача команды: пики будут построены на стороне сервера (https://github.com/katspaugh/wavesurfer.js/issues/1001)
							audioRate={audioRate} // скорость воспроизведения
						/>
						{!loaded && <p className={styles.audioPlayerWrapperPercentageLoading}>{percentageLoading}%</p>}
						{timeline &&
							<div className={styles.timeline}>
								{wavesurferRef.current && isFinite(wavesurferRef.current.getDuration()) && wavesurferRef.current.getDuration() > 0 &&
									`${timeConversion({ time: timestamp, from: 'ms', to: 'withoutMs' })} / ${timeConversion({ time: wavesurferRef.current.getDuration(), from: 'sec', to: 'withoutMs' })}`
								}
							</div>
						}
					</WaveSurfer>
				}
			</div>

			{/* скорость воспроизведения */}
			{playbackSpeed &&
				<FormControl variant="standard" sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<Select
						value={audioRate}
						onChange={e => setAudioRate(+e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{[0.75, 1, 1.2, 1.5, 1.7, 2, 3].map((value) =>
							<MenuItem key={value} value={value} sx={{ fontSize: 13, color: colorPrimary }}>{value}x</MenuItem>
						)}
					</Select>
				</FormControl>
			}

			{/* скачивание аудио */}
			{downloadOption &&
				<a
					href={url ? url : ''}
					target='_blank'
					rel="noreferrer"
					onClick={e => downloadHandler(e)}
					download={`${new Date().toISOString()}.${audioFormat}`}
					className={cn({
						[styles.download]: url !== null,
						[styles.downloadNotActive]: url === null || !loaded,
					})}
					title={translate('buttonTitle_download')}
				>
					<FontAwesomeIcon
						icon={faDownload}
						size="2xl"
						color={(url === null || !loaded) ? colorPrimary : colorSecondary}
					/>
				</a>
			}
		</div>
	);
};

export default AudioPlayer;
