import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { timeConversion } from '../helpers/timeConversion';
import { ENDPOINTS_TRANSCRIPTION, URL_TRANSCRIPTION } from '../constants/api_endpoints';
import { ISpeakerModified, ISplittedText } from '../types/sprTypes';
import { ICreateTranscriptionProps, IEditTranscriptionProps, IMatricsResponse, IModificationTranscriptProps, IRecord, IResponse, IResponseAudioPeaks, IResponseCreate, ITranscriptionData, ITranscriptionState } from '../types/transcriptionTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

// поиск самого длинного фрагмента для каждого спикера
const searchLongestFragments = (speakers: ISpeakerModified[], splitted: ISplittedText[]): ISpeakerModified[] => {
  return speakers.map((speaker, idx) => {
    const foundLongestFragment = splitted.filter(fragment => fragment.speaker === idx).reduce((accumulator, currentValue) => accumulator.duration_ms > currentValue.duration_ms ? accumulator : currentValue);
    return {
      ...speaker,
      startLongestFragment: foundLongestFragment && foundLongestFragment.start_ms,
      stopLongestFragment: foundLongestFragment && foundLongestFragment.stop_ms,
    } as ISpeakerModified;
  });
};

const initialState: ITranscriptionState = {
  transcriptionList: {
    data: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  transcription: {
    data: null,
    speakerList: [],
    status: RequestStatus.IDLE,
  },
  audio: {
    url: null,
    audioPeaks: [],
    timestamp: 0,
    audioStatus: RequestStatus.IDLE,
    audioPeakStatus: RequestStatus.IDLE,
  },
  creation: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: '',
  },
  modify: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: '',
  },
  editing: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    autosave: false,
  },
  deletion: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  metrics: {
    data: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// список сохраненных записей распознавания
export const getTranscriptionList = createAsyncThunk(
  'transcription/list',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | IRecord[]> = await axiosApi.get(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// данные распознавания
export const getTranscriptionData = createAsyncThunk(
  'transcription/data',
  async (id: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | ITranscriptionData> = await axiosApi.get(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.GET_DATA}/${id}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// аудио-файл, с которого распознавался текст
export const getTranscriptionAudio = createAsyncThunk(
  'transcription/audio',
  async (id: string, { signal }): Promise<Blob | IResponse> => {
    const response: AxiosResponse<Blob | IResponse> = await axiosApi.get(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.GET_AUDIO}/${id}`, {
      responseType: 'blob',
      signal,
    });
    return response.data;
  }
);

// получение пиков аудио-файла, с которого распознавался текст
export const getAudioPeaks = createAsyncThunk(
  'transcription/audioPeaks',
  async (id: string) => {
    const response: AxiosResponse<IResponseAudioPeaks | IResponse> = await axiosApi.get(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.WAVEFORM}/${id}`);
    return response.data;
  }
);

// создание сохраненной записи
export const createTranscription = createAsyncThunk(
  'transcription/create',
  async ({ transcriptName, taskId }: ICreateTranscriptionProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseCreate> = await axiosApi.post(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.CREATE}`, {
        name: transcriptName,
        taskID: taskId,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// перезапись в сохраненную стенограмму
export const modificationTranscript = createAsyncThunk(
  'transcription/modify',
  async ({ transcriptId, taskId }: IModificationTranscriptProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseCreate> = await axiosApi.post(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.MODIFY}`, {
        transcriptionID: transcriptId,
        taskID: taskId,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение сохраненной записи
export const editTranscription = createAsyncThunk(
  'transcription/edit',
  async ({ id, autosave }: IEditTranscriptionProps, { getState, rejectWithValue, dispatch }) => {
    try {
      autosave && dispatch(addFlagAutosave()); // ставим флаг автосохранения
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.SAVE}/${id}`, {
        data: JSON.stringify((getState() as RootState).transcription.transcription.data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление сохраненной записи
export const deleteTranscription = createAsyncThunk(
  'transcription/delete',
  async (id: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.DELETE}/${id}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// метрики
export const getMetrics = createAsyncThunk(
  'transcription/metrics',
  async (id: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse | IMatricsResponse> = await axiosApi.get(`${URL_TRANSCRIPTION}/${ENDPOINTS_TRANSCRIPTION.METRICS}/${id}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const transcriptionSlice = createSlice({
  name: 'transcription',
  initialState,
  reducers: {
    // изменение временной метки
    changeTimestamp: (state, action: PayloadAction<number>) => {
      state.audio.timestamp = action.payload;
    },
    // добавление имен спикеров
    addSpeakerList: (state, action: PayloadAction<string[]>) => {
      state.transcription.speakerList = action.payload;
    },
    // переименование спикера
    renameSpeaker: (state, action: PayloadAction<{ speakerIdx: number, newName: string }>) => {
      if (state.transcription.data && typeof state.transcription.data === 'object' && 'speakers' in state.transcription.data && Array.isArray(state.transcription.data.speakers)) {
        state.transcription.data.speakers[action.payload.speakerIdx].id = action.payload.newName;
      }
    },
    // изменение спикера
    changeSpeaker: (state, action: PayloadAction<{ newSpeakerName: string, newSpeakerId: number, fragment: ISplittedText, fragmentIdx: number, }>) => {
      if (state.transcription.data && typeof state.transcription.data === 'object' && 'splitted' in state.transcription.data && Array.isArray(state.transcription.data.splitted)) {
        // если имя спикера из существующих
        if (action.payload.newSpeakerId >= 0) {
          // меняем id спикера
          state.transcription.data.splitted[action.payload.fragmentIdx].speaker = action.payload.newSpeakerId;
          // прибавляем фрагмент к спикеру (+1)
          state.transcription.data.speakers[action.payload.newSpeakerId].fragments += 1;
          // прибавляем длительность фрагмента к спикеру
          state.transcription.data.speakers[action.payload.newSpeakerId].duration += action.payload.fragment.duration_ms;
        } else {
          // меняем id спикера на новый id
          state.transcription.data.splitted[action.payload.fragmentIdx].speaker = state.transcription.data.speakers.length;
          // создаем нового спикера
          state.transcription.data.speakers.push({
            id: action.payload.newSpeakerName,
            duration: action.payload.fragment.duration_ms,
            fragments: 1,
            age: '',
            gender: '',
            startLongestFragment: action.payload.fragment.start_ms,
            stopLongestFragment: action.payload.fragment.stop_ms,
          });
        }
        // если спикер с id - значит есть откуда удалять
        if (action.payload.fragment.speaker !== null) {
          // если фрагмент не последний
          if (state.transcription.data.speakers[action.payload.fragment.speaker].fragments > 1) {
            // уменьшаем кол-во фрагментов для прошлого спикера (-1)
            state.transcription.data.speakers[action.payload.fragment.speaker].fragments -= 1;
            // уменьшаем длительность фрагментов для прошлого спикера
            state.transcription.data.speakers[action.payload.fragment.speaker].duration -= action.payload.fragment.duration_ms;
          } else {
            // если последний фрагмент
            state.transcription.data.speakers.splice(action.payload.fragment.speaker, 1); // удаляем строку полностью
            // перебираем реплики 
            state.transcription.data.splitted = state.transcription.data.splitted.map(fragment => {
              // и переопределяем id спикера на позицию меньше у тех, где id было больше удаленной, т.к. строка выше была удалена
              if (fragment.speaker !== null && action.payload.fragment.speaker !== null && fragment.speaker > action.payload.fragment.speaker) {
                return { ...fragment, speaker: fragment.speaker - 1 };
              } else return fragment;
            });
          }
        }
        // обновляем самый длинные фрагменты спикеров
        state.transcription.data.speakers = searchLongestFragments(state.transcription.data.speakers, state.transcription.data.splitted);
      }
    },
    // изменение слова в реплике
    editWord: (state, action: PayloadAction<{ fragmentIdx: number, wordIdx: number, newValue: string }>) => {
      if (state.transcription.data && typeof state.transcription.data === 'object' && 'splitted' in state.transcription.data && Array.isArray(state.transcription.data.splitted)) {
        state.transcription.data.splitted[action.payload.fragmentIdx].words[action.payload.wordIdx].word = action.payload.newValue;
      }
    },
    // добавление нового слова в реплике
    createWord: (state, action: PayloadAction<{ fragment: ISplittedText, fragmentIdx: number, wordIdx: number, word?: string }>) => {
      if (state.transcription.data && typeof state.transcription.data === 'object' && 'splitted' in state.transcription.data && Array.isArray(state.transcription.data.splitted)) {
        state.transcription.data.splitted[action.payload.fragmentIdx].words.splice(action.payload.wordIdx + 1, 0, {
          word: action.payload.word || '',
          start: action.payload.fragment.words[action.payload.wordIdx].start,
          stop: action.payload.fragment.words[action.payload.wordIdx].stop,
          confidence: 100,
        });
      }
    },
    // удаление слова из реплики
    deleteWord: (state, action: PayloadAction<{ fragmentIdx: number, wordIdx: number, fragment: ISplittedText }>) => {
      if (state.transcription.data && typeof state.transcription.data === 'object' && 'splitted' in state.transcription.data && Array.isArray(state.transcription.data.splitted)) {
        const fragmentCopy = structuredClone(action.payload.fragment); // копируем реплику
        // если в реплике одно слово
        if (action.payload.fragment.words.length === 1) {
          state.transcription.data.splitted.splice(action.payload.fragmentIdx, 1); // удаляем реплику
          if (typeof fragmentCopy.speaker === 'number') {
            // если у спикера единственная реплика
            if (state.transcription.data.speakers[fragmentCopy.speaker].fragments === 1) {
              state.transcription.data.speakers.splice(fragmentCopy.speaker, 1); // удаляем спикера
            } else {
              state.transcription.data.speakers[fragmentCopy.speaker].fragments -= 1; // отнимаем 1 фрагмент у спикера
              state.transcription.data.speakers[fragmentCopy.speaker].duration -= fragmentCopy.duration_ms; // отнимаем продолжительность последнего слова в реплике у спикера
            }
          }
        }
        // если это слово первое в реплике
        else if (action.payload.wordIdx === 0) {
          // изменяем данные
          fragmentCopy.text = fragmentCopy.text.split(' ').slice(1).join(' ');
          fragmentCopy.words = fragmentCopy.words.slice(1);
          fragmentCopy.start_ms = fragmentCopy.words[0].start;
          fragmentCopy.start = timeConversion({ time: fragmentCopy.start_ms, from: 'ms', to: 'withMs' });
          fragmentCopy.duration_ms = fragmentCopy.words[fragmentCopy.words.length - 1].stop - fragmentCopy.words[0].start;
          fragmentCopy.duration = timeConversion({ time: fragmentCopy.duration_ms, from: 'ms', to: 'withMs' });
          const deletedDuration = action.payload.fragment.duration_ms - fragmentCopy.duration_ms; // сколько времени удалено у реплики
          (typeof fragmentCopy.speaker === 'number') && (state.transcription.data.speakers[fragmentCopy.speaker].duration -= deletedDuration); // отнимаем у спикера продолжительность удаленного слова
          state.transcription.data.splitted[action.payload.fragmentIdx] = fragmentCopy; // заменяем реплику
        }
        // если это слово последнее в реплике
        else if (action.payload.fragment.words.length - 1 === action.payload.wordIdx) {
          // изменяем данные
          fragmentCopy.text = fragmentCopy.text.split(' ').slice(0, -1).join(' ');
          fragmentCopy.words = fragmentCopy.words.slice(0, -1);
          fragmentCopy.stop_ms = fragmentCopy.words[fragmentCopy.words.length - 1].stop;
          fragmentCopy.stop = timeConversion({ time: fragmentCopy.stop_ms, from: 'ms', to: 'withMs' });
          fragmentCopy.duration_ms = fragmentCopy.words[fragmentCopy.words.length - 1].stop - fragmentCopy.words[0].start;
          fragmentCopy.duration = timeConversion({ time: fragmentCopy.duration_ms, from: 'ms', to: 'withMs' });
          const deletedDuration = action.payload.fragment.duration_ms - fragmentCopy.duration_ms; // сколько времени удалено у реплики
          (typeof fragmentCopy.speaker === 'number') && (state.transcription.data.speakers[fragmentCopy.speaker].duration -= deletedDuration); // отнимаем у спикера продолжительность удаленного слова
          state.transcription.data.splitted[action.payload.fragmentIdx] = fragmentCopy; // заменяем реплику
        }
        // если это слово в середине реплики
        else {
          // изменяем данные
          const text = fragmentCopy.text.split(' ');
          text.splice(action.payload.wordIdx, 1);
          fragmentCopy.text = text.join(' ');
          fragmentCopy.words.splice(action.payload.wordIdx, 1);
          state.transcription.data.splitted[action.payload.fragmentIdx] = fragmentCopy; // заменяем реплику
        }
        // обновляем самый длинные фрагменты спикеров
        state.transcription.data.speakers = searchLongestFragments(state.transcription.data.speakers, state.transcription.data.splitted);
      }
    },
    // разделение реплики
    splitReplica: (state, action: PayloadAction<{ speakerId: number, fragmentIdx: number, wordIdx: number, fragment: ISplittedText }>) => {
      if (state.transcription.data && typeof state.transcription.data === 'object' && 'splitted' in state.transcription.data && Array.isArray(state.transcription.data.splitted)) {
        // копируем делимую реплику
        const fragment1 = structuredClone(action.payload.fragment);
        const fragment2 = structuredClone(action.payload.fragment);
        // распределяем данные по новым репликам
        fragment1.text = fragment1.text.split(' ').slice(0, action.payload.wordIdx).join(' ');
        fragment1.words = fragment1.words.slice(0, action.payload.wordIdx);
        fragment1.stop_ms = fragment1.words[fragment1.words.length - 1].stop;
        fragment1.stop = timeConversion({ time: fragment1.stop_ms, from: 'ms', to: 'withMs' });
        fragment1.duration_ms = fragment1.words[fragment1.words.length - 1].stop - fragment1.words[0].start;
        fragment1.duration = timeConversion({ time: fragment1.duration_ms, from: 'ms', to: 'withMs' });

        fragment2.text = fragment2.text.split(' ').slice(action.payload.wordIdx, fragment2.words.length).join(' ');
        fragment2.words = fragment2.words.slice(action.payload.wordIdx, fragment2.words.length);
        fragment2.start_ms = fragment2.words[0].start;
        fragment2.start = timeConversion({ time: fragment2.start_ms, from: 'ms', to: 'withMs' });
        fragment2.duration_ms = fragment2.words[fragment2.words.length - 1].stop - fragment2.words[0].start;
        fragment2.duration = timeConversion({ time: fragment2.duration_ms, from: 'ms', to: 'withMs' });

        state.transcription.data.speakers[action.payload.speakerId].fragments += 1; // прибавляем кол-во фрагментов к спикеру
        state.transcription.data.splitted.splice(action.payload.fragmentIdx, 1, fragment1, fragment2); // заменяем фрагмент - двумя новыми

        // обновляем самый длинные фрагменты спикеров
        state.transcription.data.speakers = searchLongestFragments(state.transcription.data.speakers, state.transcription.data.splitted);
      }
    },
    // объединение реплик
    joinReplicas: (state, action: PayloadAction<{ fragmentIdx: number, withReplica: 'prev' | 'next' }>) => {
      if (state.transcription.data && typeof state.transcription.data === 'object' && 'splitted' in state.transcription.data && Array.isArray(state.transcription.data.splitted)) {
        if (action.payload.withReplica === 'prev') {
          const fragmentOriginal = state.transcription.data.splitted[action.payload.fragmentIdx - 1]; // реплика к которой клеимся
          const fragmentForJoin = state.transcription.data.splitted[action.payload.fragmentIdx]; // реплика, которую клеим
          // перемещаем данные
          fragmentOriginal.text += ` ${fragmentForJoin.text}`;
          fragmentOriginal.words.push(...fragmentForJoin.words);
          fragmentOriginal.stop_ms = fragmentForJoin.stop_ms;
          fragmentOriginal.stop = fragmentForJoin.stop;
          fragmentOriginal.duration_ms = fragmentForJoin.words[fragmentForJoin.words.length - 1].stop - fragmentOriginal.words[0].start;
          fragmentOriginal.duration = timeConversion({ time: fragmentOriginal.duration_ms, from: 'ms', to: 'withMs' });
          // отнимаем у спикера 1 фрагмент
          (typeof fragmentForJoin.speaker === 'number') && (state.transcription.data.speakers[fragmentForJoin.speaker].fragments -= 1);
          state.transcription.data.splitted.splice(action.payload.fragmentIdx, 1); // удаляем реплику
        } else {
          const fragmentOriginal = state.transcription.data.splitted[action.payload.fragmentIdx]; // реплика к которой клеимся
          const fragmentForJoin = state.transcription.data.splitted[action.payload.fragmentIdx + 1]; // реплика, которую клеим
          // перемещаем данные
          fragmentOriginal.text += ` ${fragmentForJoin.text}`;
          fragmentOriginal.words.push(...fragmentForJoin.words);
          fragmentOriginal.stop_ms = fragmentForJoin.stop_ms;
          fragmentOriginal.stop = fragmentForJoin.stop;
          fragmentOriginal.duration_ms = fragmentForJoin.words[fragmentForJoin.words.length - 1].stop - fragmentOriginal.words[0].start;
          fragmentOriginal.duration = timeConversion({ time: fragmentOriginal.duration_ms, from: 'ms', to: 'withMs' });
          // отнимаем у спикера 1 фрагмент
          (typeof fragmentForJoin.speaker === 'number') && (state.transcription.data.speakers[fragmentForJoin.speaker].fragments -= 1);
          state.transcription.data.splitted.splice(action.payload.fragmentIdx + 1, 1); // удаляем реплику
        }

        // обновляем самый длинные фрагменты спикеров
        state.transcription.data.speakers = searchLongestFragments(state.transcription.data.speakers, state.transcription.data.splitted);
      }
    },
    // добавление флага автосохранения
    addFlagAutosave: (state) => {
      state.editing.autosave = true;
    },
    // очистка state
    clearState: (state) => {
      state.transcriptionList = initialState.transcriptionList;
      state.transcription = initialState.transcription;
      state.audio = initialState.audio;
      state.creation = initialState.creation;
      state.modify = initialState.modify;
      state.editing = initialState.editing;
      state.deletion = initialState.deletion;
      state.metrics = initialState.metrics;
    },
    // очистка списка записей
    clearTranscriptionList: (state) => {
      state.transcriptionList = initialState.transcriptionList;
    },
    // очистка данных распознавания
    clearTranscriptionData: (state) => {
      state.transcription = initialState.transcription;
    },
    // очистка аудио
    clearTranscriptionAudio: (state) => {
      state.audio = initialState.audio;
    },
    // очистка ответа создания записи
    clearCreation: (state) => {
      state.creation = initialState.creation;
    },
    // очистка ответа перезаписи стенограммы
    clearModify: (state) => {
      state.modify = initialState.modify;
    },
    // очистка ответа изменения записи
    clearEditing: (state) => {
      state.editing = initialState.editing;
    },
    // очистка ответа удаления записи
    clearDeletion: (state) => {
      state.deletion = initialState.deletion;
    },
    // очистка данных метрики
    clearMetrics: (state) => {
      state.metrics = initialState.metrics;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTranscriptionList.pending, (state) => {
        state.transcriptionList.status = RequestStatus.LOADING;
      })
      .addCase(getTranscriptionList.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.transcriptionList.status = RequestStatus.IDLE;
          state.transcriptionList.data = action.payload;
        } else state.transcriptionList.status = RequestStatus.FAILED;
      })
      .addCase(getTranscriptionList.rejected, (state, action: PayloadAction<unknown>) => {
        state.transcriptionList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.transcriptionList.error = action.payload.response?.data.error;
          state.transcriptionList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getTranscriptionData.pending, (state) => {
        state.transcription.status = RequestStatus.LOADING;
      })
      .addCase(getTranscriptionData.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload !== 'string') {
          state.transcription.status = RequestStatus.IDLE;
          if ('speakers' in action.payload) {
            // задаем имена спикерам, если нет и отрезок самого длинного фрагмента
            const renamedSpeakers: ISpeakerModified[] = action.payload.speakers.map((speaker, idx) => {
              speaker.id = speaker.id || `unknown_${idx}`;
              const foundLongestFragment = action.payload && 'speakers' in action.payload && action.payload.splitted.filter(fragment => fragment.speaker === idx).reduce((accumulator, currentValue) => accumulator.duration_ms > currentValue.duration_ms ? accumulator : currentValue);
              return {
                ...speaker,
                startLongestFragment: foundLongestFragment && foundLongestFragment.start_ms,
                stopLongestFragment: foundLongestFragment && foundLongestFragment.stop_ms,
              } as ISpeakerModified;
            });
            state.transcription.data = { ...action.payload, speakers: renamedSpeakers };
          } else state.transcription.data = action.payload;
        } else state.transcription.status = RequestStatus.FAILED;
      })
      .addCase(getTranscriptionData.rejected, (state, action: PayloadAction<unknown>) => {
        state.transcription.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.transcription.data = action.payload.response?.data;
        }
      })
      .addCase(getTranscriptionAudio.pending, (state) => {
        state.audio.audioStatus = RequestStatus.LOADING;
      })
      .addCase(getTranscriptionAudio.fulfilled, (state, action) => {
        if (action.payload instanceof Blob && action.payload.type.includes('audio')) {
          state.audio.audioStatus = RequestStatus.IDLE;
          const url = window.URL.createObjectURL(action.payload);
          state.audio.url = url;
        } else {
          state.audio.audioStatus = RequestStatus.FAILED;
        }
      })
      .addCase(getTranscriptionAudio.rejected, (state) => {
        state.audio.audioStatus = RequestStatus.FAILED;
      })
      .addCase(getAudioPeaks.pending, (state) => {
        state.audio.audioPeakStatus = RequestStatus.LOADING;
      })
      .addCase(getAudioPeaks.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'waveform' in action.payload && Array.isArray(action.payload.waveform)) {
          state.audio.audioPeakStatus = RequestStatus.IDLE;
          state.audio.audioPeaks = action.payload.waveform;
        } else state.audio.audioPeakStatus = RequestStatus.FAILED;
      })
      .addCase(getAudioPeaks.rejected, (state) => {
        state.audio.audioPeakStatus = RequestStatus.FAILED;
        console.error('Peak loading error. Peaks will be built on the client side.');
      })
      .addCase(createTranscription.pending, (state) => {
        state.creation.status = RequestStatus.LOADING;
      })
      .addCase(createTranscription.fulfilled, (state, action) => {
        state.creation.status = RequestStatus.IDLE;
        if (action.payload) {
          state.creation.error = action.payload.error;
          state.creation.message = action.payload.message;
          if ('id' in action.payload && action.payload.id) {
            state.creation.id = action.payload.id;
          }
        }
      })
      .addCase(createTranscription.rejected, (state, action: PayloadAction<unknown>) => {
        state.creation.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.creation.error = action.payload.response?.data.error;
          state.creation.message = action.payload.response?.data.message;
        }
      })
      .addCase(modificationTranscript.pending, (state) => {
        state.modify.status = RequestStatus.LOADING;
      })
      .addCase(modificationTranscript.fulfilled, (state, action) => {
        state.modify.status = RequestStatus.IDLE;
        if (action.payload) {
          state.modify.error = action.payload.error;
          state.modify.message = action.payload.message;
          if ('id' in action.payload && action.payload.id) {
            state.modify.id = action.payload.id;
          }
        }
      })
      .addCase(modificationTranscript.rejected, (state, action: PayloadAction<unknown>) => {
        state.modify.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.modify.error = action.payload.response?.data.error;
          state.modify.message = action.payload.response?.data.message;
        }
      })
      .addCase(editTranscription.pending, (state) => {
        state.editing.status = RequestStatus.LOADING;
      })
      .addCase(editTranscription.fulfilled, (state, action) => {
        state.editing.status = RequestStatus.IDLE;
        if (action.payload) {
          state.editing.error = action.payload.error;
          state.editing.message = action.payload.message;
        }
      })
      .addCase(editTranscription.rejected, (state, action: PayloadAction<unknown>) => {
        state.editing.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editing.error = action.payload.response?.data.error;
          state.editing.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteTranscription.pending, (state) => {
        state.deletion.status = RequestStatus.LOADING;
      })
      .addCase(deleteTranscription.fulfilled, (state, action) => {
        state.deletion.status = RequestStatus.IDLE;
        if (action.payload) {
          state.deletion.error = action.payload.error;
          state.deletion.message = action.payload.message;
        }
      })
      .addCase(deleteTranscription.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletion.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletion.error = action.payload.response?.data.error;
          state.deletion.message = action.payload.response?.data.message;
        }
      })
      .addCase(getMetrics.pending, (state) => {
        state.metrics.status = RequestStatus.LOADING;
      })
      .addCase(getMetrics.fulfilled, (state, action) => {
        if (action.payload && typeof action.payload === 'object' && 'metrics' in action.payload) {
          state.metrics.status = RequestStatus.IDLE;
          state.metrics.data = action.payload.metrics;
        } else state.metrics.status = RequestStatus.FAILED;
      })
      .addCase(getMetrics.rejected, (state, action: PayloadAction<unknown>) => {
        state.metrics.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.metrics.error = action.payload.response?.data.error;
          state.metrics.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { changeTimestamp, addSpeakerList, renameSpeaker, changeSpeaker, editWord, createWord, deleteWord, splitReplica, joinReplicas, addFlagAutosave, clearState, clearTranscriptionList, clearTranscriptionData, clearTranscriptionAudio, clearCreation, clearModify, clearEditing, clearDeletion, clearMetrics } = transcriptionSlice.actions;

export const selectTranscriptionList = (state: RootState) => state.transcription.transcriptionList;
export const selectTranscriptionData = (state: RootState) => state.transcription.transcription;
export const selectTranscriptionAudio = (state: RootState) => state.transcription.audio;
export const selectCreation = (state: RootState) => state.transcription.creation;
export const selectModify = (state: RootState) => state.transcription.modify;
export const selectEditing = (state: RootState) => state.transcription.editing;
export const selectDeletion = (state: RootState) => state.transcription.deletion;
export const selectMetrics = (state: RootState) => state.transcription.metrics;

export default transcriptionSlice.reducer;
