import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useCookies } from 'react-cookie';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addingActiveMarksId, addingActiveMarksName, clearAddMarks, clearMarks, getMarks, getMarksList, selectAddMarks, selectMarks, selectMarksList } from '../../../store/marksSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { routeTranslation } from '../../../helpers/routeTranslation';
import { findServiceRightByType } from '../../../helpers/findServiceRightByType';
import { MARKS } from '../../../constants/accessRights';
import { CORPUS_LINK } from '../../../constants/routes';
import { MARK_ID } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import HideSidebar from '../HideSidebar/HideSidebar';
import FormAddingMarks from '../../Forms/FormAddingMarks/FormAddingMarks';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { IMarksNavbarProps } from './MarksNavbar.props';
import styles from './MarksNavbar.module.scss';

const MarksNavbar = ({ setChangeFlg, serviceType, showSidebar, setShowSidebar, setShowPage }: IMarksNavbarProps) => {
	const [activeMarksId, setActiveMarksId] = useState<string>(''); // id активного словаря меток
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления словаря меток
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления добавления словаря меток
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список словарей меток

	const dispatch = useAppDispatch();
	const marksList = useAppSelector(selectMarksList); // store - список словарей меток
	const marks = useAppSelector(selectMarks); // store - словарь меток

	const navigate = useNavigate(); // hook для навигации
	const [cookies, setCookie] = useCookies([MARK_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком словарей меток
	useEffect(() => {
		// только если список не пустой
		if (marksList.data.length > 0) {
			// если есть запись в cookie и список содержит эту запись, то передаем ее в обработчик выбора активного словаря, иначе выбираем первого из списка
			const foundMarks = marksList.data.find(marks => marks.id === cookies.markId);
			foundMarks && cookies.markId ?
				marksHandler(foundMarks.id, foundMarks.name)
				:
				marksHandler(marksList.data[0].id, marksList.data[0].name);
		}
	}, [marksList.data]);

	// следим за активным словарем меток
	useEffect(() => {
		activeMarksId && listRef.current?.children[marksList.data.findIndex(marks => marks.id === activeMarksId)]?.scrollIntoView({ block: "center" }); // показ активного словаря в центре списка с имеющейся полосой прокрутки
	}, [activeMarksId]);

	// обработчик выбора активного словаря меток
	const marksHandler = (marksId: string, marksName: string): void => {
		marks.data.length > 0 && dispatch(clearMarks()); // очищаем данные словаря, если есть
		setActiveMarksId(marksId); // устанавливаем id активного словаря
		dispatch(addingActiveMarksId(marksId)); // добавляем id активного словаря в store
		dispatch(addingActiveMarksName(marksName)); // добавляем имя активного словаря в store
		isAccess(MARKS.GET) && dispatch(getMarks(marksId)); // получаем словарь меток
		setCookie(MARK_ID, marksId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		setChangeFlg(false); // сбрасываем флаг о не сохраненных данных
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (link: string): void => {
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(link);
		}, 500);
	};

	return (
		<Slide direction="right" in={showSidebar} timeout={800} style={{ visibility: 'visible', zIndex: 10 }}>
			<div>
				<div className={styles.sidebar}>
					<HideSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

					<div className={styles.navbar}>
						{/* ошибка загрузки списка словарей меток */}
						{(marksList.status === RequestStatus.FAILED || marksList.error === ResponseStatus.FAILED) &&
							<div className={styles.navbarFailedText}>
								<span>{translate(marksList.message || 'title_loadFailed')}</span>
								{isAccess(MARKS.LIST) &&
									<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getMarksList())}>{translate('link_update')}</span>
								}
							</div>
						}

						{/* пустой список словарей меток */}
						{marksList.status === RequestStatus.IDLE && marksList.data.length === 0 &&
							<div className={styles.navbarNoMarks}>{translate('title_emptyList')}</div>
						}

						{/* загрузка списка словарей меток */}
						{marksList.status === RequestStatus.LOADING &&
							<div className={styles.navbarLoading}>
								<ProgressCircle title={translate('spinnerTitle_loading')} />
							</div>
						}

						{/* список словарей меток */}
						{marksList.status === RequestStatus.IDLE && marksList.data.length > 0 &&
							<ul className={styles.navbarMarksList} ref={listRef}>
								{marksList.data.map(({ id, name }) => (
									<li className={styles.navbarMarks} key={id}>
										<Fade in={true} timeout={500}>
											<div
												className={cn({
													[styles.navbarMarksLink]: activeMarksId !== id,
													[styles.navbarMarksLinkActive]: activeMarksId === id
												})}
												onClick={() => id !== activeMarksId && marksHandler(id, name)}
											>
												<div className={styles.navbarMarksLinkLeftBlock} title={name}>
													{name}
												</div>
												<FontAwesomeIcon icon={faAngleRight} />
											</div>
										</Fade>
									</li>
								))}
							</ul>
						}

						<div className={styles.functionButtons}>
							{/* добавление словаря меток */}
							{isAccess(MARKS.ADD) && marksList.status === RequestStatus.IDLE &&
								<Fade in={true} timeout={500}>
									<div className={styles.functionButtonsAddMarks} onClick={() => setShowModal(true)}>
										{translate('link_addMarksDictionary')}
									</div>
								</Fade>
							}
							{/* табы */}
							{isAccess(findServiceRightByType(serviceType)) &&
								<div className={styles.functionButtonsTabs}>
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => delayToHidePage(routeTranslation(serviceType))}
									>
										{translate('tab_models')}
									</div>
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => delayToHidePage(`${routeTranslation(serviceType)}/${CORPUS_LINK}`)}
									>
										{translate('tab_corpus')}
									</div>
									<div className={styles.functionButtonsTab}>{translate('tab_marks')}</div>
								</div>
							}
						</div>
					</div>
				</div>

				{showModal && <FormAddingMarks showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />}
				{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectAddMarks} clearDataResponse={clearAddMarks} titleFailed='noticeAddition_failed' titleSuccess='noticeAddition_success' />}
			</div>
		</Slide>
	);
};

export default MarksNavbar;
