import { FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { generateAnswers, getFilesList, getStatusGenerateAnswers, selectCategoriesList, selectFilesList, selectGeneratingAnswersStartStatus, selectGptList } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import { GenerateModeType, GenerateOptionType, YesNoType } from '../../../types/qasTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormGeneratingAnswersProps } from './FormGeneratingAnswers.props';

const FormGeneratingAnswers = ({ showModal, setShowModal, setShowNotification }: IFormGeneratingAnswersProps): JSX.Element => {
	const [variants, setVariants] = useState<number>(5); // количество вариантов
	const [categoryId, setCategoryId] = useState<string>(''); // id категории
	const [documentId, setDocumentId] = useState<string>(''); // id документа
	const [mode, setMode] = useState<GenerateModeType>('generate'); // режим генерации
	const [option, setOption] = useState<GenerateOptionType>('all'); // опции генерации
	const [replace, setReplace] = useState<YesNoType>('no'); // замена ответов
	const [selectModelGPT, setSelectModelGPT] = useState<string>('default'); // модель gpt

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const filesList = useAppSelector(selectFilesList); // store - список документов
	const modelGptList = useAppSelector(selectGptList); // store - список моделей gpt
	const startGenerateStatus = useAppSelector(selectGeneratingAnswersStartStatus); // store - статус начала генерации ответов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(QAS.DOC_LIST) && dispatch(getFilesList()); // получаем список документов
	}, []);

	// следим за статусом начала генерации
	useEffect(() => {
		if (startGenerateStatus.error !== ResponseStatus.SUCCESS || startGenerateStatus.status === RequestStatus.FAILED || startGenerateStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
		// если успешно - запрашиваем статус процесса генерации с задержкой, чтобы успел поменяться
		if (startGenerateStatus.error === ResponseStatus.SUCCESS && startGenerateStatus.message !== '') {
			isAccess(QAS.ANSWER_MAKER_STATUS) && setTimeout(() => {
				dispatch(getStatusGenerateAnswers());
			}, 500);
		}
	}, [startGenerateStatus]);

	// обработчик генерации ответов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		dispatch(generateAnswers({
			categoryId: categoryId === '' ? undefined : categoryId,
			documentId: documentId === '' ? undefined : documentId,
			mode,
			option,
			variants,
			replace,
			modelGpt: selectModelGPT,
		})); // генерация ответов
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет отправка - запрещаем покидать форму
		if (startGenerateStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_generatingAnswers' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				{/* кол-во вариантов ответов */}
				<FormControl fullWidth margin='dense'>
					<TextField
						required
						id="variants"
						label={translate('input_quantityAnswerVariants')}
						variant="outlined"
						type='number'
						value={variants}
						onChange={(e) => setVariants(Number(e.target.value))}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary
							},
							inputProps: { step: 1, min: 1, max: 9 }
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
				{/* категория */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
					<Select
						required={categoryId === '' && documentId === ''}
						labelId="category-label"
						id="category"
						label={translate('select_category')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={categoryId}
						onChange={(e) => setCategoryId(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='' sx={{ fontSize: 13 }}>{translate('selectItem_notSelected')}</MenuItem>
						{categoriesList.data.map((category) => (
							<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
						))}
					</Select>
				</FormControl>
				{/* документ */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="document-label" sx={{ fontSize: 13 }}>{translate('select_document')}</InputLabel>
					<Select
						required={categoryId === '' && documentId === ''}
						labelId="document-label"
						id="document"
						label={translate('select_document')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={documentId}
						onChange={(e) => setDocumentId(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{Array.isArray(filesList.data) &&
							filesList.data
								.filter(document => document.category === categoryId || categoryId === '')
								.map(({ id, title }) => (
									<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{title}</MenuItem>
								))}
					</Select>
				</FormControl>
				{/* режим генерации */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="mode-label" sx={{ fontSize: 13 }}>{translate('select_generationMode')}</InputLabel>
					<Select
						required
						labelId="mode-label"
						id="mode"
						label={translate('select_generationMode')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={mode}
						onChange={(e) => setMode(e.target.value as GenerateModeType)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='generate' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_generationByDocuments')}</MenuItem>
						<MenuItem value='paraphrase' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_paraphrasing')}</MenuItem>
					</Select>
				</FormControl>
				{/* генерировать для */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="option-label" sx={{ fontSize: 13 }}>{translate('select_generateFor')}</InputLabel>
					<Select
						required
						labelId="option-label"
						id="option"
						label={translate('select_generateFor')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={option}
						onChange={(e) => setOption(e.target.value as GenerateOptionType)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='all' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_allQuestions')}</MenuItem>
						<MenuItem value='empty' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_emptyQuestions')}</MenuItem>
					</Select>
				</FormControl>
				{/* замена ответов */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
					<InputLabel id="replace-label" sx={{ fontSize: 13 }}>{translate('select_replaceAnswers')}</InputLabel>
					<Select
						required
						labelId="replace-label"
						id="replace"
						label={translate('select_replaceAnswers')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={replace}
						onChange={(e) => setReplace(e.target.value as YesNoType)}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						<MenuItem value='yes' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_yes')}</MenuItem>
						<MenuItem value='no' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_no')}</MenuItem>
					</Select>
				</FormControl>
				{/* модель gpt */}
				<FormControl fullWidth margin='dense' sx={{
					'.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 },
				}}>
					<InputLabel id="modelGpt-label" sx={{ fontSize: 13 }}>{translate('select_generationModel')}</InputLabel>
					<Select
						labelId="modelGpt-label"
						id="modelGpt"
						label={translate('select_generationModel')}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
						value={selectModelGPT}
						onChange={(e) => setSelectModelGPT(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
					>
						<MenuItem value='default' sx={{ fontSize: 13, color: colorPrimary }}>default</MenuItem>
						{modelGptList.data.filter(model => model !== 'default').map((model) => (
							<MenuItem key={model} value={model} sx={{ fontSize: 13, color: colorPrimary }}>{model}</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						sx={{ fontSize: 11 }}
						disabled={startGenerateStatus.status === RequestStatus.LOADING}
					>
						{translate('button_start')}
						{startGenerateStatus.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormGeneratingAnswers;
