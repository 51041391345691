import { useEffect, useRef, useState } from 'react';
import { Fade, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faRotate, faXmark } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addReplicaInDialog, chatWithGpt, clearChat, clearChatAnswer, selectChat } from '../../store/qasSummareSlice';
import useTranslate from '../../hooks/useTranslate';
import { backgroundColor, colorPrimary } from '../../constants/colors';
import { RequestStatus } from '../../types/statusTypes';
import { IChatWithGptProps } from './ChatWithGpt.props';
import styles from './ChatWithGpt.module.scss';

const ChatWithGpt = ({ selectModelGPT, inputHistory }: IChatWithGptProps): JSX.Element => {
	const [inputMessage, setInputMessage] = useState<string>(''); // сообщение

	const messageListRef = useRef<HTMLDivElement>(null); // ссылка на блок сообщений
	const inputMessageRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода сообщения

	const dispatch = useAppDispatch();
	const chat = useAppSelector(selectChat); // store - чат

	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {

		return () => {
			dispatch(clearChatAnswer()); // очистка данных ответа
		};
	}, []);

	// следим за диалогом
	useEffect(() => {
		messageListRef.current?.scrollTo({
			top: messageListRef.current.scrollHeight,
			behavior: "smooth",
		}); // скролл в конец диалога
		inputMessageRef.current?.focus(); // ставим фокус в поле сообщения
	}, [chat.dialog]);

	// следим за статусом ответа
	useEffect(() => {
		// при ошибке - добавляем сообщение об ошибке
		if (chat.status === RequestStatus.FAILED) {
			dispatch(addReplicaInDialog({
				who: 'responseText',
				message: `<${translate('title_error')}: ${translate(chat.message || 'title_errorOccurred')}>`,
			})); // добавление реплики в диалог
		}
	}, [chat.status]);

	// следим за ответом
	useEffect(() => {
		if (chat.data !== null) {
			dispatch(addReplicaInDialog({
				who: 'responseText',
				message: chat.data === '' ? translate('title_noAnswer') : chat.data,
			})); // добавление реплики в диалог
		}
	}, [chat.data]);

	// обработчик нажатой клавиши
	const keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>): void => {
		// если комбинация Ctrl+Enter - отправлем запрос
		if (e.ctrlKey && e.code === 'Enter') submitHandler();
	};

	// получить историю сообщений
	const getHistory = (): string | undefined => {
		// если есть запрос на отправку истории пар и есть хотя бы одна пара сообщений
		if (inputHistory > 0 && chat.dialog.length > 1) {
			let history = '';
			let messageIndex = (chat.dialog.length - inputHistory * 2 >= 0) ? (chat.dialog.length - inputHistory * 2) : 0; // индекс сообщения, с которого начать запись истории
			for (messageIndex; messageIndex < chat.dialog.length; messageIndex++) {
				history += (chat.dialog[messageIndex].message + `\n`);
			}
			return history;
		} else return undefined;
	};

	// отправка запроса
	const submitHandler = (e?: React.FormEvent<HTMLFormElement>): void => {
		e?.preventDefault();
		dispatch(clearChatAnswer()); // очистка данных ответа
		const historyMessage = getHistory(); // история сообщений
		dispatch(addReplicaInDialog({
			who: 'clientText',
			message: inputMessage,
		})); // добавление реплики клиента в диалог
		dispatch(chatWithGpt({ text: inputMessage, modelGpt: selectModelGPT, history: historyMessage })); // отправка сообщения
		setInputMessage(''); // очистка поля сообщения
	};

	// очистка истории чата
	const clearChatHistory = (): void => {
		dispatch(clearChat());
	};

	return (
		<div className={styles.chat}>
			<div className={styles.chatMessageList} ref={messageListRef}>
				{/* приветственное сообщение */}
				{chat.dialog.length === 0 &&
					<div className={styles.chatMessageInit}>
						{translate('title_chatWidgetInitMessage')}
					</div>
				}

				{/* диалог */}
				{chat.dialog.map((replica, idx) => (
					<div
						key={replica.message + replica.who + idx}
						className={styles.chatMessageItem}
					>
						<div
							className={cn(styles.chatMessageText, {
								[styles.chatMessageTextClient]: replica.who === 'clientText',
							})}
						>
							<Fade in={true} timeout={300} mountOnEnter unmountOnExit>
								<span>{replica.message}</span>
							</Fade>
						</div>
					</div>
				))}

				{chat.status === RequestStatus.LOADING &&
					<Fade in={true} timeout={300} mountOnEnter unmountOnExit>
						<div className={styles.chatMessageItem}>
							<div className={styles.chatMessageText}>
								<span className={styles.chatMessageLoaderDots}></span>
								<span className={styles.chatMessageLoaderDots}></span>
								<span className={styles.chatMessageLoaderDots}></span>
							</div>
						</div>
					</Fade>
				}
			</div>

			<form className={styles.chatForm} onSubmit={submitHandler}>
				{/* сообщение */}
				<TextField
					multiline
					maxRows={messageListRef.current ? ((messageListRef.current?.clientHeight / 2 + 33 + 16 /* высота формы + gap */) / 17 /* высота строки */) : undefined}
					inputRef={inputMessageRef}
					variant="outlined"
					placeholder={translate('title_writeMessage') + '...'}
					value={inputMessage}
					onChange={(e) => setInputMessage(e.target.value)}
					onKeyDown={e => keyDownHandler(e)}
					InputProps={{
						style: {
							padding: 8,
							fontSize: 12,
							color: colorPrimary,
						},
						endAdornment: (
							<InputAdornment position="end" >
								{inputMessage !== '' &&
									<FontAwesomeIcon
										icon={faXmark}
										onClick={() => {
											setInputMessage('');
											inputMessageRef.current?.focus();
										}}
										style={{ cursor: 'pointer' }}
									/>
								}
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{
						width: 'calc(100% - (33px + 8px) * 2)',
						position: 'absolute',
						left: 0,
						bottom: 0,
						'::WebkitBackdropFilter': inputMessage.length ? 'blur(8px)' : 'none',
						backdropFilter: inputMessage.length > 0 ? 'blur(8px)' : 'none',
						'.MuiInputLabel-root[data-shrink="true"]': { backgroundColor: backgroundColor, borderRadius: '3px' },
					}}
				/>
				{/* кнопка отправки сообщения */}
				<button
					className={styles.chatButton}
					type='submit'
					title={translate('buttonTitle_send')}
					disabled={inputMessage === '' || chat.status === RequestStatus.LOADING}
				>
					<FontAwesomeIcon icon={faPaperPlane} color={backgroundColor} size="lg" />
				</button>
				{/* кнопка очистки истории чата */}
				<button
					className={styles.chatButton}
					type='button'
					title={translate('buttonTitle_clearChatHistory')}
					disabled={chat.status === RequestStatus.LOADING}
					onClick={clearChatHistory}
				>
					<FontAwesomeIcon icon={faRotate} color={backgroundColor} size="lg" />
				</button>
			</form>
		</div>
	);
};

export default ChatWithGpt;
