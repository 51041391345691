import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearSummare, selectSummare, selectTemplate, summarize } from '../../../store/qasSummareSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { RequestStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormCreatingProtocolProps, OptionType } from './FormCreatingProtocol.props';
import styles from './FormCreatingProtocol.module.scss';
import { selectGptList } from '../../../store/qasSlice';

// список опций использования шаблона
export const OPTION_LIST: { option: OptionType, translation: string }[] = [
	{ option: 'withTemplate', translation: 'selectItem_useTemplate' },
	{ option: 'withoutTemplate', translation: 'selectItem_withoutTemplate' },
];

const FormCreatingProtocol = ({ fullHeightFormFlg }: IFormCreatingProtocolProps): JSX.Element => {
	const [inputText, setInputText] = useState<string>(''); // текст
	const [selectTemplateOption, setSelectTemplateOption] = useState<OptionType>('withoutTemplate'); // с шаблоном/без
	const [file, setFile] = useState<File>(); // файл
	const [selectModelGPT, setSelectModelGPT] = useState<string>('default'); // модель gpt

	const dispatch = useAppDispatch();
	const template = useAppSelector(selectTemplate); // store - шаблон
	const summare = useAppSelector(selectSummare); // store - протокол
	const modelGptList = useAppSelector(selectGptList); // store - список моделей gpt

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следм за выбранным файлом
	useEffect(() => {
		if (file) {
			const reader = new FileReader();
			file && reader.readAsText(file); // читаем файл 
			reader.onload = () => {
				if (typeof reader.result === 'string') setInputText(reader.result); // записываем в текстовое поле
			};
			reader.onerror = () => console.error(reader.error); // ошибки по чтению из файла выводим в консоль
		}
	}, [file]);

	// следим за данными шаблона
	useEffect(() => {
		// если нет данных шаблона, но стоят выбор с ним - сбрасываем на "без шаблона"
		if (template.status === RequestStatus.IDLE && template.data === null && selectTemplateOption === 'withTemplate') setSelectTemplateOption('withoutTemplate');
	}, [template.data]);

	// обработчик создания протокола
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const oldOptions: Record<string, string> = {};
		// возврат к первоначальному формату правил
		for (const key in template.data?.options) {
			oldOptions[key] = template.data?.options[key][0] || '';
		}
		dispatch(clearSummare()); // очистка протокола
		isAccess(QAS.SUMMARIZE) && dispatch(summarize({
			options: selectTemplateOption === 'withTemplate' ? oldOptions : {},
			text: inputText,
			modelGpt: selectModelGPT,
		}));
	};

	return (
		<form className={cn(styles.form, {
			[styles.formFullHeight]: fullHeightFormFlg,
		})} onSubmit={submitHandler}>
			<div className={styles.topBlock}>
				{/* текст для создания протокола */}
				<textarea
					className={styles.topBlockTextarea}
					id="text"
					value={inputText}
					onChange={(e) => setInputText(e.target.value)}
					disabled={summare.status === RequestStatus.LOADING}
					placeholder={translate('title_textForCreatingProtocol')}
				/>
				{/* файл */}
				<div className={styles.inputFileBlock}>
					<input
						id='textFile'
						type="file"
						accept="text/plain"
						onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
						disabled={summare.status === RequestStatus.LOADING}
					/>
					<label htmlFor='textFile'>
						{translate('input_chooseFile')}
					</label>
				</div>
			</div>

			<div className={styles.bottomBlock}>
				{/* модель gpt */}
				<FormControl fullWidth sx={{
					'.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 },
				}}>
					<InputLabel id="modelGpt-label" sx={{ fontSize: 13 }}>{translate('select_generationModel')}</InputLabel>
					<Select
						labelId="modelGpt-label"
						id="modelGpt"
						label={translate('select_generationModel')}
						disabled={summare.status === RequestStatus.LOADING}
						value={selectModelGPT}
						onChange={(e) => setSelectModelGPT(e.target.value)}
						style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
					>
						<MenuItem value='default' sx={{ fontSize: 13, color: colorPrimary }}>default</MenuItem>
						{modelGptList.data.filter(model => model !== 'default').map((model) => (
							<MenuItem key={model} value={model} sx={{ fontSize: 13, color: colorPrimary }}>{model}</MenuItem>
						))}
					</Select>
				</FormControl>
				{/* выбор шаблона */}
				<FormControl fullWidth>
					<Select
						disabled={summare.status === RequestStatus.LOADING}
						value={selectTemplateOption}
						onChange={(e) => setSelectTemplateOption(e.target.value as OptionType)}
						style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
					>
						{OPTION_LIST.map(({ option, translation }) => (
							<MenuItem
								key={option}
								value={option}
								sx={{ fontSize: 13, color: colorPrimary }}
								disabled={template.status === RequestStatus.IDLE && template.data === null && option === 'withTemplate'}
							>
								{translate(translation)}
							</MenuItem>
						))}
					</Select>
				</FormControl>
				{/* отправка */}
				<FormControl fullWidth>
					<Button
						variant="outlined"
						type="submit"
						disabled={summare.status === RequestStatus.LOADING || inputText === ''}
						sx={{ fontSize: 11, height: 33 }}
					>
						{summare.status === RequestStatus.LOADING ?
							<>
								{translate('button_createProtocol')}
								<ProgressCircle isBtnDisabled />
							</>
							:
							translate('button_createProtocol')
						}
					</Button>
				</FormControl>
			</div>
		</form>
	);
};

export default FormCreatingProtocol;
