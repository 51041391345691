import { useEffect, useState } from 'react';
import { Document, Packer, Paragraph } from "docx";
import { faComments, faFileContract } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changeNameInTemplatesList, clearDeletingTemplate, clearEditingTemplate, clearTemplatesList, deleteTemplate, editTemplate, getTemplatesList, selectDeletingTemplate, selectEditingTemplate, selectSummare, selectTemplate } from '../../../store/qasSummareSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { downloadFile } from '../../../helpers/downloadFile';
import { QAS } from '../../../constants/accessRights';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import Controls from '../../../HOC/Controls/Controls';
import TabSwitch from '../Buttons/TabSwitch/TabSwitch';
import ExportToDoc from '../Buttons/ExportToDoc/ExportToDoc';
import AlertDialog from '../../AlertDialog/AlertDialog';
import Notification from '../../Notification/Notification';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { IInteractiveControlsProps } from './InteractiveControls.props';

const InteractiveControls = ({ changeFlg, setChangeFlg, setShowPage, showAlertDialogSave, setShowAlertDialogSave, showAlertDialogDelete, setShowAlertDialogDelete, tabSwitch, setTabSwitch }: IInteractiveControlsProps): JSX.Element => {
	const [showNotificationSave, setShowNotificationSave] = useState<boolean>(false); // показ уведомления сохранения
	const [showNotificationDelete, setShowNotificationDelete] = useState<boolean>(false); // показ уведомления удаления

	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const template = useAppSelector(selectTemplate); // store - шаблон
	const editStatus = useAppSelector(selectEditingTemplate); // store - статус сохранения шаблона
	const deleteStatus = useAppSelector(selectDeletingTemplate); // store - статус удаления шаблона
	const summare = useAppSelector(selectSummare); // store - протокол

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом сохранения/удаления шаблона
	useEffect(() => {
		if (editStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' }); // если идет сохранение шаблона
		else if (deleteStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' }); // если идет удаление шаблона
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если сохранение прошло успешно
		if (editStatus.status === RequestStatus.IDLE && editStatus.error === ResponseStatus.SUCCESS && editStatus.message !== '') {
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг возможности сохранения
			template.nameChanged && dispatch(changeNameInTemplatesList()); // меняем название в списке шаблонов, если были изменено
		}
		// если удаление прошло успешно
		if (deleteStatus.status === RequestStatus.IDLE && deleteStatus.error === ResponseStatus.SUCCESS && deleteStatus.message !== '') {
			dispatch(clearTemplatesList()); // очищаем список шаблонов
			dispatch(getTemplatesList()); // получаем заново список шаблонов
		}
	}, [editStatus, deleteStatus]);

	// обработчик сохранения шаблона
	const saveHandler = (): void => {
		setShowAlertDialogSave(false); // закрываем диалоговое окно
		setShowNotificationSave(true); // включаем уведомление
		const oldOptions: Record<string, string> = {};
		// возврат к первоначальному формату правил
		for (const key in template.data?.options) {
			oldOptions[key] = template.data?.options[key][0] || '';
		}
		template.data && dispatch(editTemplate({
			templateId: template.data.id,
			name: changeFlg.listOfChanges.includes('name') ? template.data.name : undefined,
			options: changeFlg.listOfChanges.includes('options') ? oldOptions : undefined,
		})); // сохранение
	};

	// обработчик удаления шаблона
	const deleteHandler = (): void => {
		setShowAlertDialogDelete(false); // закрываем диалоговое окно
		setShowNotificationDelete(true); // включаем уведомление
		template.data && dispatch(deleteTemplate(template.data.id)); // удаление
	};

	// обработчик экспорта в .docx
	const exportDocxFileHandler = (): void => {
		if (summare.data) {
			const arrRows = summare.data.split('\n'); // разбиваем на строки по переносу
			// создаем документ
			const doc = new Document({
				sections: [{
					children: arrRows.map(row => new Paragraph(row)),
				}],
			});

			// экспорт .docx
			Packer.toBlob(doc).then((blob) => {
				downloadFile(blob, 'protocol.docx');
			}).catch(e => console.error(e));
		}
	};

	// обработчик экспорта в .txt
	const exportTxtFileHandler = (): void => {
		summare.data && downloadFile(summare.data, 'protocol.txt');
	};

	return (
		<>
			<Controls
				header='qas'
				wideHeader
				setShowPage={setShowPage}
				leftSection={
					<div>
						<TabSwitch
							isAvailable={true}
							isActive={tabSwitch === 'summary'}
							icon={faFileContract}
							title='buttonTitle_summarization'
							onClickFunction={() => setTabSwitch('summary')}
						/>
						{isAccess(QAS.CHAT) &&
							<TabSwitch
								isAvailable={true}
								isActive={tabSwitch === 'chat'}
								icon={faComments}
								title='buttonTitle_chat'
								onClickFunction={() => setTabSwitch('chat')}
							/>
						}
					</div>
				}
				rightSection={
					<div>
						{tabSwitch === 'summary' &&
							<ExportToDoc
								isAvailable={summare.data !== null}
								exportDocxFileHandler={exportDocxFileHandler}
								exportTxtFileHandler={exportTxtFileHandler}
							/>
						}
					</div>
				}
			/>

			{showAlertDialogSave &&
				<AlertDialog
					showAlertDialog={showAlertDialogSave}
					setShowAlertDialog={setShowAlertDialogSave}
					submitHandler={saveHandler}
					title='dialog_saveTemplate'
					description='dialog_saveTemplateConfirm'
					name={template.data?.name}
				/>
			}
			{showAlertDialogDelete &&
				<AlertDialog
					showAlertDialog={showAlertDialogDelete}
					setShowAlertDialog={setShowAlertDialogDelete}
					submitHandler={deleteHandler}
					title='dialog_deleteTemplate'
					description='dialog_deleteTemplateConfirm'
					name={template.data?.name}
				/>
			}

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}

			{/* уведомление для сохранения шаблона */}
			{showNotificationSave &&
				<Notification
					showNotification={showNotificationSave}
					setShowNotification={setShowNotificationSave}
					selectDataResponse={selectEditingTemplate}
					clearDataResponse={clearEditingTemplate}
					titleFailed='noticeSaving_failed'
					titleSuccess='noticeSaving_success'
				/>
			}
			{/* уведомление для удаления шаблона */}
			{showNotificationDelete &&
				<Notification
					showNotification={showNotificationDelete}
					setShowNotification={setShowNotificationDelete}
					selectDataResponse={selectDeletingTemplate}
					clearDataResponse={clearDeletingTemplate}
					titleFailed='noticeDeletion_failed'
					titleSuccess='noticeDeletion_success'
				/>
			}
		</>
	);
};

export default InteractiveControls;
