import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector, } from '../../../store/hooks';
import { changeCorrectionInModelConfig, changeModelnameInModelConfig, changeSettingVoiceInModelConfig, changeThresholdInModelConfig, deleteModelConfig, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { ROBOT_MODEL_SERVICE_TYPE_LIST } from '../../../constants/robotConfigLists';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { ServiceTypeModelRobot } from '../../../types/cloudTypes';
import { IModelsProps } from './Models.props';
import styles from './Models.module.scss';

const Models = ({ serviceType, modelName, configData, modelsList, changeFlg, setChangeFlg }: IModelsProps): JSX.Element => {
	const [selectType, setSelectType] = useState<ServiceTypeModelRobot>(serviceType); // тип
	const [inputModelName, setInputModelName] = useState<string>(modelName); // название модели
	const [threshold, setThreshold] = useState<number>(0); // порог доверия (для smc/see)
	const [checkCorrection, setCheckCorrection] = useState<boolean>(false); // коррекция (для smc)

	const [rate, setRate] = useState<number>(0); // скорость (для tts)
	const [pitch, setPitch] = useState<number>(0); // тональность (для tts)
	const [volume, setVolume] = useState<number>(0); // громкость (для tts)
	const [frequency, setFrequency] = useState<number>(22050); // частота (для tts)

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	const changeFlgHandler = useCallback(() => {
		if (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('models')) {
			setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'models'] }));  // ставим флаг о несохраненных данных
		}
	}, []);

	// настройками модели данными и вписываем значения в поля
	useEffect(() => {
		if ('threshold' in configData) setThreshold(configData.threshold);
		else if ('confidenceThreshold' in configData) {
			setThreshold(configData.confidenceThreshold);
			setCheckCorrection((configData.correction === undefined || configData.correction === 0) ? false : true);
		}
		else if ('rate' in configData) {
			setRate(configData.rate);
			setPitch(configData.pitch);
			setVolume(configData.volume);
			setFrequency(configData.frequency);
		}
	}, [configData]);

	// следим за полем порога доверия
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода
		const handler = setTimeout(() => {
			if ('threshold' in configData && configData.threshold !== threshold) {
				dispatch(changeThresholdInModelConfig({ serviceType, modelName, threshold })); // изменяем порог доверия
				changeFlgHandler();
			} else if ('confidenceThreshold' in configData && configData.confidenceThreshold !== threshold) {
				dispatch(changeThresholdInModelConfig({ serviceType, modelName, threshold })); // изменяем порог доверия
				changeFlgHandler();
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод
		};
	}, [threshold]);

	// следим за полями скорости, тональности, громкости
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода
		const handler = setTimeout(() => {
			if ('rate' in configData && (configData.rate !== rate || configData.pitch !== pitch || configData.volume !== volume)) {
				dispatch(changeSettingVoiceInModelConfig({ serviceType, modelName, setting: { rate, pitch, volume, frequency } })); // изменяем настройки голоса
				changeFlgHandler();
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод
		};
	}, [rate, pitch, volume]);

	// обработчик изменения модели 
	const changeModelHandler = (): void => {
		if (modelName !== inputModelName) {
			dispatch(changeModelnameInModelConfig({ serviceType, oldModelName: modelName, newModelName: inputModelName }));
			changeFlgHandler();
		}
	};

	// обработчик изменения флага коррекции (для smc)
	const changeCorrectionHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckCorrection(e.target.checked);
		dispatch(changeCorrectionInModelConfig({
			modelName,
			correction: e.target.checked ? 1 : 0,
		}));
		changeFlgHandler();
	};

	// обработчик изменения частоты
	const changeFrequencyHandler = (e: SelectChangeEvent<number>): void => {
		setFrequency(+e.target.value);
		dispatch(changeSettingVoiceInModelConfig({ serviceType, modelName, setting: { rate, pitch, volume, frequency: +e.target.value } })); // изменяем настройки голоса
		changeFlgHandler();
	};

	// обработчик удаления настроек модели
	const deleteModelConfigHandler = (): void => {
		dispatch(deleteModelConfig({ serviceType, modelName }));
		changeFlgHandler();
	};

	return (
		<div className={styles.container}>
			<div className={styles.block}>
				{/* тип */}
				<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
					<Select
						required
						label={translate('select_type')}
						value={selectType}
						onChange={e => setSelectType(e.target.value as ServiceTypeModelRobot)}
						disabled
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{ROBOT_MODEL_SERVICE_TYPE_LIST.map(({ type, translation }) =>
							<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
						)}
					</Select>
				</FormControl>

				{/* модель */}
				<FormControl fullWidth>
					<Autocomplete
						freeSolo
						options={modelsList}
						value={inputModelName}
						onChange={(_, value) => {
							setInputModelName(value ? value : '');
							(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('models')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'models'] }));  // ставим флаг о несохраненных данных
						}}
						onBlur={changeModelHandler}
						disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
						renderInput={(params) =>
							<TextField
								{...params}
								label={translate(serviceType === 'tts' ? 'input_voice' : 'input_model')}
								onChange={(e) => {
									setInputModelName(e.target.value);
									(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('models')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'models'] }));  // ставим флаг о несохраненных данных
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								// InputProps={{
								// 	...params.InputProps, // важно прокинуть параметры
								// 	endAdornment: (
								// 		<div style={{ marginTop: '-7px' }}>
								// 			{clusterServer.status === RequestStatus.LOADING &&
								// 				<ProgressCircle isBtnDisabled />
								// 			}
								// 			{params.InputProps.endAdornment} {/* важно дописать параметры */}
								// 		</div>
								// 	),
								// }}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						}
						sx={{
							".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
							".MuiInputBase-input": { marginTop: -1 },
						}}
						getOptionLabel={option => option}
						renderOption={(props, option) => {
							return (
								<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
									{option}
								</span>
							);
						}}
					/>
				</FormControl>

				{/* порог доверия */}
				{(serviceType === 'smc' || serviceType === 'see') &&
					<FormControl fullWidth>
						<TextField
							label={translate('input_confidenceThreshold')}
							variant="outlined"
							type='number'
							value={threshold}
							onChange={(e) => setThreshold(Number(e.target.value))}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
								inputProps: { step: 1, min: 0, max: 100 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
				}

				{/* коррекция */}
				{serviceType === 'smc' &&
					<FormControlLabel sx={{ marginTop: '-5px', marginBottom: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
						<Checkbox
							checked={checkCorrection}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							onChange={changeCorrectionHandler}
							size='small'
						/>
					} label={translate('checkbox_correction')} />
				}

				{/* удаление настройки модели */}
				{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' &&
					<FontAwesomeIcon
						icon={faTrashCan}
						color={colorRed}
						size='lg'
						onClick={deleteModelConfigHandler}
						title={translate('buttonTitle_deleteData')}
						style={{ cursor: 'pointer' }}
					/>
				}
			</div>

			{serviceType === 'tts' &&
				<div className={styles.block}>
					{/* скорость */}
					<FormControl fullWidth>
						<TextField
							label={translate('input_rate')}
							variant="outlined"
							type='number'
							value={rate}
							onChange={(e) => setRate(Number(e.target.value))}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
								inputProps: { step: 1, min: 10, max: 200 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					{/* тональность */}
					<FormControl fullWidth>
						<TextField
							label={translate('input_pitch')}
							variant="outlined"
							type='number'
							value={pitch}
							onChange={(e) => setPitch(Number(e.target.value))}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
								inputProps: { step: 1, min: 10, max: 200 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					{/* громкость */}
					<FormControl fullWidth>
						<TextField
							label={translate('input_volume')}
							variant="outlined"
							type='number'
							value={volume}
							onChange={(e) => setVolume(Number(e.target.value))}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
								inputProps: { step: 1, min: 10, max: 200 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					{/* частота */}
					<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('select_frequency')}</InputLabel>
						<Select
							required
							label={translate('select_frequency')}
							disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
							value={frequency}
							onChange={changeFrequencyHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
						>
							{[8000, 16000, 22050].map((frequency) =>
								<MenuItem key={frequency} value={frequency} sx={{ fontSize: 13, color: colorPrimary }}>
									{Math.round(frequency / 1000)} {translate('title_kHz')}
								</MenuItem>
							)}
						</Select>
					</FormControl>
				</div>
			}
		</div>
	);
};

export default Models;
