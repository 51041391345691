import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateLeft } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import Notification from '../../../Notification/Notification';
import { IRestoreProps } from './Restore.props';

const Restore = ({ isAvailable, dataResponse, clearDataResponse, submitHandler, name, dialogTitle, dialogConfirm }: IRestoreProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	// обработчик восстановления из бэкапа
	const restoreHandler = (): void => {
		setShowNotification(true); // включаем уведомление
		setShowAlertDialog(false); // выключаем диалоговое окно
		submitHandler(); // восстановление
	};

	return (
		<>
			<button
				onClick={() => setShowAlertDialog(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_restore')}>
				<FontAwesomeIcon icon={faRotateLeft} size="xl" color={backgroundColor} />
			</button>

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={dataResponse}
					clearDataResponse={clearDataResponse}
					titleFailed='noticeRestoration_failed'
					titleSuccess='noticeRestoration_success'
				/>
			}

			<AlertDialog
				showAlertDialog={showAlertDialog}
				setShowAlertDialog={setShowAlertDialog}
				submitHandler={restoreHandler}
				title={dialogTitle}
				description={dialogConfirm}
				name={name}
			/>
		</>
	);
};

export default Restore;
