import { faFileArrowUp, faWrench } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { applyModel, changeActiveType, clearApplyResponse, clearExportResponse, clearHandlerUpload, clearImportResponse, clearInstallResponse, clearRestoreResponse, exportModel, installModel, restoreModel, selectApplyStatus, selectErrorsModel, selectExportStatus, selectFullModel, selectHandler, selectImportStatus, selectInstallStatus, selectModelName, selectRestoreStatus, selectUploadHandlerStatus, uploadHandlerPy } from '../../../store/modelSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import { CORPUS, HANDLER, MARKS, MODEL, SEE, SMC } from '../../../constants/accessRights';

import Controls from '../../../HOC/Controls/Controls';
import Version from '../Buttons/Version/Version';
import Export from '../Buttons/Export/Export';
import Import from '../Buttons/Import/Import';
import Train from '../Buttons/Train/Train';
import StopTrain from '../Buttons/StopTrain/StopTrain';
import Test from '../Buttons/Test/Test';
import UpdateGroups from '../Buttons/UpdateGroups/UpdateGroups';
import Install from '../Buttons/Install/Install';
import Apply from '../Buttons/Apply/Apply';
import Restore from '../Buttons/Restore/Restore';
import ManualCheck from '../Buttons/ManualCheck/ManualCheck';

import ManualCheckModal from '../../ManualCheck/ManualCheckModal/ManualCheckModal';

import { ModelType } from '../../../types/modelTypes';
import { ModelStatus, RequestStatus } from '../../../types/statusTypes';
import { IModelControlProps } from './ModelControl.props';

const ModelControl = ({ serviceType, setShowPage, showManualCheck, setShowManualCheck, showErrors, setShowErrors, showErrorsMark, setShowErrorsMark }: IModelControlProps): JSX.Element => {
	const dispatch = useAppDispatch();
	const modelName = useAppSelector(selectModelName); // store - имя активной модели
	const fullModel = useAppSelector(selectFullModel); // store - информация о модели
	const handler = useAppSelector(selectHandler); // store - handler.py
	const errors = useAppSelector(selectErrorsModel); // store - ошибки модели
	const exportModelStatus = useAppSelector(selectExportStatus); // store - статус экспортирования модели

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	// обработчик смены активного типа модели
	const activeTypeHandler = (type: ModelType): void => {
		dispatch(changeActiveType(type));
	};

	// обработчик экспортирования модели
	const exportHandler = (): void => {
		fullModel.modelName && dispatch(exportModel({ modelName: fullModel.modelName, modelType: fullModel.activeType, serviceType }));
	};

	// установка обработчика в рабочую версию модели
	const installHandlerToCurrentModel = (): void => {
		if (typeof handler.data === 'string') {
			const filePy = new File([handler.data], 'handler.py', { type: "text/plain" }); // создаем экземпляр файла
			const formData = new FormData();
			if (filePy) {
				formData.append('filePy', filePy);
				fullModel.modelName && dispatch(uploadHandlerPy({ modelName: fullModel.modelName, serviceType, modelType: 'current', formData })); // отправка handler.py
			}
		}
	};

	// обработчик установки модели
	const installHandler = (): void => {
		fullModel.modelName && dispatch(installModel({ modelName: fullModel.modelName, serviceType }));
	};

	// обработчик применения модели
	const applyHandler = (): void => {
		fullModel.modelName && dispatch(applyModel({ modelName: fullModel.modelName, serviceType }));
	};

	// обработчик восстановления модели
	const restoreHandler = (): void => {
		fullModel.modelName && dispatch(restoreModel({ modelName: fullModel.modelName, serviceType }));
	};

	// обработчик ручной проверки модели
	const manualCheckHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		e.stopPropagation();
		setShowManualCheck(prev => !prev); // открываем вкладку ручной проверки модели
	};

	return (
		<>
			<Controls
				header={serviceType}
				clickHandler={() => { setShowErrors(false); setShowErrorsMark(false); setShowManualCheck(false); }}
				setShowPage={setShowPage}
				leftSection={
					<div>
						<Version
							isAvailable={fullModel.modelName !== null && fullModel.activeType !== 'future' && fullModel.status !== RequestStatus.LOADING}
							isActive={fullModel.activeType === 'future'}
							version='draft'
							onClickFunction={() => activeTypeHandler('future')}
						/>
						{fullModel.fullModel?.current !== null &&
							<Version
								isAvailable={fullModel.modelName !== null && fullModel.activeType !== 'current' && fullModel.status !== RequestStatus.LOADING}
								isActive={fullModel.activeType === 'current'}
								version='prod'
								onClickFunction={() => activeTypeHandler('current')}
							/>
						}
						{fullModel.fullModel?.previous !== null &&
							<Version
								isAvailable={fullModel.modelName !== null && fullModel.activeType !== 'previous' && fullModel.status !== RequestStatus.LOADING}
								isActive={fullModel.activeType === 'previous'}
								version='backup'
								onClickFunction={() => activeTypeHandler('previous')}
							/>
						}
					</div>
				}
				rightSection={
					<>
						<div>
							{isAccess(MODEL.EXPORT) &&
								<Export
									isAvailable={fullModel.status === RequestStatus.IDLE && !fullModel.training && !fullModel.testing && fullModel.fullModel?.[fullModel.activeType] !== null && fullModel.fullModel?.[fullModel.activeType]?.status !== ModelStatus.EMPTY && fullModel.fullModel?.[fullModel.activeType]?.status !== ModelStatus.FAILED && exportModelStatus.status !== RequestStatus.LOADING && fullModel.modelName !== null}
									selectDataResponse={selectExportStatus}
									clearDataResponse={clearExportResponse}
									submitHandler={exportHandler}
								/>
							}
							{isAccess(MODEL.IMPORT) &&
								<Import
									isAvailable={fullModel.status !== RequestStatus.LOADING && !fullModel.training && !fullModel.testing && fullModel.activeType === 'future' && fullModel.modelName !== null}
									selectDataResponse={selectImportStatus}
									clearDataResponse={clearImportResponse}
									placeOfImport='model'
									serviceType={serviceType}
									modelName={fullModel.modelName}
								/>
							}
						</div>
						<div>
							{fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINING ?
								(((serviceType === 'smc' && isAccess(SMC.STOP_TRAIN)) || (serviceType === 'see' && isAccess(SEE.STOP_TRAIN))) &&
									<StopTrain
										isAvailable={fullModel.activeType === 'future' && fullModel.status === RequestStatus.IDLE && fullModel.modelName !== null}
										serviceType={serviceType}
									/>
								)
								:
								(((serviceType === 'smc' && (isAccess([SMC.TRAIN, CORPUS.LIST]) || isAccess([SMC.TRAIN, MARKS.LIST]))) || (serviceType === 'see' && isAccess([SEE.TRAIN, CORPUS.LIST]))) &&
									<Train
										isAvailable={fullModel.activeType === 'future' && !fullModel.testing && fullModel.status === RequestStatus.IDLE && fullModel.modelName !== null}
										serviceType={serviceType}
									/>
								)
							}
							{serviceType === 'smc' &&
								<>
									{isAccess([SMC.UPDATE_GROUPS, CORPUS.LIST]) &&
										<UpdateGroups
											isAvailable={fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED) && fullModel.status === RequestStatus.IDLE && fullModel.modelName !== null}
										/>
									}
									{isAccess([SMC.TEST, CORPUS.LIST]) &&
										<Test
											isAvailable={fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.INSTALLED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.APPLIED) && fullModel.status === RequestStatus.IDLE && fullModel.modelName !== null}
										/>
									}
								</>
							}
							{isAccess(HANDLER.SAVE) &&
								<Install
									isAvailable={fullModel.activeType === 'future' && fullModel.fullModel.future !== null && fullModel.fullModel.current !== null && !fullModel.training && !fullModel.testing && fullModel.status === RequestStatus.IDLE && typeof handler.data === 'string' && fullModel.modelName !== null}
									dataResponse={selectUploadHandlerStatus}
									clearDataResponse={clearHandlerUpload}
									submitHandler={installHandlerToCurrentModel}
									buttonTitle='buttonTitle_installHandler'
									buttonIcon={faFileArrowUp}
									dialogTitle='dialog_installHandler'
									dialogConfirm='dialog_installHandlerConfirm'
								/>
							}
							{fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.INSTALLED ?
								(isAccess(MODEL.APPLY) &&
									<Apply
										isAvailable={fullModel.activeType === 'future' && fullModel.status === RequestStatus.IDLE && fullModel.modelName !== null}
										dataResponse={selectApplyStatus}
										clearDataResponse={clearApplyResponse}
										submitHandler={applyHandler}
										name={fullModel.modelName || ''}
										dialogTitle='dialog_applyRobot'
										dialogConfirm='dialog_applyRobotConfirm'
									/>
								)
								:
								(isAccess(MODEL.INSTALL) &&
									<Install
										isAvailable={fullModel.activeType === 'future' && (fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TRAINED || fullModel.fullModel[fullModel.activeType]?.status === ModelStatus.TESTED) && fullModel.status === RequestStatus.IDLE && fullModel.modelName !== null}
										dataResponse={selectInstallStatus}
										clearDataResponse={clearInstallResponse}
										submitHandler={installHandler}
										buttonTitle='buttonTitle_installModel'
										buttonIcon={faWrench}
										name={fullModel.modelName || ''}
										dialogTitle='dialog_installModel'
										dialogConfirm='dialog_installModelConfirm'
									/>
								)
							}
							{isAccess(MODEL.RESTORE) &&
								<Restore
									isAvailable={fullModel.activeType === 'previous' && fullModel.status === RequestStatus.IDLE && fullModel.modelName !== null}
									dataResponse={selectRestoreStatus}
									clearDataResponse={clearRestoreResponse}
									submitHandler={restoreHandler}
									name={fullModel.modelName || ''}
									dialogTitle='dialog_restoreModel'
									dialogConfirm='dialog_restoreModelConfirm'
								/>
							}
						</div>
						<div>
							{((serviceType === 'smc' && (isAccess(SMC.CLASSIFY) || isAccess(SMC.COMPRESS) || isAccess(SMC.EMOTION) || isAccess(SMC.PUNCTUATE) || isAccess(SMC.CORRECT) || isAccess(SMC.NORMALIZE))) || (serviceType === 'see' && isAccess(SEE.SEARCH_ENTITIES))) &&
								<ManualCheck
									isAvailable={fullModel.status !== RequestStatus.LOADING && (!showErrors || (showErrors && typeof errors.errors !== 'string' && errors.errors.length > 0)) && (!showErrorsMark || (showErrorsMark && typeof errors.errors !== 'string' && errors.errors.length > 0))}
									handler={manualCheckHandler}
								/>
							}
						</div>
					</>
				}
			/>

			{!showErrors && !showErrorsMark &&
				<ManualCheckModal showManualCheck={showManualCheck} setShowManualCheck={setShowManualCheck} serviceType={serviceType} modelName={modelName} />
			}
		</>
	);
};

export default ModelControl;
