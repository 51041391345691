import { ChangeEvent, useState } from 'react';
import { FormControl, InputAdornment, TextField } from '@mui/material';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addRobotStopPhrase, changeRobotStopPhrase, deleteRobotStopPhrase, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { colorPrimary, colorRed } from '../../../constants/colors';
import { IPhraseProps } from './Phrase.props';
import styles from './Phrase.module.scss';

const Phrase = ({ changeFlg, setChangeFlg, phrase, indexPhrase, isPhrase, newPhraseFlg }: IPhraseProps): JSX.Element => {
	const [inputPhrase, setInputPhrase] = useState<string>(phrase); // фраза

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// обработчик изменения поля
	const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		setInputPhrase(e.target.value);
		if (!changeFlg.thisIs || !changeFlg.listOfChanges.includes(isPhrase)) {
			setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, isPhrase] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик нажатия клавиши в поле
	const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
		if (e.code === 'Enter') saveInStoreHandler();
	};

	// обработчик сохранения в store
	const saveInStoreHandler = (): void => {
		// если фраза изменилась
		if (phrase !== inputPhrase) {
			// если новая фраза
			if (newPhraseFlg) {
				dispatch(addRobotStopPhrase({ isPhrase, phrase: inputPhrase })); // добавляем фразу
				setInputPhrase(''); // очищаем поле
			} else {
				dispatch(changeRobotStopPhrase({ isPhrase, phrase: inputPhrase, indexPhrase })); // изменяем фразу
			}
		}
	};

	// обработчик удаления из store
	const deleteFromStoreHandler = (): void => {
		dispatch(deleteRobotStopPhrase({ isPhrase, indexPhrase })); // удаляем фразу
		if (!changeFlg.thisIs || !changeFlg.listOfChanges.includes(isPhrase)) {
			setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, isPhrase] }));  // ставим флаг о несохраненных данных
		}
	};


	return (
		<div className={cn(styles.container, {
			[styles.containerOpacity]: newPhraseFlg && inputPhrase === '',
		})}>
			<FormControl fullWidth>
				<TextField
					variant="outlined"
					placeholder={newPhraseFlg ? translate('title_enterPhrase') : undefined}
					disabled={!isAccess(SES.ROBOT_EDIT) || activeRobotVersion !== 'draft'}
					value={inputPhrase}
					onChange={changeHandler}
					onBlur={saveInStoreHandler}
					onKeyDown={keyDownHandler}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
						endAdornment: (
							<InputAdornment position="end">
								{isAccess(SES.ROBOT_EDIT) && activeRobotVersion === 'draft' && !newPhraseFlg &&
									<div className={styles.deleteButton}>
										<FontAwesomeIcon
											icon={faTrashCan}
											color={colorRed}
											size='sm'
											onClick={deleteFromStoreHandler}
											title={translate('buttonTitle_deletePhrase')}
											style={{ cursor: 'pointer' }}
										/>
									</div>
								}
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
				/>
			</FormControl>
		</div>
	);
};

export default Phrase;
