import { ChangeEvent, useEffect, useState } from 'react';
import { Button, FormControl, TextField } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { changeNameInTemplate, selectTemplate } from '../../store/qasSummareSlice';
import useTranslate from '../../hooks/useTranslate';
import useAccessRight from '../../hooks/useAccessRight';
import { QAS } from '../../constants/accessRights';
import { colorPrimary } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import RulesBlock from './RulesBlock/RulesBlock';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { ITemplateProps } from './Template.props';
import styles from './Template.module.scss';

const Template = ({ changeFlg, setChangeFlg, setShowAlertDialogSave, setShowAlertDialogDelete, fullHeightFormFlg }: ITemplateProps): JSX.Element => {
	const [inputName, setInputName] = useState<string>(''); // название шаблона

	const dispatch = useAppDispatch();
	const template = useAppSelector(selectTemplate); // store - шаблон

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за данными шаблона
	useEffect(() => {
		// если есть данные - заполняем имя, иначе очищаем
		if (template.data) setInputName(template.data.name);
		else setInputName('');
	}, [template.data]);

	// обработчик изменения названия шаблона
	const changeNameHandler = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
		setInputName(e.target.value);
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('name')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'name'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик сохранения названия шаблона в store
	const saveNameHandler = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>): void => {
		e.target.value !== template.data?.name && dispatch(changeNameInTemplate(e.target.value));
	};

	return (
		<div className={cn(styles.template, {
			[styles.templatePartialHeight]: fullHeightFormFlg,
		})}>
			{/* нет данных */}
			{(template.status === RequestStatus.IDLE && !template.data) &&
				<div className={styles.noData}>
					{translate('title_noData')}
				</div>
			}

			{/* ошибка загрузки шаблона */}
			{(template.status === RequestStatus.FAILED || template.error === ResponseStatus.FAILED) &&
				<div className={styles.failed}>
					{translate(template.message || 'title_loadFailed')}
				</div>
			}

			{/* загрузка шаблона */}
			{template.status === RequestStatus.LOADING &&
				<div className={styles.loading}>
					<ProgressCircle title={translate('spinnerTitle_loading')} />
				</div>
			}

			{/* данные шаблона */}
			{template.status === RequestStatus.IDLE && template.data &&
				<div className={styles.templateData}>
					<div className={styles.templateDataTop}>
						<FormControl fullWidth margin='dense'>
							<TextField
								id="templateName"
								label={translate('input_name')}
								variant="outlined"
								value={inputName}
								onChange={changeNameHandler}
								onBlur={saveNameHandler}
								disabled={!isAccess(QAS.TEMPLATE_EDIT)}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>

						<div className={styles.title}>{translate('title_rules')}</div>

						{Object.entries(template.data.options)
							.concat([['', ['', template.randomId]]])
							.map(([chapter, [desc, id]], idx, arrayRules) => (
								<RulesBlock
									key={id}
									chapter={chapter}
									desc={desc}
									id={id}
									index={idx}
									arrayRules={arrayRules}
									changeFlg={changeFlg}
									setChangeFlg={setChangeFlg}
								/>
							))
						}
					</div>

					<div className={styles.templateDataBottom}>
						{/* сохранение шаблона */}
						{isAccess(QAS.TEMPLATE_EDIT) &&
							<FormControl fullWidth>
								<Button
									variant="outlined"
									sx={{ fontSize: 11, overflow: 'hidden' }}
									disabled={!changeFlg.thisIs}
									onClick={() => setShowAlertDialogSave(true)}
								>
									{translate('button_save')}
								</Button>
							</FormControl>
						}
						{/* удаление шаблона */}
						{isAccess(QAS.TEMPLATE_DELETE) &&
							<FormControl fullWidth>
								<Button
									variant="outlined"
									sx={{ fontSize: 11, overflow: 'hidden' }}
									onClick={() => setShowAlertDialogDelete(true)}
									color='error'
								>
									{translate('button_delete')}
								</Button>
							</FormControl>
						}
					</div>
				</div>
			}
		</div>
	);
};

export default Template;
