import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import Notification from '../../../Notification/Notification';
import { IApplyProps } from './Apply.props';

const Apply = ({ isAvailable, dataResponse, clearDataResponse, submitHandler, name, buttonTitle = 'buttonTitle_apply', buttonIcon = faPlay, dialogTitle, dialogConfirm }: IApplyProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	// обработчик применения
	const applyHandler = (): void => {
		setShowNotification(true); // включаем уведомление
		setShowAlertDialog(false); // выключаем диалоговое окно
		submitHandler(); // применение
	};

	return (
		<>
			<button
				onClick={() => setShowAlertDialog(true)}
				disabled={!isAvailable}
				title={translate(buttonTitle)}>
				<FontAwesomeIcon icon={buttonIcon} size="xl" color={backgroundColor} />
			</button>

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={dataResponse}
					clearDataResponse={clearDataResponse}
					titleFailed='noticeApplying_failed'
					titleSuccess='noticeApplying_success'
				/>
			}

			<AlertDialog
				showAlertDialog={showAlertDialog}
				setShowAlertDialog={setShowAlertDialog}
				submitHandler={applyHandler}
				title={dialogTitle}
				description={dialogConfirm}
				name={name}
			/>
		</>
	);
};

export default Apply;
