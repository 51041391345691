import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Fade, FormControl, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectGptList } from '../../../store/qasSlice';
import { clearAddingTemplate, clearTemplate, getTemplate, getTemplatesList, selectAddingTemplate, selectTemplate, selectTemplatesList } from '../../../store/qasSummareSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { QUESTION, QUESTIONNAIRE } from '../../../constants/routes';
import { QAS_MESSAGE_HISTORY_DEPTH, QAS_TEMPLATE_ID } from '../../../constants/cookieNames';
import { colorPrimary } from '../../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import HideSidebar from '../HideSidebar/HideSidebar';
import FormAddingTemplate from '../../Forms/FormAddingTemplate/FormAddingTemplate';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { IInteractiveNavbarProps } from './InteractiveNavbar.props';
import styles from './InteractiveNavbar.module.scss';

const InteractiveNavbar = ({ showSidebar, setShowSidebar, setShowPage, setChangeFlg, tabSwitch, selectModelGPT, setSelectModelGPT, inputHistory, setInputHistory }: IInteractiveNavbarProps): JSX.Element => {
	const [activeTemplateId, setActiveTemplateId] = useState<string>(''); // id активного шаблона
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления шаблона
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список шаблонов

	const dispatch = useAppDispatch();
	const templatesList = useAppSelector(selectTemplatesList); // store - список шаблонов
	const template = useAppSelector(selectTemplate); // store - шаблон
	const modelGptList = useAppSelector(selectGptList); // store - список моделей gpt

	const navigate = useNavigate(); // hook для навигации
	const [cookies, setCookie] = useCookies([QAS_TEMPLATE_ID, QAS_MESSAGE_HISTORY_DEPTH]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за списком шаблонов
	useEffect(() => {
		// если есть доступ, список шаблонов не пустой и не было изменено имя
		if (isAccess(QAS.TEMPLATE_GET) && Array.isArray(templatesList.data) && templatesList.data.length > 0) {
			// если есть запись в cookie и список шаблонов содержит эту запись, то передаем ее в обработчик выбора активного шаблона, иначе выбираем первый шаблон из списка
			cookies[QAS_TEMPLATE_ID] && templatesList.data.find(templateItem => templateItem.id === cookies[QAS_TEMPLATE_ID]) ?
				templateHandler(cookies[QAS_TEMPLATE_ID])
				:
				templateHandler(templatesList.data[0].id);
		}
		// если список пустой и были данне шаблона - очищаем
		if (Array.isArray(templatesList.data) && templatesList.data.length === 0 && template.data) {
			dispatch(clearTemplate());
		}
	}, [templatesList.data]);

	// следим за активным шаблоном
	useEffect(() => {
		Array.isArray(templatesList.data) && listRef.current?.children[templatesList.data.findIndex(templateItem => templateItem.id === activeTemplateId)]?.scrollIntoView({ block: "center" }); // показ активного шаблона в центре списка с имеющейся полосой прокрутки
	}, [activeTemplateId]);

	// обработчик выбора активного шаблона
	const templateHandler = (templateId: string): void => {
		template.data && dispatch(clearTemplate()); // очищаем все данные о шаблоне, если есть
		setActiveTemplateId(templateId); // устанавливаем id активного шаблона
		setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг возможности сохранения
		setCookie(QAS_TEMPLATE_ID, templateId, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
		isAccess(QAS.TEMPLATE_GET) && dispatch(getTemplate(templateId)); // получаем шаблон
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (link: string): void => {
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(link);
		}, 500);
	};

	return (
		<Slide direction="right" in={showSidebar} timeout={800} style={{ visibility: 'visible', zIndex: 10 }}>
			<div>
				<div className={styles.sidebar}>
					<HideSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

					<div className={styles.navbar}>
						{tabSwitch === 'summary' ?
							<>
								{/* ошибка загрузки списка шаблонов */}
								{(templatesList.status === RequestStatus.FAILED || templatesList.error === ResponseStatus.FAILED) &&
									<div className={styles.navbarFailedText}>
										<span>{translate(templatesList.message || 'title_loadFailed')}</span>
										{isAccess(QAS.TEMPLATE_LIST) &&
											<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getTemplatesList())}>{translate('link_update')}</span>
										}
									</div>
								}

								{/* пустой список шаблонов */}
								{templatesList.status === RequestStatus.IDLE && Array.isArray(templatesList.data) && templatesList.data.length === 0 &&
									<div className={styles.navbarNoTemplates}>{translate('title_emptyList')}</div>
								}

								{/* загрузка списка шаблонов */}
								{templatesList.status === RequestStatus.LOADING &&
									<div className={styles.navbarLoading}>
										<ProgressCircle title={translate('spinnerTitle_loading')} />
									</div>
								}

								{/* список шаблонов */}
								{templatesList.status === RequestStatus.IDLE && Array.isArray(templatesList.data) && templatesList.data.length > 0 &&
									<ul className={styles.navbarTemplatesList} ref={listRef}>
										{templatesList.data.map(({ id, name }) => (
											<li className={styles.navbarTemplatesItem} key={id}>
												<Fade in={templatesList.status !== RequestStatus.LOADING || (Array.isArray(templatesList.data) && templatesList.data.length > 0)} timeout={500}>
													<div
														className={cn({
															[styles.navbarTemplatesLink]: activeTemplateId !== id,
															[styles.navbarTemplatesLinkActive]: activeTemplateId === id,
														})}
														onClick={() => isAccess(QAS.TEMPLATE_GET) && activeTemplateId !== id && templateHandler(id)}
													>
														<div className={styles.navbarTemplatesLinkLeftBlock} title={name}>
															{name}
														</div>
														<div className={cn(styles.navbarTemplatesLinkIcon, {
															[styles.navbarTemplatesLinkIconActive]: activeTemplateId === id,
														})}>
															<FontAwesomeIcon icon={faAngleRight} />
														</div>
													</div>
												</Fade>
											</li>
										))}
									</ul>
								}
							</>
							:
							<Fade in={true} timeout={300}>
								<div className={styles.navbarChatForm}>
									{/* модель gpt */}
									<FormControl fullWidth sx={{
										'.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 },
									}}>
										<InputLabel id="modelGpt-label" sx={{ fontSize: 13 }}>{translate('select_generationModel')}</InputLabel>
										<Select
											labelId="modelGpt-label"
											id="modelGpt"
											label={translate('select_generationModel')}
											// disabled={chat.status === RequestStatus.LOADING}
											value={selectModelGPT}
											onChange={(e) => setSelectModelGPT(e.target.value)}
											style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
										>
											<MenuItem value='default' sx={{ fontSize: 13, color: colorPrimary }}>default</MenuItem>
											{modelGptList.data.filter(model => model !== 'default').map((model) => (
												<MenuItem key={model} value={model} sx={{ fontSize: 13, color: colorPrimary }}>{model}</MenuItem>
											))}
										</Select>
									</FormControl>
									{/* глубина истории сообщений */}
									<FormControl fullWidth>
										<TextField
											label={translate('input_messageHistoryDepth')}
											variant="outlined"
											type='number'
											value={inputHistory}
											onChange={(e) => setInputHistory(+e.target.value)}
											onBlur={e => {
												let integerValue = Math.round(+e.target.value);
												if (integerValue < 3) integerValue = 3;
												else if (integerValue > 10) integerValue = 10;
												setInputHistory(integerValue);
												setCookie(QAS_MESSAGE_HISTORY_DEPTH, integerValue, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
											}}
											// disabled={chat.status === RequestStatus.LOADING}
											InputProps={{
												style: {
													height: 33,
													fontSize: 13,
													color: colorPrimary,
												},
												inputProps: { min: 3, max: 10 },
											}}
											InputLabelProps={{
												style: {
													fontSize: 13,
												},
											}}
											sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
										/>
									</FormControl>
								</div>
							</Fade>
						}

						<div className={styles.functionButtons}>
							{/* добавление шаблона */}
							{isAccess(QAS.TEMPLATE_ADD) && (templatesList.status === RequestStatus.IDLE || Array.isArray(templatesList.data)) && tabSwitch === 'summary' &&
								<Fade in={true} timeout={500}>
									<div className={styles.functionButtonsAddTemplate} onClick={() => setShowModal(true)}>
										{translate('link_addTemplate')}
									</div>
								</Fade>
							}
							{/* табы */}
							{(isAccess(QAS.DOC_LIST) || isAccess([QAS.QUESTION_LIST, QAS.QUESTION_GET])) &&
								<div className={styles.functionButtonsTabs}>
									{isAccess(QAS.DOC_LIST) &&
										<div
											className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
											onClick={() => delayToHidePage(QUESTIONNAIRE)}
										>
											{translate('tab_documents')}
										</div>
									}
									{isAccess([QAS.QUESTION_LIST, QAS.QUESTION_GET]) &&
										<div
											className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
											onClick={() => delayToHidePage(`${QUESTIONNAIRE}/${QUESTION}`)}
										>
											{translate('tab_cache')}
										</div>
									}
									<div className={styles.functionButtonsTab}>{translate('tab_interactive')}</div>
								</div>
							}
						</div>
					</div>
				</div>

				{showModal && <FormAddingTemplate showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />}
				{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectAddingTemplate} clearDataResponse={clearAddingTemplate} titleFailed={translate('noticeAddition_failed')} titleSuccess={translate('noticeAddition_success')} />}
			</div>
		</Slide>
	);
};

export default InteractiveNavbar;
