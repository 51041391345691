import { FormEvent, useEffect, useRef, useState } from 'react';
import { Button, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectModelName } from '../../../store/modelSlice';
import { clearNormalization, clearSynthesisData, normalizeText, selectNormalization, selectRevoiceData, selectSynthesisData, synthesizeSpeech } from '../../../store/ttsSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { TTS } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { FormatType } from '../../../types/ttsTypes';
import { RequestStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import NoticeSingleAction from '../../Notification/NoticeSingleAction/NoticeSingleAction';
import { IFormSynthesisProps } from './FormSynthesis.props';
import styles from './FormSynthesis.module.scss';

const listOfFormats: FormatType[] = ['wav', 'ogg', 'opus', 'mp3'];

const FormSynthesis = ({ setWhatsPlaying, format, setFormat }: IFormSynthesisProps): JSX.Element => {
	const [text, setText] = useState<string>(''); // поле текста
	const [rate, setRate] = useState<number>(100); // поле скорости
	const [pitch, setPitch] = useState<number>(100); // поле тональности
	const [volume, setVolume] = useState<number>(100); // поле громкости
	const [frequency, setFrequency] = useState<number>(22050); // частота

	const [rowsText, setRowsText] = useState<number>(1); // количество строк текстового поля для полного заполнения высоты
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления статуса синтеза

	const formRef = useRef<HTMLFormElement>(null); // ссылка на блок формы
	const fieldsRef = useRef<HTMLDivElement>(null); // ссылка на блок полей
	const buttonsRef = useRef<HTMLDivElement>(null); // ссылка на блок кнопок

	const dispatch = useAppDispatch();
	const modelName = useAppSelector(selectModelName); // store - имя модели
	const normalizationData = useAppSelector(selectNormalization); // store - данные нормализации
	const synthesisData = useAppSelector(selectSynthesisData); // store - данные синтеза речи
	const revoiceData = useAppSelector(selectRevoiceData); // store - искажение голоса

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за полями формы и если что изменилось - очищаем результат синтеза и нормализации
	useEffect(() => {
		if (synthesisData.dataUrl !== null || synthesisData.status === RequestStatus.FAILED) {
			dispatch(clearSynthesisData());
			dispatch(clearNormalization());
		}
	}, [text, rate, pitch, volume, frequency, format]);

	// подписываемся на событие изменения размеров экрана и запускаем функцию подсчета кол-во строк для заполнения высоты формы
	useEffect(() => {
		window.addEventListener('resize', resizeHandler);
		// отписываемся
		return () => {
			window.removeEventListener('resize', resizeHandler);
		};
	}, []);

	// следим за ссылками блоков и запускаем функцию подсчета кол-во строк для заполнения высоты формы
	useEffect(() => {
		if (formRef.current && fieldsRef.current && buttonsRef.current) {
			setTimeout(() => resizeHandler(), 500);
		}
	}, [formRef.current, fieldsRef.current, buttonsRef.current]);

	// следим за данными нормализации
	useEffect(() => {
		// если есть и отличается от текста в форме - меняем
		if (normalizationData.text && normalizationData.text !== text) setText(normalizationData.text);
	}, [normalizationData]);

	// следим за данными синтеза
	useEffect(() => {
		// если есть ошибка - включаем уведомление
		if (synthesisData.status === RequestStatus.FAILED) setShowNotification(true);
	}, [synthesisData]);

	// функция подсчета кол-ва строк для заполнения высоты формы
	const resizeHandler = (): void => {
		if (formRef.current && fieldsRef.current && buttonsRef.current) {
			const lineHeight = 19; // длина строки
			const sumOfIndents = 40; // сумма всех отступов
			setRowsText(Math.floor((formRef.current.clientHeight - fieldsRef.current.clientHeight - buttonsRef.current.clientHeight - sumOfIndents) / lineHeight));
		}
	};

	// обработчик нормализации
	const normalizeHandler = (): void => {
		(normalizationData.text || normalizationData.status === RequestStatus.FAILED) && dispatch(clearNormalization());
		dispatch(normalizeText(text));
	};

	// обработчик синтеза
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		setWhatsPlaying('synthesis'); // обозначаем для указания ссылки плееру
		(synthesisData.dataUrl !== null || synthesisData.status === RequestStatus.FAILED) && dispatch(clearSynthesisData()); // если есть данные синтеза - очищаем
		modelName && dispatch(synthesizeSpeech({ modelName, text, rate, pitch, volume, frequency, format })); // синтез речи
	};

	return (
		<>
			<form className={styles.form} onSubmit={submitHandler} ref={formRef}>
				<div className={styles.formFields}>
					{/* текст */}
					<FormControl fullWidth>
						<TextField
							required
							id="text"
							label={translate('input_text')}
							variant="outlined"
							multiline
							rows={rowsText}
							value={text}
							onChange={(e) => setText(e.target.value)}
							disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end" >
										{text.length > 0 &&
											<FontAwesomeIcon icon={faXmark} onClick={() => normalizationData.status !== RequestStatus.LOADING && synthesisData.status !== RequestStatus.LOADING && setText('')} style={{ cursor: 'pointer ' }} />
										}
									</InputAdornment>
								),
								style: {
									padding: 8,
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					<div className={styles.formFieldsInner} ref={fieldsRef}>
						<div className={styles.formBlock}>
							{/* скорость */}
							<FormControl fullWidth>
								<TextField
									id="rate"
									required
									label={translate('input_rate')}
									variant="outlined"
									type='number'
									value={rate}
									onChange={(e) => setRate(Math.round(Number(e.target.value)))}
									disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
											color: colorPrimary,
										},
										inputProps: { min: 0 }
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
							{/* тональность */}
							<FormControl fullWidth>
								<TextField
									id="pitch"
									required
									label={translate('input_pitch')}
									variant="outlined"
									type='number'
									value={pitch}
									onChange={(e) => setPitch(Math.round(Number(e.target.value)))}
									disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
											color: colorPrimary,
										},
										inputProps: { min: 0 }
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
							{/* громкость */}
							<FormControl required fullWidth>
								<TextField
									id="volume"
									required
									label={translate('input_volume')}
									variant="outlined"
									type='number'
									value={volume}
									onChange={(e) => setVolume(Math.round(Number(e.target.value)))}
									disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
											color: colorPrimary,
										},
										inputProps: { min: 0 }
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
						</div>
						<div className={styles.formBlock}>
							{/* частота */}
							<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }} required>
								<InputLabel sx={{ fontSize: 13 }}>{translate('select_frequency')}</InputLabel>
								<Select
									label={translate('select_frequency')}
									disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
									value={frequency}
									onChange={(e) => setFrequency(+e.target.value)}
									style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
								>
									{[8000, 16000, 22050].map((frequency) =>
										<MenuItem key={frequency} value={frequency} sx={{ fontSize: 13, color: colorPrimary }}>{Math.round(frequency / 1000)} {translate('title_kHz')}</MenuItem>
									)}
								</Select>
							</FormControl>
							{/* формат */}
							<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }} required>
								<InputLabel sx={{ fontSize: 13 }}>{translate('select_format')}</InputLabel>
								<Select
									label={translate('select_format')}
									disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING}
									value={format}
									onChange={(e) => setFormat(e.target.value as FormatType)}
									style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
								>
									{listOfFormats.map((format) =>
										<MenuItem key={format} value={format} sx={{ fontSize: 13, color: colorPrimary }}>{format}</MenuItem>
									)}
								</Select>
							</FormControl>
						</div>
					</div>
				</div>

				<div className={styles.formButtons} ref={buttonsRef}>
					{/* нормализация */}
					{isAccess(TTS.NORMALIZE) &&
						<FormControl fullWidth>
							<Button
								variant="outlined"
								type="button"
								disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING || !text}
								sx={{ overflow: 'hidden', fontSize: 11 }}
								onClick={normalizeHandler}
							>
								{translate('button_normalize')}
								{normalizationData.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
							</Button>
						</FormControl>
					}
					{/* синтез */}
					<FormControl fullWidth>
						<Button
							variant="outlined"
							type="submit"
							disabled={normalizationData.status === RequestStatus.LOADING || synthesisData.status === RequestStatus.LOADING || revoiceData.status === RequestStatus.LOADING || !text}
							sx={{ overflow: 'hidden', fontSize: 11 }}
						>
							{translate('button_synthesize')}
							{synthesisData.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
						</Button>
					</FormControl>
				</div>
			</form>

			{showNotification &&
				<NoticeSingleAction
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					title={synthesisData.message || 'noticeSynthesis_failed'}
				/>
			}
		</>
	);
};

export default FormSynthesis;
