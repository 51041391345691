import { useEffect, useRef } from 'react';
import { Grow, Paper, Popper } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import { selectRecognitionData } from '../../../../store/sprSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { colorPrimary } from '../../../../constants/colors';
import ResultRecognition from '../../../ResultRecognition/ResultRecognition';
import { IPopupResultProps } from './PopupResult.props';
import styles from './PopupResult.module.scss';

const PopupResult = ({ anchorRef, open, setOpen }: IPopupResultProps): JSX.Element => {
	const prevOpen = useRef(open); // return focus to the button when we transitioned from !open -> open

	const recognitionData = useAppSelector(selectRecognitionData); // store - данные распознавания речи

	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current!.focus();
		}
		prevOpen.current = open;
	}, [open]);

	const handleClose = (event: Event | React.SyntheticEvent) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return;
		setOpen(false);
	};

	return (
		<Popper
			open={open}
			anchorEl={anchorRef.current}
			role={undefined}
			placement="bottom-start"
			transition
			disablePortal
			style={{ zIndex: 40, minWidth: '200px', maxWidth: 'calc(100% - 400px)' }}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }}
				>
					<Paper>
						<ResultRecognition
							requestStatus={recognitionData.status}
							data={recognitionData.data}
							timestamp={recognitionData.timestamp}
							mode="sync"
						/>
						<button
							type='button'
							className={styles.buttonClose}
							onClick={handleClose}
							title={translate('buttonTitle_close')}
						>
							<FontAwesomeIcon icon={faXmark} size='xl' color={colorPrimary} />
						</button>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
};

export default PopupResult;
