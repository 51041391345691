import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Button, Fade, FormControl, InputAdornment, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearAuthData, login, selectAuthData } from '../../store/authSlice';
import { selectWallpaper } from '../../store/langSlice';
import useTranslate from '../../hooks/useTranslate';
import { ACCESS_TOKEN, REFRESH_TOKEN } from '../../constants/cookieNames';
import { backgroundColorGradient } from '../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import styles from './Login.module.scss';

const Login = (): JSX.Element => {
	const [userName, setUserName] = useState<string>(''); // имя пользователя
	const [password, setPassword] = useState<string>(''); // пароль
	const [showPassword, setShowPassword] = useState<boolean>(false); // флаг отображения пароля

	const dispatch = useAppDispatch();
	const authData = useAppSelector(selectAuthData); // store - данные авторизации
	const wallpaper = useAppSelector(selectWallpaper); // store - обои

	const navigate = useNavigate(); // hook для навигации
	const location = useLocation(); // hook для определения адреса
	const [cookies] = useCookies([ACCESS_TOKEN, REFRESH_TOKEN]); // hook для работы с cookie
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		// если есть токены - перенаправляем на предыдущую/главную страницу
		if ((cookies.accessToken && cookies.refreshToken) || (authData.token.access && authData.token.refresh)) {
			navigate(location.state || '/');
		}
	}, [authData.token.access]);

	// обработчик формы
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (authData.status === RequestStatus.FAILED || authData.message !== '') dispatch(clearAuthData()); // если есть данные ошибки - очищаем
		dispatch(login({ userName: userName.trim(), password: password.trim() })); // авторизация
	};

	return (
		<Fade in={true} timeout={1000}>
			<div
				className={styles.login}
				style={wallpaper.dataUrl ? { backgroundImage: `url(${wallpaper.dataUrl})` } : { background: backgroundColorGradient }}
			>
				<form className={styles.loginForm} onSubmit={(e) => submitHandler(e)}>
					<FormControl margin='dense' sx={{ width: 300 }}>
						<TextField
							autoFocus
							required
							label={translate("input_userName")}
							id="userName"
							variant="outlined"
							size="small"
							value={userName}
							error={authData.error === ResponseStatus.FAILED || authData.status === RequestStatus.FAILED}
							onChange={e => setUserName(e.target.value)}
							disabled={authData.status === RequestStatus.LOADING}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -2 } }}
						/>
					</FormControl>
					<FormControl margin='dense' sx={{ width: 300 }}>
						<TextField
							required
							label={translate("input_password")}
							id="password"
							variant="outlined"
							size="small"
							type={showPassword ? 'text' : 'password'}
							value={password}
							error={authData.error === ResponseStatus.FAILED || authData.status === RequestStatus.FAILED}
							onChange={e => setPassword(e.target.value)}
							disabled={authData.status === RequestStatus.LOADING}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end" >
										<FontAwesomeIcon
											icon={showPassword ? faEyeSlash : faEye}
											onClick={() => authData.status !== RequestStatus.LOADING && setShowPassword(prev => !prev)}
											style={{ cursor: 'pointer' }}
										/>
									</InputAdornment>
								),
								style: {
									height: 33,
									fontSize: 13
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -2 } }}
						/>
					</FormControl>
					<FormControl margin='dense' sx={{ width: 300 }}>
						<Button
							variant="outlined"
							type="submit"
							disabled={authData.status === RequestStatus.LOADING}
							sx={{ fontSize: 11 }}
						>
							{translate('button_signIn')}
							{authData.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
						</Button>
					</FormControl>

					{(authData.error === ResponseStatus.FAILED || authData.status === RequestStatus.FAILED) &&
						<p className={styles.loginError}>{translate(authData.message || 'Internal Server Error')}</p>
					}
				</form>
			</div>
		</Fade>
	);
};

export default Login;

