import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_QAS, URL_QAS } from '../constants/api_endpoints';
import { IAddTemplateProps, IChatProps, IDialog, IEditTemplateProps, IQasSummareState, IResponse, IResponseAddingTemplate, IResponseChat, IResponseSummarize, IResponseTemplate, IResponseTemplatesList, ISummarizeProps } from '../types/qasSummareTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: IQasSummareState = {
  templatesList: {
    status: RequestStatus.IDLE,
    data: null,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  template: {
    status: RequestStatus.IDLE,
    data: null,
    error: ResponseStatus.SUCCESS,
    message: '',
    nameChanged: false,
    randomId: Math.random(),
  },
  addingTemplate: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    id: null,
  },
  editingTemplate: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  deletingTemplate: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  summare: {
    status: RequestStatus.IDLE,
    data: null,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  chat: {
    status: RequestStatus.IDLE,
    data: null,
    dialog: [],
    error: ResponseStatus.SUCCESS,
    message: '',
  },
};

// получение списка шаблонов
export const getTemplatesList = createAsyncThunk(
  'qasSummare/getTemplatesList',
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseTemplatesList | IResponse> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.TEMPLATE_LIST}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// получение шаблона
export const getTemplate = createAsyncThunk(
  'qasSummare/getTemplate',
  async (templateId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseTemplate> = await axiosApi.get(`${URL_QAS}/${ENDPOINTS_QAS.TEMPLATE_GET}/${templateId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление шаблона
export const addTemplate = createAsyncThunk(
  'qasSummare/addTemplate',
  async ({ name, options }: IAddTemplateProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseAddingTemplate> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.TEMPLATE_ADD}`, {
        name,
        options: JSON.stringify(options),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение шаблона
export const editTemplate = createAsyncThunk(
  'qasSummare/editTemplate',
  async ({ templateId, name, options }: IEditTemplateProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.TEMPLATE_EDIT}/${templateId}`, {
        data: JSON.stringify({ name, options }),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление шаблона
export const deleteTemplate = createAsyncThunk(
  'qasSummare/deleteTemplate',
  async (templateId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_QAS}/${ENDPOINTS_QAS.TEMPLATE_DELETE}/${templateId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// протокол
export const summarize = createAsyncThunk(
  'qasSummare/summarize',
  async ({ text, formData, options, modelGpt }: ISummarizeProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseSummarize> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.SUMMARIZE}`, {
        text,
        file: formData?.get('file'),
        options: JSON.stringify(options),
        model: modelGpt,
      }, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// чат
export const chatWithGpt = createAsyncThunk(
  'qasSummare/chatWithGpt',
  async ({ text, history, modelGpt }: IChatProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponseChat | IResponse> = await axiosApi.post(`${URL_QAS}/${ENDPOINTS_QAS.CHAT}`, {
        text,
        history,
        model: modelGpt,
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const qasSummareSlice = createSlice({
  name: 'qasSummare',
  initialState,
  reducers: {
    // изменение названия шаблона 
    changeNameInTemplate: (state, action: PayloadAction<string>) => {
      if (state.template.data) {
        state.template.data.name = action.payload;
        state.template.nameChanged = true;
      }
    },
    // изменение названия шаблона в списке шаблонов
    changeNameInTemplatesList: (state) => {
      if (state.templatesList.data && state.template.data) {
        state.templatesList.data = state.templatesList.data.map(templateItem => {
          if (templateItem.id === state.template.data?.id) return { ...templateItem, name: state.template.data.name };
          else return templateItem;
        });
      }
    },
    // изменение раздела правила
    changeRuleChapter: (state, action: PayloadAction<{ oldChapter: string, newChapter: string }>) => {
      if (state.template.data) {
        const arrChangedOptions = Object.entries(state.template.data.options).map(rule => {
          if (rule[0] === action.payload.oldChapter) return [action.payload.newChapter, rule[1]];
          else return rule;
        });
        const objChangedOptions: Record<string, [string, number]> = Object.fromEntries(arrChangedOptions);
        state.template.data.options = objChangedOptions;
      }
    },
    // изменение описания правила
    changeRuleDescription: (state, action: PayloadAction<{ chapter: string, newDesc: string }>) => {
      if (state.template.data) {
        state.template.data.options[action.payload.chapter] = [action.payload.newDesc, state.template.data.options[action.payload.chapter][1]];
      }
    },
    // добавление правила
    addRule: (state, action: PayloadAction<{ chapter: string, desc: string, id: number }>) => {
      if (state.template.data) {
        state.template.data.options[action.payload.chapter] = [action.payload.desc, action.payload.id];
        state.template.randomId = Math.random(); // меняем id для следующего правила 
      }
    },
    // удаление правила
    deleteRule: (state, action: PayloadAction<string>) => {
      if (state.template.data) {
        delete state.template.data.options[action.payload];
      }
    },
    // добавление реплики в диалог
    addReplicaInDialog: (state, action: PayloadAction<IDialog>) => {
      state.chat.dialog.push(action.payload);
    },
    // очистка ответа gpt
    clearChatAnswer: (state) => {
      state.chat.status = initialState.chat.status;
      state.chat.error = initialState.chat.error;
      state.chat.message = initialState.chat.message;
      state.chat.data = initialState.chat.data;
    },
    // очистка state
    clearState: (state) => {
      state.templatesList = initialState.templatesList;
      state.template = initialState.template;
      state.addingTemplate = initialState.addingTemplate;
      state.editingTemplate = initialState.editingTemplate;
      state.deletingTemplate = initialState.deletingTemplate;
      state.summare = initialState.summare;
      state.chat = initialState.chat;
    },
    // очистка списка шаблонов
    clearTemplatesList: (state) => {
      state.templatesList = initialState.templatesList;
    },
    // очистка шаблона
    clearTemplate: (state) => {
      state.template = initialState.template;
    },
    // очистка статуса добавления шаблона
    clearAddingTemplate: (state) => {
      state.addingTemplate = initialState.addingTemplate;
    },
    // очистка статуса изменения шаблона
    clearEditingTemplate: (state) => {
      state.editingTemplate = initialState.editingTemplate;
    },
    // очистка статуса удаления шаблона
    clearDeletingTemplate: (state) => {
      state.deletingTemplate = initialState.deletingTemplate;
    },
    // очистка протокола
    clearSummare: (state) => {
      state.summare = initialState.summare;
    },
    // очистка чата
    clearChat: (state) => {
      state.chat = initialState.chat;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemplatesList.pending, (state) => {
        state.templatesList.status = RequestStatus.LOADING;
      })
      .addCase(getTemplatesList.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'templates' in action.payload && Array.isArray(action.payload.templates)) {
          state.templatesList.status = RequestStatus.IDLE;
          state.templatesList.data = action.payload.templates.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
        } else state.templatesList.status = RequestStatus.FAILED;
      })
      .addCase(getTemplatesList.rejected, (state, action: PayloadAction<unknown>) => {
        state.templatesList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.templatesList.error = action.payload.response?.data.error;
          state.templatesList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getTemplate.pending, (state) => {
        state.template.status = RequestStatus.LOADING;
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'data' in action.payload && action.payload.data) {
          state.template.status = RequestStatus.IDLE;
          // state.template.data = action.payload.data;
          const { options, ...data } = action.payload.data;
          // меняем формат правил
          const newOptions: Record<string, [string, number]> = {};
          for (const key in options) {
            newOptions[key] = [options[key], Math.random()];
          }
          state.template.data = { ...data, options: newOptions };
        } else state.template.status = RequestStatus.FAILED;
      })
      .addCase(getTemplate.rejected, (state, action: PayloadAction<unknown>) => {
        state.template.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.template.error = action.payload.response?.data.error;
          state.template.message = action.payload.response?.data.message;
        }
      })
      .addCase(addTemplate.pending, (state) => {
        state.addingTemplate.status = RequestStatus.LOADING;
      })
      .addCase(addTemplate.fulfilled, (state, action) => {
        if (action.payload) {
          state.addingTemplate.status = RequestStatus.IDLE;
          state.addingTemplate.error = action.payload.error;
          state.addingTemplate.message = action.payload.message;
          if (action.payload.id) state.addingTemplate.id = action.payload.id;
        } else state.addingTemplate.status = RequestStatus.FAILED;
      })
      .addCase(addTemplate.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingTemplate.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingTemplate.error = action.payload.response?.data.error;
          state.addingTemplate.message = action.payload.response?.data.message;
        }
      })
      .addCase(editTemplate.pending, (state) => {
        state.editingTemplate.status = RequestStatus.LOADING;
      })
      .addCase(editTemplate.fulfilled, (state, action) => {
        if (action.payload) {
          state.editingTemplate.status = RequestStatus.IDLE;
          state.editingTemplate.error = action.payload.error;
          state.editingTemplate.message = action.payload.message;
        } else state.editingTemplate.status = RequestStatus.FAILED;
      })
      .addCase(editTemplate.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingTemplate.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingTemplate.error = action.payload.response?.data.error;
          state.editingTemplate.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteTemplate.pending, (state) => {
        state.deletingTemplate.status = RequestStatus.LOADING;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingTemplate.status = RequestStatus.IDLE;
          state.deletingTemplate.error = action.payload.error;
          state.deletingTemplate.message = action.payload.message;
        } else state.deletingTemplate.status = RequestStatus.FAILED;
      })
      .addCase(deleteTemplate.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingTemplate.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingTemplate.error = action.payload.response?.data.error;
          state.deletingTemplate.message = action.payload.response?.data.message;
        }
      })
      .addCase(summarize.pending, (state) => {
        state.summare.status = RequestStatus.LOADING;
      })
      .addCase(summarize.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'summary' in action.payload && action.payload.summary) {
          state.summare.status = RequestStatus.IDLE;
          state.summare.data = action.payload.summary;
        } else state.summare.status = RequestStatus.FAILED;
      })
      .addCase(summarize.rejected, (state, action: PayloadAction<unknown>) => {
        state.summare.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.summare.error = action.payload.response?.data.error;
          state.summare.message = action.payload.response?.data.message;
        }
      })
      .addCase(chatWithGpt.pending, (state) => {
        state.chat.status = RequestStatus.LOADING;
      })
      .addCase(chatWithGpt.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'text' in action.payload && action.payload.text) {
          state.chat.status = RequestStatus.IDLE;
          state.chat.data = action.payload.text;
        } else state.chat.status = RequestStatus.FAILED;
      })
      .addCase(chatWithGpt.rejected, (state, action: PayloadAction<unknown>) => {
        state.chat.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.chat.error = action.payload.response?.data.error;
          state.chat.message = action.payload.response?.data.message || `${action.payload.response?.status} ${action.payload.code}`;
        }
      });
  },
});

export const { changeNameInTemplate, changeNameInTemplatesList, changeRuleChapter, changeRuleDescription, addRule, deleteRule, addReplicaInDialog, clearChatAnswer, clearState, clearTemplatesList, clearTemplate, clearAddingTemplate, clearEditingTemplate, clearDeletingTemplate, clearSummare, clearChat } = qasSummareSlice.actions;

export const selectTemplatesList = (state: RootState) => state.qasSummare.templatesList;
export const selectTemplate = (state: RootState) => state.qasSummare.template;
export const selectAddingTemplate = (state: RootState) => state.qasSummare.addingTemplate;
export const selectEditingTemplate = (state: RootState) => state.qasSummare.editingTemplate;
export const selectDeletingTemplate = (state: RootState) => state.qasSummare.deletingTemplate;
export const selectSummare = (state: RootState) => state.qasSummare.summare;
export const selectChat = (state: RootState) => state.qasSummare.chat;

export default qasSummareSlice.reducer;
