import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectModelName } from '../../../store/modelSlice';
import { clearExportDictionary, clearImportDictionary, clearPutDictionary, exportDictionary, putDictionary, selectDictionary, selectExportDictionaryStatus, selectImportDictionaryStatus, selectPutDictionaryStatus } from '../../../store/ttsSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import { TTS } from '../../../constants/accessRights';
import Controls from '../../../HOC/Controls/Controls';
import Export from '../Buttons/Export/Export';
import Import from '../Buttons/Import/Import';
import Save from '../Buttons/Save/Save';
import { RequestStatus } from '../../../types/statusTypes';
import { ISynthesisControlProps } from './SynthesisControl.props';

const SynthesisControl = ({ changeFlg, setShowPage }: ISynthesisControlProps): JSX.Element => {

	const dispatch = useAppDispatch();
	const modelName = useAppSelector(selectModelName); // store - имя активной модели
	const dictionary = useAppSelector(selectDictionary); // store - словарь ударений
	const exportDictionaryStatus = useAppSelector(selectExportDictionaryStatus); // store - статус экспорта словаря ударений

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	// обработчик экспорта словаря ударений
	const exportHandler = (): void => {
		dispatch(exportDictionary());
	};

	// обработчик сохранения таблицы ударений
	const changeHandler = (): void => {
		dispatch(putDictionary({ text: dictionary.data }));
	};

	return (
		<Controls
			header='tts'
			setShowPage={setShowPage}
			rightSection={
				<>
					<div>
						{isAccess([TTS.DICTIONARY_GET, TTS.DICTIONARY_EXPORT]) &&
							<Export
								isAvailable={dictionary.status === RequestStatus.IDLE && exportDictionaryStatus.status !== RequestStatus.LOADING && modelName !== null}
								selectDataResponse={selectExportDictionaryStatus}
								clearDataResponse={clearExportDictionary}
								submitHandler={exportHandler}
							/>
						}
						{isAccess([TTS.DICTIONARY_GET, TTS.DICTIONARY_IMPORT]) &&
							<Import
								isAvailable={dictionary.status !== RequestStatus.LOADING && modelName !== null}
								selectDataResponse={selectImportDictionaryStatus}
								clearDataResponse={clearImportDictionary}
								placeOfImport='dictionary'
								serviceType='tts'
								modelName={modelName}
							/>
						}
					</div>
					<div>
						{isAccess([TTS.DICTIONARY_GET, TTS.DICTIONARY_SAVE]) &&
							<Save
								changeFlg={changeFlg}
								dataResponse={selectPutDictionaryStatus}
								clearDataResponse={clearPutDictionary}
								submitHandler={changeHandler}
								dialogTitle='dialog_saveStressDictionary'
								dialogConfirm='dialog_saveStressDictionaryConfirm'
							/>
						}
					</div>
				</>
			}
		/>
	);
};

export default SynthesisControl;
