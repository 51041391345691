import { Slide } from '@mui/material';
import cn from 'classnames';
import useTranslate from '../../hooks/useTranslate';
import UserMenu from '../../components/Controls/Buttons/UserMenu/UserMenu';
import { IControlsProps } from './Controls.props';
import styles from './Controls.module.scss';

const Controls = ({ header, wideHeader = false, clickHandler, setShowPage, leftSection, rightSection }: IControlsProps): JSX.Element => {

	const translate = useTranslate(); // hook для перевода текста

	return (
		<Slide direction="down" in={true} mountOnEnter unmountOnExit timeout={500}>
			<div className={styles.container}>

				<div className={cn(styles.header, {
					[styles.headerWide]: wideHeader,
				})}>
					{translate(header)}
				</div>

				<div className={styles.controls} onClick={clickHandler}>
					<div className={styles.controlsSection}>
						{leftSection}
					</div>

					<div className={styles.controlsSection}>
						{rightSection}
						<div>
							<UserMenu setShowPage={setShowPage} />
						</div>
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default Controls;
