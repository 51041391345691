import { FormEvent, useEffect, useState } from 'react';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { selectTranscriptionAudio, selectTranscriptionData } from '../../../store/transcriptionSlice';
import { clearState, recognizeSpeech, selectRecognitionAsyncData, selectRecognitionData } from '../../../store/sprSlice';
import { clearClusterServer, getClusterServerModels, selectClusterServer } from '../../../store/serverSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SERVER } from '../../../constants/accessRights';
import { RequestStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormReRecognitionProps } from './FormReRecognition.props';
import styles from './FormReRecognition.module.scss';

const FormReRecognition = ({ showModal, setShowModal, setShowNotificationSuccess, setShowNotificationFailed }: IFormReRecognitionProps): JSX.Element => {
	const [selectModel, setSelectModel] = useState<string>(''); // имя продовой модели
	const [modelNamesProd, setModelNamesProd] = useState<string[]>([]); // список моделей кластера

	const [definitionOfSpeakers, setDefinitionOfSpeakers] = useState<boolean>(false); // флаг определения спикеров
	const [normalization, setNormalization] = useState<boolean>(false); // флаг нормализации
	const [punctuation, setPunctuation] = useState<boolean>(true); // флаг пунктуации
	const [speakerCounterFlg, setSpeakerCounterFlg] = useState<boolean>(false); // флаг указания кол-ва спикеров
	const [speakerCounter, setSpeakerCounter] = useState<number>(1); // кол-во спикеров
	const [toxicity, setToxicity] = useState<boolean>(true); // анализ на токсичность
	const [voiceAnalyzer, setVoiceAnalyzer] = useState<boolean>(true); // голосовой анализ
	const [emotion, setEmotion] = useState<boolean>(true); // текстовый анализ

	const dispatch = useAppDispatch();
	const transcription = useAppSelector(selectTranscriptionData); // store - данные сохранненной записи распознавания
	const audio = useAppSelector(selectTranscriptionAudio); // store - аудио-файл сохраненной записи распознавания
	const recognitionData = useAppSelector(selectRecognitionData); // store - распознавание речи
	const recognitionAsyncData = useAppSelector(selectRecognitionAsyncData); // store - данные асинхронного режима
	const clusterServer = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(SERVER.MODEL_LIST) && dispatch(getClusterServerModels({ serviceType: 'spr' })); // получаем продовые модели

		// при уходе со страницы
		return () => {
			dispatch(clearState()); // очищаем state spr
			dispatch(clearClusterServer()); // очищаем список продовых моделей
		};
	}, []);

	// следим за статусом получения продовых моделей с серверов cluster
	useEffect(() => {
		// если нет ошибок и есть данные
		if (clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0) {
			const clusterServers = Object.keys(clusterServer.data); // пишем сервера cluster'а
			const arrModelNames: string[] = [];
			clusterServers.forEach(server => {
				clusterServer.data && clusterServer.data[server]?.forEach(modelName => {
					!arrModelNames.includes(modelName) && arrModelNames.push(modelName); // оставляем все уникальные модели
				});
			});
			setModelNamesProd(arrModelNames); // пишем список моделей в state
			// если список моделей кластера содержит модель на которой была распознана стенограмма - вписываем в поле
			if (transcription.data && 'model' in transcription.data && arrModelNames.includes(transcription.data.model)) {
				setSelectModel(transcription.data.model);
			}
		}
	}, [clusterServer.status]);

	// следим за статусом отправки аудио на распознавание
	useEffect(() => {
		// если ошибка отправки на распознавание
		if (recognitionData.status === RequestStatus.FAILED || (recognitionData.data && 'message' in recognitionData.data)) {
			handleClose(); // закрываем форму
			setShowNotificationFailed(true); // включаем уведомление
		}
	}, [recognitionData.status]);

	// следим за id задачи асинхронного распознавания
	useEffect(() => {
		// если есть задача
		if (recognitionAsyncData.taskId) {
			handleClose(); // закрываем форму
			setShowNotificationSuccess(true); // включаем уведомление
		}
	}, [recognitionAsyncData.taskId]);

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет импорт робота - запрещаем покидать форму
		if (recognitionData.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	// обработчик импорта робота
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		audio.url && fetch(audio.url)
			.then(response => response.blob())
			.then(blob => {
				const formData = new FormData();
				formData.append('file', blob);
				dispatch(recognizeSpeech({
					modelName: selectModel,
					formData,
					asyncMode: 1,
					speakers: definitionOfSpeakers ? 1 : 0,
					normalization: normalization && selectModel !== 'big' ? 1 : 0,
					punctuation: punctuation && selectModel !== 'big' ? 1 : 0,
					vadType: 'webrtc',
					speakerCounter: speakerCounterFlg && speakerCounter > 0 ? speakerCounter : undefined,
					toxicity: toxicity ? 1 : 0,
					voiceAnalyzer: voiceAnalyzer ? 1 : 0,
					emotion: emotion ? 1 : 0,
				})); // распознавание речи
			})
			.catch(error => console.error('Error fetch:', error));
	};

	return (
		<ModalFormWindow
			showModal={showModal}
			setShowModal={setShowModal}
			headerTitle={`${translate("formHeader_reRecognition")} "${transcription.data && 'name' in transcription.data ? transcription.data?.name : '-'}"`}
			close={handleClose}
		>
			<form className={styles.form} onSubmit={(e) => submitHandler(e)}>
				{/* модель */}
				<FormControl fullWidth sx={{ marginBottom: '8px', '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_model')}</InputLabel>
					<Select
						required
						labelId="modelName-label"
						label={translate('select_model')}
						value={selectModel}
						onChange={(e) => setSelectModel(e.target.value)}
						disabled={recognitionData.status === RequestStatus.LOADING}
						style={{ fontSize: 13, height: 33, textAlign: 'left' }}
					>
						{modelNamesProd.map((modelName) => (
							<MenuItem key={modelName} value={modelName} sx={{ fontSize: 13 }}>{modelName}</MenuItem>
						))}
					</Select>
				</FormControl>

				{selectModel !== 'big' &&
					<>
						{/* нормализация */}
						<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
							<Checkbox
								checked={normalization}
								onChange={e => setNormalization(e.target.checked)}
								size='small'
								disabled={recognitionData.status === RequestStatus.LOADING}
							/>
						} label={translate('checkbox_normalization')} />

						{/* пунктуация */}
						<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
							<Checkbox
								checked={punctuation}
								onChange={e => setPunctuation(e.target.checked)}
								size='small'
								disabled={recognitionData.status === RequestStatus.LOADING}
							/>
						} label={translate('checkbox_punctuation')} />
					</>
				}

				{/* анализ на токсичность */}
				<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={toxicity}
						onChange={e => setToxicity(e.target.checked)}
						size='small'
						disabled={recognitionData.status === RequestStatus.LOADING}
					/>
				} label={translate('checkbox_toxicity')} />

				{/* голосовой анализ */}
				<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={voiceAnalyzer}
						onChange={e => setVoiceAnalyzer(e.target.checked)}
						size='small'
						disabled={recognitionData.status === RequestStatus.LOADING}
					/>
				} label={translate('checkbox_voiceAnalysis')} />

				{/* текстовый анализ */}
				<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={emotion}
						onChange={e => setEmotion(e.target.checked)}
						size='small'
						disabled={recognitionData.status === RequestStatus.LOADING}
					/>
				} label={translate('checkbox_textAnalysis')} />

				{/* определение говорящих */}
				<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={definitionOfSpeakers}
						onChange={e => setDefinitionOfSpeakers(e.target.checked)}
						size='small'
						disabled={recognitionData.status === RequestStatus.LOADING}
					/>
				} label={translate('checkbox_definitionOfSpeakers')} />

				{/* указать кол-во спикеров */}
				<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-10px', /* marginBottom: '8px', */ '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={speakerCounterFlg}
						onChange={e => setSpeakerCounterFlg(e.target.checked)}
						size='small'
						disabled={recognitionData.status === RequestStatus.LOADING}
					/>
				} label={translate('checkbox_specifySpeakerCounter')} />

				{/* кол-во спикеров */}
				{speakerCounterFlg &&
					<FormControl fullWidth margin='dense'>
						<TextField
							required
							label={translate('input_quantitySpeakers')}
							variant="outlined"
							type='number'
							disabled={recognitionData.status === RequestStatus.LOADING}
							value={speakerCounter}
							onChange={(e) => setSpeakerCounter(+e.target.value)}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
								},
								inputProps: { min: 1 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
				}

				<FormControl fullWidth margin='dense'>
					<Button
						variant="outlined"
						type="submit"
						disabled={recognitionData.status === RequestStatus.LOADING}
						sx={{ fontSize: 11 }}
					>
						{translate('button_recognize')}
						{recognitionData.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
					</Button>
				</FormControl>
			</form>
		</ModalFormWindow>
	);
};

export default FormReRecognition;
