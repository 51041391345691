import { useEffect, useState } from 'react';
import { FormControl, TextField } from '@mui/material';
import { useAppSelector } from '../../../../store/hooks';
import { selectAddingTemplate } from '../../../../store/qasSummareSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { colorPrimary } from '../../../../constants/colors';
import { RequestStatus } from '../../../../types/statusTypes';
import { IRulesBlockProps } from './RulesBlock.props';

const RulesBlock = ({ chapter: initialChapter, desc: initialDesc, index, dataRules, setDataRules }: IRulesBlockProps): JSX.Element => {
	const [chapter, setChapter] = useState<string>(''); // раздел
	const [desc, setDesc] = useState<string>(''); // описание

	const addingTemplateStatus = useAppSelector(selectAddingTemplate); // store - статус добавления шаблона

	const translate = useTranslate(); // hook для перевода текста

	// следим за начальными состояниями и вписываем
	useEffect(() => {
		setChapter(initialChapter);
		setDesc(initialDesc);
	}, [initialChapter, initialDesc]);

	// следим за значениями
	useEffect(() => {
		// если заполнены оба поля у последней пары - добавляем новую пару
		if (desc !== '' && chapter !== '' && dataRules.length - 1 === index) {
			setDataRules(prev => ([...prev, ['', '', Math.random()]]));
		}
		// если не заполнено хотя бы одно поле у предпоследней пары - удаляем последнюю пару, если там пусто
		if ((desc === '' || chapter === '') && dataRules.length - 2 === index && dataRules[dataRules.length - 1][0] === '' && dataRules[dataRules.length - 1][1] === '') {
			setDataRules(prev => {
				prev.pop();
				return [...prev];
			});
		}
		// если пусты оба поля и блок не последний и не первый - удаляем
		if (desc === '' && chapter === '' && dataRules.length - 1 !== index && dataRules.length > 1) {
			setDataRules(prev => {
				prev.splice(index, 1);
				return [...prev];
			});
		}
	}, [desc, chapter]);

	return (
		<>
			{/* раздел */}
			<FormControl margin='dense'>
				<TextField
					variant="outlined"
					placeholder={translate('Раздел')}
					value={chapter}
					onChange={(e) => setChapter(e.target.value)}
					onBlur={(e) => setDataRules(prev => {
						prev[index][0] = e.target.value;
						return [...prev];
					})}
					disabled={addingTemplateStatus.status === RequestStatus.LOADING}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '0 14px' },
					}}
				/>
			</FormControl>
			{/* описание */}
			<FormControl fullWidth margin='dense'>
				<TextField
					variant="outlined"
					placeholder={translate('Описание')}
					value={desc}
					onChange={(e) => setDesc(e.target.value)}
					onBlur={(e) => setDataRules(prev => {
						prev[index][1] = e.target.value;
						return [...prev];
					})}
					disabled={addingTemplateStatus.status === RequestStatus.LOADING}
					InputProps={{
						style: {
							height: 33,
							fontSize: 13,
							color: colorPrimary,
						},
					}}
					InputLabelProps={{
						style: {
							fontSize: 13,
						},
					}}
					sx={{
						'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
						'.MuiInputBase-input': { padding: '0 14px' },
					}}
				/>
			</FormControl>
		</>
	);
};

export default RulesBlock;
