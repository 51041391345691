import { ChangeEvent, useEffect, useState } from 'react';
import { Autocomplete, Button, Checkbox, Fade, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, Slide, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faComment, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addDataElem, changeForEndpointRunOnly, clearDataElem, deleteDataElem, editDataElem, editDataElemNameInList, editDataElemRType, editDataElemRTypeInList, replaceDataElemActionList, selectDataElem, selectEditingData } from '../../store/sesSlice';
import { selectActiveRobotId, selectActiveRobotVersion, } from '../../store/sesRobotSlice';
import { selectScriptList } from '../../store/sesScriptSlice';
import { clearModel, getInfoModel, selectFullModel } from '../../store/modelSlice';
import { clearClusterServer, getClusterServerModels, selectClusterServer } from '../../store/serverSlice';
import { selectChannelList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { MODEL, SEE, SERVER, SES, SMC } from '../../constants/accessRights';
import { DATA_ELEM_EVENT_LIST, DATA_ELEM_TYPE_LIST, ENPOINT_TYPES_LIST } from '../../constants/robotConfigLists';
import { colorPrimary, colorSecondary } from '../../constants/colors';
import { DataElemActionEventType, DataElemRType } from '../../types/sesTypes';
import { DataElemType } from '../../types/cloudTypes';
import { RequestStatus, ResponseStatus } from '../../types/statusTypes';
import SlaveData from '../SlaveData/SlaveData';
import FormAddingSlaveData from '../Forms/FormAddingSlaveData/FormAddingSlaveData';
import ManualCheckModal from '../ManualCheck/ManualCheckModal/ManualCheckModal';
import ActionEvent from '../ActionEvent/ActionEvent';
import FormAddingAction from '../Forms/FormAddingAction/FormAddingAction';
import AlertDialog from '../AlertDialog/AlertDialog';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import { IConfigDataElemProps } from './ConfigDataElem.props';
import styles from './ConfigDataElem.module.scss';

const ConfigDataElem = ({ showDataElem, setShowDataElem }: IConfigDataElemProps): JSX.Element => {
	const [inputDataName, setInputDataName] = useState<string>(''); // название элемента данных
	const [selectType, setSelectType] = useState<DataElemType>('smc'); // тип
	const [selectRType, setSelectRType] = useState<DataElemRType>('report'); // подтип

	const [showComment, setShowComment] = useState<boolean>(false); // показ комментария
	const [inputComment, setInputComment] = useState<string>(''); // комментарий

	const [inputModelName, setInputModelName] = useState<string>(''); // название модели
	const [modelsList, setModelsList] = useState<string[]>([]); // список моделей
	const [inputClassname, setInputClassname] = useState<string>(''); // класс/сущность/параметр (param)
	const [classnamesList, setClassnamesList] = useState<string[]>([]); // список классов
	const [inputWeight, setInputWeight] = useState<number>(-1); // вес

	const [forEndpointRunOnlyFlg, setForEndpointRunOnlyFlg] = useState<boolean>(false); // флаг использования элемента данных только для конечной точки
	const [slaveData, setSlaveData] = useState<string[]>([]); // требуемые данные
	const [selectEvent, setSelectEvent] = useState<DataElemActionEventType>('alone'); // событие
	const [inputChannel, setInputChannel] = useState<string>('default'); // канал

	const [changeFlg, setChangeFlg] = useState<{ thisIs: boolean, listOfChanges: string[] }>({ thisIs: false, listOfChanges: [] }); // флаг, уведомляющий об изменении данных и возможности сохранить эти изменения
	const [showAlertDialogDel, setShowAlertDialogDel] = useState<boolean>(false); // показ диалогового окна при удалении элемента данных
	const [showManualCheck, setShowManualCheck] = useState<boolean>(false); // показ вкладки ручной проверки модели

	const dispatch = useAppDispatch();
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота
	const dataElem = useAppSelector(selectDataElem); // store - элемент данных
	const editingDataElem = useAppSelector(selectEditingData); // store - статус изменения элемента данных
	const clusterServer = useAppSelector(selectClusterServer); // store - список моделей на сервере cluster
	const fullModel = useAppSelector(selectFullModel); // store - модель
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за открытием вкладки
	useEffect(() => {
		// при закрытии
		if (!showDataElem) {
			dispatch(clearDataElem()); // очищаем данные элемента
			dispatch(clearClusterServer()); // очищаем список моделей серверов обработки
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
			// очищаем поля
			setInputDataName('');
			setSelectType('smc');
			setSelectRType('report');
			setShowComment(false);
			setInputComment('');
			setInputModelName('');
			setModelsList([]);
			setForEndpointRunOnlyFlg(false);
			setSlaveData([]);
			setInputWeight(-1);
			setInputClassname('');
			setClassnamesList([]);
			setSelectEvent('alone');
			setInputChannel('default');
		}
	}, [showDataElem]);

	// следим за получением данных элемента
	useEffect(() => {
		// если есть данные - вписываем в поля
		if (dataElem.element) {
			!inputDataName && setInputDataName(dataElem.element.name);
			setSelectType(dataElem.element.type);
			setSelectRType(dataElem.element.rtype);
			!inputComment && dataElem.element.comment && setInputComment(dataElem.element.comment);
			!inputModelName && setInputModelName(dataElem.element.model);
			!inputClassname && setInputClassname(dataElem.element.param);
			inputWeight === -1 && setInputWeight(dataElem.element.weight);
			setForEndpointRunOnlyFlg(dataElem.element.forEndpointRunOnly);
			setSlaveData(dataElem.element.slaveData);
			if (dataElem.element && isAccess(SERVER.MODEL_LIST) && clusterServer.data === null && dataElem.element.type !== 'script') {
				dispatch(getClusterServerModels({ serviceType: dataElem.element.type })); // получаем список моделей, если указан тип
			}
		}
	}, [dataElem.element]);

	// следим за статусом получения продовых моделей с серверов cluster
	useEffect(() => {
		// если нет ошибок и есть данные
		if (clusterServer.status === RequestStatus.IDLE && clusterServer.data !== null && typeof clusterServer.data !== 'string' && Object.keys(clusterServer.data).length > 0) {
			const clusterServers = Object.keys(clusterServer.data); // пишем сервера cluster'а
			const arrModelNames: string[] = [];
			clusterServers.forEach(server => {
				clusterServer.data && clusterServer.data[server]?.forEach(modelName => {
					!arrModelNames.includes(modelName) && arrModelNames.push(modelName); // оставляем все уникальные модели
				});
			});
			setModelsList(arrModelNames); // пишем список моделей в state
		}
	}, [clusterServer]);

	// следим за выбором события
	useEffect(() => {
		// если есть событие и в нем отсутствует выбранный канал - выбираем первый из возможных
		if (dataElem.element && dataElem.element.actions[selectEvent] && !dataElem.element.actions[selectEvent][inputChannel]) {
			setInputChannel(Object.keys(dataElem.element.actions[selectEvent])[0]);
		}
		// если нет события - выбираем default канал
		else if (dataElem.element && !dataElem.element.actions[selectEvent]) {
			setInputChannel('default');
		}
	}, [selectEvent]);

	// следим за полем названия модели и списком моделей
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода названия модели
		const handler = setTimeout(() => {
			// если в списке моделей есть имя модели и есть тип сервиса
			if (modelsList.includes(inputModelName) && selectType !== 'script') {
				dispatch(clearModel()); // очищаем данные модели
				isAccess(MODEL.INFO) && dispatch(getInfoModel({ modelName: inputModelName.replace(/(-new)|(-planned)/, ''), serviceType: selectType })); // получаем инфо о модели
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод названия модели
		};
	}, [inputModelName, modelsList]);

	// следим за данными модели
	useEffect(() => {
		// если в списке моделей есть имя модели
		if (modelsList.includes(inputModelName)) {
			// пишем классы модели в зависимости от выбранного типа
			if (inputModelName.includes('-new') || inputModelName.includes('-planned')) setClassnamesList(fullModel.fullModel.future?.classes || []);
			else setClassnamesList(fullModel.fullModel.current?.classes || []);
		}
	}, [fullModel.fullModel]);

	// следим за статусом сохранения элемента данных
	useEffect(() => {
		// если изменение элемента данных прошло успешно
		if (editingDataElem.status === RequestStatus.IDLE && editingDataElem.error === ResponseStatus.SUCCESS && editingDataElem.message !== '') {
			if (dataElem.element) {
				changeFlg.listOfChanges.includes('name') && dispatch(editDataElemNameInList({ dataElemId: dataElem.element.id, dataElemName: inputDataName })); // если изменилось имя - меняем в store
				changeFlg.listOfChanges.includes('rtype') && dispatch(editDataElemRTypeInList({ dataElemId: dataElem.element.id, dataElemRType: selectRType })); // если изменился подтип - меняем в store
			}
			setChangeFlg({ thisIs: false, listOfChanges: [] }); // сбрасываем флаг о несохраненных данных
		}
	}, [editingDataElem]);

	// обработчик изменения подтипа
	const changeRTypeHandler = (e: SelectChangeEvent<"report" | "service">): void => {
		setSelectRType(e.target.value as DataElemRType);
		dispatch(editDataElemRType(e.target.value as DataElemRType));
		dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('rtype')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'rtype'] }));  // ставим флаг о несохраненных данных;
	};

	// обработчик изменения флага использования только для конечной точки
	const changeForEndpointRunOnlyFlgHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setForEndpointRunOnlyFlg(e.target.checked);
		dispatch(changeForEndpointRunOnly(e.target.checked));
		dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('forEndpointRunOnly')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'forEndpointRunOnly'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик добавления элемента данных
	const addDataElemHandler = (): void => {
		activeRobotId && dispatch(addDataElem({
			robotId: activeRobotId,
			data: {
				name: inputDataName,
				type: selectType,
				model: inputModelName,
				param: inputClassname,
				actions: dataElem.element?.actions || {},
				forEndpointRunOnly: forEndpointRunOnlyFlg,
				slaveData,
				weight: inputWeight,
				rtype: selectRType,
				comment: inputComment,
			}
		})); // добавляем элемент
	};

	// обработчик сохранения элемента данных
	const editDataElemHandler = (): void => {
		dataElem.element && activeRobotId && dispatch(editDataElem({
			robotId: activeRobotId,
			dataId: dataElem.element.id,
			data: {
				name: changeFlg.listOfChanges.includes('name') ? inputDataName : undefined,
				type: changeFlg.listOfChanges.includes('type') ? selectType : undefined,
				model: changeFlg.listOfChanges.includes('model') ? inputModelName : undefined,
				param: changeFlg.listOfChanges.includes('param') ? inputClassname : undefined,
				actions: changeFlg.listOfChanges.includes('actions') ? dataElem.element.actions : undefined,
				forEndpointRunOnly: changeFlg.listOfChanges.includes('forEndpointRunOnly') ? forEndpointRunOnlyFlg : undefined,
				slaveData: changeFlg.listOfChanges.includes('slaveData') ? slaveData : undefined,
				weight: changeFlg.listOfChanges.includes('weight') ? inputWeight : undefined,
				rtype: changeFlg.listOfChanges.includes('rtype') ? selectRType : undefined,
				comment: changeFlg.listOfChanges.includes('comment') ? inputComment : undefined,
			}
		})); // изменяем элемент
	};

	// обработчик удаления элемента данных
	const deleteDataElemHandler = (): void => {
		setShowAlertDialogDel(false); // закрываем диалоговое окно
		dataElem.element && activeRobotId && dispatch(deleteDataElem({ robotId: activeRobotId, dataId: dataElem.element.id })); // удаление элемента данных
		closeHandler(true); // закрываем вкладку
	};

	// обработчик перемещения действий
	const moveAction = (dragIndex: number, hoverIndex: number) => {
		if (dataElem.element) {
			dispatch(replaceDataElemActionList({
				actionEvent: selectEvent,
				channel: inputChannel,
				actionList: update(dataElem.element.actions[selectEvent][inputChannel], {
					$splice: [
						[dragIndex, 1],
						[hoverIndex, 0, dataElem.element.actions[selectEvent][inputChannel][dragIndex]],
					],
				}),
			})); // изменение последовательности действий
			dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] })); // ставим флаг о несохраненных данных
		}
	};

	// обработчик закрытия вкладки
	const closeHandler = (force: boolean = false): void => {
		// если нет изменений или принудительное закрытие
		(!changeFlg.thisIs || force) && setShowDataElem(false);
	};

	return (
		<Slide direction="up" in={showDataElem} mountOnEnter unmountOnExit>
			<div className={cn(styles.modal, {
				[styles.modalDarkBackground]: changeFlg.thisIs, // темный фон, когда есть изменения
			})} onMouseDown={() => closeHandler()}>
				<div className={styles.data} onMouseDown={(e) => e.stopPropagation()}>

					{/* загрузка элемента данных */}
					{dataElem.status === RequestStatus.LOADING &&
						<div className={styles.loading}>
							<ProgressCircle title={translate('spinnerTitle_loading')} />
						</div>
					}

					{/* ошибка получения элемента данных */}
					{(dataElem.status === RequestStatus.FAILED || dataElem.error === ResponseStatus.FAILED) &&
						<div className={styles.failed}>
							{translate(dataElem.message || 'title_loadFailed')}
						</div>
					}

					{/* элемент данных */}
					{dataElem.element &&
						<Fade in={true} timeout={500}>
							<div className={styles.dataWrapper}>
								<div className={styles.dataTop}>
									<div className={styles.dataTopMain}>
										<div className={styles.dataTopMainBlock}>
											{/* название */}
											<FormControl fullWidth margin='dense'>
												<TextField
													required
													label={translate('input_name')}
													variant="outlined"
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													value={inputDataName}
													onChange={(e) => {
														setInputDataName(e.target.value);
														dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('name')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'name'] }));  // ставим флаг о несохраненных данных
													}}
													InputProps={{
														style: {
															height: 33,
															fontSize: 13,
															color: colorPrimary
														},
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											</FormControl>

											{/* тип */}
											<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, flexShrink: 0, '.MuiSelect-select': { paddingBlock: 0 }, }}>
												<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
												<Select
													required
													label={translate('select_type')}
													value={selectType}
													onChange={(e) => setSelectType(e.target.value as DataElemType)}
													disabled
													style={{ fontSize: 13, height: 33, color: colorPrimary }}
												>
													{DATA_ELEM_TYPE_LIST.map(({ type, translation }) =>
														<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
													)}
												</Select>
											</FormControl>

											{/* подтип */}
											<FormControl margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, flexShrink: 0, '.MuiSelect-select': { paddingBlock: 0 }, }}>
												<InputLabel sx={{ fontSize: 13 }}>{translate('select_subtype')}</InputLabel>
												<Select
													label={translate('select_subtype')}
													value={selectRType}
													onChange={changeRTypeHandler}
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													style={{ fontSize: 13, height: 33, color: colorPrimary }}
												>
													{ENPOINT_TYPES_LIST.filter(typeItem => typeItem.type === 'report' || typeItem.type === 'service').map(({ type, translation }) =>
														<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
													)}
												</Select>
											</FormControl>

											{/* кнопка показа комментария */}
											<FontAwesomeIcon
												icon={inputComment ? faCommentDots : faComment}
												color={inputComment ? colorSecondary : colorPrimary}
												size="2xl"
												onClick={() => setShowComment(prev => !prev)}
												title={translate(showComment ? 'buttonTitle_hideComment' : 'buttonTitle_showComment')}
												style={{ cursor: 'pointer' }}
											/>
										</div>

										{/* комментарий */}
										{showComment &&
											<FormControl fullWidth margin='dense'>
												<TextField
													multiline
													maxRows={3}
													label={translate('input_comment')}
													variant="outlined"
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													value={inputComment}
													onChange={(e) => {
														setInputComment(e.target.value);
														dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('comment')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'comment'] }));  // ставим флаг о несохраненных данных
													}}
													InputProps={{
														style: {
															padding: '8px 13px',
															fontSize: 13,
															color: colorPrimary,
														},
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											</FormControl>
										}

										<div className={styles.dataTopMainBlock}>
											{selectType === 'script' ?
												// скрипт интеграции
												<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
													<InputLabel sx={{ fontSize: 13 }}>{translate('select_integrationScript')}</InputLabel>
													<Select
														required
														label={translate('select_integrationScript')}
														disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
														value={inputModelName}
														onChange={e => {
															setInputModelName(e.target.value);
															dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('model')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'model'] }));  // ставим флаг о несохраненных данных
														}}
														style={{ fontSize: 13, height: 33, color: colorPrimary }}
													>
														{scriptList.data.map(({ id, name }) =>
															<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
														)}
													</Select>
												</FormControl>
												:
												// модель
												<FormControl fullWidth margin='dense'>
													<Autocomplete
														freeSolo
														options={modelsList}
														value={inputModelName}
														onChange={(_, value) => {
															setInputModelName(value ? value : '');
															dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('model')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'model'] }));  // ставим флаг о несохраненных данных
														}}
														disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
														renderInput={(params) =>
															<TextField
																{...params}
																label={translate('input_model')}
																onChange={(e) => {
																	setInputModelName(e.target.value);
																	dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('model')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'model'] }));  // ставим флаг о несохраненных данных
																}}
																InputLabelProps={{
																	style: {
																		fontSize: 13,
																	},
																}}
																InputProps={{
																	...params.InputProps, // важно прокинуть параметры
																	startAdornment: (
																		<div style={{ marginTop: '-7px' }}>
																			{/* тестирование модели */}
																			{clusterServer.status === RequestStatus.IDLE && inputModelName !== '' &&
																				((selectType === 'smc' && (isAccess(SMC.CLASSIFY) || isAccess(SMC.COMPRESS) || isAccess(SMC.EMOTION) || isAccess(SMC.PUNCTUATE) || isAccess(SMC.CORRECT) || isAccess(SMC.NORMALIZE))) || (selectType === 'see' && isAccess(SEE.SEARCH_ENTITIES))) &&
																				<FontAwesomeIcon
																					icon={faCheck}
																					size="lg"
																					onClick={() => setShowManualCheck(prev => !prev)}
																					title={translate('configDataElem_testModelBtn')}
																					style={{ cursor: 'pointer' }}
																				/>
																			}
																		</div>
																	),
																	endAdornment: (
																		<div style={{ marginTop: '-7px' }}>
																			{clusterServer.status === RequestStatus.LOADING &&
																				<ProgressCircle isBtnDisabled />
																			}
																			{params.InputProps.endAdornment} {/* важно дописать параметры */}
																		</div>
																	),
																}}
																sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
															/>
														}
														sx={{
															".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
															".MuiInputBase-input": { marginTop: -1 },
														}}
														getOptionLabel={option => option}
														renderOption={(props, option) => {
															return (
																<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
																	{option}
																</span>
															);
														}}
													/>
												</FormControl>
											}

											{/* класс/сущность/параметр */}
											<FormControl fullWidth margin='dense'>
												<Autocomplete
													freeSolo
													options={classnamesList}
													value={inputClassname}
													onChange={(_, value) => {
														setInputClassname(value ? value : '');
														dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('param')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'param'] }));  // ставим флаг о несохраненных данных
													}}
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													renderInput={(params) =>
														<TextField
															{...params}
															label={translate(selectType === 'smc' ? 'input_class' : selectType === 'see' ? 'input_entity' : 'input_param')}
															onChange={(e) => {
																setInputClassname(e.target.value);
																dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('param')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'param'] }));  // ставим флаг о несохраненных данных
															}}
															InputLabelProps={{
																style: {
																	fontSize: 13,
																},
															}}
															InputProps={{
																...params.InputProps, // важно прокинуть параметры
																endAdornment: (
																	<div style={{ marginTop: '-7px' }}>
																		{fullModel.status === RequestStatus.LOADING &&
																			<ProgressCircle isBtnDisabled />
																		}
																		{params.InputProps.endAdornment} {/* важно дописать параметры */}
																	</div>
																),
															}}
															sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
														/>
													}
													sx={{
														".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
														".MuiInputBase-input": { marginTop: -1 },
													}}
													getOptionLabel={option => option}
													renderOption={(props, option) => {
														return (
															<span {...props} style={{ fontSize: 13, color: colorPrimary, textAlign: 'left' }}>
																{option}
															</span>
														);
													}}
												/>
											</FormControl>

											{/* вес */}
											<FormControl margin='dense' sx={{ flexShrink: 0 }}>
												<TextField
													label={translate('input_weight')}
													variant="outlined"
													type='number'
													value={inputWeight}
													onChange={(e) => {
														setInputWeight(+e.target.value);
														dataElem.element?.id && (!changeFlg.thisIs || !changeFlg.listOfChanges.includes('weight')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'weight'] }));  // ставим флаг о несохраненных данных
													}}
													disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
													InputProps={{
														style: {
															height: 33,
															fontSize: 13,
															color: colorPrimary,
														},
														inputProps: { min: 0, max: 9 }
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											</FormControl>
										</div>

										{/* требуемые данные (slaveData) */}
										{selectType === 'script' && (activeRobotVersion === 'draft' || dataElem.element.slaveData.length > 0) &&
											<fieldset className={styles.dataTopMainFieldset}>
												<legend>{translate('title_requiredData')}</legend>
												{dataElem.element.slaveData.map((element, idx) => (
													<SlaveData key={element + idx} slaveData={element} idx={idx} changeFlg={changeFlg} setChangeFlg={setChangeFlg} />
												))}
												{isAccess(SES.DATA_EDIT) && activeRobotVersion === 'draft' &&
													<FormAddingSlaveData changeFlg={changeFlg} setChangeFlg={setChangeFlg} />
												}
											</fieldset>
										}

										{/* только для запуска конечной точки */}
										<FormControlLabel sx={{ overflow: 'hidden', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
											<Checkbox
												checked={forEndpointRunOnlyFlg}
												disabled={!isAccess(SES.DATA_EDIT) || activeRobotVersion !== 'draft'}
												onChange={changeForEndpointRunOnlyFlgHandler}
												size='small'
											/>
										} label={translate('checkbox_forEndpointRunOnly')} />
									</div>

									<div className={styles.dataTopEvents}>
										<h3 className={styles.dataTopEventsTitle}>{translate('title_events')}</h3>

										<div className={styles.dataTopEventsBlock}>
											{/* событие */}
											<FormControl fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
												<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
												<Select
													label={translate('select_type')}
													value={selectEvent}
													onChange={(e) => setSelectEvent(e.target.value as DataElemActionEventType)}
													style={{ fontSize: 13, height: 33, color: colorPrimary }}
												>
													{DATA_ELEM_EVENT_LIST.map(({ event, translation }) =>
														<MenuItem key={event} value={event} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
													)}
												</Select>
											</FormControl>

											{/* канал */}
											<FormControl fullWidth>
												<Autocomplete
													freeSolo={isAccess(SES.DATA_EDIT) && activeRobotVersion === 'draft'}
													options={Array.from(
														new Set(
															(Object.keys(dataElem.element.actions[selectEvent] || {}))
																.concat(channelList.data.map(channel => channel.name), 'default')
														))}
													value={inputChannel}
													onChange={(_, value) => setInputChannel(value ? value : '')}
													noOptionsText={<div className={styles.dataTopEventsNoDataTitle}>{translate('title_notFound')}</div>}
													renderInput={(params) =>
														<TextField
															{...params}
															label={translate('input_channel')}
															onChange={(e) => isAccess(SES.DATA_EDIT) && setInputChannel(e.target.value)}
															InputLabelProps={{
																style: {
																	fontSize: 13,
																},
															}}
															sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
														/>
													}
													sx={{
														".MuiInputBase-root": { height: 33, fontSize: 13, color: colorPrimary },
														".MuiInputBase-input": { marginTop: -1 },
													}}
													getOptionLabel={option => option}
													renderOption={(props, option) => {
														return (
															<span {...props} style={{ fontSize: 13, color: colorPrimary }}>
																{option}
															</span>
														);
													}}
												/>
											</FormControl>
										</div>

										{/* действия */}
										<div className={styles.dataTopEventsActions}>
											<DndProvider backend={HTML5Backend}>
												{dataElem.element.actions[selectEvent] && dataElem.element.actions[selectEvent][inputChannel]?.length > 0 && dataElem.element.actions[selectEvent][inputChannel].map((action, idx) => (
													<ActionEvent
														key={`${action.action}${idx}${action.action === 'say' && action.type + (action.type === 'external' ? action.script : action.type === 'internal' ? action.service : action.text.toString())}${action.action === 'transfer' && action.destination}`}
														action={action}
														idx={idx}
														channel={inputChannel}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
														actionFor={{
															for: 'dataElement',
															event: selectEvent,
														}}
														moveAction={moveAction}
													/>
												))}
											</DndProvider>

											{isAccess(SES.DATA_EDIT) && activeRobotVersion === 'draft' &&
												<FormAddingAction
													channel={inputChannel}
													changeFlg={changeFlg}
													setChangeFlg={setChangeFlg}
													actionFor={{
														for: 'dataElement',
														event: selectEvent,
													}}
												/>
											}
										</div>
									</div>
								</div>

								{activeRobotVersion === 'draft' &&
									<div className={styles.dataButtons}>
										{dataElem.element.id ?
											<>
												{/* сохранить */}
												{isAccess(SES.DATA_EDIT) &&
													<FormControl fullWidth>
														<Button
															variant="outlined"
															disabled={!changeFlg.thisIs}
															sx={{ fontSize: 11 }}
															onClick={editDataElemHandler}
														>
															{translate('button_save')}
														</Button>
													</FormControl>
												}
												{/* закрыть */}
												<FormControl fullWidth>
													<Button
														variant="outlined"
														sx={{ fontSize: 11 }}
														onClick={() => closeHandler(true)}
													>
														{translate('button_close')}
													</Button>
												</FormControl>
												{/* удалить */}
												{isAccess(SES.DATA_DELETE) &&
													<FormControl fullWidth>
														<Button
															variant="outlined"
															sx={{ fontSize: 11 }}
															onClick={() => setShowAlertDialogDel(true)}
															color='error'
														>
															{translate('button_delete')}
														</Button>
													</FormControl>
												}
											</>
											:
											// добавить
											isAccess(SES.DATA_ADD) &&
											<FormControl fullWidth>
												<Button
													variant="outlined"
													sx={{ fontSize: 11 }}
													onClick={addDataElemHandler}
												>
													{translate('button_addElement')}
												</Button>
											</FormControl>
										}
									</div>
								}
							</div>
						</Fade>
					}

					{selectType !== 'script' &&
						<ManualCheckModal
							showManualCheck={showManualCheck}
							setShowManualCheck={setShowManualCheck}
							serviceType={selectType}
							modelName={inputModelName}
							modelNameAsIs
						/>
					}

					<AlertDialog
						showAlertDialog={showAlertDialogDel}
						setShowAlertDialog={setShowAlertDialogDel}
						submitHandler={deleteDataElemHandler}
						title='dialog_deleteDataElem'
						description='dialog_deleteDataElemConfirm'
						name={dataElem.element?.name}
					/>
				</div>
			</div>
		</Slide>
	);
};

export default ConfigDataElem;
