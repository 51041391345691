import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { clearCreation, clearModify, selectCreation, selectModify } from '../../../../store/transcriptionSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import FormCreateTranscript from '../../../Forms/FormCreateTranscript/FormCreateTranscript';
import Notification from '../../../Notification/Notification';
import { INewTranscriptProps } from './NewTranscript.props';

const NewTranscript = ({ isAvailable }: INewTranscriptProps): JSX.Element => {
	const [showModalCreateTranscript, setShowModalCreateTranscript] = useState<boolean>(false); // показ формы для создания стенограммы
	const [showNotificationCreate, setShowNotificationCreate] = useState<boolean>(false); // показ уведомления о создании
	const [showNotificationModify, setShowNotificationModify] = useState<boolean>(false); // показ уведомления о замене

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<button
				onClick={() => setShowModalCreateTranscript(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_createTranscript')}>
				<FontAwesomeIcon icon={faFileCirclePlus} size="xl" color={backgroundColor} />
			</button>

			{showModalCreateTranscript &&
				<FormCreateTranscript
					showModal={showModalCreateTranscript}
					setShowModal={setShowModalCreateTranscript}
					setShowNotificationCreate={setShowNotificationCreate}
					setShowNotificationModify={setShowNotificationModify}
				/>
			}

			{showNotificationCreate &&
				<Notification
					showNotification={showNotificationCreate}
					setShowNotification={setShowNotificationCreate}
					selectDataResponse={selectCreation}
					clearDataResponse={clearCreation}
					titleFailed='noticeAddition_failed'
					titleSuccess='noticeAddition_success'
				/>
			}
			{showNotificationModify &&
				<Notification
					showNotification={showNotificationModify}
					setShowNotification={setShowNotificationModify}
					selectDataResponse={selectModify}
					clearDataResponse={clearModify}
					titleFailed='noticeModification_failed'
					titleSuccess='noticeModification_success'
				/>
			}
		</>
	);
};

export default NewTranscript;
