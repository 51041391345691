import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Switch, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearReplaceInQuestion, getStatusProcessingQuestions, processQuestions, replaceTextInAllAnswers, selectCategoriesList, selectChannelList, selectGptList, selectProcessingQuestionsStartStatus, selectReplaceInQuestion } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { colorPrimary } from '../../../constants/colors';
import { CachingType } from '../../../types/qasTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormProcessingQuestionsProps } from './FormProcessingQuestions.props';
import styles from './FormProcessingQuestions.module.scss';

const FormProcessingQuestions = ({ showModal, setShowModal, setShowNotification, questionUpdateHandler }: IFormProcessingQuestionsProps): JSX.Element => {
	const [switchChecked, setSwitchChecked] = useState<boolean>(false); // переключатель выбора формата данных (false - text, true - json)
	const [file, setFile] = useState<File>(); // текстовый файл с вопросами
	const [selectCategoryId, setSelectCategoryId] = useState<string>(''); // id категории
	const [selectChannelId, setSelectChannelId] = useState<string>(''); // id канала
	const [selectCaching, setSelectCaching] = useState<CachingType>('all'); // кеширование
	const [checkGPT, setCheckGPT] = useState<boolean>(true); // использование gpt
	const [checkCreative, setCheckCreative] = useState<boolean>(false); // использование творческого режима
	const [addAlias, setAddAlias] = useState<boolean>(true); // добавление вопроса в похожие в кэше, если похожая формулировка найдена
	const [thresholdFlg, setThresholdFlg] = useState<boolean>(false); // флаг порога похожести
	const [thresholdValue, setThresholdValue] = useState<number>(90); // значение порога похожести
	const [selectModelGPT, setSelectModelGPT] = useState<string>('default'); // модель gpt

	const [inputSearch, setInputSearch] = useState<string>(''); // текст для поиска
	const [inputReplacement, setInputReplacement] = useState<string>(''); // текст для замены

	const dispatch = useAppDispatch();
	const startProcessingQuestionsStatus = useAppSelector(selectProcessingQuestionsStartStatus); // store - статус запуска массовой обработки вопросов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const channelList = useAppSelector(selectChannelList); // store - список каналов
	const modelGptList = useAppSelector(selectGptList); // store - список моделей gpt
	const replaceInQuestion = useAppSelector(selectReplaceInQuestion); // store - данные замены текста в ответах

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	const [tab, setTab] = useState<'loading' | 'replacement'>(isAccess([QAS.PROCESSING_QUESTIONS_START, QAS.PROCESSING_QUESTIONS_STOP, QAS.PROCESSING_QUESTIONS_STATUS]) ? 'loading' : 'replacement'); // табы

	// следим за checkbox'ом gpt
	useEffect(() => {
		!checkGPT && setCheckCreative(false); // если убран gpt - убираем творческий режим
	}, [checkGPT]);

	// следим за статусом массовой обработки вопросов
	useEffect(() => {
		if (startProcessingQuestionsStatus.error !== ResponseStatus.SUCCESS || startProcessingQuestionsStatus.status === RequestStatus.FAILED || startProcessingQuestionsStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification(true); // включаем уведомление
		}
		// если успешно - запрашиваем статус процесса генерации
		if (startProcessingQuestionsStatus.error === ResponseStatus.SUCCESS && startProcessingQuestionsStatus.message !== '') {
			isAccess(QAS.PROCESSING_QUESTIONS_STATUS) && dispatch(getStatusProcessingQuestions());
		}
	}, [startProcessingQuestionsStatus]);

	// следим за данными замены ответов
	useEffect(() => {
		// если заменили текст
		if (typeof replaceInQuestion.replacements === 'number' && replaceInQuestion.replacements > 0) {
			questionUpdateHandler && questionUpdateHandler(); // обновляем текущий вопрос
		}
	}, [replaceInQuestion.replacements]);

	// обработчик массовой обработки вопросов
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (tab === 'loading') {
			const formData = new FormData();
			if (file) {
				formData.append('file', file);
				dispatch(processQuestions({
					formData,
					categoryId: selectCategoryId ? selectCategoryId : undefined,
					channel: selectChannelId ? selectChannelId : undefined,
					caching: selectCaching,
					gpt: checkGPT ? 'yes' : 'no',
					creative: checkCreative ? 'yes' : 'no',
					addAlias: addAlias ? 'yes' : 'no',
					threshold: thresholdFlg ? thresholdValue : undefined,
					modelGpt: selectModelGPT,
					format: switchChecked ? 'json' : 'text',
				})); // массовая обработка вопросов
			}
		} else {
			dispatch(clearReplaceInQuestion()); // очистка данных замены ответов
			dispatch(replaceTextInAllAnswers({ categoryId: selectCategoryId, search: inputSearch, replace: inputReplacement })); // замена текста во всех ответах
		}
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет отправка - запрещаем покидать форму
		if (startProcessingQuestionsStatus.status === RequestStatus.LOADING || replaceInQuestion.status === RequestStatus.LOADING) return;
		dispatch(clearReplaceInQuestion()); // очистка данных замены ответов
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_processingQuestions' close={handleClose}>
			<form onSubmit={submitHandler}>
				{(isAccess([QAS.PROCESSING_QUESTIONS_START, QAS.PROCESSING_QUESTIONS_STOP, QAS.PROCESSING_QUESTIONS_STATUS]) && isAccess(QAS.QUESTION_REPLACE)) &&
					<ButtonGroup fullWidth sx={{ marginBottom: '8px' }}>
						<Button variant={tab === 'loading' ? "contained" : "outlined"} sx={{ overflow: 'hidden', fontSize: 11 }} onClick={() => setTab('loading')}>{translate('button_loading')}</Button>
						<Button variant={tab === 'replacement' ? "contained" : "outlined"} sx={{ overflow: 'hidden', fontSize: 11 }} onClick={() => setTab('replacement')}>{translate('button_replacement')}</Button>
					</ButtonGroup>
				}
				{tab === 'loading' ?
					<>
						<div className={styles.block}>
							<FormControl fullWidth>
								<TextField
									id="file"
									disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
									variant="outlined"
									type="file"
									required
									onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.files && setFile(e.target.files[0])}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13,
											color: colorPrimary,
										},
										inputProps: { accept: "text/csv, text/plain" }
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
							<div className={styles.switchBlock}>
								{translate('title_text')}
								<Switch
									checked={switchChecked}
									onChange={e => setSwitchChecked(e.target.checked)}
								/>
								{translate('title_json')}
							</div>
						</div>

						{categoriesList.data.length > 0 &&
							<FormControl fullWidth margin='dense' sx={{
								'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
								'.MuiInputBase-input': { padding: '8px 14px' },
							}}>
								<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
								<Select
									labelId="category-label"
									id="category"
									label={translate('select_category')}
									disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
									value={selectCategoryId}
									onChange={(e) => setSelectCategoryId(e.target.value)}
									style={{ fontSize: 13, height: 33, color: colorPrimary }}
								>
									<MenuItem value='' sx={{ fontSize: 13 }}>{translate('selectItem_notSelected')}</MenuItem>
									{categoriesList.data.map((category) => (
										<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						}

						{channelList.data.length > 0 &&
							<FormControl fullWidth margin='dense' sx={{
								'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
								'.MuiInputBase-input': { padding: '8px 14px' },
							}}>
								<InputLabel id="channel-label" sx={{ fontSize: 13 }}>{translate('select_channel')}</InputLabel>
								<Select
									labelId="channel-label"
									id="channel"
									label={translate('select_channel')}
									disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
									value={selectChannelId}
									onChange={(e) => setSelectChannelId(e.target.value)}
									style={{ fontSize: 13, height: 33, color: colorPrimary }}
								>
									{channelList.data.map((channel) => (
										<MenuItem key={channel.id} value={channel.id} sx={{ fontSize: 13, color: colorPrimary }}>{channel.name}</MenuItem>
									))}
								</Select>
							</FormControl>
						}

						<FormControl fullWidth margin='dense' sx={{
							'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
							'.MuiInputBase-input': { padding: '8px 14px' },
						}}>
							<InputLabel id="caching-label" sx={{ fontSize: 13 }}>{translate('select_useAnswersCache')}</InputLabel>
							<Select
								labelId="caching-label"
								id="caching"
								label={translate('select_useAnswersCache')}
								disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
								value={selectCaching}
								onChange={(e) => setSelectCaching(e.target.value as CachingType)}
								style={{ fontSize: 13, height: 33, color: colorPrimary }}
							>
								<MenuItem value='no' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_doNotUse')}</MenuItem>
								<MenuItem value='all' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_anyAnswers')}</MenuItem>
								<MenuItem value='checked' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_onlyCheckedAnswers')}</MenuItem>
							</Select>
						</FormControl>

						{/* модель gpt */}
						<FormControl fullWidth margin='dense' sx={{
							'.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 },
						}}>
							<InputLabel id="modelGpt-label" sx={{ fontSize: 13 }}>{translate('select_generationModel')}</InputLabel>
							<Select
								labelId="modelGpt-label"
								id="modelGpt"
								label={translate('select_generationModel')}
								disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
								value={selectModelGPT}
								onChange={(e) => setSelectModelGPT(e.target.value)}
								style={{ fontSize: 13, height: 33, color: colorPrimary, textAlign: 'left' }}
							>
								<MenuItem value='default' sx={{ fontSize: 13, color: colorPrimary }}>default</MenuItem>
								{modelGptList.data.filter(model => model !== 'default').map((model) => (
									<MenuItem key={model} value={model} sx={{ fontSize: 13, color: colorPrimary }}>{model}</MenuItem>
								))}
							</Select>
						</FormControl>

						<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
							<Checkbox
								checked={checkGPT}
								disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
								onChange={e => setCheckGPT(e.target.checked)}
								size='small'
							/>
						} label={translate('checkbox_useGpt')} />

						<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-15px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
							<Checkbox
								checked={checkCreative}
								disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING || !checkGPT}
								onChange={e => setCheckCreative(e.target.checked)}
								size='small'
							/>
						} label={translate('checkbox_useCreative')} />

						<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
							<Checkbox
								checked={addAlias}
								disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
								onChange={e => setAddAlias(e.target.checked)}
								size='small'
							/>
						} label={translate('checkbox_useCacheAlias')} />

						<FormControlLabel sx={{ overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
							<Checkbox
								checked={thresholdFlg}
								disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING}
								onChange={e => setThresholdFlg(e.target.checked)}
								size='small'
							/>
						} label={translate('checkbox_setTreshold')} />

						<TextField
							variant="outlined"
							type='number'
							disabled={!thresholdFlg}
							value={thresholdValue}
							onChange={(e) => setThresholdValue(+e.target.value)}
							InputProps={{
								style: {
									height: 23,
									fontSize: 13,
									color: colorPrimary,
									marginTop: '6px'
								},
								inputProps: { min: 1, max: 99 }
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</>
					:
					<>
						{/* категория */}
						<FormControl fullWidth margin='dense' sx={{
							'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
							'.MuiInputBase-input': { padding: '8px 14px' },
						}} required>
							<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
							<Select
								labelId="category-label"
								id="category"
								label={translate('select_category')}
								disabled={replaceInQuestion.status === RequestStatus.LOADING}
								value={selectCategoryId}
								onChange={(e) => setSelectCategoryId(e.target.value)}
								style={{ fontSize: 13, height: 33, color: colorPrimary }}
							>
								<MenuItem value='' sx={{ fontSize: 13 }}>{translate('selectItem_notSelected')}</MenuItem>
								{categoriesList.data.map((category) => (
									<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>{category.name}</MenuItem>
								))}
							</Select>
						</FormControl>

						{/* искомый текст */}
						<FormControl fullWidth margin='dense'>
							<TextField
								required
								label={translate("input_textToSearchInAnswers")}
								variant="outlined"
								value={inputSearch}
								onChange={(e) => setInputSearch(e.target.value)}
								disabled={replaceInQuestion.status === RequestStatus.LOADING}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>

						{/* текст для замены */}
						<FormControl fullWidth margin='dense'>
							<TextField
								required
								label={translate("input_replacement")}
								variant="outlined"
								value={inputReplacement}
								onChange={(e) => setInputReplacement(e.target.value)}
								disabled={replaceInQuestion.status === RequestStatus.LOADING}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>

						{replaceInQuestion.replacements !== null &&
							<div className={styles.blockInfo}>{translate('title_replacedOccurrences')}: {replaceInQuestion.replacements}</div>
						}
						{(replaceInQuestion.status === RequestStatus.FAILED || replaceInQuestion.error === ResponseStatus.FAILED) &&
							<div className={styles.blockInfoError}>{translate(replaceInQuestion.message || 'title_errorOccurred')}</div>
						}
					</>
				}

				<div className={styles.block}>
					{/* запуск */}
					<FormControl fullWidth margin='dense'>
						<Button
							variant="outlined"
							type="submit"
							sx={{ fontSize: 11 }}
							disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING || replaceInQuestion.status === RequestStatus.LOADING}
						>
							{translate('button_start')}
							{(startProcessingQuestionsStatus.status === RequestStatus.LOADING || replaceInQuestion.status === RequestStatus.LOADING) &&
								<ProgressCircle isBtnDisabled />
							}
						</Button>
					</FormControl>

					{/* закрыть */}
					{tab === 'replacement' &&
						<FormControl fullWidth margin='dense'>
							<Button
								variant="outlined"
								type="button"
								sx={{ fontSize: 11 }}
								disabled={startProcessingQuestionsStatus.status === RequestStatus.LOADING || replaceInQuestion.status === RequestStatus.LOADING}
								onClick={handleClose}
							>
								{translate('button_close')}
							</Button>
						</FormControl>
					}
				</div>
			</form>
		</ModalFormWindow>
	);
};

export default FormProcessingQuestions;
