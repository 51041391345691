import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearClearDraftingRobot, clearDraftRobot, selectActiveRobotId, selectClearDraftingRobot, selectRobot } from '../../../../store/sesRobotSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import Notification from '../../../Notification/Notification';
import { IClearDraftRobotProps } from './ClearDraftRobot.props';

const ClearDraftRobot = ({ isAvailable }: IClearDraftRobotProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const dispatch = useAppDispatch();
	const robotInfo = useAppSelector(selectRobot); // store - информация о роботе
	const activeRobotId = useAppSelector(selectActiveRobotId); // store - id активного робота

	const translate = useTranslate(); // hook для перевода текста

	// обработчик обнуления черновика робота из прода
	const clearDraftRobotHandler = (): void => {
		if (activeRobotId) {
			setShowNotification(true); // включаем уведомление
			setShowAlertDialog(false); // выключаем диалоговое окно
			dispatch(clearDraftRobot(activeRobotId)); // обнуляем черновик продом
		}
	};

	return (
		<>
			<button
				onClick={() => setShowAlertDialog(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_clearDraft')}>
				<FontAwesomeIcon icon={faReply} size="xl" color={backgroundColor} />
			</button>

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={selectClearDraftingRobot}
					clearDataResponse={clearClearDraftingRobot}
					titleFailed='noticeClearDraft_failed'
					titleSuccess='noticeClearDraft_success'
				/>
			}

			<AlertDialog
				showAlertDialog={showAlertDialog}
				setShowAlertDialog={setShowAlertDialog}
				submitHandler={clearDraftRobotHandler}
				title='dialog_clearDraftRobot'
				description='dialog_сlearDraftRobotConfirm'
				name={robotInfo.data?.name}
			/>
		</>
	);
};

export default ClearDraftRobot;
