import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearDeletion, clearEditing, deleteTranscription, editTranscription, getTranscriptionList, selectDeletion, selectEditing, selectTranscriptionAudio, selectTranscriptionData } from '../../../store/transcriptionSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SERVER, SPR, TRANSCRIPTION } from '../../../constants/accessRights';
import { AUTOSAVE_TRANSCRIPT } from '../../../constants/cookieNames';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import Controls from '../../../HOC/Controls/Controls';
import ToggleColoring from '../Buttons/ToggleColoring/ToggleColoring';
import ToggleAutosave from '../Buttons/ToggleAutosave/ToggleAutosave';
import Delete from '../Buttons/Delete/Delete';
import ExportToDocSpr from '../Buttons/ExportToDocSpr/ExportToDocSpr';
import ReRecognition from '../Buttons/ReRecognition/ReRecognition';
import Metrics from '../Buttons/Metrics/Metrics';
import Save from '../Buttons/Save/Save';
import ScreenLock from '../../ScreenLock/ScreenLock';
import { ITranscriptControlsProps } from './TranscriptControls.props';

const TranscriptControls = ({ changeFlg, setChangeFlg, activeRecordId, setShowPage }: ITranscriptControlsProps): JSX.Element => {
	const [showScreenLock, setShowScreenLock] = useState<{ isShow: boolean, title: string }>({ isShow: false, title: '' }); // показ экрана блокировки и подпись

	const dispatch = useAppDispatch();
	const transcription = useAppSelector(selectTranscriptionData); // store - данные сохранненной записи распознавания
	const audio = useAppSelector(selectTranscriptionAudio); // store - аудио-файл сохраненной записи распознавания
	const deleteStatus = useAppSelector(selectDeletion); // store -  статус удаления стенограммы
	const editStatus = useAppSelector(selectEditing); // store -  статус сохранения стенограммы

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за статусом изменения/удаления стенограммы
	useEffect(() => {
		if (editStatus.status === RequestStatus.LOADING && !editStatus.autosave) setShowScreenLock({ isShow: true, title: 'spinnerTitle_saving' }); // если идет сохранение (не автоматическое)
		else if (deleteStatus.status === RequestStatus.LOADING) setShowScreenLock({ isShow: true, title: 'spinnerTitle_deletion' }); // если идет удаление
		else setShowScreenLock({ isShow: false, title: '' }); // иначе выключаем экран блокировки

		// если сохранилось успешно
		if (editStatus.status === RequestStatus.IDLE && editStatus.error === ResponseStatus.SUCCESS && editStatus.message === 'success') {
			setChangeFlg(false); // выключаем флаг сохранения данных
			editStatus.autosave && dispatch(clearEditing()); // очищаем статус (для режима автосохранения)
		}
		// если удаление прошло успешно - получаем заново список распознаваний
		deleteStatus.status === RequestStatus.IDLE && deleteStatus.error === ResponseStatus.SUCCESS && deleteStatus.message === 'success' && dispatch(getTranscriptionList());
	}, [editStatus, deleteStatus]);

	// обработчик сохранения изменений
	const changeHandler = (): void => {
		dispatch(editTranscription({ id: activeRecordId }));
	};

	// обработчик удаления стенограммы
	const deleteTranscriptHandler = (): void => {
		activeRecordId && dispatch(deleteTranscription(activeRecordId));
	};

	return (
		<>
			<Controls
				header='spr'
				setShowPage={setShowPage}
				leftSection={
					<>
						<div>
							<ToggleColoring
								isAvailable={true}
							/>
						</div>
						<div>
							{isAccess(TRANSCRIPTION.SAVE) &&
								<ToggleAutosave
									isAvailable={true}
									cookieName={AUTOSAVE_TRANSCRIPT}
									changeFlg={changeFlg}
									saveFunction={() => dispatch(editTranscription({ id: activeRecordId, autosave: true }))}
								/>
							}
						</div>
					</>
				}
				rightSection={
					<>
						<div>
							{isAccess([SERVER.MODEL_LIST, SPR.RECOGNITION]) &&
								<ReRecognition
									isAvailable={audio.url !== null}
								/>
							}
							{isAccess(TRANSCRIPTION.METRICS) &&
								<Metrics
									isAvailable={transcription.data !== null && transcription.status !== RequestStatus.LOADING}
								/>
							}
						</div>
						<div>
							{isAccess(TRANSCRIPTION.SAVE) &&
								<Save
									changeFlg={changeFlg}
									dataResponse={selectEditing}
									clearDataResponse={clearEditing}
									submitHandler={changeHandler}
									name={transcription.data && typeof transcription.data === 'object' && 'speakers' in transcription.data ? transcription.data.name : ''}
									dialogTitle='dialog_saveTranscript'
									dialogConfirm='dialog_saveChangesConfirm'
								/>
							}
							<ExportToDocSpr
								isAvailable={transcription.data !== null && 'splitted' in transcription.data && Array.isArray(transcription.data.splitted) && transcription.data.splitted.length > 0}
								fileName={transcription.data && 'name' in transcription.data ? transcription.data.name : 'document'}
								data={{
									type: 'dialog',
									data: (transcription.data !== null && 'splitted' in transcription.data && Array.isArray(transcription.data.splitted) && transcription.data.splitted) || [],
								}}
								speakerList={transcription.speakerList}
							/>
							{isAccess(TRANSCRIPTION.DELETE) &&
								<Delete
									isAvailable={transcription.data !== null && transcription.status !== RequestStatus.LOADING}
									dataResponse={selectDeletion}
									clearDataResponse={clearDeletion}
									submitHandler={deleteTranscriptHandler}
									name={transcription.data && 'name' in transcription.data && transcription.data.name ? transcription.data.name : ''}
									buttonTitle='buttonTitle_deleteTranscript'
									dialogTitle='dialog_deleteTranscript'
									dialogConfirm='dialog_deleteTranscriptConfirm'
								/>
							}
						</div>
					</>
				}
			/>

			{showScreenLock.isShow && <ScreenLock title={translate(showScreenLock.title)} />}
		</>
	);
};

export default TranscriptControls;
