import Controls from '../../../HOC/Controls/Controls';
import { IReportsControlsProps } from './ReportsControls.props';

const ReportsControls = ({ setShowPage }: IReportsControlsProps): JSX.Element => {

	return (
		<Controls
			header='res'
			setShowPage={setShowPage}
		/>
	);
};

export default ReportsControls;
