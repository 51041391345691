import { useEffect, useState } from 'react';
import { Slide } from '@mui/material';
import { useAppSelector } from '../../store/hooks';
import { selectDataServers } from '../../store/serverSlice';
import useTranslate from '../../hooks/useTranslate';
import { IServerStatusProps } from './ServerStatus.props';
import styles from './ServerStatus.module.scss';

const ServerStatus = ({ showServers, setShowServers }: IServerStatusProps): JSX.Element => {
	const [failServers, setFailServers] = useState<string[]>([]); // список сбойных серверов
	const [showWebsocketServers, setShowWebsocketServers] = useState<boolean>(false); // показ вебсокет-серверов

	const dataServers = useAppSelector(selectDataServers); // store - данные о серверах

	const translate = useTranslate(); // hook для перевода текста

	// следим за открытие вкладки
	useEffect(() => {
		if (showServers) document.addEventListener('keydown', keyDownHandler); // вешаем обработчик
		return () => {
			document.removeEventListener('keydown', keyDownHandler); // удаляем обработчик
		};
	}, [showServers]);

	// следим за данными о серверах
	useEffect(() => {
		dataServers && dataServers.fail && setFailServers(Object.keys(dataServers.fail)); // если есть данные - записываем в state сбойные серверы
	}, [dataServers]);

	// обработчик нажатия клавиш
	const keyDownHandler = (e: KeyboardEvent): void => {
		if (e.ctrlKey && e.altKey && e.code === 'KeyW') setShowWebsocketServers(prev => !prev); // вкл/выкл отображение вебсокет-серверов
	};

	// функция получения локального времени сбоя сервера
	const getLocalServerCrashTime = (serverAddress: string): string => {
		if (dataServers) {
			// секунды переводим в милисекунды
			const date = new Date(+(dataServers?.fail[serverAddress]) * 1000).toLocaleString();
			return `${translate('title_failureTime')}: ${date}`;
		}
		return '';
	};

	return (
		<Slide direction="left" in={showServers} mountOnEnter unmountOnExit>
			<div className={styles.modal} onMouseDown={() => setShowServers(false)}>
				<div className={styles.server} onMouseDown={(e) => e.stopPropagation()}>

					<h3 className={styles.serverTitle}>
						<span className={(failServers.length || !dataServers || typeof dataServers === 'string' || !Object.keys(dataServers).length) ? styles.serverStatusFailed : styles.serverStatus}></span>
						{translate('title_servers')}
					</h3>

					<h4 className={styles.serverTrainerTitle}>{translate('title_training')}:</h4>
					<div className={styles.serverItem}>
						{dataServers?.trainer && <span className={failServers.includes(dataServers?.trainer) ? styles.serverStatusFailed : styles.serverStatus}></span>}
						{dataServers?.trainer ? dataServers?.trainer : translate('title_notFound').toLowerCase()}
					</div>
					{failServers.length > 0 && dataServers?.trainer && failServers.includes(dataServers?.trainer) &&
						<p className={styles.serverFailedTime}>{getLocalServerCrashTime(dataServers?.trainer)}</p>
					}

					<h4 className={styles.serverClusterTitle}>{translate('title_processing')}:</h4>
					<ul className={styles.serverListCluster}>
						{dataServers?.cluster && Array.isArray(dataServers.cluster) ?
							dataServers?.cluster.map((server) => (
								<li key={server}>
									<div className={styles.serverItem}>
										<span className={failServers.includes(server) ? styles.serverStatusFailed : styles.serverStatus}></span>
										{server}
									</div>
									{failServers.length > 0 && failServers.includes(server) &&
										<p className={styles.serverFailedTime}>{getLocalServerCrashTime(server)}</p>
									}
								</li>
							))
							:
							<span className={styles.serverItem}>{translate('title_notFound').toLowerCase()}</span>
						}
					</ul>

					{showWebsocketServers &&
						<>
							<h4 className={styles.serverWebsocketTitle}>{translate('title_websocket')}:</h4>
							<ul className={styles.serverListWebsocket}>
								{dataServers?.websockets && Array.isArray(dataServers.websockets) ?
									dataServers?.websockets.map((server) => (
										<li key={server}>
											<div className={styles.serverItem}>
												<span className={failServers.includes(server) ? styles.serverStatusFailed : styles.serverStatus}></span>
												{server}
											</div>
											{failServers.length > 0 && failServers.includes(server) &&
												<p className={styles.serverFailedTime}>{getLocalServerCrashTime(server)}</p>
											}
										</li>
									))
									:
									<span className={styles.serverItem}>{translate('title_notFound').toLowerCase()}</span>
								}
							</ul>
						</>
					}
				</div>

				<div className={styles.tagClose} onClick={() => setShowServers(false)}>
					{translate('tag_close')}
				</div>
			</div>
		</Slide>
	);
};

export default ServerStatus;
