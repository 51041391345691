import cn from 'classnames';
import { useAppSelector } from '../../../store/hooks';
import { selectDataServers } from '../../../store/serverSlice';
import useTranslate from '../../../hooks/useTranslate';
import { ITagProps } from './Tag.props';
import styles from './Tag.module.scss';

const Tag = ({ setShowTag, tagName, serverStatus = false, smallTitleSize = false }: ITagProps): JSX.Element => {
	const dataServers = useAppSelector(selectDataServers); // store - информация о серверах

	const translate = useTranslate(); // hook для перевода текста

	return (
		<div className={styles.tag} onClick={() => setShowTag(prev => !prev)}>
			<div className={cn(styles.tagTitle, {
				[styles.tagTitleSmallSize]: smallTitleSize,
			})}>
				{serverStatus &&
					<span className={cn(styles.serverStatus, {
						[styles.serverStatusSuccess]: dataServers && dataServers.fail && !Object.keys(dataServers?.fail).length
					})}></span>
				}
				{translate(tagName)}
			</div>
		</div>
	);
};

export default Tag;
