import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Fade, FormControl, InputAdornment, InputLabel, MenuItem, Select, Slide, TextField } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changeAnswerFilter, changeAnswerTypeFilter, changeCategoryFilter, changeQuestionFilter, clearAddingQuestion, clearEditingQuestion, clearQuestion, getQuestionsList, selectAddingQuestion, selectCategoriesList, selectEditingQuestion, selectQuestion, selectQuestionsList } from '../../../store/qasSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { QAS } from '../../../constants/accessRights';
import { INTERACTIVE, QUESTIONNAIRE } from '../../../constants/routes';
import { QAS_QUESTION_ID } from '../../../constants/cookieNames';
import { colorPrimary } from '../../../constants/colors';
import { IQuestionItem } from '../../../types/qasTypes';
import { AnswerFilterType } from '../../../types/questionTypes';
import { RequestStatus } from '../../../types/statusTypes';
import HideSidebar from '../HideSidebar/HideSidebar';
import FormAddingQuestion from '../../Forms/FormAddingQuestion/FormAddingQuestion';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import Notification from '../../Notification/Notification';
import { IQuestionNavbarProps } from './QuestionNavbar.props';
import styles from './QuestionNavbar.module.scss';

const QuestionNavbar = ({ questionHandler, showSidebar, setShowSidebar, setShowPage, filter, highlightMultipleQuestions, selectedListQuestionIds, setSelectedListQuestionIds }: IQuestionNavbarProps): JSX.Element => {
	const [selectCategoryId, setSelectCategoryId] = useState<string>(filter.category); // фильтр по категории
	const [searchQuestion, setSearchQuestion] = useState<string>(filter.question); // поиск по вопросам
	const [searchAnswer, setSearchAnswer] = useState<string>(filter.answer); // поиск по ответам
	const [selectAnswerType, setSelectAnswerType] = useState<AnswerFilterType>(filter.answerType); // фильтр по ответам
	// const [advancedSearchFlg, setAdvancedSearchFlg] = useState<boolean>(false); // флаг показа расширенного поиска
	const [showModal, setShowModal] = useState<boolean>(false); // показ формы добавления вопроса
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомлений
	const inputQuestionRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода вопроса
	const inputAnswerRef = useRef<HTMLInputElement>(null); // ссылка на поле для ввода ответа
	const listRef = useRef<HTMLDivElement>(null); // ссылка на список вопросов

	const dispatch = useAppDispatch();
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const questionItem = useAppSelector(selectQuestion); // store - вопрос/ответ
	const editStatus = useAppSelector(selectEditingQuestion); // store - статус сохранения ответов

	const navigate = useNavigate(); // hook для навигации
	const [cookies] = useCookies([QAS_QUESTION_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// функция фильтрации списка вопросов
	const filterFunction = (questionItem: IQuestionItem): boolean => {
		return (questionItem.category === selectCategoryId || selectCategoryId === '') &&
			((selectAnswerType === 'all' && (questionItem.checked >= 0 || questionItem.unchecked >= 0)) ||
				(selectAnswerType === 'checked' && questionItem.checked > 0) ||
				(selectAnswerType === 'unchecked' && questionItem.unchecked > 0) ||
				(selectAnswerType === 'empty' && (questionItem.checked === 0 && questionItem.unchecked === 0))
			);
	};

	// виртуализация списка вопросов
	const virtualizer = useVirtualizer({
		count: questionsList.data.filter(filterFunction).length,
		getScrollElement: () => listRef.current,
		estimateSize: () => 19,
		// overscan: 1,
	});

	const items = virtualizer.getVirtualItems();

	// следим за списком вопросов, фильтрацией по категории/типу ответов
	useEffect(() => {
		if (editStatus.autosave) {
			dispatch(clearEditingQuestion()); // очищаем статус (для режима автосохранения)
		} else {
			// если есть доступ и список вопросов не пустой
			if (isAccess(QAS.QUESTION_GET) && questionsList.data.filter(filterFunction).length > 0) {
				// если есть запись в cookie и список вопросов содержит эту запись, то передаем ее в обработчик выбора активного вопроса, иначе выбираем первый вопрос из списка
				cookies[QAS_QUESTION_ID] && questionsList.data
					.filter(filterFunction)
					.find(questionItem => questionItem.id === cookies[QAS_QUESTION_ID]) ?
					questionHandler(cookies[QAS_QUESTION_ID])
					:
					questionHandler(questionsList.data.filter(filterFunction)[0].id);
			} else {
				questionItem.questionId && dispatch(clearQuestion()); // иначе очищаем все данные о вопросе, если есть
			}
			selectedListQuestionIds.length > 0 && setSelectedListQuestionIds([]); // сброс выделенных вопросов
		}
	}, [questionsList.data, selectCategoryId, selectAnswerType]);

	// следим за полями поиска ответов/вопросов и флагом установки поиска по запросу
	useEffect(() => {
		// через пол-секунды бездействия после окончания ввода значения для фильтрации
		const handler = setTimeout(() => {
			// только если изменилось поле вопроса/ответа, либо хотя бы один символ, кроме пробела, либо пустое поле
			if ((searchQuestion !== filter.question && (/\S/.test(searchQuestion) || searchQuestion === '')) || (searchAnswer !== filter.answer && (/\S/.test(searchAnswer) || searchAnswer === ''))) {
				dispatch(changeAnswerFilter(searchAnswer)); // ставим фильтр по ответу в store
				dispatch(changeQuestionFilter(searchQuestion));  // ставим фильтр по вопросу в store

				dispatch(getQuestionsList({ answer: searchAnswer, question: searchQuestion })); // фильтруем
			}
		}, 500);

		return () => {
			clearTimeout(handler); // сбрасываем timeout, если продолжается ввод значения для фильтрации
		};
	}, [searchAnswer, searchQuestion]);

	// следим за активным вопросом
	useEffect(() => {
		const indexQuestion = questionsList.data.filter(filterFunction).findIndex(fileItem => fileItem.id === questionItem.questionId);
		virtualizer.scrollToIndex(indexQuestion, { align: 'center' }); // показ активного вопроса в центре списка с имеющейся полосой прокрутки
		// listRef.current?.children[questionsList.data.filter(filterFunction).findIndex(fileItem => fileItem.id === questionItem.questionId)]?.scrollIntoView({ block: "center" }); // показ активного вопроса в центре списка с имеющейся полосой прокрутки
	}, [questionItem.questionId]);

	// обработчик изменения checkbox (выделение вопросов)
	const checkboxChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		// если выбран checkbox - добавляем в список
		if (e.target.checked) setSelectedListQuestionIds(prevState => [...prevState, e.target.id]);
		// иначе убираем его из этого списка
		else setSelectedListQuestionIds(prevState => prevState.filter(id => id !== e.target.id));
	};

	// обработчик изменения checkbox (выделение всех вопросов)
	const checkboxAllChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		// если выбран checkbox - добавляем весь список
		if (e.target.checked) setSelectedListQuestionIds(questionsList.data.filter(filterFunction).map(question => question.id));
		// иначе очищаем
		else setSelectedListQuestionIds([]);
	};

	// обработчик выбора активного вопроса
	const questionSelectionHandler = (id: string) => {
		// если текущий id не равен id выбираемого вопроса
		if (id !== questionItem.questionId) {
			questionHandler(id); // запускаем обработчик получения вопроса
		}
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (link: string): void => {
		setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(link);
		}, 500);
	};

	return (
		<Slide direction="right" in={showSidebar} timeout={800} style={{ visibility: 'visible', zIndex: 10 }}>
			<div>
				<div className={styles.sidebar}>
					<HideSidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

					<div className={styles.navbar}>
						{/* поиск вопроса */}
						{(questionsList.status !== RequestStatus.FAILED) &&
							<div className={styles.navbarSearch}>
								<div className={styles.navbarSearchBlock}>
									<FormControl sx={{
										'.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 },
										width: '65%'
									}}>
										<InputLabel id="categoryFilter-label" sx={{ fontSize: 12 }}>{translate('select_category')}</InputLabel>
										<Select
											labelId="categoryFilter-label"
											id="categoryFilter"
											label={translate('select_category')}
											value={selectCategoryId}
											onChange={(e) => { setSelectCategoryId(e.target.value); dispatch(changeCategoryFilter(e.target.value)); }}
											style={{ fontSize: 12, height: 33, color: colorPrimary }}
										>
											<MenuItem value='' sx={{ fontSize: 12 }}>{translate('selectItem_notSelected')}</MenuItem>
											{categoriesList.data.map((category) => (
												<MenuItem key={category.id} value={category.id} sx={{ fontSize: 12, color: colorPrimary }}>{category.name}</MenuItem>
											))}
										</Select>
									</FormControl>
									<FormControl sx={{
										'.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 },
										width: '35%'
									}}>
										<InputLabel id="answerFilter-label" sx={{ fontSize: 12 }}>{translate('select_answers')}</InputLabel>
										<Select
											labelId="answerFilter-label"
											id="answerFilter"
											label={translate('select_answers')}
											value={selectAnswerType}
											onChange={(e) => { setSelectAnswerType(e.target.value as AnswerFilterType); dispatch(changeAnswerTypeFilter(e.target.value as AnswerFilterType)); }}
											style={{ fontSize: 12, height: 33, color: colorPrimary }}
										>
											<MenuItem value='all' sx={{ fontSize: 12, color: colorPrimary }}>{translate('selectItem_all')}</MenuItem>
											<MenuItem value='checked' sx={{ fontSize: 12, color: colorPrimary }}>{translate('selectItem_checked')}</MenuItem>
											<MenuItem value='unchecked' sx={{ fontSize: 12, color: colorPrimary }}>{translate('selectItem_unchecked')}</MenuItem>
											<MenuItem value='empty' sx={{ fontSize: 12, color: colorPrimary }}>{translate('selectItem_empty')}</MenuItem>
										</Select>
									</FormControl>
								</div>
								<FormControl fullWidth>
									<TextField
										inputRef={inputQuestionRef}
										id="questionSearch"
										label={translate("input_question")}
										variant="outlined"
										value={searchQuestion}
										onChange={(e) => setSearchQuestion(e.target.value)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end" >
													{searchQuestion.length > 0 &&
														<FontAwesomeIcon
															icon={faXmark}
															onClick={() => {
																if (questionsList.status !== RequestStatus.LOADING) {
																	setSearchQuestion('');
																	inputQuestionRef.current?.focus();
																}
															}}
															style={{ cursor: questionsList.status !== RequestStatus.LOADING ? 'pointer ' : 'not-allowed' }}
														/>
													}
												</InputAdornment>
											),
											style: {
												height: 33,
												fontSize: 12,
												color: colorPrimary,
											},
										}}
										InputLabelProps={{
											style: {
												fontSize: 12,
											},
										}}
										sx={{
											'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
											'.MuiInputBase-input': { padding: '0 14px' },
										}}
									/>
								</FormControl>
								<FormControl fullWidth>
									<TextField
										inputRef={inputAnswerRef}
										id="answerSearch"
										label={translate("input_answer")}
										variant="outlined"
										value={searchAnswer}
										onChange={(e) => setSearchAnswer(e.target.value)}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end" >
													{searchAnswer.length > 0 &&
														<FontAwesomeIcon
															icon={faXmark}
															onClick={() => {
																if (questionsList.status !== RequestStatus.LOADING) {
																	setSearchAnswer('');
																	inputAnswerRef.current?.focus();
																}
															}}
															style={{ cursor: questionsList.status !== RequestStatus.LOADING ? 'pointer ' : 'not-allowed' }}
														/>
													}
												</InputAdornment>
											),
											style: {
												height: 33,
												fontSize: 12,
												color: colorPrimary,
											},
										}}
										InputLabelProps={{
											style: {
												fontSize: 12,
											},
										}}
										sx={{
											'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
											'.MuiInputBase-input': { padding: '0 14px' },
										}}
									/>
								</FormControl>

								<div className={styles.navbarSearchCounter}>{translate('title_quantityQuestions')}: {questionsList.data.filter(filterFunction).length}</div>

								{/* выделение всех вопросов */}
								{highlightMultipleQuestions &&
									<label className={styles.navbarSearchSelectAll}>
										<input
											type="checkbox"
											checked={selectedListQuestionIds.length === questionsList.data.filter(filterFunction).length && selectedListQuestionIds.length !== 0}
											onChange={checkboxAllChangeHandler}
										/>
										{translate('checkbox_selectAll')} ({translate('title_selected')}: {selectedListQuestionIds.length})
									</label>
								}
							</div>
						}

						{/* ошибка загрузки списка вопросов */}
						{(questionsList.status === RequestStatus.FAILED || questionsList.message !== '') &&
							<div className={styles.navbarFailedText}>
								<span>{translate(questionsList.message || 'title_loadFailed')}</span>
								{isAccess(QAS.QUESTION_LIST) &&
									<span className={styles.navbarFailedUpdate} onClick={() => dispatch(getQuestionsList({ answer: filter.answer, question: filter.question }))}>{translate('link_update')}</span>
								}
							</div>
						}

						{/* пустой список вопросов */}
						{(questionsList.status === RequestStatus.IDLE || questionsList.update) && questionsList.data.filter(filterFunction).length === 0 &&
							<div className={styles.navbarNoQuestions}>{translate('title_emptyList')}</div>
						}

						{/* загрузка списка вопросов */}
						{questionsList.status === RequestStatus.LOADING && !questionsList.update &&
							<div className={styles.navbarLoading}>
								<ProgressCircle title={translate('spinnerTitle_loading')} />
							</div>
						}

						{/* список вопросов */}
						{(questionsList.status === RequestStatus.IDLE || questionsList.update) && questionsList.data.length > 0 &&
							<div className={styles.navbarQuestions} ref={listRef}>
								<div
									style={{
										height: `${virtualizer.getTotalSize()}px`,
										width: '100%',
										position: 'relative',
									}}
								>
									<div
										style={{
											position: 'absolute',
											top: 0,
											left: 0,
											width: '100%',
											transform: `translateY(${items[0]?.start ?? 0}px)`,
										}}
									>
										{items.map((virtualItem) => {
											const { question, id } = questionsList.data.filter(filterFunction)[virtualItem.index];
											return (
												<div
													key={id}
													data-index={virtualItem.index}
													ref={virtualizer.measureElement}
												>
													<div className={styles.navbarQuestionsItem}>
														<Fade in={questionsList.status !== RequestStatus.LOADING || (questionsList.data.length > 0)} timeout={500}>
															<div
																className={cn({
																	[styles.navbarQuestionsLink]: questionItem.questionId !== id,
																	[styles.navbarQuestionsLinkActive]: questionItem.questionId === id,
																})}
																onClick={() => isAccess(QAS.QUESTION_GET) && questionSelectionHandler(id)}
															>
																<div className={styles.navbarQuestionsLinkLeftBlock} title={question}>
																	{highlightMultipleQuestions &&
																		<input
																			type='checkbox'
																			id={id}
																			checked={selectedListQuestionIds.includes(id)}
																			onChange={checkboxChangeHandler}
																			onClick={e => e.stopPropagation()}
																		/>
																	}
																	<span>{question}</span>
																</div>
																<div className={cn(styles.navbarQuestionsLinkIcon, {
																	[styles.navbarQuestionsLinkIconActive]: questionItem.questionId === id,
																})}>
																	<FontAwesomeIcon icon={faAngleRight} />
																</div>
															</div>
														</Fade>
													</div>
												</div>
											);
										})}
									</div>
								</div>
							</div>
						}

						<div className={styles.functionButtons}>
							{/* добавление вопроса */}
							{isAccess(QAS.QUESTION_ADD) && (questionsList.status === RequestStatus.IDLE || questionsList.update) &&
								<Fade in={true} timeout={500}>
									<div className={styles.functionButtonsAddQuestion} onClick={() => setShowModal(true)} >
										{translate('link_addQuestion')}
									</div>
								</Fade>
							}
							{/* табы */}
							{(isAccess(QAS.DOC_LIST) || isAccess(QAS.SUMMARIZE)) &&
								<div className={styles.functionButtonsTabs}>
									{isAccess(QAS.DOC_LIST) &&
										<div
											className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
											onClick={() => delayToHidePage(QUESTIONNAIRE)}>
											{translate('tab_documents')}
										</div>
									}
									<div className={styles.functionButtonsTab}>{translate('tab_cache')}</div>
									{isAccess(QAS.SUMMARIZE) &&
										<div
											className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
											onClick={() => delayToHidePage(`${QUESTIONNAIRE}/${INTERACTIVE}`)}>
											{translate('tab_interactive')}
										</div>
									}
								</div>
							}
						</div>
					</div>
				</div>

				{showModal && <FormAddingQuestion showModal={showModal} setShowModal={setShowModal} setShowNotification={setShowNotification} />}
				{showNotification && <Notification showNotification={showNotification} setShowNotification={setShowNotification} selectDataResponse={selectAddingQuestion} clearDataResponse={clearAddingQuestion} titleFailed={translate('noticeAddition_failed')} titleSuccess={translate('noticeAddition_success')} />}
			</div>
		</Slide>
	);
};

export default QuestionNavbar;
