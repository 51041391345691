import { useState } from 'react';
import { Popover } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../store/hooks';
import { selectMetrics } from '../../../../store/transcriptionSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor, colorPrimary } from '../../../../constants/colors';
import { RequestStatus } from '../../../../types/statusTypes';
import ProgressCircle from '../../../ProgressCircle/ProgressCircle';
import { IMetricsProps } from './Metrics.props';
import styles from './Metrics.module.scss';

const Metrics = ({ isAvailable }: IMetricsProps): JSX.Element => {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null); // якорь

	const metrics = useAppSelector(selectMetrics); // метрики

	const translate = useTranslate(); // hook для перевода текста

	return (
		<>
			<button
				onClick={e => setAnchorEl(e.currentTarget)}
				disabled={!isAvailable}
				title={translate('buttonTitle_metrics')}
			>
				<FontAwesomeIcon icon={faChartLine} size="xl" color={backgroundColor} />
			</button>

			<Popover
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				sx={{
					'& .MuiPaper-root': {
						padding: '16px',
						color: colorPrimary,
					}
				}}
			>
				{metrics.status === RequestStatus.LOADING &&
					<div><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
				}

				{metrics.status === RequestStatus.FAILED &&
					<div>{translate(metrics.message || 'title_loadFailed')}</div>
				}

				{metrics.status === RequestStatus.IDLE && metrics.data &&
					<div className={styles.data}>
						{Object.entries(metrics.data).map(metric => (
							<div key={metric[0]}>{metric[0].toUpperCase()}: {Number(metric[1])?.toFixed(2)}%</div>
						))}
					</div>
				}
			</Popover>
		</>
	);
};

export default Metrics;
