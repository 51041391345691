import { FormEvent, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Button, ButtonGroup, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearTranscriptionList, createTranscription, getTranscriptionList, modificationTranscript, selectCreation, selectModify, selectTranscriptionList } from '../../../store/transcriptionSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { TRANSCRIPTION } from '../../../constants/accessRights';
import { SPR_TASK_ID } from '../../../constants/cookieNames';
import { colorPrimary } from '../../../constants/colors';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { IFormCreateTranscriptProps } from './FormCreateTranscript.props';

const FormCreateTranscript = ({ showModal, setShowModal, setShowNotificationCreate, setShowNotificationModify }: IFormCreateTranscriptProps): JSX.Element => {
	const [tab, setTab] = useState<'create' | 'modify'>('create'); // табы создания/замены
	const [transcriptName, setTranscriptName] = useState<string>(''); // название стенограммы для создания
	const [transcriptId, setTranscriptId] = useState<string>(''); // id стенограммы для замены

	const dispatch = useAppDispatch();
	const transcriptionList = useAppSelector(selectTranscriptionList); // store - список сохранненных записей распознавания
	const transcriptCreationStatus = useAppSelector(selectCreation); // store - статус создания стенограммы
	const transcriptModifyStatus = useAppSelector(selectModify); // store - статус создания стенограммы

	const [cookies] = useCookies([SPR_TASK_ID]); // hook для работы с cookie
	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	useEffect(() => {
		isAccess(TRANSCRIPTION.LIST) && dispatch(getTranscriptionList()); // получаем список сохраненных распознаваний
		!isAccess(TRANSCRIPTION.CREATE) && setTab('modify'); // если нет доступа к созданию - открываем форму замены

		// при закрытии
		return () => {
			dispatch(clearTranscriptionList()); // очищаем список стенограмм
		};
	}, []);

	// следим за статусом создания стенограммы
	useEffect(() => {
		if (transcriptCreationStatus.error !== ResponseStatus.SUCCESS || transcriptCreationStatus.status === RequestStatus.FAILED || transcriptCreationStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotificationCreate(true); // включаем уведомление
		}
	}, [transcriptCreationStatus]);

	// следим за статусом замены стенограммы
	useEffect(() => {
		if (transcriptModifyStatus.error !== ResponseStatus.SUCCESS || transcriptModifyStatus.status === RequestStatus.FAILED || transcriptModifyStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotificationModify(true); // включаем уведомление
		}
	}, [transcriptModifyStatus]);

	// обработчик создания стенограммы
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		tab === 'create' && dispatch(createTranscription({ transcriptName, taskId: cookies.sprTaskId })); // создаем стенограмму
		tab === 'modify' && dispatch(modificationTranscript({ transcriptId, taskId: cookies.sprTaskId })); // перезаписываем стенограмму
	};

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет создание/замена стенограммы - запрещаем покидать форму
		if (transcriptCreationStatus.status === RequestStatus.LOADING || transcriptModifyStatus.status === RequestStatus.LOADING) return;
		setShowModal(false);
	};

	return (
		<ModalFormWindow showModal={showModal} setShowModal={setShowModal} headerTitle='formHeader_creatingTranscript' close={handleClose}>
			<form onSubmit={(e) => submitHandler(e)}>
				{isAccess([TRANSCRIPTION.CREATE, TRANSCRIPTION.MODIFY]) &&
					<ButtonGroup fullWidth sx={{ marginBottom: '4px' }} disabled={transcriptCreationStatus.status === RequestStatus.LOADING || transcriptModifyStatus.status === RequestStatus.LOADING}>
						<Button
							variant={tab === 'create' ? "contained" : "outlined"}
							sx={{ width: '51%', overflow: 'hidden', fontSize: 11 }}
							onClick={() => setTab('create')}
						>
							{translate('button_creation')}
						</Button>
						<Button
							variant={tab === 'modify' ? "contained" : "outlined"}
							sx={{ width: '51%', overflow: 'hidden', fontSize: 11 }}
							onClick={() => setTab('modify')}
						>
							{translate('button_replacement')}
						</Button>
					</ButtonGroup>
				}

				{tab === 'create' &&
					<>
						<FormControl fullWidth margin='dense'>
							<TextField
								autoFocus
								required
								id="newTranscript"
								label={translate('input_name')}
								variant="outlined"
								disabled={transcriptCreationStatus.status === RequestStatus.LOADING}
								value={transcriptName}
								onChange={(e) => setTranscriptName(e.target.value)}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13
									},
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
						<FormControl fullWidth margin='dense'>
							<Button
								variant="outlined"
								type="submit"
								sx={{ fontSize: 11 }}
								disabled={transcriptCreationStatus.status === RequestStatus.LOADING}
							>
								{translate('button_create')}
								{transcriptCreationStatus.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
							</Button>
						</FormControl>
					</>
				}

				{tab === 'modify' &&
					<>
						<FormControl margin='dense' fullWidth sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, marginTop: '8px', '.MuiSelect-select': { paddingBlock: 0 }, }}>
							<InputLabel sx={{ fontSize: 13 }}>{translate('select_transcript')}</InputLabel>
							<Select
								label={translate('select_transcript')}
								value={transcriptId}
								required
								onChange={(e) => setTranscriptId(e.target.value)}
								disabled={transcriptModifyStatus.status === RequestStatus.LOADING}
								style={{ fontSize: 13, height: 33, color: colorPrimary }}
							>
								{Array.isArray(transcriptionList.data) && transcriptionList.data.map(({ id, name }) =>
									<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
								)}
							</Select>
						</FormControl>
						<FormControl fullWidth margin='dense'>
							<Button
								variant="outlined"
								type="submit"
								sx={{ fontSize: 11 }}
								disabled={transcriptModifyStatus.status === RequestStatus.LOADING}
							>
								{translate('button_replace')}
								{transcriptModifyStatus.status === RequestStatus.LOADING && <ProgressCircle isBtnDisabled />}
							</Button>
						</FormControl>
					</>
				}
			</form>
		</ModalFormWindow>
	);
};

export default FormCreateTranscript;
