import { useRef } from 'react';
import { LiveAudioVisualizer } from 'react-audio-visualize';
import { colorSecondary } from '../../constants/colors';
import styles from './AudioVisualizer.module.scss';

const AudioVisualizer = ({ mediaRecorder }: { mediaRecorder?: MediaRecorder }): JSX.Element => {
	const container = useRef<HTMLDivElement>(null); // ссылка на контейнер

	return (
		<div className={styles.container} ref={container}>
			{mediaRecorder && container.current &&
				<LiveAudioVisualizer
					mediaRecorder={mediaRecorder}
					width={container.current?.clientWidth}
					height={32}
					barWidth={1}
					gap={0}
					barColor={colorSecondary}
					maxDecibels={0}
				/>
			}
		</div>
	);
};

export default AudioVisualizer;
