import { useEffect, useState } from 'react';
import { Fade } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { clearState, getQueue, selectAudio, selectQueue, selectRecognitionAsyncData } from '../../store/sprSlice';
import { clearClusterServer, clearDataServers, getClusterServerModels, getDataServers } from '../../store/serverSlice';
import { selectImportStatus } from '../../store/modelSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SERVER, SERVICE, SPR } from '../../constants/accessRights';
import { RequestStatus } from '../../types/statusTypes';
import PageWrapper from '../../HOC/PageWrapper/PageWrapper';
import QueueNavbar from '../../components/Navbars/QueueNavbar/QueueNavbar';
import QueueControls from '../../components/Controls/QueueControls/QueueControls';
import SingleServerTag from '../../components/Tags/SingleServerTag/SingleServerTag';
import AudioPlayer from '../../components/AudioPlayer/AudioPlayer';
import ResultRecognition from '../../components/ResultRecognition/ResultRecognition';
import InfoRecognition from '../../components/ResultRecognition/InfoRecognition/InfoRecognition';
import Speakers from '../../components/ResultRecognition/Speakers/Speakers';
import ProgressCircle from '../../components/ProgressCircle/ProgressCircle';
import { IQueueProps } from './Queue.props';
import styles from './Queue.module.scss';

const Queue = ({ serviceType }: IQueueProps): JSX.Element => {
	const [showPage, setShowPage] = useState<boolean>(true); // показ страницы

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	const dispatch = useAppDispatch();
	const queue = useAppSelector(selectQueue); // store - очередь распознавания
	const recognitionAsyncData = useAppSelector(selectRecognitionAsyncData); // store - асинхронное распознавание речи
	const audio = useAppSelector(selectAudio); // store - аудио-файл асинхронного распознавания речи
	const importStatus = useAppSelector(selectImportStatus); // store - статус импортирования модели

	useEffect(() => {
		isAccess(SPR.QUEUE) && dispatch(getQueue()); // получаем очередь распознавания
		isAccess(SERVER.MODEL_LIST) && dispatch(getClusterServerModels({ serviceType })); // получаем продовые модели
		isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType })); // получаем данные о серверах

		// при уходе со страницы
		return () => {
			dispatch(clearState()); // очищаем state spr
			dispatch(clearDataServers()); // очищаем данные по серверам
			dispatch(clearClusterServer()); // очищаем список продовых моделей
		};
	}, []);

	// следим за статусом импорта модели 
	useEffect(() => {
		// автозапрос списка серверов каждые 30 сек
		const interval = setInterval(() => {
			isAccess(SERVER.ADDRESSES) && dispatch(getDataServers({ serviceType }));
		}, 30000);

		// если идет импорт - удаляем автозапрос получения данных о серверах
		importStatus.status === RequestStatus.LOADING && clearInterval(interval);

		// при уходе со страницы
		return () => {
			clearInterval(interval); // удаляем автозапрос получения данных о серверах
		};
	}, [importStatus.status]);

	return (
		<PageWrapper showPage={showPage} setShowPage={setShowPage} accessToService={SERVICE.SPR}>
			<>
				<QueueNavbar setShowPage={setShowPage} />
				<QueueControls serviceType={serviceType} setShowPage={setShowPage} />
				<SingleServerTag />

				<Fade in={true} timeout={700} style={{ transitionDelay: '500ms' }}>
					<div className={cn(styles.wrapper, {
						[styles.wrapperDirectionColumn]: recognitionAsyncData.data && typeof recognitionAsyncData.data === 'object' && !('speakers' in recognitionAsyncData.data)
					})}>
						{queue.status === RequestStatus.IDLE && queue.data !== null && Object.keys(queue.data).length > 0 ?
							<>
								<div className={styles.wrapperLeft}>
									{(audio.audioStatus !== RequestStatus.IDLE || audio.audioUrl) &&
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperAudioTrack}>
												<div>
													{(audio.audioStatus === RequestStatus.LOADING || audio.audioPeakStatus === RequestStatus.LOADING) &&
														<div><ProgressCircle title={translate('spinnerTitle_loading')} inLine /></div>
													}
													{audio.audioStatus === RequestStatus.FAILED && <p>{translate('title_loadFailed')}</p>}
													{audio.audioUrl && audio.audioPeakStatus !== RequestStatus.LOADING &&
														<AudioPlayer
															url={audio.audioUrl}
															sendTimestamp='recognitionAsync'
															timestamp={recognitionAsyncData.timestamp}
															timeline
															playbackSpeed
															peaks={audio.audioPeaks.length > 0 ? audio.audioPeaks : undefined}
															audioFormat='mp3'
														/>
													}
												</div>
											</div>
										</Fade>
									}

									{(recognitionAsyncData.data !== null || recognitionAsyncData.requestStatus !== RequestStatus.IDLE || recognitionAsyncData.responseStatus) &&
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperResultRecognition}>
												<ResultRecognition
													requestStatus={recognitionAsyncData.requestStatus}
													responseStatus={recognitionAsyncData.responseStatus}
													data={recognitionAsyncData.data}
													timestamp={recognitionAsyncData.timestamp}
													speakerList={recognitionAsyncData.speakerList}
												/>
											</div>
										</Fade>
									}
								</div>

								{recognitionAsyncData.data && typeof recognitionAsyncData.data === 'object' && 'created' in recognitionAsyncData.data &&
									<div className={cn({
										[styles.wrapperRight]: recognitionAsyncData.data && typeof recognitionAsyncData.data === 'object' && 'speakers' in recognitionAsyncData.data
									})}>
										<Fade in={true} timeout={500}>
											<div className={styles.wrapperInfo}>
												<div>
													<InfoRecognition modelName={'model' in recognitionAsyncData.data ? recognitionAsyncData.data.model : '-'} created={recognitionAsyncData.data.created} processed={recognitionAsyncData.data.processed} />
												</div>
											</div>
										</Fade>

										{'speakers' in recognitionAsyncData.data && Array.isArray(recognitionAsyncData.data.speakers) && recognitionAsyncData.data.speakers.length > 0 &&
											<Fade in={true} timeout={500}>
												<div className={styles.wrapperSpeakers}>
													<Speakers speakers={recognitionAsyncData.data.speakers} speakerNameList={recognitionAsyncData.speakerList} from='recognition' audioUrl={audio.audioUrl ? audio.audioUrl : undefined} />
												</div>
											</Fade>
										}
									</div>
								}
							</>
							:
							<div className={styles.noData}><div>{translate('title_noData')}</div></div>
						}
					</div>
				</Fade>
			</>
		</PageWrapper>
	);
};

export default Queue;
