import { useState } from 'react';
import useAccessRight from '../../../hooks/useAccessRight';
import { QAS, SERVER } from '../../../constants/accessRights';
import TagsWrapper from '../../../HOC/TagsWrapper/TagsWrapper';
import Categories from '../../Categories/Categories';
import Channels from '../../Channels/Channels';
import ServerStatus from '../../ServerStatus/ServerStatus';
import Tag from '../Tag/Tag';

const QuestionnarieTags = (): JSX.Element => {
	const [showCategories, setShowCategories] = useState<boolean>(false); // показ вкладки категорий
	const [showChannels, setShowChannels] = useState<boolean>(false); // показ вкладки каналов
	const [showServers, setShowServers] = useState<boolean>(false); // показ вкладки серверов

	const isAccess = useAccessRight(); // hook для проверки прав доступа

	return (
		<>
			<TagsWrapper>
				<>
					{isAccess(QAS.CATEGORY_LIST) &&
						<Tag setShowTag={setShowCategories} tagName='tag_categories' />
					}
					{isAccess(QAS.CHANNEL_LIST) &&
						<Tag setShowTag={setShowChannels} tagName='tag_channels' />
					}
					{isAccess(SERVER.ADDRESSES) &&
						<Tag setShowTag={setShowServers} tagName='tag_servers' serverStatus />
					}
				</>
			</TagsWrapper>

			<Categories showCategories={showCategories} setShowCategories={setShowCategories} />
			<Channels showChannels={showChannels} setShowChannels={setShowChannels} />
			<ServerStatus showServers={showServers} setShowServers={setShowServers} />
		</>
	);
};

export default QuestionnarieTags;
