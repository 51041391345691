import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { selectModelName } from '../../../../store/modelSlice';
import { clearStopTrainModelSmc, selectStopTrainModelSmc, stopTrainModelSmc } from '../../../../store/smcSlice';
import { clearStopTrainModelSee, selectStopTrainModelSee, stopTrainModelSee } from '../../../../store/seeSlice';
import useTranslate from '../../../../hooks/useTranslate';
import { backgroundColor } from '../../../../constants/colors';
import AlertDialog from '../../../AlertDialog/AlertDialog';
import Notification from '../../../Notification/Notification';
import { IStopTrainProps } from './StopTrain.props';

const StopTrain = ({ isAvailable, serviceType }: IStopTrainProps): JSX.Element => {
	const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false); // показ диалогового окна
	const [showNotification, setShowNotification] = useState<boolean>(false); // показ уведомления

	const translate = useTranslate(); // hook для перевода текста

	const dispatch = useAppDispatch();
	const modelName = useAppSelector(selectModelName); // store - имя активной модели

	// обработчик остановки обучения
	const stopTrainHandler = (): void => {
		if (modelName) {
			setShowNotification(true); // включаем уведомление
			setShowAlertDialog(false); // выключаем диалоговое окно
			serviceType === 'smc' && dispatch(stopTrainModelSmc({ modelName })); // останавливаем обучение в smc
			serviceType === 'see' && dispatch(stopTrainModelSee({ modelName })); // останавливаем обучение в see
		}
	};

	return (
		<>
			<button
				onClick={() => setShowAlertDialog(true)}
				disabled={!isAvailable}
				title={translate('buttonTitle_stopTraining')}>
				<FontAwesomeIcon icon={faStop} size="xl" color={backgroundColor} />
			</button>

			{showNotification &&
				<Notification
					showNotification={showNotification}
					setShowNotification={setShowNotification}
					selectDataResponse={serviceType === 'smc' ? selectStopTrainModelSmc : selectStopTrainModelSee}
					clearDataResponse={serviceType === 'smc' ? clearStopTrainModelSmc : clearStopTrainModelSee}
					titleFailed='noticeStop_failed'
					titleSuccess='noticeStop_success'
				/>
			}

			<AlertDialog
				showAlertDialog={showAlertDialog}
				setShowAlertDialog={setShowAlertDialog}
				submitHandler={stopTrainHandler}
				title='dialog_stopTrain'
				description='dialog_stopTrainConfirm'
				name={modelName || ''}
			/>
		</>
	);
};

export default StopTrain;
