import { FormEvent, memo, useEffect, useState } from 'react';
import { Button, FormControl, Popover, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import cn from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { changePhraseInActionDataElem, changePhraseInActionEndpoint, changePhraseInActionEndpointEntry, deletePhraseInActionDataElem, deletePhraseInActionEndpoint, deletePhraseInActionEndpointEntry } from '../../../store/sesSlice';
import { changePhraseInActionRobot, changePhraseInException, deletePhraseInActionRobot, deletePhraseInException, selectActiveRobotVersion } from '../../../store/sesRobotSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { SES } from '../../../constants/accessRights';
import { colorPrimary, colorRed, colorSecondary } from '../../../constants/colors';
import { IPhraseProps } from './Phrase.props';
import styles from './Phrase.module.scss';

const Phrase = ({ phrase, idxPhrase, arrayPhrases, channel, idxAction, changeFlg, setChangeFlg, actionFor }: IPhraseProps): JSX.Element => {
	const [buttonsInPhrase, setButtonsInPhrase] = useState<string[] | null>(phrase.match(/{button:.+?=.+?}/g)); // кнопки во фразе
	const [inputPhrase, setInputPhrase] = useState<string>(phrase.replace(/{button:.+?=.+?}/g, '')); // голая фраза
	const [maxRows, setMaxRows] = useState<number>(2); // max кол-во строк для показа фразы

	const [anchorForButtonForm, setAnchorForButtonForm] = useState<HTMLElement | null>(null); // якорь для формы добавления/изменения кнопки
	const [buttonChangeFlg, setButtonChangeFlg] = useState<{ isChange: boolean, originalTemplate: string }>({ isChange: false, originalTemplate: '' }); // флаг изменения кнопки
	const [inputButtonName, setInputButtonName] = useState<string>(''); // название кнопки
	const [inputMessageToSend, setInputMessageToSend] = useState<string>(''); // отправляемый текст

	const [anchorForContextMenu, setAnchorForContextMenu] = useState<HTMLDivElement | null>(null); // якорь для контекстного меню

	const dispatch = useAppDispatch();
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за кнопками во фразе
	useEffect(() => {
		Array.isArray(buttonsInPhrase) && handlerSavingPhraseToStore(inputPhrase); // сохранение
	}, [buttonsInPhrase]);

	// обработчик сохранения фразы в store
	const handlerSavingPhraseToStore = (inputText: string): void => {
		setMaxRows(2);
		const phraseWithButtons = Array.isArray(buttonsInPhrase) ? inputText + buttonsInPhrase.join('') : inputText; // полная фраза с шаблоном кнопок
		// если были изменения
		if (phraseWithButtons !== phrase) {
			if (actionFor.for === 'robotException') {
				dispatch(changePhraseInException({
					exception: actionFor.exception,
					channel,
					idxAction,
					idxPhrase,
					text: phraseWithButtons,
				})); // изменяем фразу в действии исключения робота
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (actionFor.for === 'endpointEntry') {
				dispatch(changePhraseInActionEndpointEntry({
					channel,
					idxAction,
					idxPhrase,
					text: phraseWithButtons,
				})); // изменяем фразу в действии входа КТ
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else {
				actionFor.for === 'robotEvent' && dispatch(changePhraseInActionRobot({
					actionEvent: actionFor.event,
					channel,
					idxAction,
					idxPhrase,
					text: phraseWithButtons,
				})); // изменяем фразу в действии события робота
				actionFor.for === 'dataElement' && dispatch(changePhraseInActionDataElem({
					actionEvent: actionFor.event,
					channel,
					idxAction,
					idxPhrase,
					text: phraseWithButtons,
				})); // изменяем фразу в действии ЭД
				actionFor.for === 'endpoint' && dispatch(changePhraseInActionEndpoint({
					channel,
					idxAction,
					idxPhrase,
					text: phraseWithButtons,
				})); // изменяем фразу в действии КТ
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		}
	};

	// обработчик удаления фразы
	const phraseDeletionHandler = (): void => {
		if (actionFor.for === 'robotException') {
			dispatch(deletePhraseInException({
				exception: actionFor.exception,
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в исключении робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(deletePhraseInActionEndpointEntry({
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в действии входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(deletePhraseInActionRobot({
				actionEvent: actionFor.event,
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в действии робота
			actionFor.for === 'dataElement' && dispatch(deletePhraseInActionDataElem({
				actionEvent: actionFor.event,
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в действии ЭД
			actionFor.for === 'endpoint' && dispatch(deletePhraseInActionEndpoint({
				channel,
				idxAction,
				idxPhrase,
			})); // удаляем фразу в действии КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик контекстного меню
	const contextMenuHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
		// если есть доступ
		if ((((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && arrayPhrases.length > 1 && activeRobotVersion === 'draft') {
			e.preventDefault();
			setAnchorForContextMenu(e.currentTarget); // ставим якорь у элемента для контекстного меню
			anchorForButtonForm && setAnchorForButtonForm(null); // закрытие формы изменения кнопки, если была открыта
		}
	};

	// обработчик открытия формы редактирования кнопки
	const buttonEditFormOpenHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, buttonCode: string, buttonName: string, messageToSend: string): void => {
		// если есть доступ
		if ((((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft') {
			setButtonChangeFlg({ isChange: true, originalTemplate: buttonCode }); // ставим флаг изменения кнопки
			// заполняем поля
			setInputButtonName(buttonName);
			setInputMessageToSend(messageToSend);
			setAnchorForButtonForm(e.currentTarget); // открываем форму
		}
	};

	// обработчик вставки/редактирования кнопки во фразе
	const buttonInsertHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const buttonTemplate = `{button:${inputButtonName}=${inputMessageToSend}}`; // шаблон кнопки
		setAnchorForButtonForm(null); // закрываем форму
		// если изменение
		if (buttonChangeFlg.isChange) {
			setButtonsInPhrase(prev => {
				if (Array.isArray(prev)) return prev.map(buttonCode => {
					if (buttonCode === buttonChangeFlg.originalTemplate) return buttonTemplate;
					else return buttonCode;
				});
				else return [buttonTemplate];
			}); // изменяем кнопку в state
		} else {
			setButtonsInPhrase(prev => {
				if (Array.isArray(prev)) return [...prev, buttonTemplate];
				else return [buttonTemplate];
			}); // добавляем кнопку в state
		}
	};

	// обработчик удаления кнопки из фразы
	const buttonDeleteHandler = (): void => {
		setAnchorForButtonForm(null); // закрываем форму
		setButtonsInPhrase(prev => {
			if (Array.isArray(prev)) return prev.filter(buttonCode => buttonCode !== buttonChangeFlg.originalTemplate);
			else return prev;
		}); // удаляем кнопку из state
	};

	return (
		<div className={cn(styles.phraseBlock, {
			[styles.phraseBlockOpacity]: arrayPhrases.length - 1 === idxPhrase && (inputPhrase === '' && !buttonsInPhrase) && arrayPhrases.length > 1,
		})}
			onContextMenu={contextMenuHandler}
		>
			{Array.isArray(buttonsInPhrase) ?
				<fieldset className={cn(styles.phraseBlockWithButtons, {
					[styles.phraseBlockWithButtonsFocus]: maxRows === 5,
					[styles.phraseBlockWithButtonsActive]: anchorForContextMenu,
				})}>
					<legend>{`${translate('input_phrase')} ${idxPhrase + 1}`}</legend>
					<FormControl fullWidth>
						<TextField
							// required={arrayPhrases.length - 1 !== idxPhrase || arrayPhrases.length === 1}
							multiline
							disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							variant="outlined"
							maxRows={maxRows}
							value={inputPhrase}
							onChange={(e) => setInputPhrase(e.target.value)}
							onBlur={(e) => handlerSavingPhraseToStore(e.target.value)}
							onFocus={() => setMaxRows(5)}
							InputProps={{
								style: {
									padding: '0',
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{
								'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
								'.MuiOutlinedInput-notchedOutline': { border: 'none' }
							}}
						/>
					</FormControl>
					{/* блок кнопок */}
					<div className={styles.buttonBlock}>
						{buttonsInPhrase.map((buttonCode) => {
							const endIndexName = buttonCode.indexOf('=');
							const buttonName = buttonCode.slice(8, endIndexName);
							const messageToSend = buttonCode.slice(endIndexName + 1, -1);
							return (
								<button
									key={buttonCode}
									className={styles.button}
									type='button'
									onClick={e => buttonEditFormOpenHandler(e, buttonCode, buttonName, messageToSend)}
								>
									{buttonName}
								</button>
							);
						})}
					</div>
				</fieldset>
				:
				<FormControl fullWidth margin='dense'>
					<TextField
						// required={arrayPhrases.length - 1 !== idxPhrase || arrayPhrases.length === 1}
						multiline
						disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						label={`${translate('input_phrase')} ${idxPhrase + 1}`}
						variant="outlined"
						maxRows={maxRows}
						value={inputPhrase}
						onChange={(e) => setInputPhrase(e.target.value)}
						onBlur={(e) => handlerSavingPhraseToStore(e.target.value)}
						onFocus={() => setMaxRows(5)}
						InputProps={{
							style: {
								padding: '8px 13px',
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{
							'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
							'.MuiOutlinedInput-notchedOutline': { border: anchorForContextMenu ? `1px solid ${colorSecondary}` : undefined },
						}}
					/>
				</FormControl>
			}

			{/* контекстное меню */}
			<Popover
				anchorEl={anchorForContextMenu}
				open={Boolean(anchorForContextMenu)}
				onClose={() => setAnchorForContextMenu(null)}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'center',
					horizontal: 'center',
				}}
				sx={{
					'& .MuiPaper-root': {
						padding: '10px 16px',
						// width: '300px',
						color: colorPrimary,
					},
				}}
			>
				<div className={styles.contextMenuItem} onClick={e => setAnchorForButtonForm(e.currentTarget)}>
					<FontAwesomeIcon
						icon={faSquarePlus}
						color={colorPrimary}
						size='lg'
					/>
					{translate('buttonTitle_addButton')}
				</div>
				{arrayPhrases.length - 1 !== idxPhrase &&
					<div className={styles.contextMenuItem} onClick={phraseDeletionHandler}>
						<FontAwesomeIcon
							icon={faTrashCan}
							color={colorRed}
							size='lg'
						/>
						{translate('buttonTitle_deletePhrase')}
					</div>
				}
			</Popover>

			{/* добавление/изменение/удаление кнопки */}
			<Popover
				anchorEl={anchorForButtonForm}
				open={Boolean(anchorForButtonForm)}
				onClose={() => {
					setInputButtonName('');
					setInputMessageToSend('');
					setButtonChangeFlg({ isChange: false, originalTemplate: '' }); // сброс флага изменения
					setAnchorForButtonForm(null);
				}}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				sx={{
					'& .MuiPaper-root': {
						padding: '8px 12px',
						width: '300px',
						color: colorPrimary,
					}
				}}
			>
				<form onSubmit={buttonInsertHandler}>
					<FormControl fullWidth margin='dense'>
						<TextField
							required
							label={translate('input_name')}
							variant="outlined"
							value={inputButtonName}
							onChange={(e) => setInputButtonName(e.target.value)}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					<FormControl fullWidth margin='dense'>
						<TextField
							required
							label={translate('input_textToSend')}
							variant="outlined"
							value={inputMessageToSend}
							onChange={(e) => setInputMessageToSend(e.target.value)}
							InputProps={{
								style: {
									height: 33,
									fontSize: 13,
									color: colorPrimary,
								},
							}}
							InputLabelProps={{
								style: {
									fontSize: 13,
								},
							}}
							sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
						/>
					</FormControl>
					{buttonChangeFlg.isChange ?
						<div style={{ display: 'flex', gap: '12px' }}>
							<FormControl fullWidth margin='dense'>
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									type='submit'
								>
									{translate('button_save')}
								</Button>
							</FormControl>
							<FormControl fullWidth margin='dense'>
								<Button
									variant="outlined"
									sx={{ fontSize: 11 }}
									type='button'
									color='error'
									onClick={buttonDeleteHandler}
								>
									{translate('button_delete')}
								</Button>
							</FormControl>
						</div>
						:
						<FormControl fullWidth margin='dense'>
							<Button
								variant="outlined"
								sx={{ fontSize: 11 }}
								type='submit'
							>
								{translate('button_add')}
							</Button>
						</FormControl>
					}
				</form>
			</Popover>
		</div>
	);
};

export default memo(Phrase);
