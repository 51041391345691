import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Fade, Slide } from '@mui/material';
import cn from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearMetrics, clearTranscriptionAudio, clearTranscriptionData, getAudioPeaks, getMetrics, getTranscriptionAudio, getTranscriptionData, getTranscriptionList, selectTranscriptionAudio, selectTranscriptionList } from '../../../store/transcriptionSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { RECOGNIZER } from '../../../constants/routes';
import { SERVICE, SPR, TRANSCRIPTION } from '../../../constants/accessRights';
import { SPR_RECORD_ID } from '../../../constants/cookieNames';
import { RequestStatus } from '../../../types/statusTypes';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import { ITranscriptNavbarProps } from './TranscriptNavbar.props';
import styles from './TranscriptNavbar.module.scss';

const TranscriptNavbar = ({ activeRecordId, setActiveRecordId, setChangeFlg, setShowPage }: ITranscriptNavbarProps): JSX.Element => {
	const [promiseGetAudio, setPromiseGetAudio] = useState<Promise<any> | null>(null); // промис для отмены запроса получения аудио-файла
	const listRef = useRef<HTMLUListElement | null>(null); // ссылка на список сохраненных записей

	const dispatch = useAppDispatch();
	const transcriptionList = useAppSelector(selectTranscriptionList); // store - список сохранненных записей распознавания
	const audio = useAppSelector(selectTranscriptionAudio); // store - аудио-файл сохраненной записи распознавания

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const navigate = useNavigate(); // hook для навигации
	const translate = useTranslate(); // hook для перевода текста
	const [cookies, setCookie] = useCookies([SPR_RECORD_ID]); // hook для работы с cookie

	// следим за промисом
	useEffect(() => {
		// при уходе со страницы останавливаем запрос аудио-файла, если был в процессе
		return () => {
			// @ts-ignore
			promiseGetAudio && promiseGetAudio.abort();
		};
	}, [promiseGetAudio]);

	// следим за данными списка сохраненных записей
	useEffect(() => {
		// только если список записей не пустой
		if (transcriptionList.status === RequestStatus.IDLE && Array.isArray(transcriptionList.data) && transcriptionList.data.length > 0) {
			// если есть запись в cookie и список записей содержит эту запись, то передаем ее в обработчик выбора активной записи, иначе выбираем первую запись из списка записей
			cookies.sprRecordId && transcriptionList.data.find(record => record.id === cookies.sprRecordId) ?
				recordHandler(cookies.sprRecordId)
				:
				recordHandler(transcriptionList.data[0].id);
		}
	}, [transcriptionList]);

	// следим за статусом загрузки аудио-файла
	useEffect(() => {
		// если закончилась загрузка аудио - сбрасываем промис для отмены запроса
		audio.audioStatus !== RequestStatus.LOADING && setPromiseGetAudio(null);
		// если ошибка (случай с отменой запроса) - запрашиваем другой аудио-файл
		audio.audioStatus === RequestStatus.FAILED && recordHandler(activeRecordId);
	}, [audio.audioStatus]);

	// следим за активной записью распознавания
	useEffect(() => {
		Array.isArray(transcriptionList.data) && listRef.current?.children[transcriptionList.data.findIndex(record => record.id === activeRecordId)]?.scrollIntoView({ block: "center" }); // показ активной сохраненной записи в центре списка с имеющейся полосой прокрутки
	}, [activeRecordId]);

	// обработчик выбора активной записи
	const recordHandler = (id: string): void => {
		setActiveRecordId(id); // устанавливаем id активной записи
		// если сохранен промис для отмены запроса
		if (promiseGetAudio) {
			// @ts-ignore
			promiseGetAudio.abort(); // прерываем запрос
			setPromiseGetAudio(null); // обнуляем промис
			return;
		}
		setChangeFlg(false); // сбрасываем флаг о несохраненных данных стенограммы
		dispatch(clearTranscriptionData()); // очищаем данные сохраненной записи распознавания
		dispatch(clearTranscriptionAudio()); // очищаем аудио сохраненной записи распознавания
		dispatch(clearMetrics()); // очищаем данные метрик
		isAccess(TRANSCRIPTION.WAVEFORM) && dispatch(getAudioPeaks(id)); // получаем пики аудио-файла
		if (isAccess(TRANSCRIPTION.GET_AUDIO)) {
			const promise = dispatch(getTranscriptionAudio(id)); // получаем аудио-файл сохраненной записи распознавания
			setPromiseGetAudio(promise); // устанавливаем промис для случая с отменой запроса
		}
		isAccess(TRANSCRIPTION.GET_DATA) && dispatch(getTranscriptionData(id)); // получаем данные сохраненной записи распознавания
		isAccess(TRANSCRIPTION.METRICS) && dispatch(getMetrics(id)); // получаем данные метрик
		setCookie(SPR_RECORD_ID, id, { path: '/', maxAge: 2_592_000 }); // устанавливаем cookie на месяц
	};

	// задержка для перехода на другую страницу
	const delayToHidePage = (route: string): void => {
		setShowPage && setShowPage(false); // уводим страницу в фон
		setTimeout(() => {
			navigate(route);
		}, 500);
	};

	return (
		<Slide direction="right" in={true} mountOnEnter unmountOnExit timeout={800}>
			<div className={styles.sidebar}>
				<div className={styles.navbar}>
					{/* ошибка загрузки списка сохраненных записей распознаваний */}
					{(transcriptionList.status === RequestStatus.FAILED) &&
						<div className={styles.navbarFailedText}>
							<span>{translate(transcriptionList.message || 'title_loadFailed')}</span>
							{isAccess(TRANSCRIPTION.LIST) && <span className={styles.navbarFailedUpdate} onClick={() => dispatch(getTranscriptionList())}>{translate('link_update')}</span>}
						</div>
					}

					{/* пустой список сохраненных записей */}
					{(transcriptionList.status === RequestStatus.IDLE && Array.isArray(transcriptionList.data) && transcriptionList.data.length === 0) &&
						<div className={styles.navbarNoRecords}>{translate('title_emptyList')}</div>
					}

					{/* загрузка списка сохраненных записей */}
					{(transcriptionList.status === RequestStatus.LOADING) &&
						<div className={cn(styles.navbarLoading, {
							[styles.navbarLoadingShort]: isAccess(SERVICE.SPR) || isAccess([SERVICE.SPR, SPR.QUEUE, SPR.ASYNC_RESULT, SPR.AUDIO]),
						})}><ProgressCircle title={translate('spinnerTitle_loading')} /></div>
					}

					{/* список сохраненных записей */}
					<ul className={styles.navbarRecordList} ref={listRef}>
						{Array.isArray(transcriptionList.data) && transcriptionList.data.map((record) => (
							<li
								className={styles.navbarRecord}
								key={record.id}
							>
								<Fade in={transcriptionList.status !== RequestStatus.LOADING || transcriptionList.data !== null} timeout={500}>
									<div
										className={cn({
											[styles.navbarRecordLink]: activeRecordId !== record.id,
											[styles.navbarRecordLinkActive]: activeRecordId === record.id,
										})}
										onClick={() => record.id !== activeRecordId && recordHandler(record.id)}
									>
										<div className={styles.navbarRecordLinkLeftBlock} title={record.name}>{record.name}</div>
										<FontAwesomeIcon icon={faAngleRight} />
									</div>
								</Fade>
							</li>
						))}
					</ul>

					<div className={styles.functionButtons}>
						{/* табы */}
						{isAccess(SERVICE.SPR) &&
							<div className={styles.functionButtonsTabs}>
								{isAccess(SERVICE.SPR) &&
									<div
										className={cn(styles.functionButtonsTab, styles.functionButtonsTabNonActive)}
										onClick={() => delayToHidePage(RECOGNIZER)}>
										{translate('tab_queue')}
									</div>
								}
								<div className={styles.functionButtonsTab}>{translate('tab_transcripts')}</div>
							</div>
						}
					</div>
				</div>
			</div>
		</Slide>
	);
};

export default TranscriptNavbar;
